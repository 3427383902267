import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Space,
  Upload,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import "./Document.scss";
import {
  FilePdfOutlined,
  CheckCircleFilled,
  CloseSquareOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import { profileService } from "../../../Services/ProfileServices";
import TextAreaFloatLabel from "../../Common/FloatLabel/TextArea";
import Cookies from "js-cookie";
import FileView from "../../../Utils/FileView";
import { AssignmentApiService } from "../../../Services/AssignmentServices";
import { dynamicErrorMsg } from "../../../Constants/utilitis";
const Document = () => {
  const [documentForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [heading, setHeading] = useState(null);
  const [editData, setEditData] = useState(null);
  const [view, setView] = useState(false);
  const [staffId, setStaffId] = useState(
    Cookies.get("userId") ? Cookies.get("userId") : null
  );

  useEffect(() => {
    if (editData?.id) {
      documentForm.setFieldsValue({
        description: editData?.description,
        docType: editData?.docType,
        staffId: staffId,
      });
    } else {
      documentForm.resetFields();
    }
  }, [editData]);

  useEffect(() => {
    getStaffIdentityList();
  }, []);

  const getStaffIdentityList = () => {
    profileService
      .staffIdentityList(staffId)
      .then((res) => {
        setDocumentList(res?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const OnFinish = (values) => {
    const id = editData?.id;

    const formData = new FormData();

    formData.append("docType", values.docType);
    formData.append("staffId", staffId);
    formData.append("description", values.description);
    fileList.map((file, i) => {
      formData.append(`supportingIdentityDocs`, file);
    });
    if (id) {
      profileService
        .staffIdentityEdit(id, formData)
        .then((res) => {
          notification.success({
            message: `Document Uploaded Successfully `,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          getStaffIdentityList();
          setShowModal(false);
          setEditData(null);
          documentForm.resetFields();
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Update", "Staff Identity")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    } else {
      profileService
        .staffIdentityCreate(formData)
        .then((res) => {
          notification.success({
            message: `Document Updated Successfully `,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          getStaffIdentityList();
          setShowModal(false);
          setEditData(null);
          documentForm.resetFields();
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Create", "Staff Identity")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });;
        });
    }
  };
  const handleUpload = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList(files);
  };

  const hnadleShowModal = () => {
    setShowModal(true);
  };
  const handleCancel = () => {
    setShowModal(false);
    documentForm.resetFields();
  };

  const downloadQuestionPaper = (url, document) => {
    setView(true);
    setFileData(document?.docUrls);
    setHeading(document.docType);
  };
  const handleEdit = (record) => {
    setShowModal(true);
    setEditData(record);
  };

  const handleDeleteFile = (url, index) => {
    const assignmentId = editData?.docType;
    const params = {
      url: url,
    };
    AssignmentApiService.deleteIndividualFile(
      assignmentId,
      params
    )((res) => {
      getStaffIdentityList();
      notification.success({
        message: `Delete Document Sucessfully`,
        placement: "bottomRight",
        icon: (
          <CheckCircleFilled
            style={{
              color: "#108ee9",
            }}
          />
        ),
      });
      if (editData) {
        const updatedSupportDocUrls = [...editData.docUrls];
        updatedSupportDocUrls.splice(index, 1);
        setEditData((prevRow) => ({
          ...prevRow,
          docUrls: updatedSupportDocUrls,
        }));
      }
      setFileList([]);
      getStaffIdentityList();
    }).catch((err) => {
      notification.error({
        message: `Delete Document Failed, try again later`,
        icon: (
          <DislikeOutlined
            style={{
              color: "red",
            }}
          />
        ),
        duration: 3,
        placement: "bottomRight",
      });
    });
  };
  return (
    <div className="document-container">
      <header>
        <h2>Documents</h2>
        <Button className="blue-button" onClick={hnadleShowModal}>
          Create{" "}
        </Button>
      </header>
      <article className="list-container">
        {documentList.map((document, index) => (
          <div className="cards" key={index}>
            {/* <h1 className="h1">Document ID : {document.id}</h1> */}
            <p>
              <b>Document Type :</b> {document.docType}
            </p>
            <p>
              <b>Description : </b>
            </p>
            <p className="dec">{document.description}</p>
            <div>
              <p>
                <b>Files : </b>
              </p>
              <ul>
                {document.docUrls.map((url, i) => (
                  <li key={i}>
                    {url && (
                      <>
                        <span>
                          <FilePdfOutlined
                            style={{
                              marginTop: 10,
                              cursor: "pointer",
                              fontSize: 40,
                            }}
                            onClick={() => downloadQuestionPaper(url, document)}
                          />
                          &nbsp;
                        </span>
                      </>
                    )}
                  </li>
                ))}
              </ul>
              <div className="footer">
                <Button onClick={() => handleEdit(document)}>Edit</Button>
              </div>
            </div>
          </div>
        ))}
      </article>
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />
      <Modal
        onCancel={handleCancel}
        open={showModal}
        title={
          editData && Object.keys(editData).length > 0
            ? "Document Upload"
            : "Edit Documents"
        }
        footer={null}
      >
        <Form form={documentForm} name="documentForm" onFinish={OnFinish}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item name="docType">
                <InputFloatLabel label="Document Type" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item name="supportingIdentityDocs">
                <Upload
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  onChange={handleUpload}
                  multiple
                  className="upload"
                >
                  <Button className="upload">Identity Documents</Button>
                </Upload>
              </Form.Item>
              {/* {editData && Object.keys(editData).length > 0 ? (
                <div className="file_uploading-icon">
                  {editData.docUrls.map((url, index) => {
                    let fileIcon;

                    return (
                      <div key={index} className="file-wrapper">
                        <FilePdfOutlined
                          style={{ fontSize: "30px", color: "black" }}
                          onClick={() => handleFileView(url, editData)}
                        />
                        <CloseSquareOutlined
                          onClick={() => handleDeleteFile(url, index)}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )} */}
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item name="description">
                <TextAreaFloatLabel
                  label="Description"
                  // id="inputs"
                  rows={12}
                  maxLength={100}
                  autoSize={{ minRows: 4, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Document;
