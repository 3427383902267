import React, { useState, useEffect } from "react";
import "./HolidayTabs.scss";
import HolidayList from "../HolidayList/HolidayList";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";
import { HolidaysService } from "../../../Services/HolidaysService";
import { createErrorMessage, updateErrorMessage, deleteErrorMessage, dynamicErrorMsg } from "../../../Constants/utilitis";

import moment from "moment";
import { Form, Select, Calendar, Tooltip, Spin, notification } from "antd";
import { Modal } from "antd";

import TextAreaFloatLabel from "../../Common/FloatLabel/TextArea";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import DatePickerFloatLabel from "../../Common/FloatLabel/DatePicker";
import { DislikeOutlined } from "@ant-design/icons";

const { Option } = Select;

const getMonthData = (value) => {
  if (value.month() === 8) {
    return 1394;
  }
};

const Holidays = () => {
  const [Holidaysform] = Form.useForm();

  const [holidays, setHolidays] = useState([]);
  const [onHoverDate, setIsonHoverDate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [totalCountofHolidays, setTotalCountofHolidays] = useState();
  const [hoveredDate, setHoveredDate] = useState(null);
  const [loadingHolidays, setLoadingHolidays] = useState(true);
  const [loadingCallender, setLoadingCallender] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dateRender = (current) => {
    const formattedDate = moment(current).format("YYYY-MM-DD");
    const isHoliday = holidays.find((h) => h.date === formattedDate);
    if (isHoliday) {
      return (
        <div
          className="ant-picker-cell-inner"
          style={{ backgroundColor: "red" }}
        >
          <div>{current.date()}</div>
          <span
            onMouseEnter={() => setIsonHoverDate(moment("YYYY-MM-DD"))}
          ></span>
        </div>
      );
    } else {
      return <div className="ant-picker-cell-inner">{current.date()}</div>;
    }
  };

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      holidayDate: values.holidayDate.format("YYYY-MM-DD HH:mm:ss"),
    };

    //post api call
    HolidaysService.createHoliday(payload)
      .then((response) => {})
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Create", "Holiday")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const GetHolidaysData = () => {
    HolidaysService.listOfHolidays()
      .then((response) => {
        setLoading(false);
        setHolidays(response.data.data);

        setTotalCountofHolidays(response.data.recordCount);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Holidays")
      });
  };

  //Get all Holidays
  useEffect(() => {
    GetHolidaysData();
  }, []);

  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateCellRender = (date) => {
    const holiday = holidays.find((holiday) =>
      holiday.holidayDate.includes(date.format("YYYY-MM-DD"))
    );

    if (holiday) {
      return (
        <Tooltip
          title={
            <div className="toolTip">
              <p>Holiday Name:&nbsp;{holiday.holidayName}</p>
              <p>Observance:&nbsp;{holiday.observance}</p>
              <p>Description:&nbsp;{holiday.description}</p>
              <p>Holiday Type:&nbsp;{holiday.holidayType}</p>
            </div>
          }
        >
          <div className="calender-texts">
            <span>{holiday.holidayName}</span>
            <span className="type">{holiday.holidayType}</span>
          </div>
        </Tooltip>
      );
    }
  };
  console.log("dateCellRender", holidays);

  const holidaysLength = holidays.length;

  const noDataFunction = () => {
    setLoading(false);
  };

  const formatHolidayDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();

    const month = date.toLocaleString("default", { month: "long" }).slice(0, 3);
    return `${day} ${month}`;
  };

  return (
    <>
      <div>
        <div className="tabholidayview">
          <section>
            <div className="calender_div">
              {loading ? (
                <div className="loadingCallenderCss">
                  <Spin size="large" />
                  <p>{noDataFunction()}</p>
                </div>
              ) : holidaysLength > 0 ? (
                // <div>
                //   <Calendar
                //     dateCellRender={dateCellRender}
                // monthCellRender={monthCellRender}
                // picker="month"
                //     className="custom-calendar"
                //   />
                // </div>
                <section className="flex-container">
                  <div
                    className="fixed-width"
                    // style={{
                    //   width: 330,
                    //   height: 100,
                    // border: `1px solid ${token.colorBorderSecondary}`,
                    // borderRadius: token.borderRadiusLG,
                    // }}
                  >
                    <Calendar
                      fullscreen={false}
                      monthCellRender={monthCellRender}
                      picker="month"
                      // onPanelChange={onPanelChange}
                    />
                  </div>
                  <div className="Events-container">
                    <h2 className="header-text">Events</h2>
                    <article className="eventarticle">
                      {holidays.map((holiday) => (
                        <div className="single-data" key={holiday.holidayId}>
                          <div className="event-date">
                            {formatHolidayDate(holiday.holidayDate)}
                            <span>
                              {new Date(holiday.holidayDate).toLocaleString(
                                "default",
                                { weekday: "short" }
                              )}
                            </span>
                          </div>
                          <div className="event-month">
                            {new Date(holiday.holidayDate).toLocaleString(
                              "default",
                              { month: "long" }
                            )}
                          </div>
                          <span className="event-title">
                            {holiday.holidayName}
                          </span>
                        </div>
                      ))}
                    </article>
                  </div>
                </section>
              ) : (
                <div className="loadingCallenderCss">
                  <EmptyComponent />
                </div>
              )}
            </div>
            <div className="sidecard-div">
              {loading ? (
                <div className="loadingHolidays">
                  <Spin size="large" />
                  <p>{noDataFunction()}</p>
                </div>
              ) : holidaysLength > 0 ? (
                <div className="holidadyleft">
                  <h2 className="header-text ">
                    Total Holidays : {totalCountofHolidays}
                  </h2>
                  <HolidayList holidays={holidays} />
                </div>
              ) : (
                <div className="loadingHolidays">
                  <EmptyComponent />
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Holidays;
