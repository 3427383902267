import React, { useState, useEffect } from "react";
import { Modal, Radio } from "antd";
import axios from "axios";
import Cookies from "js-cookie";

const QuestionPaperView = ({ view, setView, fileData, heading }) => {
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [fileContent, setFileContent] = useState(null);
  const accessToken = Cookies.get("accessToken");

  console.log("heading", heading);
  const handleCancel = () => {
    setView(false);
  };

  const handleFileChange = (e) => {
    setSelectedFileIndex(e.target.value);
  };

  useEffect(() => {
    const fetchFileContent = async () => {
      try {
        const response = await axios.get(
          `https://api.edpedia.co/v1.0/instAdmin/api/storage/download?url=${fileData[selectedFileIndex]}`,

          {
            headers: {
              Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            },
          }
        );
        setFileContent(response.data);
        console.log(response?.data);
      } catch (error) {
        console.error("Error downloading question paper:", error);
      }
    };

    fetchFileContent();
  }, [selectedFileIndex, fileData]);

  console.log("fileData", fileData);

  return (
    <div>
      <Modal
        title={`Files View : ${heading}`}
        open={view}
        centered
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        {/* <div>
          <Radio.Group onChange={handleFileChange} value={selectedFileIndex}>
            {fileData &&
              fileData?.map((file, index) => (
                <Radio key={index} value={index}>
                  {`File ${index + 1}`}
                </Radio>
              ))}
          </Radio.Group>
        </div> */}
        <div>
          <iframe
            src={fileData}
            style={{ width: "100%", height: "500px" }}
            title="File Preview"
          />
        </div>
      </Modal>
    </div>
  );
};

export default QuestionPaperView;
