import { GetAPIRequest } from "./Api";
import {findInstituteInfo} from "../Constants/constants"

const getInstituteInfo = (params) => {
return GetAPIRequest({
    url:findInstituteInfo, params
})
}

export const InstituteService = {
    getInstituteInfo
}