// config.js

let loginUrl;

if (process.env.NODE_ENV === "production") {
  // Production environment variables
  loginUrl = process.env.REACT_APP_LOGIN_URL;
} else {
  // Development environment variables
  loginUrl = process.env.REACT_APP_LOGIN_URL;
}

const config = {
  loginUrl,
};

export default config;
