import React, { useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  DatePicker,
  TimePicker,
  Button,
  Typography,
  Anchor,
  message,
  List,
  Space,
  Table,
  notification,
} from "antd";
import "../UnlockRequest.scss";
import { hrRequestServices } from "../../../../../Services/HumanResourceService";
import moment from "moment/moment";
import {
  DislikeOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import { createErrorMessage, dynamicErrorMsg } from "../../../../../Constants/utilitis";
const CreateUnlockRequest = ({
  openModal,
  setOpenModal,
  setViewModal,
  viewModal,
  rowData,
  loadUnlockData,
}) => {
  const [unlockForm] = Form.useForm();

  const handleCancelViewModal = () => {
    setViewModal(false);
  };
  const handleCancel = () => {
    setOpenModal(false);
    unlockForm.resetFields();
  };
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;

  const onFinish = (values) => {
    const payload = {
      reqMonth: values.reqMonth.format("MMMM"),
      reqYear: values.reqYear.format("YYYY"),
      reason: values.reason,
    };

    hrRequestServices
      .CreateUnlockRequest(staffId, payload)
      .then((res) => {
        notification.success({
          message: `Unlock Request Create Successfully`,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
        loadUnlockData();
        setOpenModal(false);
        unlockForm.resetFields();
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Create", "Unlock Request")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  const data = [
    {
      field: "Status",
      value: rowData?.status,
    },
    {
      field: "Request Month",
      value: rowData?.reqMonth,
    },
    {
      field: "Request year",
      value: rowData?.reqYear,
    },
    {
      field: "Reason",
      value: rowData?.reason,
    },
  ];
  const columns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <>
      <Modal
        className="studymaterial-modal"
        title="Unlock Request"
        centered
        open={openModal}
        width={500}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          form={unlockForm}
          initialValues={{ remember: false }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="reqMonth"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker picker="month" format="MMMM" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={24}>
              <Form.Item
                name="reqYear"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker picker="year" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="reason"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Input placeholder="Reason" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button" htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* <Modal
        title="Unlock Request Details"
        centered
        open={viewModal}
        width={500}
        footer={null}
        onCancel={handleCancelViewModal}
      >
        {rowData && (
          <>
            <div className="flex-div">
              <span>Status :</span> <p>{rowData.status}</p>
            </div>
            <div className="flex-div">
              <span>Month :</span> <p>{rowData.reqMonth}</p>
            </div>
            <div className="flex-div">
              <span>Year :</span> <p>{rowData.reqYear}</p>
            </div>
          </>
        )}
      </Modal> */}
      <Modal
        open={viewModal}
        title="Unlock Details"
        onCancel={handleCancelViewModal}
        footer={null}
        className="Unlock Details"
        centered
      >
        {rowData && (
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            showHeader={false}
          />
        )}
      </Modal>
    </>
  );
};

export default CreateUnlockRequest;
