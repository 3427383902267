import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Space,
  Upload,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  LeftOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  DislikeOutlined,
  FilePdfOutlined,
  LikeTwoTone,
  FileOutlined,
  CheckCircleFilled,
  EyeOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import DatePickerFloatLabel from "../../Common/FloatLabel/DatePicker";
import { reimbursementService } from "../../../Services/ReimbursementService";
import { createErrorMessage, dynamicErrorMsg, updateErrorMessage, yearDateFormat } from "../../../Constants/utilitis";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import FileView from "../../../Utils/FileView";
const CreateReimbursement = ({
  getData,
  openModal,
  setOpenModal,
  rowData,
  setRowData,
}) => {
  const [forms] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [heading, setHeading] = useState(null);
  const [view, setView] = useState(false);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  const expenseDate = dayjs(rowData?.expenseDate);
  useEffect(() => {
    if (rowData?.id) {
      forms.setFieldsValue({
        title: rowData?.title,
        expenseDate: expenseDate,
        amount: rowData?.amount,
        description: rowData?.description,
      });
    } else {
      forms.resetFields();
    }
  }, [rowData, forms]);

  const disabledDate = (current) => {
    return current && current > dayjs().startOf("day");
  };

  const handleFileView = (url, record) => {
    setView(true);
    setFileData([url]);
    setHeading(record?.assignmentTitle);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setRowData(null);
    forms.resetFields();
  };

  const handleUpload = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList(files);
  };
  const onFinish = (values) => {
    const id = rowData?.id;
    const expenseDate = yearDateFormat(values.expenseDate);
    const formData = new FormData();
    formData.append("staffId", staffId);
    formData.append("title", values.title);
    formData.append("amount", values.amount);
    formData.append("description", values.description);
    formData.append("expenseDate", expenseDate);

    if (id) {
      reimbursementService
        .editReimbursement(id, formData)
        .then(() => {
          notification.success({
            message: `  Reimbursement Updated Sucessfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          getData();
          setOpenModal(false);
          forms.resetFields();
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error,"Update","Reimbursement")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    } else {
      reimbursementService
        .createReimbursement(formData)
        .then(() => {
          notification.success({
            message: `Reimbursement Created Sucessfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          getData();
          setOpenModal(false);
          forms.resetFields();
        })
        .catch((error) => {
          const errorMessage = createErrorMessage(error,"Create","Reimbursement")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });    
      }
  };
  const onFinishFailed = () => {
    notification.error({
      message: `"An error occurred , try again later."`,
      icon: (
        <DislikeOutlined
          style={{
            color: "red",
          }}
        />
      ),
      duration: 3,
      placement: "bottomRight",
    });
  };
  return (
    <div>
      <Modal
        title={rowData ? " Edit Reimbursement" : " Create Reimbursement"}
        className="assignment-modal"
        open={openModal}
        footer={null}
        width={600}
        onCancel={handleCancel}
        centered
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Form
          name="forms"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={forms}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label="Title" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  label="Amount"
                  type="number"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label="Description" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="expenseDate"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePickerFloatLabel
                  label="Expense Date"
                  disabledDate={disabledDate}
                  style={{ width: "100%" }}
                  format={yearDateFormat}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="receipt">
                <Upload
                  onChange={handleUpload}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  multiple
                  showUploadList={true}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />} style={{ width: 310 }}>
                    Upload Receipt
                  </Button>
                </Upload>
              </Form.Item>

              {rowData &&
                Object.keys(rowData).length > 0 &&
                rowData.receipt !== null &&
                rowData.receipt !== "" && (
                  <div className="file_uploading-icon">
                    <div className="file-wrapper">
                      <FileOutlined
                        style={{ fontSize: "30px", color: "black" }}
                        onClick={() => handleFileView(rowData.receipt, rowData)}
                      />
                      {/* <CloseSquareOutlined
        onClick={() => handleDeleteFile(editRow.receipt, 0)}
      /> */}
                    </div>
                  </div>
                )}
            </Col>
          </Row>

          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />
    </div>
  );
};

export default CreateReimbursement;
