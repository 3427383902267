import React, { useState } from "react";
import { Input } from "antd";

import "./index.css";

const TextArea = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, required } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input.TextArea {...props} value={value} />
      <label className={labelClass}>
        {isOccupied ? label : placeholder}
      </label>
    </div>
  );
};

export default TextArea;
