import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Upload,
  notification,
} from "antd";
import { useEffect, useState } from "react";
import { ExamService } from "../../../Services/ExamServices";
import { createErrorMessage, updateErrorMessage, deleteErrorMessage, dynamicErrorMsg } from "../../../Constants/utilitis";

import {
  CheckCircleFilled,
  DeleteOutlined,
  DislikeOutlined,
  UploadOutlined,
  CloseSquareOutlined,
  FilePdfOutlined,
  LikeTwoTone,
} from "@ant-design/icons";
import "../Exam.scss";
import FloatInputAll from "../../Common/FloatLabel/InputAll";
import QuestionPaperView from "../../../Utils/QuestionPaperView";
import { AssignmentApiService } from "../../../Services/AssignmentServices";

const AddQuestionPaper = ({
  rowData,
  setRowData,
  openquestionpaperModal,
  setUploadQuestionPaperModal,
  questionPaperList,
}) => {
  const { Option } = Select;
  const [questionPaperForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [view, setView] = useState(false);
  const [heading, setHeading] = useState(null);

  const handleCancelPaper = () => {
    setUploadQuestionPaperModal(false);
  };

  const handleUpload = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList(files);
  };

  console.log("rowData-rowData", rowData);

  useEffect(() => {
    if (rowData?.id) {
      questionPaperForm.setFieldsValue({
        setNo: rowData?.setNo,
        questionPaperCode: rowData?.questionPaperCode,
      });
    } else {
      questionPaperForm.resetFields();
    }
  }, [rowData, questionPaperForm]);

  console.log("rowData-rowData", rowData);

  const onFinish = (values) => {
    const id = rowData?.id;
    const examScheduleId = rowData?.examScheduleId;
    const formData = new FormData();

    fileList.map((file, i) => {
      formData.append(`questionPaper`, file);
    });
    formData.append("examTTId", rowData?.examScheduleId);
    formData.append("setNo", rowData?.setNo);
    formData.append("questionPaperCode", values?.questionPaperCode);
    formData.append("examTTId", examScheduleId);

    //     setNo: 1
    // questionPaperCode: jhfjfkf678
    // questionPaper: (binary)
    // examTTId: 106

    if (id) {
      ExamService.editQuestionPaper(id, formData)
        .then((res) => {
          notification.success({
            message: `Question Paper Editing Sucessfully .`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          setUploadQuestionPaperModal(false);
          questionPaperList();
          questionPaperForm.resetFields();
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error,"Update", "Qusetion Paper")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });

        });
    }
  };
  const onFinishFailed = () => {
    notification.error({
      message: "Error",
      description:
        "An error occurred while Editing Question Paper, try again later.",
      icon: (
        <DislikeOutlined
          style={{
            color: "red",
          }}
        />
      ),
      duration: 3,
      placement: "bottomRight",
    });
  };

  const handleFileView = (url, record) => {
    setView(true);
    setFileData(url);
    setHeading(record?.id);
  };

  const handleDeleteFile = (url, index) => {
    const idd = rowData?.id;
    const params = {
      url: url,
    };
    ExamService.deleteQuestionPaper(idd, params)
    .then((res) => {
      questionPaperList();
      notification.success({
        message: `Delete File Sucessfully`,
        placement: "bottomRight",
        icon: (
          <LikeTwoTone
            style={{
              color: "#108ee9",
            }}
          />
        ),
      });
      if (rowData) {
        const updatedSupportDocUrls = [...rowData.supportDocUrls];
        updatedSupportDocUrls.splice(index, 1);
        setRowData((prevRow) => ({
          ...prevRow,
          supportDocUrls: updatedSupportDocUrls,
        }));
      }
      setFileList([]);
      questionPaperList();
    })
    .catch((error)=> {
      const errorMessage = dynamicErrorMsg(error,"Delete", "Question Paper")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
    })
    
  };

  return (
    <>
      <Modal
        open={openquestionpaperModal}
        onCancel={handleCancelPaper}
        footer={null}
        title={rowData ? "Question Paper" : " Question Paper"}
        wrapClassName="vertical-scroll-modal"
      >
        <Form
          name="questionPaperForm "
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={questionPaperForm}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item name="questionPaperCode">
                <FloatInputAll
                  className="float-inputs"
                  label="Question Paper Code"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="questionPaper">
                <Upload
                  onChange={handleUpload}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  multiple
                  showUploadList={true}
                  beforeUpload={() => false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{ width: 240, height: 40 }}
                  >
                    Question Paper
                  </Button>
                </Upload>
              </Form.Item>
              {rowData && rowData?.id ? (
                <>
                  {rowData?.questionPaper ? (
                    <>
                      <FilePdfOutlined
                        style={{ fontSize: "30px", color: "black" }}
                        onClick={() =>
                          handleFileView(rowData?.questionPaper, rowData)
                        }
                      />
                      <CloseSquareOutlined
                        onClick={() => handleDeleteFile(rowData)}
                      />
                    </>
                  ) : (
                    <span style={{ color: "red", marginBottom: 20 }}>
                      No Question Paper Available
                    </span>
                  )}
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button" htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancelPaper}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>

      <QuestionPaperView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />
    </>
  );
};

export default AddQuestionPaper;
