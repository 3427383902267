import { GetAPIRequest, PostAPIRequest } from "./Api";
import {
  FAMOUS_BOOKS_LIST,
  GET_ALL_BOOKS,
  GET_ALL_CATEGORIES_LIST,
  GET_ALL_CATEGORY_BOOKS,
  GET_AUTHOR_BOOKS,
  GET_BOOK_HISTORY,
  GET_TOP_AUTHORS_BOOKS_LIST,
  LATEST_BOOKS_LIST,
  RENT_BOOK_INFO,
  RENT_BOOK_URL,
  REQUESTED_BOOKS_ENDPOINT,
  EDIT_SUGGESTED_BOOK_ENDPOINT,
  REQUESTED_BOOKS_URL,
  REQUEST_BOOK_URL,
  REQUEST_BOOK_URL_ENDPOINT,
  MISSING_BOOK_REPORT,
  MISSING_BOOK_REPORT_ENDPOINT,
  AUTHOR_BOOKS,
  SUGGESTEDBOOKS,
  EDIT_SUGGESTED_BOOK,
  SUGGESTED_GET_BOOKS,
} from "../Constants/constants";
const getAuthorBooks = (params) => {
  return GetAPIRequest({
    url: GET_AUTHOR_BOOKS,
    params,
  });
};
const getAllAuthorBooks = () => {
  return GetAPIRequest({
    url: GET_AUTHOR_BOOKS,
  });
};
const getAllCategoriesList = () => {
  return GetAPIRequest({
    url: GET_ALL_CATEGORIES_LIST,
  });
};
const getAllBooks = () => {
  return GetAPIRequest({
    url: GET_ALL_BOOKS,
  });
};
const getAllCategoryBooks = (params) => {
  return GetAPIRequest({
    url: GET_ALL_CATEGORY_BOOKS,
    params,
  });
};
const getBookHistory = (params) => {
  return GetAPIRequest({
    url: GET_BOOK_HISTORY,
    params,
  });
};
const getLatestBooks = () => {
  return GetAPIRequest({
    url: LATEST_BOOKS_LIST,
  });
};
const getFamousBooks = () => {
  return GetAPIRequest({
    url: FAMOUS_BOOKS_LIST,
  });
};
const getTopAuthorBooks = () => {
  return GetAPIRequest({
    url: GET_TOP_AUTHORS_BOOKS_LIST,
  });
};
const getRequestedBooks = (params) => {
  return GetAPIRequest({
    url: REQUESTED_BOOKS_URL,
    params,
  });
};
const getRentBookInfo = (params) => {
  return GetAPIRequest({
    url: RENT_BOOK_INFO,
    params,
  });
};
const rentBook = (payLoad) => {
  return PostAPIRequest({
    url: RENT_BOOK_URL,
    data: payLoad,
  });
};

const requestBook = (bookId, params) => {
  return PostAPIRequest({
    url: REQUEST_BOOK_URL + bookId + REQUEST_BOOK_URL_ENDPOINT,
    params,
  });
};
const missingBookReport = (bookId) => {
  return PostAPIRequest({
    url: MISSING_BOOK_REPORT + bookId + MISSING_BOOK_REPORT_ENDPOINT,
  });
};
const editSuggestionBook = (bookId, payLoad) => {
  return PostAPIRequest({
    url: EDIT_SUGGESTED_BOOK + bookId + EDIT_SUGGESTED_BOOK_ENDPOINT,
    data: payLoad,
  });
};

const createSuggestedBooks = (payLoad, params) => {
  return PostAPIRequest({
    url: SUGGESTEDBOOKS,
    data: payLoad,
    params,
  });
};

const authorBooks = (params) => {
  return GetAPIRequest({
    url: AUTHOR_BOOKS,
    params,
  });
};

const getAllSuggestedBooksApi = (params) => {
  return GetAPIRequest({
    url: SUGGESTED_GET_BOOKS,
    params,
  });
};

export const LibraryService = {
  authorBooks,
  getAuthorBooks,
  getAllAuthorBooks,
  getAllCategoriesList,
  getAllBooks,
  getAllCategoryBooks,
  getBookHistory,
  rentBook,
  requestBook,
  getAllSuggestedBooksApi,
  getLatestBooks,
  getFamousBooks,
  getRequestedBooks,
  getTopAuthorBooks,
  getRentBookInfo,
  missingBookReport,
  createSuggestedBooks,
  editSuggestionBook,
};
