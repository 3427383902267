import React, { useEffect, useState } from "react";
import {
  Card,
  Image,
  Button,
  Select,
  Row,
  Col,
  Empty,
  message,
  notification,
} from "antd";
import { GirlUniform } from "../../../assets/icons";
import { BiRupee } from "react-icons/bi";
import "./CartProducts.scss";
import { EdpediaStoreService } from "../../../Services/EdpediaStoreService";
import { CheckCircleFilled,DislikeOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
const { Option } = Select;
const userId = Cookies.get("userId");
const CartProducts = () => {
  const [listOfCartProducts, setListOfCartProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const getListOfCartItems = () => {
    EdpediaStoreService.cartList(userId)
      .then((response) => {
        setListOfCartProducts(response.data);
        const initialQuantities = {};
        response.data.cartItems.forEach((item) => {
          initialQuantities[item.id] = 1;
        });
        setQuantities(initialQuantities);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getListOfCartItems();
  }, []);
  const handleQuantityChange = (itemId, quantity) => {
    setQuantities({
      ...quantities,
      [itemId]: quantity,
    });
  };
  const removeFromCartFun = (product) => {
    EdpediaStoreService.deleteProductFromCart(userId, product.productId)
      .then((response) => {
        message.success("Product removed from cart is sucessfull");
      })
      .catch((error) => {
        message.success("Product removed from cart is failed");
      });
  };

  const handleOrder = (item) => {
    const payload = {
      totalAmount: item?.totalAmount,
      paymentMethod: item?.paymentMethod,
      shippingAddressId: item?.shippingAddressId,
      billingAddressId: item?.billingAddressId,
    };
    console.log("item?.id", item?.productId);
    const productId = item?.productId;
    EdpediaStoreService.orderProductsFromCart(productId, payload)
      .then((response) => {
        notification.success({
          message: "Order created sucessfully",
          placement: "bottomRight",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 2,
        });
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="cart-product">
      {listOfCartProducts?.cartItems?.length > 0 ? (
        <>
          {listOfCartProducts?.cartItems.map((item) => {
            const productPrice = item.productPrices;
            const deliveryCharges = 40; // Assuming fixed delivery charges
            const offerPrice = productPrice * 0.25; // 25% discount

            const totalPrice =
              productPrice * quantities[item.id] + deliveryCharges - offerPrice;
            return (
              <>
                <Card hoverable>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={7}>
                      <Image
                        src={GirlUniform}
                        alt="Product"
                        className="product-image"
                        style={{ width: "250px", height: "250px" }}
                      />
                    </Col>
                    <Col span={10}>
                      <div className="product-info">
                        <h3>
                          {item?.productName} ({item?.brandName})
                        </h3>
                        <p>{item?.description}</p>
                        <p>Seller : Eaglemount Company</p>
                        <h4>25% - OFF</h4>
                        <div className="product-details">
                          <div className="price-quantity">
                            <p>Price: $ {item?.productPrices}</p>
                            <Select
                              // defaultValue="1"
                              value={quantities[item.id]}
                              className="quantity-select"
                              onChange={(value) =>
                                handleQuantityChange(
                                  item.id,
                                  parseInt(value, 10)
                                )
                              }
                            >
                              {[...Array(10)].map((_, index) => (
                                <Option
                                  key={index + 1}
                                  value={(index + 1).toString()}
                                >
                                  {index + 1}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="action-buttons">
                          <Button type="primary" className="move-to-wishlist">
                            Move to Wishlist
                          </Button>{" "}
                          <Button
                            onClick={() => removeFromCartFun(item)}
                            type="danger"
                            className="remove-from-cart"
                          >
                            Remove From Cart
                          </Button>
                        </div>
                      </div>
                    </Col>
                    <Col span={7}>
                      <div className="price-details-container">
                        <Button className="price-details-button">
                          Price Details
                        </Button>
                        <div className="single-item">
                          <span>
                            Price : <BiRupee className="rupeeIcon" />{" "}
                            {item?.productPrices}
                          </span>
                        </div>
                        <div className="single-item">
                          <span>
                            Deliver Charges : <BiRupee className="rupeeIcon" />
                            40
                          </span>
                        </div>
                        <div className="single-item">
                          <span>
                            Discount : <BiRupee className="rupeeIcon" />0
                          </span>
                        </div>

                        <div className="total single-item">
                          <span>
                            Total Amount :<BiRupee className="rupeeIcon" />
                            {totalPrice}
                          </span>
                        </div>

                        <div className="saving">
                          <span>You saved 500 in this offer .</span>
                        </div>

                        <div className="delivery-time">
                          <span>Delivery in 2 Dec-10 Dec </span>
                          {/* <Button className="submitButton"> Enter Details</Button> */}
                        </div>
                        <Button onClick={() => handleOrder(item)}>
                          Place Order
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </>
            );
          })}
        </>
      ) : (
        <>
          <Empty />
        </>
      )}
    </div>
  );
};

export default CartProducts;
