import React from "react";
import { Topbar, Subject } from "../../components";
import SEO from "../../components/reuse";

function Subjects() {
  return (
    <main>
      {/* <Topbar /> */}
      <SEO title="Subjects" />
      <Subject />
    </main>
  );
}

export default Subjects;
