import React, { useState, useEffect } from "react";
import { Button, Card, Dropdown, Menu, message, notification } from "antd";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import { EdpediaStoreService } from "../../../Services/EdpediaStoreService";
import AddressCreateModal from "./AddressCreateModal";
import { CheckCircleFilled, PlusOutlined } from "@ant-design/icons";
import "./Address.scss";
import { useNavigate } from "react-router-dom";
const { Meta } = Card;

const Address = () => {
  const navigate = useNavigate();
  const userId = Cookies.get("userId");
  const [addressList, setAddressList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);

  const getAddressList = () => {
    EdpediaStoreService.addressList(userId)
      .then((response) => {
        setAddressList(response.data);
      })
      .catch((error) => console.log(error));
  };

  const handleEdit = (address) => {
    setSelectedAddress(address);
    setVisible(true);
  };

  const handleDelete = (addressId) => {
    EdpediaStoreService.deleteAddress(addressId)
      .then(() => {
        // message.success("Address deleted successfully");
        notification.success({
          message: "Address deleted successfully",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
        getAddressList();
      })
      .catch((error) => {
        message.success("Address deleted failed");
      });
  };

  useEffect(() => {
    getAddressList();
  }, []);

  const menu = (address) => (
    <Menu>
      <Menu.Item key="edit" onClick={() => handleEdit(address)}>
        <EditOutlined /> Edit
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleDelete(address.id)}>
        <DeleteOutlined /> Delete
      </Menu.Item>
    </Menu>
  );

  const handleBack = () => {
    navigate("/Edpedia-store");
  };

  return (
    <main>
      <section className="address-container">
        <div className="address-header">
          <div className="back-container">
            <Button className="button" onClick={handleBack}>
              <LeftOutlined />
            </Button>
            <h1>Address List</h1>
          </div>
          <Button onClick={() => setVisible(true)} className="button">
            Create
          </Button>
        </div>

        {addressList?.map((address) => (
          <div className="address-cards-conatainer">
            <Card
              key={address.id}
              className="address-card"
              title={address.fullName}
              extra={
                <Dropdown overlay={() => menu(address)}>
                  <Button icon={<DownOutlined />} />
                </Dropdown>
              }
            >
              <p>{`${address.addressLine1}, ${address.city}, ${address.country}`}</p>
            </Card>
          </div>
        ))}
      </section>
      <AddressCreateModal
        visible={visible}
        setVisible={setVisible}
        selectedAddress={selectedAddress}
        getAddressList={getAddressList}
        setSelectedAddress={setSelectedAddress}
      />
    </main>
  );
};

export default Address;
