import React from "react";
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";

function StarRating({ numberOfRatings }) {
  // Calculate the number of full stars
  const fullStars = Math.floor(numberOfRatings);

  // Calculate the decimal part
  const decimalPart = numberOfRatings - fullStars;

  // Create an array to store the star icons
  const starIcons = [];

  // Add full stars
  for (let i = 0; i < fullStars; i++) {
    starIcons.push(<BsStarFill key={`full-star-${i}`} className="star-icon" />);
  }

  // Add a partially filled star if there's a decimal part
  if (decimalPart > 0) {
    starIcons.push(<BsStarHalf key="partial-star" className="star-icon" />);
  }

  // Fill the remaining space with empty stars
  const emptyStars = 5 - Math.ceil(numberOfRatings);
  for (let i = 0; i < emptyStars; i++) {
    starIcons.push(<BsStar key={`empty-star-${i}`} className="star-icon" />);
  }

  return <div className="icon-container">{starIcons}</div>;
}

export default StarRating;
