import React, { useState, useEffect } from "react";
import { Table, Button, Space, Spin, notification } from "antd";
import { hrRequestServices } from "../../../../Services/HumanResourceService";
import "./RequestAttendance.scss";
import { EditOutlined, DislikeOutlined } from "@ant-design/icons";
import CreateRegularizartion from "./CreateRegularizartion";
import Cookies from "js-cookie";
import { dynamicErrorMsg } from "../../../../Constants/utilitis";
const Regularization = () => {
  const [regularizationData, setRegularizationData] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowData, setRowData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [updatedAttendance, setUpdatedAttendance] = useState([]);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  useEffect(() => {
    loadResignationData();
  }, []);

  const loadResignationData = () => {
    const staffId = staffInfo?.staffId;
    hrRequestServices
      .getStaffRegularization(staffId)
      .then((res) => {
        const updatedAttendanceData = res?.data?.map((item) => {
          return item.updatedAttendance.checkIn;
        });
        setRegularizationData(res.data);
        setUpdatedAttendance(updatedAttendanceData);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Staff Regularization")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEditRegularization = (record) => {
    setRowData(record);

    setOpenModal(true);
  };

  const showViewModal = (record) => {
    setViewModal(true);
    setRowData(record);
  };

  const columns1 = [
    {
      title: "Date",
      dataIndex: "updatedAttendance",
      align: "center",
      key: "date",
      sorter: (a, b) => {
        const dateA = new Date(
          a.updatedAttendance.reduce(
            (earliestDate, current) =>
              new Date(current.reqDate) < new Date(earliestDate.reqDate)
                ? current
                : earliestDate,
            a.updatedAttendance[0]
          ).reqDate
        );

        const dateB = new Date(
          b.updatedAttendance.reduce(
            (earliestDate, current) =>
              new Date(current.reqDate) < new Date(earliestDate.reqDate)
                ? current
                : earliestDate,
            b.updatedAttendance[0]
          ).reqDate
        );

        return dateA - dateB;
      },
      render: (attendance) => (
        <div>
          {attendance.map((item, index) => (
            <div key={index}>{item.reqDate}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Check In",
      dataIndex: "updatedAttendance",
      key: "checkIn",
      align: "center",
      sorter: (a, b) => {
        const timeA = new Date(a.updatedAttendance[0]?.checkIn);
        const timeB = new Date(b.updatedAttendance[0]?.checkIn);

        return timeA - timeB;
      },
      render: (attendance) => (
        <div>
          {attendance.map((item, index) => (
            <div key={index}>{item.checkIn}</div>
          ))}
        </div>
      ),
    },

    {
      title: "Check Out",
      dataIndex: "updatedAttendance",
      key: "checkOut",
      align: "center",
      sorter: (a, b) => {
        const timeA = new Date(a.updatedAttendance[0].checkOut);
        const timeB = new Date(b.updatedAttendance[0].checkOut);

        return timeA - timeB;
      },
      render: (attendance) => (
        <div>
          {attendance.map((item, index) => (
            <div key={index}>{item.checkOut}</div>
          ))}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
      headerStyle: {
        backgroundColor: "#f0f0f0",
        fontWeight: "bold",
      },

      render: (text) => <div>{text}</div>,
    },
    // {
    //   title: "Task Done",
    //   dataIndex: "updatedAttendance",
    //   key: "taskDone",
    //   align: "center",
    //   render: (attendance) => (
    //     <div
    //     >
    //       {attendance.map((item, index) => (
    //         <div key={index}>

    //           {item.taskDone}
    //         </div>
    //       ))}
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      key: "action",
      align: "center",
      headerStyle: {
        backgroundColor: "#f0f0f0",
        fontWeight: "bold",
      },
      render: (_, record) => (
        <Space>
          {/* <Button
            className="full-details-button"
            onClick={() => showViewModal(record)}
          >
            <EyeOutlined />
          </Button> */}
          <Button
            onClick={() => {
              handleEditRegularization(record);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const createRegularaization = () => {
    setOpenModal(!openModal);
    setRowData(null);
  };

  return (
    <div>
      <div className="unlock-header">
        <div className="add-button-container">
          <h1 className="header-text">Request Staff Attendance</h1>
          <Button
            onClick={() => createRegularaization()}
            className="blue-button request-btn"
          >
            Create Request
          </Button>
        </div>
        <CreateRegularizartion
          setOpenModal={setOpenModal}
          openModal={openModal}
          setViewModal={setViewModal}
          viewModal={viewModal}
          rowData={rowData}
          setRowData={setRowData}
          loadResignationData={loadResignationData}
        />
      </div>
      <div>
        {isLoading ? (
          <Spin spinning={isLoading} className="loading-spinner" />
        ) : (
          <Table
            dataSource={regularizationData}
            columns={columns1}
            showSorterTooltip={false}
          />
        )}
      </div>
    </div>
  );
};

export default Regularization;
