import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  notification,
} from "antd";
import InputMask from "react-input-mask";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import DatePickerFloatLabel from "../../Common/FloatLabel/DatePicker";
import TextAreaFloatLabel from "../../Common/FloatLabel/TextArea";
import useLocationData from "../../Common/LocationData/countryStateCity";
import FloatInputAll from "../../Common/FloatLabel/InputAll";
import moment from "moment";
import { studentService } from "../../../Services/StudentService";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
import { dynamicErrorMsg, updateErrorMessage } from "../../../Constants/utilitis";
const EditStudent = ({
  isEditing,
  setIsEditing,
  editStudentRow,
  getAllStudents,
  setEditStudentRow,
}) => {
  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [selectedNationality, setSelectedNationality] = useState("");
  const [studentForm] = Form.useForm();
  const { Option } = Select;
  const nationalities = [
    "American",
    "Canadian",
    "British",
    "Australian",
    "French",
    "German",
    "Italian",
    "Spanish",
    "Chinese",
    "Japanese",
    "Indian",
    "Russian",
    "Brazilian",
    "Mexican",
    "South African",
    "Nigerian",
    "Egyptian",
    "Saudi Arabian",
    "Israeli",
    "Iranian",
  ];

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      setSelectedNationality(selectedOption);
    } else {
      setSelectedNationality(null);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleStateChange1 = (value) => {
    handleStateChange(value);
    setIsCityDisabled(!value);
    studentForm.setFieldsValue({ city: undefined });
  };

  const onFinish = (values) => {
    const updatedDate = moment(values.dateOfBirth)?.format("YYYY-MM-DD");
    const payload = {
      ...values,
      dateOfBirth: updatedDate,
      parentAadhaarNumber: values?.parentAadhaarNumber.replace(/-/g, ""),
      studentAadhaarNumber: values?.studentAadhaarNumber.replace(/-/g, ""),
      classId: editStudentRow.classId,
    };

    studentService
      .editStudent(editStudentRow.studentId, payload)
      .then((response) => {
        setIsEditing(false);
        setEditStudentRow(null);
        getAllStudents();
        notification.success({
          message: `${values.firstName} ${values.lastName} Updated Successfully`,
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error,"Update","Student")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  const { countries, states, cities, handleCountryChange, handleStateChange } =
    useLocationData();

  const handleCountryChange1 = (value) => {
    handleCountryChange(value);
    setIsStateDisabled(!value);
    setIsCityDisabled(true);
    handleStateChange(null);
    studentForm.setFieldsValue({ state: undefined });
    studentForm.setFieldsValue({ city: undefined });
  };

  const disabledDate = (current) => {
    return current && current >= moment().subtract(2, "years").endOf("day");
  };

  useEffect(() => {
    if (editStudentRow?.studentId) {
      // const filteredData = classState?.filter(
      //   (item) => item?.className === editStudentRow?.className
      // );

      // const options = filteredData?.map((item) => ({
      //   label: item.section,
      //   value: item.classId,
      // }));

      // setSectionList(options);

      const studentName = editStudentRow?.studentName?.split(" ");
      const firstName = studentName?.length > 0 ? studentName[0] : "";
      const lastName = studentName?.length > 0 ? studentName[1] : "";
      const updatedDate =
        editStudentRow?.dateOfBirth === undefined
          ? moment(new Date(), "YYYY-MM-DD")
          : moment(editStudentRow.dateOfBirth, "YYYY-MM-DD");
      studentForm.setFieldsValue({
        ...editStudentRow,
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: updatedDate,
        className: editStudentRow.className,
        section: editStudentRow.section,
        postalCode: editStudentRow.postalCode,
        parentAadhaarNumber: editStudentRow?.parentAadhaarNumber,
        parentWhatsappNumber: editStudentRow?.parentWhatsappNumber,
        parentEmail: editStudentRow.parentEmail,
      });
    } else {
      studentForm.resetFields();
    }
  }, [editStudentRow, studentForm]);

  return (
    <>
      <Modal
        title="Edit Student"
        centered
        open={isEditing}
        width={700}
        footer={null}
        onCancel={handleCancel}
        wrapClassName="vertical-scroll-modal"
        bodyStyle={{ maxHeight: "400px", overflowY: "auto", overflowX: "clip" }}
        className="EditStudent-popup"
      >
        <Form
          name={editStudentRow?.studentId ? "editStudentRow" : "addStudent"}
          form={studentForm}
          onFinish={onFinish}
          autoComplete="off"
          initialValues={editStudentRow?.studentId ? { remember: true } : null}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  type="text"
                  label="First Name"
                  name="firstName"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  type="text"
                  label="Last Name"
                  name="lastName"
                />
              </Form.Item>
            </Col>
            <Col span={12} className="gutter-row">
              <Form.Item
                name="className"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  type="text"
                  label="Class Name"
                  name="className"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12} className="gutter-row">
              <Form.Item
                name="section"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  type="text"
                  label="Section"
                  name="section"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <SelectFloatLabel label="Gender" name="gender">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="others">Others</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="contact"
                rules={[
                  // {
                  //   required: true,
                  //   message: "",
                  // },
                  {
                    min: 10,
                    message: "Please enter valid phone number",
                  },
                  {
                    max: 10,
                    message: "Cannot exceed more than 10",
                  },
                ]}
              >
                <InputFloatLabel
                  label="Student Mobile Number"
                  name="contact"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="fatherName"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label="Father's Name" name="fatherName" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="motherName"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel label="Mother's Name" name="motherName" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="parentEmail"
                // rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  label="Parent Email"
                  name="parentEmail"
                  type="email"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item name="email">
                <InputFloatLabel
                  label="Student Email"
                  name="email"
                  type="email"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <SelectFloatLabel
                  showSearch
                  label="Nationality"
                  onChange={handleSelectChange}
                  value={selectedNationality}
                  style={{ textAlign: "left" }}
                >
                  {nationalities?.map((nationality, index) => (
                    <option key={index} value={nationality}>
                      {nationality}
                    </option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="dateOfBirth"
                style={{ width: 315 }}
                rules={[{ required: true, message: "" }]}
              >
                <DatePickerFloatLabel
                  disabledDate={disabledDate}
                  label="Date Of Birth (DD-MM-YYYY)"
                  dateOfBirth
                  // format="DD-MM-YYYY"
                  style={{ width: 315 }}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="country"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <SelectFloatLabel
                  label="Country"
                  onChange={handleCountryChange1}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                >
                  {countries.map((country) => (
                    <Option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="parentContactNumber"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    min: 10,
                    message: "Invalid Number",
                  },
                  {
                    max: 10,
                    message: "Invalid Number",
                  },
                ]}
              >
                <InputFloatLabel label="Parent Contact Number" type="number" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="state"
                rules={[
                  {
                    required: true,
                    message: "",
                    disabled: isStateDisabled,
                  },
                ]}
              >
                <SelectFloatLabel
                  label="State"
                  onChange={handleStateChange1}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  disabled={isStateDisabled}
                >
                  {states.map((state) => (
                    <Option key={state.isoCode} value={state.isoCode}>
                      {state.name}
                    </Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="parentAadhaarNumber"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputMask mask="9999-9999-9999" maskChar="_">
                  {() => (
                    <FloatInputAll label="Parent Aadhaar Number" type="text" />
                  )}
                </InputMask>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "",
                    disabled: isCityDisabled,
                  },
                ]}
              >
                <SelectFloatLabel
                  label="City"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  optionFilterProp="children"
                  disabled={isCityDisabled}
                >
                  {cities.map((city) => (
                    <Option key={city.cityId} value={city.name}>
                      {city.name}
                    </Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="postalCode"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel
                  name="postalCode"
                  type="number"
                  label="Postal Code"
                />
              </Form.Item>
            </Col>
            <Row>
              <Col span={12}></Col>
            </Row>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="parentWhatsappNumber"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    min: 10,
                    message: "Invalid Number",
                  },
                  {
                    max: 10,
                    message: "Invalid Number",
                  },
                ]}
              >
                <InputFloatLabel
                  name="whatsAppNumber"
                  label="Whatsapp Number"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="studentAadhaarNumber"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputMask mask="9999-9999-9999" maskChar="_">
                  {() => (
                    <FloatInputAll label="Student Aadhaar Number" type="text" />
                  )}
                </InputMask>
                {/* <InputFloatLabel
                  label="Parent Mobile Number"
                  name="parentContactNumber"
                  type="number"
                /> */}
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="address"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <TextAreaFloatLabel
                  name="address"
                  label="Address"
                  rows={8}
                  maxLength={100}
                  autoSize={{ minRows: 4, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button" htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EditStudent;
