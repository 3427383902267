import React, { useEffect, useState } from "react";
import { Calendar, Spin, Switch, message, notification, theme } from "antd";
import "./StaffAttendance.scss";
import { staffAttendanceService } from "../../../Services/StaffAttendanceService";
import { createErrorMessage, updateErrorMessage, submitErrorMessage, dynamicErrorMsg } from "../../../Constants/utilitis";

import {
  DislikeOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
import Cookies from "js-cookie";

const StaffAttendance = () => {
  const [intimeCheck, setIntimeCheck] = useState(false);
  const [outtimeCheck, setOuttimeCheck] = useState(false);
  const [checkInTime, setCheckInTime] = useState(null);
  const [checkOutTime, setCheckOutTime] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [time, setTime] = useState(
    new Date().toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(
        new Date().toLocaleTimeString("en-IN", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.log("Error getting geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const getAttendanceList = () => {
    const staffId = staffInfo?.staffId;
    staffAttendanceService
      .getStaffAttendance(staffId)
      .then((response) => {
        setCheckInTime(response?.data?.checkIn || "");
        setCheckOutTime(response?.data?.checkOut || "");
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Staff Attendance")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getAttendanceList();
  }, []);
  const [warningDisplayed, setWarningDisplayed] = useState(false);
  useEffect(() => {
    if (checkInTime) {
      setIntimeCheck(true);
      setWarningDisplayed(false);
    } else if (warningDisplayed) {
      notification.warning({
        message: "You haven't checked in. Please first check in.",
        placement: "bottomRight",
      });
      setWarningDisplayed(true);
    }
  }, [checkInTime, warningDisplayed]);

  // useEffect(() => {
  //   if (checkInTime) {
  //     setIntimeCheck(true);
  //   } else {
  //     notification.warning({
  //       message: "You haven't checked in. Please first check in.",
  //       placement: "bottomRight",
  //     });
  //   }
  // }, [checkInTime]);

  useEffect(() => {
    if (checkOutTime) {
      setOuttimeCheck(true);
    }
  }, [checkOutTime]);

  const handleSwitchChangeCheckIn = (checked) => {
    if (checked) {
      const centerLatitude = 17.404;
      const centerLongitude = 78.391;
      const radius = 10000;
      const distance = calculateDistance(
        latitude,
        longitude,
        centerLatitude,
        centerLongitude
      );
      if (distance <= radius) {
        const staffId = staffInfo?.staffId;
        const payload = {
          checkIn: formattedTime,
        };
        staffAttendanceService
          .staffCheckIn(staffId, payload)
          .then((response) => {
            setIntimeCheck(true);
            setCheckInTime(formattedTime);
            setWarningDisplayed(false);
            notification.success({
              message: `Check in at ${formattedTime}`,
              placement: "bottomRight",
              icon: (
                <LikeTwoTone
                  style={{
                    color: "#108ee9",
                  }}
                />
              ),
            });
          })
          .catch((error) => {
            const errorMessage = dynamicErrorMsg(error, "Submit", "Staff CheckIn")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
          });
      } else {
        notification.success({
          message: `You are outside the school zone`,
          placement: "bottomRight",
          icon: (
            <DislikeOutlined
              style={{
                color: "red",
              }}
            />
          ),
        });
      }
    }
  };

  const handleSwitchChangeCheckOut = (checked) => {
    if (checked) {
      const centerLatitude = 17.404;
      const centerLongitude = 78.391;
      const radius = 10000;
      const distance = calculateDistance(
        latitude,
        longitude,
        centerLatitude,
        centerLongitude
      );
      if (distance <= radius) {
        const staffId = staffInfo?.staffId;
        const payload = {
          checkOut: formattedTime,
          taskDone: "completed",
        };
        staffAttendanceService
          .staffCheckOut(staffId, payload)
          .then((response) => {
            setOuttimeCheck(true);
            setCheckOutTime(formattedTime);
            notification.success({
              message: `Check out at ${formattedTime}`,
              placement: "bottomRight",
              icon: (
                <LikeTwoTone
                  style={{
                    color: "#108ee9",
                  }}
                />
              ),
            });
          })
          .catch((error) => {
            const errorMessage = dynamicErrorMsg( error, "Submit", "Staff CheckOut")
            notification.error({
              message: errorMessage,
              placement: "bottomRight",
              icon: <DislikeOutlined style={{ color: "red" }} />,
            });

          });
      } else {
        notification.success({
          message: `You are outside the school zone`,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
      }
    }
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c * 1000;
    return distance;
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const currentDate = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("en-IN", options);
  const dayOfWeek = currentDate.toLocaleDateString("en-IN", {
    weekday: "long",
  });
  const formattedTime = time.replace("pm", "PM").toUpperCase();
  const onPanelChange = (value, mode) => {};
  const { token } = theme.useToken();
  return (
    <section className="staff-attendance-container">
      <div className="header-container">
        <h1 className="header-text">My Attendance</h1>
        {/* <h2 className="header-text">Today's Status</h2> */}
        {/* <h3>
          {formattedDate} : {dayOfWeek}, {formattedTime}
        </h3> */}
      </div>

      {/* //new card */}
      <div className="my-attenace-container">
        <div
          style={{
            width: 300,
            height: 100,
            border: `1px solid ${token.colorBorderSecondary}`,
            borderRadius: token.borderRadiusLG,
          }}
        >
          <Calendar fullscreen={false} onPanelChange={onPanelChange} />

          <div className="calenderblock">
            <div className="eventlist">
              <div className="listtop">
                <div className="circledaa"></div>
                <div className="eventday">
                  <p>National Day</p>
                  <p className="subday">Holiday</p>
                </div>
              </div>
              <div className="eventdate">10th</div>
            </div>
            <div className="eventlist">
              <div className="listtop">
                <div className="circledaa"></div>
                <div className="eventday">
                  <p>National Day</p>
                  <p className="subday">Holiday</p>
                </div>
              </div>
              <div className="eventdate">10th</div>
            </div>
            <div className="eventlist">
              <div className="listtop">
                <div className="circledaa"></div>
                <div className="eventday">
                  <p>National Day</p>
                  <p className="subday">Holiday</p>
                </div>
              </div>
              <div className="eventdate">10th</div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Spin spinning={isLoading} className="loading-spinner" />
        ) : (
          <section className="cardsection">
            <div className="card-block">
              <div className="check-cards">
                <div className="checkinout">
                  <p className="">Check In</p>
                  <p className="header-text">{checkInTime}</p>
                </div>
                <Switch
                  className={
                    intimeCheck ? "switch-checked" : "switch-unchecked"
                  }
                  checked={intimeCheck}
                  onChange={handleSwitchChangeCheckIn}
                />
              </div>
              <div className="check-cards">
                <div>
                  <p className="header-text">Check Out</p>
                  <p className="header-text">{checkOutTime}</p>
                </div>
                <Switch
                  className={
                    outtimeCheck ? "switch-checked" : "switch-unchecked"
                  }
                  checked={outtimeCheck}
                  onChange={handleSwitchChangeCheckOut}
                />
              </div>
            </div>

            <div className="Events-container">
              <h2 className="header-text">Events</h2>
              <div className="">
                <article className="eventarticle">
                  <div className="single-data">
                    <div className="event-date">
                      15 <span>Wed</span>
                    </div>
                    <div className="event-month">Janaury</div>
                    <span className="event-title">Pongol</span>
                  </div>
                  <div className="single-data">
                    <div className="event-date">
                      15 <span>Wed</span>
                    </div>
                    <div className="event-month">Janaury</div>
                    <span className="event-title">Sankranti</span>
                  </div>
                  <div className="single-data">
                    <div className="event-date">
                      15 <span>Wed</span>
                    </div>
                    <div className="event-month">Janaury</div>
                    <span className="event-title">Republic Day</span>
                  </div>
                  <div className="single-data">
                    <div className="event-date">
                      15 <span>Wed</span>
                    </div>
                    <div className="event-month">Janaury</div>
                    <span className="event-title">Independence Day</span>
                  </div>
                  <div className="single-data">
                    <div className="event-date">
                      15 <span>Wed</span>
                    </div>
                    <div className="event-month">Janaury</div>
                    <span className="event-title">Pongol</span>
                  </div>
                  <div className="single-data">
                    <div className="event-date">
                      15 <span>Wed</span>
                    </div>
                    <div className="event-month">Janaury</div>
                    <span className="event-title">Sankranti</span>
                  </div>
                  <div className="single-data">
                    <div className="event-date">
                      15 <span>Wed</span>
                    </div>
                    <div className="event-month">Janaury</div>
                    <span className="event-title">Republic Day</span>
                  </div>
                  <div className="single-data">
                    <div className="event-date">
                      15 <span>Wed</span>
                    </div>
                    <div className="event-month">Janaury</div>
                    <span className="event-title">Independence Day</span>
                  </div>
                </article>
              </div>
            </div>
          </section>
        )}
      </div>
    </section>
  );
};

export default StaffAttendance;
