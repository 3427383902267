import { Button, Col, Modal, Row, Space, notification } from "antd";
import React from "react";
import { DislikeOutlined, LikeTwoTone } from "@ant-design/icons";
import Cookies from "js-cookie";
import { LibraryService } from "../../../Services/LibraryService";
import { createErrorMessage, dynamicErrorMsg, getErrorMessage } from "../../../Constants/utilitis";

const RequestBookModal = ({
  isRequestModalVisible,
  setRequestModalVisible,
  selectedBook,
}) => {
  const handleRequestCancel = () => {
    setRequestModalVisible(false);
  };
  const staffId = Cookies.get("userId");
  const currentDate = new Date();
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = currentDate
    .toLocaleDateString("en-GB", options)
    .split("/")
    .reverse()
    .join("-");

  const handleRequestOk = () => {
    const params = {
      userId: staffId,
      // userName: "Venkatesh",
      userType: "Staff",
      requestedDate: formattedDate,
    };
    LibraryService.requestBook(selectedBook?.id, params)
      .then((response) => {
        notification.success({
          message: `${selectedBook?.bookName} Book Requested Sucessfully`,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Create","Book Request")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  return (
    <>
      <Modal
        open={isRequestModalVisible}
        onCancel={handleRequestCancel}
        footer={null}
        className="custom-modal"
      >
        <div>
          <p
            style={{
              fontSize: "20px",
              fontWeight: "700",
              cursor: "pointer",
              marginBottom: "35px",
            }}
          >
            Are you sure you want to Request the book?
          </p>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button
                  className="newCancelButton"
                  onClick={handleRequestCancel}
                >
                  Cancel
                </Button>
              </Space>
            </Col>
            <Col span={12} align="start">
              <Space direction="vertical" size="large">
                <Button htmlType="submit" onClick={handleRequestOk}>
                  Request
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default RequestBookModal;
