import React, { useEffect, useState } from "react";
import { Button, Table, Space, notification, Modal } from "antd";
import {
  EyeOutlined,
  CloseOutlined,
  CheckOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import { PlusCircleOutlined } from "@ant-design/icons";
import "./Meetings.scss";
import EditMetting from "./EditMettings/EditMetting";
import { meetingServices } from "../../Services/MeetingServices";
import Cookies from "js-cookie";
import { dynamicErrorMsg } from "../../Constants/utilitis";
const Meetings = () => {
  const [openModal, setOpenModal] = useState(false);
  const [meetingData, setMeetingData] = useState([]);
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [studyModalDetails, setStudyModalDetails] = useState(false);
  const [viewData, setViewData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  useEffect(() => {
    loadMettingData();
  }, []);
  const loadMettingData = () => {
    meetingServices
      .meetingList(staffId)
      .then((res) => {
        setMeetingData(res.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Meetings")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleEditResignation = () => {
    setOpenModal(true);
  };
  const handleChecked = (record) => {
    setOpenApproveModal(true);
    // setRowRecord(record);
    setMeetingId(record);
  };
  const handleModalCancel = () => {
    setStudyModalDetails(false);
  };
  const handleViewResignation = (record) => {
    meetingServices
      .viewMeeting(record.meetingId)
      .then((res) => {
        setStudyModalDetails(true);
        setMeetingId(record);
        setViewData(record);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      key: "frequency",
      align: "center",
      sorter: (a, b) => a.frequency - b.frequency,
      render: (text) => {
        if (text !== null) {
          return text;
        }
        return " -";
      },
    },
    {
      title: "Meeting Date",
      dataIndex: "meetingDate",
      key: "meetingDate",
      align: "center",
      sorter: (a, b) => {
        // Parse date strings as dd-mm-yyyy into Date objects
        const dateA = new Date(
          a.meetingDate.split("-").reverse().join("-")
        ).getTime();
        const dateB = new Date(
          b.meetingDate.split("-").reverse().join("-")
        ).getTime();
        return dateA - dateB;
      },
      defaultSortOrder: "descend",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      width: "95px",
      align: "center",
      sorter: (a, b) => {
        const timeStringToMinutes = (timeString) => {
          const [time, period] = timeString.split(" ");
          const [hours, minutes] = time.split(":").map(Number);
          const totalMinutes = hours * 60 + minutes;
          return period === "pm" ? totalMinutes + 720 : totalMinutes;
        };

        return (
          timeStringToMinutes(a.startTime) - timeStringToMinutes(b.startTime)
        );
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
      width: 100,
      sorter: (a, b) => {
        const timeStringToMinutes = (timeString) => {
          const [time, period] = timeString.split(" ");
          const [hours, minutes] = time.split(":").map(Number);
          const totalMinutes = hours * 60 + minutes;
          return period === "pm" ? totalMinutes + 720 : totalMinutes;
        };

        return timeStringToMinutes(a.endTime) - timeStringToMinutes(b.endTime);
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      align: "center",
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space>
          {/* <EyeOutlined onClick={() => handleViewResignation(record)} /> */}
          <CloseOutlined
            onClick={handleEditResignation}
            className="view-icon-btn"
          />
          <CheckOutlined
            className="right-icon-btn"
            onClick={() => handleChecked(record)}
          />
        </Space>
      ),
    },
  ];

  const viewcColumns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];
  const datas = [
    {
      field: "Title",
      value: viewData?.title,
    },
    {
      field: "Frequency",
      value: viewData?.frequency,
    },
    {
      field: "Meeting Date",
      value: viewData.meetingDate,
    },
    {
      field: "Start Time",
      value: viewData?.startTime,
    },
    {
      field: "End Time",
      value: viewData?.endTime,
    },
    {
      field: "Duration",
      value: viewData?.duration,
    },
  ];
  return (
    <>
      <main>
        <article className="mettings-container">
          <div className="meetings-header">
            <h2 className="header-text ">1:1 Meetings</h2>
          </div>
          <EditMetting
            meetingData={meetingData}
            openModal={openModal}
            setOpenModal={setOpenModal}
            loadMettingData={loadMettingData}
            setOpenApproveModal={setOpenApproveModal}
            openApproveModal={openApproveModal}
            meetingId={meetingId}
          />
          <div className="table-div">
            <Table
              dataSource={meetingData}
              columns={columns}
              showSorterTooltip={false}
            />
          </div>
        </article>
        <Modal
          open={studyModalDetails}
          title="Meeting Details"
          onCancel={handleModalCancel}
          footer={null}
          width={600}
          centered
          className="view-assignment"
        >
          {viewData && (
            <Table
              columns={viewcColumns}
              dataSource={datas}
              pagination={false}
              showHeader={false}
            />
          )}
        </Modal>
      </main>
    </>
  );
};

export default Meetings;
