import React from "react";
import { Topbar, Profile } from "../../components";
import SEO from "../../components/reuse";
function Profiles() {
  return (
    <main>
      {/* <Topbar /> */}
      <SEO title="PROFILE" />
      <Profile />
    </main>
  );
}

export default Profiles;
