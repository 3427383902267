import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import config from "../components/Utils/Config";

export function checkAuth() {
  const tokenInCookies = Cookies.get("accessToken");
  const urlParams = new URLSearchParams(window.location.search);
  const tokenInUrl = urlParams.get("accessToken");
  const branchCode = urlParams.get("branchCode");
  const userId = urlParams.get("userId");
  const entity = urlParams.get("entity");

  if (tokenInCookies) {
    // Token is already in cookies
    // Update cookies with the latest details if present in the URL
    if (tokenInUrl && (branchCode || userId || entity)) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1);
      Cookies.set("accessToken", tokenInUrl, { expires: expirationDate });

      if (branchCode) {
        Cookies.set("branchCode", branchCode, { expires: expirationDate });
      }
      if (userId) {
        Cookies.set("userId", userId, { expires: expirationDate });
      }
      if (entity) {
        Cookies.set("entity", entity, { expires: expirationDate });
      }

      // Remove token details from URL
      urlParams.delete("accessToken");
      urlParams.delete("branchCode");
      urlParams.delete("userId");
      urlParams.delete("entity");

      // Redirect to the same URL without token details
      const newUrl = `${window.location.pathname}`;
      setTimeout(() => {
        window.location.href = newUrl;
      }, 0);
    }
  } else if (tokenInUrl && branchCode && userId) {
    // Token is in URL, store it in cookies
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);
    Cookies.set("accessToken", tokenInUrl, { expires: expirationDate });
    Cookies.set("branchCode", branchCode, { expires: expirationDate });
    Cookies.set("userId", userId, { expires: expirationDate });
    Cookies.set("entity", entity, { expires: expirationDate });

    // Remove token details from URL
    urlParams.delete("accessToken");
    urlParams.delete("branchCode");
    urlParams.delete("userId");
    urlParams.delete("entity");

    // Redirect to the same URL without token details
    const newUrl = `${window.location.pathname}`;
    setTimeout(() => {
      window.location.href = newUrl;
    }, 0);
  } else {
    // Redirect to the login
    redirectToPing();
  }
}

export function setUserAuthInfo(tokens, url, navigate) {
  if (tokens && tokens.accessToken) {
    var decoded = jwtDecode(tokens.accessToken);
    if (decoded.exp) {
      var exp = decoded.exp;
      var username = decoded.first_name + " " + decoded.last_name;
      setCookie("jobTitle", decoded.designation, exp);
      setCookie("userName", username, exp);
      setCookie("firstName", decoded.first_name, exp);
      setCookie("lastName", decoded.last_name, exp);
      setCookie("sub", decoded.id, exp);
      setCookie("email", decoded.email, exp);
      setCookie("accessToken", tokens.accessToken, exp);
      setCookie("refresh-token", tokens.refresh_token, exp);
      setTimeout(() => {
        if (url) {
          //window.open(url, "_self");
          navigate(url);
        } else {
          window.open(
            `${window.location.protocol}//${window.location.host}`,
            "_self"
          );
        }
      }, 10);
    }
  }
}

export function isAuthenticated() {
  let c_url = new URL(window.location.href);
  let searchParams = new URLSearchParams(c_url.search);
  let token = getCookie("accessToken");
  if (token === null || token === "") {
    if (searchParams.get("code") || searchParams.get("accessToken")) {
      var accessToken = searchParams.get("accessToken");
      var decoded = jwtDecode(accessToken);
      if (decoded.exp) {
        var exp = decoded.exp;
        var username = decoded.first_name + " " + decoded.last_name;
        setCookie("accessToken", searchParams.get("accessToken"), exp);
        setCookie("refresh-token", searchParams.get("refresh_token"), exp);
        setCookie("refresh-token", searchParams.get("staffId"), exp);
      }
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function getRefreshToken() {
  var isRefreshToken = false;
  /*let accessToken = getCookie("accessToken");
  let refreshToken = getCookie("refresh-token");
  var promiseObj = await $httpClient.getApiCall(
    "/identity/ping/refreshtoken?refreshToken=" +
      refreshToken +
      "&grantType=refresh_token"
  );

  $.when(promiseObj).done((response) => {
    isRefreshToken = true;
    var decoded = jwt_decode(response.accessToken);
    if (decoded.exp) {
      setCookie("accessToken", response.accessToken, decoded.exp);
      setCookie("refresh-token", response.refresh_token, decoded.exp);
    }
  });*/

  return isRefreshToken;
}

export function redirectToPing() {
  let client_uri = window.location.protocol + "//" + window.location.host;
  /*if (
    localStorage.getItem("isPrevState") != "" &&
    localStorage.getItem("isPrevState") != undefined &&
    localStorage.getItem("CURRENTURL") != "" &&
    localStorage.getItem("CURRENTURL") != undefined
  ) {
    var CURRENTURL = localStorage.getItem("CURRENTURL");
    var CURRENTURLHOST = localStorage.getItem("CURRENTURLHOST");
    var CURRENTURLPATHNAME = localStorage.getItem("CURRENTURLPATHNAME");
    client_uri = CURRENTURL + CURRENTURLHOST + CURRENTURLPATHNAME;
  }*/
  let uri = `${config.loginUrl}`;
  localStorage.setItem("redirect-uri", uri);
  sessionStorage.clear();
  setTimeout(() => {
    window.location.href = uri;
  }, 1000);
}

export function setCookie(cname, cvalue, exdays) {
  if (cname === "refresh-token") {
    var d = new Date();
    var minutes = 90;
    d.setTime(d.getTime() + minutes * 60 * 1000);
    let expires = ""; // "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  } else {
    const d = new Date(exdays * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
}

export function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

// export function checkCookie() {
//   let user = getCookie('username') as string;
//   if (user !== '') {
//   } else {
//     user = prompt('Please enter your name:', '');
//     if (user !== '' && user !== null) {
//       setCookie('username', user, 365);
//     }
//   }
// }

export function checkUnauthorised(res) {
  if (res && (res.errorCode === 401 || res.errorCode === "401")) {
    return {
      status: true,
      message: `${res.errorMessage} <a href="mailto:${res.supportEmail}">${res.supportEmail}</a>`,
    };
  }
  return {
    status: false,
  };
}
