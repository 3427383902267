import { Button, Col, Modal, Row, Space, notification } from "antd";
import React, { useEffect, useState } from "react";
import { DislikeOutlined } from "@ant-design/icons";
import "../Books/Books.scss";
import { LibraryService } from "../../../Services/LibraryService";
import Cookies from "js-cookie";
import { createErrorMessage, dynamicErrorMsg } from "../../../Constants/utilitis";

const RentBookModal = ({
  isRentModalVisible,
  selectedBook,
  setRentModalVisible,
  getAllBooks,
}) => {
  const staffId = Cookies.get("userId");
  const [rentedBookInformation, setRentedBookInformation] = useState([]);

  const getBookInfo = () => {
    const params = {
      bookId: selectedBook?.id,
    };

    LibraryService.getRentBookInfo(params)
      .then((response) => {
        setRentedBookInformation(response.data);
        // onHandleRent(response.data, selectedBook);
      })
      .catch((error) => {
        //  console.log(error)
      });
    // .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getBookInfo();
  }, [selectedBook]);

  const handleRentOk = (values) => {
    const params = {
      userId: staffId,
      bookId: selectedBook?.id,
      userType: "Staff",
    };
    LibraryService.rentBook(params)
      .then((response) => {
        notification.success({
          message: `${selectedBook?.bookName} Book Rented Sucessfully`,
          placement: "bottomRight",
        });
        getAllBooks();
        setRentModalVisible(false);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Create", "Book Rent")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        setRentModalVisible(false);
      });
  };

  const handleRentCancel = () => {
    setRentModalVisible(false);
  };

  return (
    <Modal
      open={isRentModalVisible}
      // onOk={handleRentOk}
      onCancel={handleRentCancel}
      footer={null}
      className="custom-modal"
    >
      <div style={{ marginBottom: "20px" }}>
        <h3 className="header-text">Read Carefully</h3>

        <h4 className="high high2">Rent Book Information: </h4>

        <div className="datesInfo">
          <p>
            <span className="high">RentalDate:</span>{" "}
            {rentedBookInformation?.rentalDate}
          </p>
          <p>
            <span className="high">DueDate:</span>{" "}
            {rentedBookInformation?.dueDate}
          </p>
          <p>
            <span className="high">Fine Amount for Missing books:</span>
            {rentedBookInformation?.missingBooksFineAmount}
          </p>
          <p>
            <span className="high">Per Day Fine Amount: </span>
            {rentedBookInformation?.perDayFineAmount}
          </p>

          <p className="centertext">Are you sure you want to take the Rent?</p>
        </div>
      </div>
      <Row className="my-row">
        <Col span={12} align="end">
          <Space direction="vertical" size="large">
            <Button
              htmlType="submit"
              className="submit-button"
              onClick={handleRentOk}
            >
              Rent
            </Button>
          </Space>
        </Col>
        <Col span={12} align="start">
          <Space direction="vertical" size="large">
            <Button onClick={handleRentCancel} className="newCancelButton">
              Cancel
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default RentBookModal;
