import React, { useEffect, useState } from "react";
import {
  Menu,
  Pagination,
  Input,
  Tabs,
  Button,
  Modal,
  Card,
  Row,
  Col,
  notification,
} from "antd";
import "./Authors.scss";

import BookImage from "../../../assets/images/BookImage.jpg";
import { DislikeOutlined, LeftOutlined } from "@ant-design/icons";
// import AuthorsCard from "../../components/AuthorsCard";
import { LibraryService } from "../../../Services/LibraryService";
import { useNavigate, useParams } from "react-router-dom";
import EmptyComponent from "../../Common/EmptyComponent";
import { dynamicErrorMsg } from "../../../Constants/utilitis";

const { TabPane } = Tabs;
const { Search } = Input;
const tabsData = [
  {
    tabName: "A",
    key: "1",
    tabContent: "Tab A",
  },
  {
    tabName: "B",
    key: "2",
    tabContent: "Tab B",
  },
  {
    tabName: "C",
    key: "3",
    tabContent: "Tab C",
  },
  {
    tabName: "D",
    key: "4",
    tabContent: "Tab D",
  },
  {
    tabName: "E",
    key: "5",
    tabContent: "Tab E",
  },
  {
    tabName: "F",
    key: "6",
    tabContent: "Tab F",
  },
  {
    tabName: "G",
    key: "7",
    tabContent: "Tab G",
  },
  {
    tabName: "H",
    key: "8",
    tabContent: "Tab H",
  },
  {
    tabName: "I",
    key: "9",
    tabContent: "Tab I",
  },
  {
    tabName: "J",
    key: "10",
    tabContent: "Tab J",
  },
  {
    tabName: "K",
    key: "11",
    tabContent: "Tab K",
  },
  {
    tabName: "L",
    key: "12",
    tabContent: "Tab L",
  },
  {
    tabName: "M",
    key: "13",
    tabContent: "Tab M",
  },
  {
    tabName: "N",
    key: "14",
    tabContent: "Tab N",
  },
  {
    tabName: "O",
    key: "15",
    tabContent: "Tab O",
  },
  {
    tabName: "P",
    key: "16",
    tabContent: "Tab P",
  },
  {
    tabName: "Q",
    key: "17",
    tabContent: "Tab Q",
  },
  {
    tabName: "R",
    key: "18",
    tabContent: "Tab R",
  },
  {
    tabName: "S",
    key: "19",
    tabContent: "Tab S",
  },
  {
    tabName: "T",
    key: "20",
    tabContent: "Tab T",
  },
  {
    tabName: "U",
    key: "21",
    tabContent: "Tab U",
  },
  {
    tabName: "V",
    key: "22",
    tabContent: "Tab V",
  },
  {
    tabName: "W",
    key: "23",
    tabContent: "Tab W",
  },
  {
    tabName: "X",
    key: "24",
    tabContent: "Tab X",
  },
  {
    tabName: "Y",
    key: "25",
    tabContent: "Tab Y",
  },
  {
    tabName: "Z",
    key: "26",
    tabContent: "Tab Z",
  },
];

export default function Authors({ setActiveTabKey }) {
  const [authorsData, setAuthorsData] = useState(null);
  const [allAutorsData, setAllAuthorsData] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [searchedText, setSearchedText] = useState("");
  const [authorBookList, setAuthorBookList] = useState([]);
  const [authorName, setAuthorname] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const handleTabChange = (key) => {
    if (key !== "all") {
      setSelectedTab(key);
    }
  };

  const getAuthorBooks = () => {
    const params = {
      firstAlphabet: selectedTab,
    };
    LibraryService.getAuthorBooks(params)
      .then((response) => {
        setAuthorsData(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Author Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const getAllAuthorBooks = () => {
    const params = { authorName: searchedText };

    LibraryService.getAuthorBooks(params)
      .then((response) => {
        setAllAuthorsData(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Author Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getAllAuthorBooks();
  }, [searchedText]);

  useEffect(() => {
    if (selectedTab !== null) {
      getAuthorBooks();
    }
  }, [selectedTab]);

  const handleClickAuthorCard = (item) => {
    setSelectedAuthor(item?.name);
    const params = { authorName: item?.name };
    LibraryService.authorBooks(params)
      .then((res) => {
        setAuthorBookList(res?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Author Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  const handleback = () => {
    setSelectedAuthor(null);
  };

  return (
    <div className="author-wrapper">
      <div className="first-item-container">
        <div className="book-total-container">
          <h1 className="header-text">Authors Count</h1>
          <div className="books-count-container">
            <p className="books-count">{allAutorsData?.authorCount || 0}</p>
          </div>
        </div>
        <Search
          placeholder="Search by author name"
          style={{ width: 300 }}
          onChange={(event) => setSearchedText(event.target.value)}
        />
      </div>
      <div className="second-item-container">
        {selectedAuthor ? (
          <>
            <div className="author-books-container">
              <Button onClick={handleback} className="author-book-btn">
                <LeftOutlined />
              </Button>
              <Row gutter={[16, 16]}>
                {authorBookList.length === 0 ? (
                  <div className="empty-component">
                    <EmptyComponent />
                  </div>
                ) : (
                  <>
                    {authorBookList.map((item) => (
                      <Col key={item.id} xs={12} sm={8} md={6} lg={4}>
                        <Card
                          className="author-books-card"
                          onClick={handleback}
                          cover={
                            <div
                              style={{
                                maxHeight: 200,
                                maxWidth: "100%",
                                overflow: "hidden",
                              }}
                            >
                              <img
                                alt="img"
                                src={item.image}
                                style={{
                                  maxHeight: "100%",
                                  width: "100%",
                                }}
                              />
                            </div>
                          }
                        >
                          <p> {item?.bookName}</p>
                          <p>Rate -{item.bookRate}</p>
                        </Card>
                      </Col>
                    ))}
                  </>
                )}
              </Row>
            </div>
          </>
        ) : (
          <>
            <Tabs defaultActiveKey="all" onChange={handleTabChange}>
              <TabPane tab={<span className="tab">All</span>} key="all">
                {allAutorsData?.authors?.length === 0 ? (
                  <EmptyComponent />
                ) : (
                  <div className="authors-cards-main-container">
                    {allAutorsData?.authors?.map((item) => {
                      return (
                        <div
                          className="card-container"
                          onClick={() => handleClickAuthorCard(item)}
                        >
                          <img src={item.image} alt="img" class="card-image" />
                          <div className="author-card-content-container">
                           <div className="auspace">
                           <p>Name : {item.name}</p>
                            <p>{item.email}</p>
                            <p>
                              Books Published : {item.numbersOfBooksPublished}
                            </p>
                           </div>
                            <div className="icon-container">
                              <i className="star-icon"></i>
                              <i className="star-icon"></i>
                              <i className="star-icon"></i>
                              <i className="star-icon"></i>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {/* <div className="pagination-container">
              <Pagination defaultCurrent={1} total={50} />
            </div> */}
              </TabPane>
              {tabsData?.map((tabs) => (
                <TabPane tab={tabs.tabName} key={tabs.tabName}>
                  {authorsData?.authors?.length === 0 ? (
                    <EmptyComponent />
                  ) : (
                    <div className="authors-cards-main-container">
                      {authorsData?.authors?.map((item) => {
                        return (
                          <div className="card-container">
                            <img
                              src={item.image}
                              alt="img"
                              class="card-image"
                            />
                            <div className="author-card-content-container">
                              <p>Name : {item.name}</p>
                              <p>{item.email}</p>
                              <p>
                                Books Published : {item.numbersOfBooksPublished}
                              </p>
                              <div className="icon-container">
                                <i className="star-icon"></i>
                                <i className="star-icon"></i>
                                <i className="star-icon"></i>
                                <i className="star-icon"></i>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </TabPane>
              ))}
            </Tabs>
          </>
        )}
      </div>
    </div>
  );
}
