import React, { useEffect, useState } from "react";
import "./CheckoutForm.scss";
import {
  Button,
  Col,
  Row,
  Modal,
  Form,
  Input,
  Radio,
  notification,
} from "antd";
import { BiRupee } from "react-icons/bi";
import {
  CheckCircleFilled,
  PlusOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import FloatInput from "../../Common/FloatLabel/Input";
import AddressForm from "./AddressForm";
import AddressCard from "./AddressCard";
import PaymentCard from "./PaymentCard";
import { EdpediaStoreService } from "../../../Services/EdpediaStoreService";
import Cookies from "js-cookie";
import AddressCreateModal from "../Address/AddressCreateModal";

const RadioGroup = Radio.Group;
const CheckOutForm = ({ selectedProduct, checkOutProduct }) => {
  const userId = Cookies.get("userId");
  // const { checkOutProduct } = props;
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [addressList, setAddressList] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState([]);

  const addressModalOpen = () => {
    setVisible(true);
  };
  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };

  const getAddressList = () => {
    EdpediaStoreService.addressList(userId)
      .then((response) => {
        setAddressList(response.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getAddressList();
  }, []);
  const handleRadioChange = (e) => {
    setSelectedAddressId(e.target.value);
  };

  const handleOrder = (item) => {
    const payload = {
      userId: userId,
      totalAmount: item.afterDiscountAmount + 40,
      status: "ORDER CREATED",
      products: {
        productId: item.id,
        brandId: item.brandId,
        sizeId: item.productSizes[0].sizeId,
        quantity: 1,
      },
      shippingAddressId: selectedAddressId,
      billingAddressId: selectedAddressId,
    };
    const productId = item?.productId;
    EdpediaStoreService.createOrder(payload)
      .then((response) => {
        console.log(response);
        notification.success({
          message: `Order Created Sucessful`,
          placement: "bottomRight",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 2,
        });
      })
      .catch((err) => {
        notification.success({
          message: `Order creating failed `,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "#108ee9" }} />,
        });
      });
  };

  return (
    <>
      {checkOutProduct !== null && (
        <div className="checkout-container">
          <Row gutter={{ md: 10, lg: 12 }}>
            <Col span={16}>
              <div className="price-header">
                <h3>Place Order</h3>
              </div>
              <div className="address-container">
                <RadioGroup
                  className="annoucement-btns"
                  onChange={handleTabChange}
                  value={activeTabKey}
                  optionType="button"
                  buttonStyle="solid"
                  style={{ marginBottom: 10 }}
                >
                  <Radio.Button className="RadioButtons" value="1">
                    Address
                  </Radio.Button>
                  <Radio.Button className="RadioButtons" value="2">
                    Payment
                  </Radio.Button>
                  <Radio.Button className="RadioButtons" value="3">
                    Item Delivery
                  </Radio.Button>
                </RadioGroup>
                <div className="add-address">
                  <div className="radio-container">
                    {activeTabKey === "1" && (
                      <>
                        <span
                          onClick={addressModalOpen}
                          className="add-address-text"
                        >
                          <PlusOutlined /> <span>Add Address</span>
                        </span>
                      </>
                    )}
                    {activeTabKey === "2" && (
                      <>
                        {/* <PaymentCard /> */}
                        <h4>Credit card</h4>
                        <h4>Debit card</h4>
                        <h4>Upi card</h4>
                      </>
                    )}
                    {activeTabKey === "3" && (
                      <>
                        <h1>Delivery</h1>
                      </>
                    )}
                  </div>
                </div>
                <div className="button-div">
                  <h2>Select address</h2>
                  <Radio.Group
                    onChange={handleRadioChange}
                    value={selectedAddressId}
                  >
                    {addressList?.map((item) => (
                      <Radio key={item.id} value={item.id}>
                        {`${item.fullName}, ${item.addressLine1}, ${item.addressLine2}, ${item.city}`}
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span={7}>
              <div className="price-details-container">
                <div className="price-header">
                  {" "}
                  <h3>Price Details</h3>
                </div>
                <div className="single-item">
                  <span>
                    Actual Price :<BiRupee />
                    {selectedProduct?.actualPrice}
                  </span>
                </div>
                <div className="single-item">
                  <span>
                    Discount : <BiRupee />
                    {selectedProduct?.actualPrice -
                      selectedProduct?.afterDiscountAmount}
                  </span>
                </div>
                <div className="single-item">
                  <span>
                    Final Price :<BiRupee />
                    {selectedProduct?.afterDiscountAmount}
                  </span>
                </div>
                <div className="single-item">
                  <span>
                    Deliver Charges : <BiRupee />
                    40
                  </span>
                </div>

                <div className=" total single-item">
                  <span>
                    Total Amount : <BiRupee />
                    {selectedProduct?.afterDiscountAmount + 40}
                  </span>
                </div>

                <div className="saving">
                  <span>You saved 500 in this offer .</span>
                </div>

                <div className="delivery-time">
                  <span>Delivery in 2 Dec-10 Dec </span>
                  {/* <Button className="submitButton"> Enter Details</Button> */}
                </div>
                <Button onClick={() => handleOrder(selectedProduct)}>
                  Place Order
                </Button>
              </div>
            </Col>
          </Row>
          {/* <AddressForm
            showAddressModal={showAddressModal}
            setShowAddressModal={setShowAddressModal}
          /> */}
          <AddressCreateModal
            visible={visible}
            setVisible={setVisible}
            selectedAddress={selectedAddress}
            getAddressList={getAddressList}
          />
        </div>
      )}
    </>
  );
};

export default CheckOutForm;
