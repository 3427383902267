import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Select,
  Modal,
  Row,
  Col,
  Upload,
  message,
  Space,
  notification,
  Table,
  DatePicker,
} from "antd";
import {
  CloseSquareOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  LikeTwoTone,
  DislikeOutlined,
  CheckCircleFilled,
  DownloadOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import "dayjs/locale/en"; // Import the locale if needed

import { AssignmentApiService } from "../../../Services/AssignmentServices";
import { dateWithTime, dynamicErrorMsg, yearDateFormat } from "../../../Constants/utilitis";
import { createErrorMessage, updateErrorMessage, deleteErrorMessage } from "../../../Constants/utilitis";
import Cookies from "js-cookie";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "./CreateAssignment.scss";
import _ from "lodash";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import DatePickerFloatLabel from "../../Common/FloatLabel/DatePicker";
import { Assignment, DeleteOutline } from "@mui/icons-material";
import axios from "axios";
import moment from "moment";
import FileView from "../../../Utils/FileView";
import TextArea from "../../Common/FloatLabel/TextArea";
const CreateAssignments = ({
  isModalVisible1,
  setIsModalVisible1,
  editStaffRow,
  setEditStaffRow,
  setReloadAssignmentData,
  modalRowData,
  isModalVisible,
  setIsModalVisible,
  assignmentLists,
}) => {
  const [classOptions, setClassOptions] = useState(null);
  const [sectionOptions, setSctionOptions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [heading, setHeading] = useState(null);
  const [view, setView] = useState(false);
  const [defaultSubject, setDefaultSubject] = useState(null);
  const { Option } = Select;
  const [assignmentForm] = Form.useForm();

  const subjectsInfo = Cookies.get("subjectsInfo")
    ? JSON.parse(Cookies.get("subjectsInfo"))
    : [];
  const classInfo = Cookies.get("classInfo")
    ? JSON.parse(Cookies.get("classInfo"))
    : [];

  useEffect(() => {
    const classWithAssignment = classInfo?.filter(
      (item) => parseInt(item?.classId) == editStaffRow?.classId
    );
    const subjectWithAssignment = subjectsInfo?.filter(
      (item) =>
        parseInt(item?.subjectId) === editStaffRow?.subjectId &&
        parseInt(item?.classId) === editStaffRow?.classId
    );
    setSelectedSubject(subjectWithAssignment[0]?.subjectId);

    const subjects = getSubjectsByClassName(
      classWithAssignment[0]?.className,
      classInfo,
      subjectsInfo
    );

    setSubjectOptions(subjects);

    // const dueDates = yearDateFormat(editStaffRow?.dueDate);
    // const givenDates = yearDateFormat(editStaffRow?.givenDate);
    const dueDates =
      editStaffRow?.dueDate === undefined
        ? dayjs(new Date(), "YYYY-MM-DD")
        : dayjs(editStaffRow?.dueDate, "YYYY-MM-DD");

    const givenDates =
      editStaffRow?.givenDate === undefined
        ? dayjs(new Date(), "YYYY-MM-DD")
        : dayjs(editStaffRow?.givenDate, "YYYY-MM-DD");
    setSelectedSection(classWithAssignment[0]?.section);

    if (editStaffRow?.assignmentId) {
      assignmentForm.setFieldsValue({
        className: classWithAssignment[0]?.className,
        assignmentTitle: editStaffRow?.assignmentTitle,
        dueDate: dueDates,
        givenDate: givenDates,
        reviewStatus: editStaffRow?.reviewStatus,
        status: editStaffRow?.status,
        type: editStaffRow?.type,
        mode: editStaffRow?.mode,
        description: editStaffRow?.description,
      });
    } else {
      assignmentForm.resetFields();
    }
  }, [editStaffRow, assignmentForm]);

  const onFinish = (values) => {
    const assignmentName =
      values.assignmentTitle.charAt(0).toUpperCase() +
      values.assignmentTitle.slice(1);

    const id = editStaffRow?.assignmentId;

    const classId = classInfo.filter(
      (item) => item.className === values.className
    )[0]?.classId;

    const dueDates = yearDateFormat(values.dueDate);
    const givenDate = yearDateFormat(values.givenDate);

    const formData = new FormData();
    formData.append("classId", parseInt(classId));
    formData.append("subjectId", parseInt(selectedSubject));
    formData.append("assignmentTitle", values.assignmentTitle);
    formData.append("givenDate", givenDate);
    formData.append("dueDate", dueDates);
    formData.append("type", values.type);
    formData.append("mode", values.mode);
    formData.append("description", values.description);
    fileList.map((file, i) => {
      formData.append(`supportingDocs`, file);
    });

    if (id) {
      formData.append("reviewStatus", values.reviewStatus);
    }
    if (!id) {
      AssignmentApiService.createAssignment(formData)
        .then((response) => {
          setIsModalVisible1(false);
          assignmentLists();
          notification.success({
            message: `${assignmentName} Created Sucessfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          assignmentForm.resetFields();
          setEditStaffRow(null);
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Create", "Assignment")
          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
          });
    } else {
      AssignmentApiService.editAssignment(id, formData)
        .then((response) => {
          setIsModalVisible1(false);
          assignmentLists();
          notification.success({
            message: `${assignmentName} Updated Sucessfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          assignmentForm.resetFields();
          setEditStaffRow(null);
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Update", "Assignment")
          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
          });
    }
  };

  useEffect(() => {
    classList();
  }, []);

  /* class state*/
  useEffect(() => {
    const uniqueClasses = _.uniqBy(classInfo, "className").map((getClass) => {
      return { label: getClass.className, value: getClass.className };
    });
    setClassOptions(uniqueClasses);
  }, []);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /*get subject*/
  function getSubjectsByClassName(classID, classList, subjectList) {
    var classId;
    for (var i = 0; i < classList.length; i++) {
      if (classList[i].classId == classID) {
        classId = classList[i].classId;
        break;
      }
    }
    var matchingSubjects = [];
    for (var i = 0; i < subjectList.length; i++) {
      if (subjectList[i].classId === classId) {
        matchingSubjects.push({
          label: capitalize(subjectList[i].subjectName),
          value: subjectList[i].subjectId,
        });
      }
    }

    return matchingSubjects;
  }

  /*get section*/
  const getsectionOptionsFromClass = (className, classList) => {
    const filteredSection = classList?.filter(
      (item) => item.className === className
    );

    const getSection = filteredSection?.map((item) => {
      return {
        label: item.section,
        value: item.classId,
      };
    });
    return getSection ?? [];
  };

  const handleClassDropdown = (e) => {
    setSelectedSection([]);
    setSelectedSubject([]);

    const sectionOptions = getsectionOptionsFromClass(e, classInfo);

    const uniqueSubjectIds = subjectsInfo.filter(
      (subject) => subject.classId == sectionOptions?.classId
    );
    // Filter sectionOptions based on uniqueSubjectIds
    const filteredSectionOptions = sectionOptions.filter((section) =>
      uniqueSubjectIds.includes(section.value)
    );

    if (Array.isArray(sectionOptions)) {
      const sort = sectionOptions.sort((a, b) => {
        if (a.label && b.label) {
          return a.label.localeCompare(b.label);
        } else {
          return 0;
        }
      });

      setSctionOptions(sort);
    } else {
      setSctionOptions([]);
    }
  };

  const handleSectionChange = (val) => {
    setSelectedSection(val);

    const subjects = getSubjectsByClassName(val, classInfo, subjectsInfo);

    setSubjectOptions(subjects);
  };

  const handleSubject = (val) => {
    setSelectedSubject(val);
  };

  const classList = () => {
    classInfo.map((item) => {
      const classes = [];
      if (item?.classId == editStaffRow?.classId) {
        classes.push(item);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    // message.error("failed to Add Aassignment");
  };
  const handleOk = () => {
    setIsModalVisible1(false);
  };

  const handleCancel = () => {
    setIsModalVisible1(false);
    setEditStaffRow(null);
    assignmentForm.resetFields();
  };

  const handleUpload = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList(files);
  };

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const disabledEndDate = (current) => {
    const startDate = assignmentForm.getFieldValue("givenDate");
    return current && current < startDate?.startOf("day");
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const downloadAassignment = (record) => {
    setView(true);
    setFileData(record?.supportDocUrls);
    setHeading(record?.assignmentTitle);
  };

  const handleFileView = (url, record) => {
    setView(true);
    setFileData([url]);
    setHeading(record?.assignmentTitle);
  };

  const datas = [
    {
      field: "Class Name",
      value:
        classInfo.find((c) => c.classId == modalRowData.classId)?.className ||
        "N/A",
    },
    {
      field: "Section",
      value:
        classInfo.find((c) => c.classId == modalRowData.classId)?.section ||
        "N/A",
    },
    {
      field: "Subject Name",
      value:
        subjectsInfo.find((c) => c.subjectId == modalRowData.subjectId)
          ?.subjectName || "N/A",
    },
    {
      field: "assignmentTitle",
      value: modalRowData?.assignmentTitle,
    },
    {
      field: "dueDate",
      value: modalRowData?.dueDate,
    },
    {
      field: "givenDate",
      value: modalRowData?.givenDate,
    },
    {
      field: "mode",
      value: modalRowData?.mode,
    },
    {
      field: "description",
      value: modalRowData?.description,
    },
    {
      field: "Type",
      value: modalRowData?.type,
    },
    {
      field: "supporting Docs",
      value: modalRowData?.supportDocUrls ? (
        <FolderOpenOutlined
          style={{
            fontSize: "30px",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => {
            downloadAassignment(modalRowData);
          }}
        />
      ) : (
        "N/A"
      ),
    },
  ];

  const viewColumns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const handleDeleteFile = (url, index) => {
    const assignmentId = editStaffRow?.assignmentId;
    const params = {
      url: url,
    };
    AssignmentApiService.deleteIndividualFile(assignmentId, params)
      .then((res) => {
        assignmentLists();
        notification.success({
          message: `Delete Assignment Sucessfully`,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
        if (editStaffRow) {
          const updatedSupportDocUrls = [...editStaffRow.supportDocUrls];
          updatedSupportDocUrls.splice(index, 1);
          setEditStaffRow((prevRow) => ({
            ...prevRow,
            supportDocUrls: updatedSupportDocUrls,
          }));
        }
        setFileList([]);
        assignmentLists();
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Delete", "Assignment")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  return (
    <>
      <Modal
        title={
          editStaffRow && Object.keys(editStaffRow).length > 0
            ? "Edit Assignment"
            : "Create Assignment"
        }
        className="assignment-modal"
        open={isModalVisible1}
        onOk={handleOk}
        width={700}
        onCancel={handleCancel}
        centered
        okButtonProps={{
          style: {
            display: "none",
          },
        }}
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
      >
        <Form
          name="AssignmentForm"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={assignmentForm}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="assignmentTitle"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  className="float-inputs"
                  label="Assignment Title"
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item name="mode" rules={[{ required: true, message: "" }]}>
                <SelectFloatLabel label="Assignment Mode">
                  <Option value="Online">Online</Option>
                  <Option value="Offline">Offline</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="className"
                rules={[{ required: true, message: "" }]}
              >
                <SelectFloatLabel
                  dropdownStyle={{ height: "200px" }}
                  options={classOptions}
                  onChange={handleClassDropdown}
                  label="Select Class"
                  name="className"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                rules={[{ required: true, message: "" }]}
                // name="section"
              >
                <SelectFloatLabel
                  onChange={handleSectionChange}
                  value={selectedSection}
                  options={sectionOptions}
                  // name="section"
                  label="Select Section"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item rules={[{ required: true, message: "" }]}>
                <Select
                  style={{ height: 40, width: "310px" }}
                  value={selectedSubject}
                  onChange={handleSubject}
                  placeholder="Subject"
                >
                  {subjectOptions?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <h1>{defaultSubject}</h1>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <SelectFloatLabel label="Assignment Type">
                  <Option value="diagram">Diagram</Option>
                  <Option value="essay">Essay</Option>
                  <Option value="problems">Problems</Option>
                  <Option value="research">Research</Option>
                  <Option value="presentation">Prsentation</Option>
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            {editStaffRow && Object.keys(editStaffRow).length < 0 ? (
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="reviewStatus"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <SelectFloatLabel label="Review Status">
                    <Option value="Inprogress">Inprogress</Option>
                    <Option value="Pending">Pending</Option>
                    <Option value="Complete">Complete</Option>
                  </SelectFloatLabel>
                </Form.Item>
              </Col>
            ) : (
              ""
            )}

            <Col span={12}>
              <Form.Item
                name="givenDate"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <DatePicker
                  id="inputs"
                  name="givenDate"
                  style={{ width: 300 }}
                  placeholder="Given Date"
                  format={yearDateFormat}
                  disabledDate={disabledStartDate}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="dueDate"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                style={{ width: 945 }}
              >
                <DatePicker
                  className="float-inputs"
                  placeholder="Due Date"
                  name="dueDate"
                  style={{ width: 335 }}
                  disabledDate={disabledEndDate}
                  format={yearDateFormat}
                />
              </Form.Item>
            </Col>

            {editStaffRow && Object.keys(editStaffRow).length > 0 ? (
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="reviewStatus"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <SelectFloatLabel label="Review Status">
                    <Option value="Inprogress">Inprogress</Option>
                    <Option value="Pending">Pending</Option>
                    <Option value="Complete">Complete</Option>
                  </SelectFloatLabel>
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <TextArea
                  label="Description"
                  rows={8}
                  // maxLength={100}
                  autoSize={{ minRows: 4, maxRows: 5 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="supportingDocs">
                <Upload
                  onChange={handleUpload}
                  type="file"
                  accept=".png, .jpg, .jpeg .pdf"
                  multiple
                  showUploadList={true}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />} style={{ width: 310 }}>
                    Upload Assignment
                  </Button>
                </Upload>
              </Form.Item>

              {editStaffRow && Object.keys(editStaffRow).length > 0 ? (
                <div className="file_uploading-icon">
                  {editStaffRow.supportDocUrls.map((url, index) => {
                    let fileIcon;

                    return (
                      <div key={index} className="file-wrapper">
                        <FilePdfOutlined
                          style={{ fontSize: "30px", color: "black" }}
                          onClick={() => handleFileView(url, editStaffRow)}
                        />
                        <CloseSquareOutlined
                          onClick={() => handleDeleteFile(url, index)}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        open={isModalVisible}
        title="Assignment Details"
        onCancel={handleModalCancel}
        footer={null}
        width={600}
        centered
        className="view-assignment"
      >
        {modalRowData && (
          <Table
            columns={viewColumns}
            dataSource={datas}
            pagination={false}
            showHeader={false}
          />
        )}
      </Modal>
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />
    </>
  );
};

export default CreateAssignments;
