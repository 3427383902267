import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.scss";
import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import Edpedia from "../../../src/assets/icons/Edpedia.svg";
import {
  announcements,
  assignments,
  attendance,
  exams,
  holidays,
  library,
  meetings,
  results,
  studymaterial,
  subjects,
  syllabus,
} from "../../assets/icons/";
import {
  AppstoreOutlined,
  TableOutlined,
  UserOutlined,
  CalendarOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const Sidebar = ({ getToken, ...props }) => {
  const [select, setSelect] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    // setCollapsed(!collapsed);
  };

  function handleClick(index) {
    setSelect(index);
    // localStorage.setItem("selectedItem", index);
  }

  // const storedValue = localStorage.getItem("selectedItem");

  // useEffect(() => {
  //   if (storedValue) {
  //     setSelect(storedValue);
  //   } else {
  //     setSelect("1");
  //   }
  // }, [select, storedValue]);

  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("/timetable")) {
      setSelect("timetable");
    } else if (pathname.includes("/subjects")) {
      setSelect("subjects");
    } else if (pathname.includes("/syllabus")) {
      setSelect("syllabus");
    } else if (pathname.includes("/library")) {
      setSelect("library");
    } else if (pathname.includes("/exams")) {
      setSelect("exams");
    } else if (pathname.includes("/results")) {
      setSelect("results");
    } else if (pathname.includes("/assignments")) {
      setSelect("assignments");
    } else if (pathname.includes("/studymaterials")) {
      setSelect("studyMaterials");
    } else if (pathname.includes("/holidays")) {
      setSelect("holidays");
    } else if (pathname.includes("/announcement")) {
      setSelect("announcement");
    } else if (pathname.includes("/meetings")) {
      setSelect("meetings");
    } else if (pathname.includes("/dashboard")) {
    } else if (pathname.includes("/students")) {
      setSelect("students");
    } else if (pathname.includes("/studentattendance")) {
      setSelect("studentattendance");
    } else if (pathname.includes("/myattendance")) {
      setSelect("myattendance");
    }
  }, [location]);

  const navigate = useNavigate();

  const onHandleTap = () => {
    localStorage.setItem("selectedItem", "");
    setSelect("");
    navigate("/");
  };

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem(
      <Link to="/" className="link">
        Dashboard
      </Link>,
      "dashboard",
      <AppstoreOutlined />
    ),
    getItem(
      <Link to="/students" className="link">
        Students
      </Link>,
      "students",
      <UserOutlined />
    ),
    getItem(
      <Link to="/homework" className="link">
        Homework
      </Link>,
      "homework",
      <BookOutlined />
    ),
    getItem(
      <Link to="/timetable" className="link">
        Time Table
      </Link>,
      "timetable",
      <TableOutlined />
    ),

    getItem(
      <Link to="/myattendance" className="link">
        My Attendance
      </Link>,
      "myattendance",
      <img src={attendance} alt="attendance" className="custom-icon" />
    ),
    getItem(
      <Link to="/studentattendance" className="link">
        Student Attendance
      </Link>,
      "studentattendance",
      <UserOutlined />
    ),
    getItem(
      <Link to="/payslips" className="link">
        Payslips
      </Link>,
      "payslip",
      <UserOutlined />
    ),

    getItem(
      <Link to="/subjects" className="link">
        Subjects
      </Link>,
      "subjects",
      <img src={subjects} alt="subjects" className="custom-icon" />
    ),
    getItem(
      <Link to="/syllabus">Syllabus</Link>,
      "syllabus",
      <img src={syllabus} alt="syllabus" className="custom-icon" />
    ),
    getItem(
      <Link to="/library">Library</Link>,
      "library",
      <img src={library} alt="library" className="custom-icon" />
    ),

    getItem(
      <Link to="/exams" className="link">
        Exams
      </Link>,
      "exams",
      <img src={exams} alt="exams" className="custom-icon" />
    ),
    getItem(
      <Link to="/results" className="link">
        Results
      </Link>,
      "results",
      <img src={results} alt="results" className="custom-icon" />
    ),

    getItem(
      <Link to="/assignments" className="link">
        Assignments
      </Link>,
      "assignments",
      <img src={assignments} alt="assignment" className="custom-icon" />
    ),
    getItem(
      <Link to="/studymaterials" className="link">
        Study Material
      </Link>,
      "studyMaterials",
      <img src={studymaterial} alt="studyMaterrials" className="custom-icon" />
    ),
    getItem(
      <Link to="/holidays" className="link">
        Holiday
      </Link>,
      "holidays",
      <img src={holidays} alt="holidays" className="custom-icon" />
    ),
    getItem(
      <Link to="/leaves" className="link">
        Leaves Managemet
      </Link>,
      "leaves",
      <CalendarOutlined />
    ),
    getItem(
      <Link to="/transport" className="link">
        Transport
      </Link>,
      "transport",
      <img src={holidays} alt="holidays" className="custom-icon" />
    ),
    getItem(
      <Link to="/Edpedia-store" className="link">
        Edpedia Store
      </Link>,
      "Edpedia-store",
      <img src={holidays} alt="EdpediaStoreHome" className="custom-icon" />
    ),
    getItem(
      <Link to="/announcement">Announcements</Link>,
      "announcement",
      <img src={announcements} alt="announcement" className="custom-icon" />
    ),

    getItem(
      <Link to="/meetings">1 : 1 Meetings</Link>,
      "meetings",
      <img src={meetings} alt="meetings" className="custom-icon" />
    ),
    getItem(
      <Link to="/reimbursement">Reimbursement</Link>,
      "reimbursement",
      <img src={announcements} alt="reimbursement" className="custom-icon" />
    ),
  ];

  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];
  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    if (collapsed) {
      document.body.classList.add("collapsed");
    } else {
      document.body.classList.remove("collapsed");
    }
  }, [collapsed]);

  return (
    <div className="leftmenu-block">
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={80}
      >
        <div
          className={`${collapsed ? "collapsed" : ""}`}
          onMouseEnter={toggleCollapsed}
          onMouseLeave={toggleCollapsed}
        >
          <div className="topnavlogo">
            <div className="edpedia-logo-container">
              <a
                href="https://www.edpedia.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Edpedia} alt="Edpedia" className="edpedia-logo" />
              </a>
            </div>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={select}
              onClick={({ key }) => handleClick(key)}
              defaultOpenKeys={["classdetails"]}
              mode="inline"
              className="leftmenu"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              inlineCollapsed={collapsed}
              items={items}
            />
          </div>
        </div>
      </Sider>
    </div>
  );
};

export default Sidebar;
