import {
  CREATE_HOMEWORK,
  DELETE_HOMEWORK,
  GET_HOMEWORK,
} from "../Constants/constants";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "./Api";

const createHomework = (payLoad, params) => {
  return PostAPIRequest({ url: CREATE_HOMEWORK, data: payLoad, params });
};
const getHomework = (staffId, params) => {
  return GetAPIRequest({
    url: GET_HOMEWORK.replace("{staffId}", staffId),
    params,
  });
};
const deleteHomework = (staffId, params) => {
  return DeleteAPIRequest({
    url: DELETE_HOMEWORK.replace("{staffId}", staffId),
    params,
  });
};
export const HomeworkService = { createHomework, getHomework, deleteHomework };
