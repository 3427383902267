import React, { useEffect } from "react";
import StudentAttendance from "../../components/Attendance/StudentAttendaceHome/StudentAttendance/Attendance";
import StaffAttendance from "../../components/Attendance/MyAttendaceHome/StaffAttendance";
import Regularization from "../../components/Attendance/MyAttendaceHome/RequestAttendance/Regularization";
import SEO from "../../components/reuse";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Radio } from "antd";
import RequestStudentAttendace from "../../components/Attendance/StudentAttendaceHome/RequestStudentAttendace/RequestStudentAttendace";
const RadioGroup = Radio.Group;
const AttendanceList = () => {
  const [activeTabKey, setActiveTabKey] = useState("selfAttendance");
  const location = useLocation();
  const studentAttendance = location.state;

  const [time, setTime] = useState(
    new Date().toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  );

  useEffect(() => {
    if (studentAttendance === "studentAttendance") {
      setActiveTabKey("studentAttendance");
    } else {
      setActiveTabKey("selfAttendance");
    }
  }, [studentAttendance]);

  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(
        new Date().toLocaleTimeString("en-IN", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <main>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <RadioGroup
          onChange={handleTabChange}
          value={activeTabKey}
          optionType="button"
          buttonStyle="solid"
        >
          <Radio.Button value="selfAttendance">My Attendance</Radio.Button>
          <Radio.Button value="studentAttendance">
            Student Attendance
          </Radio.Button>
          <Radio.Button value="requestAttendance">
            Request Staff Attendance
          </Radio.Button>
          <Radio.Button value="requestStudentAttendance">
            Request Student Attendance
          </Radio.Button>
        </RadioGroup>
        {/* <div style={{ textAlign: "center" }}>
          <h4>
            {formattedDate} : {dayOfWeek}, {formattedTime}
          </h4>
        </div> */}
      </div>
      <SEO title="attendance" />
      {activeTabKey === "selfAttendance" && (
        <div>
          <StaffAttendance />
        </div>
      )}
      {activeTabKey === "studentAttendance" && (
        <div>
          <StudentAttendance />
        </div>
      )}
      {activeTabKey === "requestAttendance" && <Regularization />}
      {activeTabKey === "requestStudentAttendance" && (
        <RequestStudentAttendace />
      )}
    </main>
  );
};

export default AttendanceList;
