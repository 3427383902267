import {
  GET_ALL_CATEGORIES_PRODUCTS_LIST,
  FIND_CATEGORY_PRODUCTS_URL,
  FIND_CATEGORY_PRODUCTS_URL_ENDPOINT,
  FIND_SUBCATEGORY,
  FIND_SUBCATEGORY_ENDPOINT,
  GET_PRODUCTS_USING_SUB_CATEGORYOID,
  GET_PRODUCTS_USING_SUB_CATEGORYOID_ENDPOINT,
  FIND_ADDRESS_BY_USER,
  FIND_ADDRESS_BY_USER_ENDPOINT,
  CREATE_ADDRESS,
  CREATE_ADDRESS_ENDPOINT,
  EDIT_ADDRESS,
  EDIT_ADDRESS_ENDPOINT,
  DELETE_ADDRESS,
  DELETE_ADDRESS_ENDPOINT,
  CART_LIST,
  ADD_PRODUCT_TO_CART_ENDPOINT,
  ADD_PRODUCT_TO_CART,
  DELETE_PRODUCT_FROM_CART,
  DELETE_PRODUCT_FROM_CART_ENDPOINT,
  USER_ORDER_LIST,
  USER_ORDER_LIST_ENDPOINT,
  ORDER_FROM_CART,
  ORDER_FROM_CART_ENDPOINT,
  GET_WISHLIST_USING_BRANCHCODE,
  GET_WISHLIST_USING_BRANCHCODE_ENDPOINT,
  WISHLIST_ADD,
  REMOVE_WISHLIST,
  REMOVE_WISHLIST_ENDPOINT,
  CREATE_ORDER,
} from "../Constants/constants";
import { DeleteAPIRequest, GetAPIRequest, PostAPIRequest } from "./Api";

// category

const getProductsCategoriesList = () => {
  return GetAPIRequest({
    url: GET_ALL_CATEGORIES_PRODUCTS_LIST,
  });
};

const findCategoryProducts = (categoryId) => {
  return GetAPIRequest({
    url:
      FIND_CATEGORY_PRODUCTS_URL +
      categoryId +
      FIND_CATEGORY_PRODUCTS_URL_ENDPOINT,
  });
};

// sub category
const findSubCategoryList = (categoryId) => {
  return GetAPIRequest({
    url: FIND_SUBCATEGORY + categoryId + FIND_SUBCATEGORY_ENDPOINT,
  });
};
//products
const getProductBySubCategoryId = (subcategoryId) => {
  return GetAPIRequest({
    url:
      GET_PRODUCTS_USING_SUB_CATEGORYOID +
      subcategoryId +
      GET_PRODUCTS_USING_SUB_CATEGORYOID_ENDPOINT,
  });
};

// address
const addressList = (userId) => {
  return GetAPIRequest({
    url: FIND_ADDRESS_BY_USER + userId + FIND_ADDRESS_BY_USER_ENDPOINT,
  });
};

const createAddress = (userId, payload) => {
  return PostAPIRequest({
    url: CREATE_ADDRESS + userId + CREATE_ADDRESS_ENDPOINT,
    data: payload,
  });
};

const editAddress = (addressId, payload) => {
  return PostAPIRequest({
    url: EDIT_ADDRESS + addressId + EDIT_ADDRESS_ENDPOINT,
    data: payload,
  });
};

const deleteAddress = (addressId) => {
  return DeleteAPIRequest({
    url: DELETE_ADDRESS + addressId + DELETE_ADDRESS_ENDPOINT,
  });
};

// cart
const cartList = (userId) => {
  return GetAPIRequest({
    url: CART_LIST + userId,
  });
};

const addProductToCart = (userId, payload) => {
  return PostAPIRequest({
    url: ADD_PRODUCT_TO_CART + userId + ADD_PRODUCT_TO_CART_ENDPOINT,
    data: payload,
  });
};

const deleteProductFromCart = (userId, productId) => {
  return DeleteAPIRequest({
    url:
      DELETE_PRODUCT_FROM_CART +
      userId +
      DELETE_PRODUCT_FROM_CART_ENDPOINT +
      productId,
  });
};

// order
const orderList = (userId) => {
  return GetAPIRequest({
    url: USER_ORDER_LIST + userId + USER_ORDER_LIST_ENDPOINT,
  });
};

const orderProductsFromCart = (cartId, payload) => {
  return PostAPIRequest({
    url: ORDER_FROM_CART + cartId + ORDER_FROM_CART_ENDPOINT,
    data: payload,
  });
};

const createOrder = (payload) => {
  return PostAPIRequest({
    url: CREATE_ORDER,
    data: payload,
  });
};

const getWishlistData = (branchId) => {
  return GetAPIRequest({
    url:
      GET_WISHLIST_USING_BRANCHCODE +
      branchId +
      GET_WISHLIST_USING_BRANCHCODE_ENDPOINT,
  });
};
const addInWishList = (payload) => {
  return PostAPIRequest({ url: WISHLIST_ADD, data: payload });
};
const removeFromWishlist = (productId) => {
  return DeleteAPIRequest({
    url: REMOVE_WISHLIST + "EDBIN1001" + REMOVE_WISHLIST_ENDPOINT + productId,
  });
};

export const EdpediaStoreService = {
  getProductsCategoriesList,
  findCategoryProducts,
  findSubCategoryList,
  getProductBySubCategoryId,
  addressList,
  createAddress,
  editAddress,
  deleteAddress,
  cartList,
  addProductToCart,
  deleteProductFromCart,
  orderList,
  createOrder,
  orderProductsFromCart,
  getWishlistData,
  addInWishList,
  removeFromWishlist,
};
