import { useEffect, useState } from "react";
import ClassDropdown from "../Common/Class/ClassDropdown";
import SectionDropdown from "../Common/Section/SectionDropdown";
import SubjectDropdown from "../Common/Subject/SubjectDropdown";
import { EditOutlined, PlusOutlined, DislikeOutlined } from "@ant-design/icons";
import { Button, Space, Table, notification } from "antd";
import "./homework.scss";
import Cookies from "js-cookie";
import { HomeworkService } from "../../Services/HomeworkService";
import CreateHomework from "./createHomework";
import { dynamicErrorMsg } from "../../Constants/utilitis";

const Homework = () => {
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSectionn, setSelectedSection] = useState(null);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [homework, setHomework] = useState([]);
  const [editRow, seteditRow] = useState(null);
  const onClassChange = (value) => {
    setSelectedClass(value);
    setSelectedSubject("");
  };
  const onSubjectChange = (value) => {
    setSelectedSubject(value);
  };
  const onSectionChange = (value) => {
    setSelectedSection(value);
    // setSelectedSubject(null);
  };
  const handleEditExpense = (record) => {
    seteditRow(record);
    setIsModalVisible1(true);
  };
  const showModal = () => {
    setIsModalVisible1(true);
  };
  console.log("class is here", selectedSectionn);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  console.log("staffid for the good", staffId);
  const getHomeworks = () => {
    if (!selectedSectionn) {
      return;
    }
    const params = {
      classId: selectedSectionn.classId,
      //subjectId: selectedSubject,
    };
    HomeworkService.getHomework(staffId, params)
      .then((response) => {
        setHomework(response?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Homework")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    selectedSectionn && getHomeworks();
  }, [selectedSectionn]);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);

        return dateA - dateB;
      },
      defaultSortOrder: "descend",
    },
    {
      title: "subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
    },
    {
      title: "task",
      dataIndex: "task",
      key: "task",
      align: "center",
      sorter: (a, b) => a.task.localeCompare(b.task),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space>
          <EditOutlined onClick={() => handleEditExpense(record)} />
        </Space>
      ),
    },
  ];
  return (
    <main>
      <div className="main-container">
        <h1>Homework</h1>
        <div className="fields-container">
          <div>
            <ClassDropdown
              onClassChange={onClassChange}
              selectedClass={selectedClass}
            />
          </div>
          <div>
            <SectionDropdown
              classValue={selectedClass}
              onSectionChange={onSectionChange}
              // selectedSection={selectedSection}
            />
          </div>
          <div>
            <SubjectDropdown
              selectedSubject={selectedSubject}
              onSubjectChange={onSubjectChange}
              selectedClass={selectedClass}
              style={{ width: 200 }}
            />
          </div>
          <Button className="blue-button" onClick={showModal}>
            <PlusOutlined className="assignment-icon" />
            Create Homework
          </Button>
        </div>
      </div>
      <Table
        dataSource={homework}
        columns={columns}
        showSorterTooltip={false}
        pagination={false}
      />
      {isModalVisible1 && (
        <CreateHomework
          setIsModalVisible1={setIsModalVisible1}
          selectedStaff = {staffInfo.staffId}
          isModalVisible1={isModalVisible1}
          seteditRow={seteditRow}
          editRow={editRow}
          getHomeworks={getHomeworks}
          selectedSectionn={selectedSectionn}
        />
      )}
    </main>
  );
};
export default Homework;
