import React from "react";
import { Topbar, Study } from "../../components";
import SEO from "../../components/reuse";

function Studys() {
  return (
    <main>
      {/* <Topbar /> */}
      <SEO title="study" />
      <Study />
    </main>
  );
}

export default Studys;
