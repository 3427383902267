import {
  DatePicker,
  Empty,
  List,
  Space,
  Table,
  notification,
  Select,
  Button,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  EyeOutlined,
  CloseOutlined,
  CheckOutlined,
  DislikeOutlined,
  CheckCircleFilled,
  FileOutlined,
} from "@ant-design/icons";

import { attendanceService } from "../../Services/AttendanceService";
import Cookies from "js-cookie";
import FileView from "../../Utils/FileView";
import moment from "moment";
import "./Payslips.scss";
import dayjs from "dayjs";
import axios from "axios";
import { downloadErrorMessage, dynamicErrorMsg } from "../../Constants/utilitis";
const Payslips = () => {
  const { Option } = Select;
  const [payslipData, setPayslipData] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [heading, setHeading] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [view, setView] = useState(false);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  console.log("staffId", staffId);
  useEffect(() => {
    getPayslip();
  }, [selectedMonth, selectedYear]);

  const getPayslip = () => {
    const params = {
      year: selectedYear,
      month: selectedMonth,
    };

    attendanceService
      .generatePayslips(staffId, params)
      .then((res) => {
        setPayslipData(res.data);
      })
      .catch((err) => {});
  };

  const downloadStudents = (row) => {
    return axios.get(
      `https://api.edpedia.co/v1.0/instAdmin/api/storage/download?url=${row?.docUrl}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
      }
    );
  };

  function downloadFile(data, filename) {
    const blob = new Blob([data], { type: "application/octet-stream" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    // Download after 1 second to ensure file has been fully downloaded
    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }

  const downloadResultsScoreCardDetails = (row) => {
    downloadStudents(row)
      .then((response) => {
        if (response && response.data) {
          downloadFile(response.data, `${row?.month}_${row?.year}_Payslip.pdf`);
          //   getStaffPayslips();
          notification.success({
            message: "The File is downloading...",
            description: `Payslip are downloading sucessfully `,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        } else {
        }
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Download","Student score card")
      
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const getLastThreeYears = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 3 }, (_, index) => currentYear - index).map(
      (year) => (
        <Option key={year} value={year}>
          {year}
        </Option>
      )
    );
  };

  const getMonthOptions = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.map((month, index) => (
      <Option key={index} value={month}>
        {month}
      </Option>
    ));
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  return (
    <main className="payslip-container">
      <div
        className="payslip-filtes"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <h1>Payslip</h1>

        <div style={{ display: "flex" }}>
          <Select
            style={{ width: 120, marginLeft: 10 }}
            value={selectedYear}
            onChange={handleYearChange}
            placeholder="Select Year"
          >
            {getLastThreeYears()}
          </Select>
          <Select
            style={{ width: 120 }}
            value={selectedMonth}
            onChange={handleMonthChange}
            placeholder="Select Month"
          >
            {getMonthOptions()}
          </Select>
        </div>
      </div>

      <div className="payslip-cards">
        {payslipData.length > 0 ? (
          payslipData.map((item) => (
            <Card key={item.id} title={item.month} className="payslip-card">
              {/* <p>Year : {item.year}</p>
              <p>Month : {item.month}</p> */}

              <div className="file-wrapper">
                {item.docUrl && item.docUrl !== "" ? (
                  <FileOutlined
                    className="file-style"
                    onClick={() => downloadResultsScoreCardDetails(item)}
                  />
                ) : (
                  ""
                )}
              </div>
            </Card>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // height: "100vh",
              margin: "auto",
            }}
          >
            <Empty description="No payslips available" />
          </div>
        )}
      </div>
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />
    </main>
  );
};

export default Payslips;
