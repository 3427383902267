import {
  DownloadOutlined,
  FileDoneOutlined,
  FilePdfOutlined,
  FolderOpenOutlined,
} from "@ant-design/icons";
import { AddAlarmTwoTone } from "@mui/icons-material";
import { Spin, Table } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useState } from "react";
import CreateResult from "../CreateResults/CreateResults";
import { Navigate, useNavigate } from "react-router-dom";
import FileView from "../../../Utils/FileView";
const Previous = ({
  data,
  selectedExam,
  loading,
  questionPaperData,
  questionPaperList,
}) => {
  const navigate = useNavigate();

  const filteredExams = data[selectedExam] || [];
  const [createResultsState, setCreateResultsState] = useState(false);
  const [selectedRecord, setSelctedRecord] = useState([]);
  const [fileData, setFileData] = useState(null);
  const [view, setView] = useState(false);
  const [heading, setHeading] = useState(null);
  const createResutsComponent = (record) => {
    setCreateResultsState(true);
    setSelctedRecord(record);
  };

  useEffect(() => {
    if (createResultsState) {
      navigate("/createResult", { state: { data: selectedRecord } });
    }
  }, [createResultsState, selectedRecord]);

  const columns = [
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
      align: "center",
      sorter: (a, b) => a.examName.localeCompare(b.examName),
    },
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
      align: "center",
      sorter: (a, b) => a.className.localeCompare(b.className),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
    },
    {
      title: "Exam Date",
      dataIndex: "examDate",
      key: "examDate",
      align: "center",
      sorter: (a, b) => a.examDate - b.examDate,
    },
    {
      title: "Start Date",
      dataIndex: "startTime",
      key: "startTime",
      align: "center",
      sorter: (a, b) => a.startTime - b.startTime,
    },
    {
      title: "End Date",
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
      sorter: (a, b) => a.endTime - b.endTime,
    },
    {
      title: "Question Paper",
      dataIndex: "questionPaper",
      key: "questionPaper",
      align: "center",
      render: (questionPaper, record) => console.log(record),
      // <div>
      //   {questionPaper ? (
      //     <div className="pdf-icon-div">
      //       <FolderOpenOutlined
      //         className="files"
      //         onClick={() => downloadQuestionPaper(record)}
      //       />
      //     </div>
      //   ) : null}
      // </div>
    },
    {
      title: "Add Results",
      dataIndex: "addResults",
      key: "addResults",
      align: "center",
      // sorter: (a, b) => a.endTime - b.endTime,
      render: (_, record) => {
        return (
          <FileDoneOutlined
            style={{ fontSize: 20 }}
            onClick={() => createResutsComponent(record)}
          />
        );
      },
    },
  ];

  const downloadQuestionPaper = (record) => {
    console.log(record);
    setView(true);
    setFileData(record?.questionPaper);
    setHeading(`${record.examName}, ${record.subject} subject`);
  };

  return (
    <div className="previous-container">
      {loading ? (
        <Spin spinning={loading} className="loading-spinner" />
      ) : (
        <Table
          dataSource={filteredExams}
          columns={columns}
          showSorterTooltip={false}
          pagination={false}
        />
      )}
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />
    </div>
  );
};

export default Previous;
