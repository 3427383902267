import React, { useEffect, useState } from "react";
import {
  Card,
  Spin,
  Row,
  Col,
  Progress,
  Modal,
  Button,
  Space,
  Form,
  Select,
  message,
  notification,
  DatePicker,
} from "antd";
import Cookies from "js-cookie";
import { syllabusServices } from "../../Services/SyllabusService";
import EmptyComponent from "../Common/EmptyComponent/EmptyComponent";
import ClassDropdown from "../Common/Class/ClassDropdown";
import SectionDropdown from "../Common/Section/SectionDropdown";
import SubjectDropdown from "../Common/Subject/SubjectDropdown";
import InputFloatLabel from "../Common/FloatLabel/Input";
import SelectFloatLabel from "../Common/FloatLabel/Select";
import DatePickerFloatLabel from "../Common/FloatLabel/DatePicker";
import "./Syllabus.scss";
import axios from "axios";
import {
  CheckOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeTwoTone,
  CheckCircleFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { downloadErrorMessage, dynamicErrorMsg, updateErrorMessage } from "../../Constants/utilitis";
const Syllabus = () => {
  const [syllabusData, setSyllabusData] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [selectedSubChapter, setSelectedSubChapter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { Option } = Select;
  const [syllabusForm] = Form.useForm();
  const [topicEditData, setTopicEditData] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const showModal = (topic) => {
    setIsOpen(true);
    setTopicEditData(topic);
  };

  useEffect(() => {
    if (syllabusData && syllabusData?.length > 0) {
      const firstChapter = syllabusData[0];
      setSelectedChapter(firstChapter?.chapterId);
      if (firstChapter.subChapters?.length > 0) {
        const firstSubChapter = firstChapter.subChapters[0];
        setSelectedSubChapter(firstSubChapter.subChapterId);
      }
    }
  }, [syllabusData]);

  const fetchSyllabusData = () => {
    setLoading(true);
    const params = {
      classId: selectedSection.classId,
      subjectId: selectedSubject,
    };
    syllabusServices
      .syllabusList(params)
      .then((res) => {
        const data = res?.data?.chapters;

        setSyllabusData(data);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedSection && selectedSubject) {
      fetchSyllabusData();
    }
  }, [selectedSection, selectedSubject]);
  const handleModalCancel = () => {
    setIsOpen(false);
  };
  const onClassChange = (value) => {
    setSelectedClass(value);
    setSelectedSubject("");
  };

  const onSectionChange = (value) => {
    setSelectedSection(value);
  };
  const onSubjectChange = (value) => {
    setSelectedSubject(value);
  };

  useEffect(() => {
    const startDate =
      topicEditData?.targetStartDate === undefined
        ? moment(new Date(), "YYYY-MM-DD")
        : moment(topicEditData?.targetStartDate, "YYYY-MM-DD");
    const EndDate =
      topicEditData?.targetEndDate === undefined
        ? moment(new Date(), "YYYY-MM-DD")
        : moment(topicEditData?.targetEndDate, "YYYY-MM-DD");
    if (topicEditData?.topicId) {
      syllabusForm.setFieldsValue({
        topicName: topicEditData?.topicName,
        targetStartDate: startDate,
        status: topicEditData?.status,
        targetEndDate: EndDate,
      });
    } else {
      syllabusForm.resetFields();
    }
  }, [topicEditData]);

  const onFinish = (values) => {
    const id = topicEditData?.topicId;
    const payload = {
      ...values,
      targetStartDate: moment(values.targetStartDate).format("YYYY-MM-DD"),
      targetEndDate: moment(values.targetEndDate).format("YYYY-MM-DD"),
      chapterId: topicEditData?.chapterId,
      subChapterId: topicEditData?.subChapterId,
    };

    if (id) {
      syllabusServices
        .editSyllabus(id, payload)
        .then((res) => {
          notification.success({
            message: `${values.topicName} Topic Updated`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 2,
          });
          setIsOpen(false);
          fetchSyllabusData();
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Update", "Syllabus")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    }
  };
  const onFinishFailed = () => {};

  const downloadStaff = () => {
    return axios.get(
      `https://api.edpedia.co/v1.0/instAdmin/api/syllabus/exportSyllabus?classId=${selectedSection?.classId}&subjectId=${selectedSubject}`,
      {
        responseType: "arraybuffer",
        headers: {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        },
      }
    );
  };
  function downloadFile(data, filename) {
    const blob = new Blob([data], { type: "application/vnd.ms-excel" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;

    // Download after 1 second to ensure file has been fully downloaded
    setTimeout(() => {
      a.click();
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
  const downloadStaffDetails = () => {
    downloadStaff()
      .then((response) => {
        if (response && response.data) {
          downloadFile(response.data, "syllabus.pdf");
          notification.success({
            message: "The File is downloading...",
            description: `Syllabus downloading sucessfully `,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        } else {
          console.log("No response data available");
        }
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Download", 'Staff Details')
      
      notification.error({
        message: errorMessage,
        placement: "bottomRight",
        icon: <DislikeOutlined style={{ color: "red" }} />,
      });
      });
  };
  return (
    <main>
      <div className="syllabus-list">
        <section className="dropdown-group">
          <h2 className="header-text ">Syllabus</h2>
          <div className="divdrop">
            <div>
              <ClassDropdown
                onClassChange={onClassChange}
                selectedClass={selectedClass}
              />
            </div>
            <div>
              <SectionDropdown
                classValue={selectedClass}
                onSectionChange={onSectionChange}
                // selectedSection={selectedSection}
              />
            </div>
            <div>
              <SubjectDropdown
                selectedSubject={selectedSubject}
                onSubjectChange={onSubjectChange}
                selectedClass={selectedClass}
              />
            </div>
            <div>
              <Button
                style={{
                  backgroundColor: "#172b4d",
                  color: "#fff",
                  borderRadius: "5px",
                }}
                onClick={() => downloadStaffDetails()}
              >
                <DownloadOutlined />
              </Button>
            </div>
          </div>
        </section>
        <section className="cards-section">
          <Card title="Chapters" className="cards">
            {loading ? (
              <Spin spinning={loading} className="loading-spinner" />
            ) : syllabusData.length === 0 ? (
              <EmptyComponent />
            ) : (
              syllabusData?.map((chapter) => (
                <div
                  id="card-body-content"
                  key={chapter.chapterId}
                  className={`status-display ${
                    selectedChapter == chapter.chapterId ? "active" : ""
                  }`}
                  onClick={() => setSelectedChapter(chapter.chapterId)}
                >
                  <div className="chapter-details">
                    <h3>{chapter.chapterName}</h3>
                  </div>
                  <Progress
                    type="line"
                    percent={chapter.completionPercentage}
                    strokeColor={
                      chapter.completionPercentage >= 0 &&
                      chapter.completionPercentage <= 30
                        ? "#aa0b28"
                        : chapter.completionPercentage >= 31 &&
                          chapter.completionPercentage <= 75
                        ? "#cbaf1e"
                        : "#5f9e20"
                    }
                    format={() => (
                      <span
                        style={{
                          color: "black",
                        }}
                      >
                        {Math.floor(chapter.completionPercentage)} %
                      </span>
                    )}
                  />
                </div>
              ))
            )}
          </Card>

          <Card title="Subchapters" className="cards">
            {loading ? (
              <Spin spinning={loading} className="loading-spinner" />
            ) : syllabusData.length === 0 ? (
              <EmptyComponent />
            ) : (
              syllabusData &&
              syllabusData?.map((chapter) => (
                <div key={chapter.chapterId} className="card-body-content">
                  {selectedChapter == chapter.chapterId &&
                    chapter.subChapters?.map((subChapter) => (
                      <div
                        key={subChapter.subChapterId}
                        className={`status-display ${
                          selectedSubChapter == subChapter.subChapterId
                            ? "active"
                            : ""
                        }`}
                        onClick={() =>
                          setSelectedSubChapter(subChapter.subChapterId)
                        }
                      >
                        <h3>{subChapter.subChapterName}</h3>
                      </div>
                    ))}
                </div>
              ))
            )}
          </Card>
          <Card
            title="Topics"
            className="cards"
            extra={
              <div className="header-status">
                <span>Status</span>
                <span>Action</span>
              </div>
            }
          >
            {loading ? (
              <Spin spinning={loading} className="loading-spinner" />
            ) : syllabusData.length === 0 ? (
              <EmptyComponent />
            ) : (
              syllabusData?.map((chapter) => (
                <div key={chapter.chapterId} className="card-body-content">
                  {chapter.subChapters?.map((subChapter) => (
                    <div key={subChapter.subChapterId}>
                      {selectedChapter == chapter.chapterId &&
                        selectedSubChapter == subChapter.subChapterId &&
                        subChapter?.topics?.map((topic) => (
                          <div key={topic.topicId} className="topic-status">
                            <h4>{topic.topicName}</h4>
                            <p>{topic.status}</p>
                            <div className="action-icon">
                              {topic.status === "COMPLETED" && (
                                <CheckOutlined className="right-icon" />
                              )}
                              <EditOutlined
                                className="right-icon-btn"
                                onClick={() => showModal(topic)}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
              ))
            )}
          </Card>
        </section>
        <Modal
          open={isOpen}
          onCancel={handleModalCancel}
          footer={null}
          title="Update Topics"
        >
          <Form
            name="AssignmentForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={syllabusForm}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24}>
                <Form.Item
                  name="topicName"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel
                    className="float-inputs"
                    label="Topic Name"
                    type="text"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="status"
                  rules={[{ required: true, message: "" }]}
                >
                  <SelectFloatLabel label="Status">
                    <Option value="COMPLETED">COMPLETED</Option>
                    <Option value="PENDING">PENDING</Option>
                  </SelectFloatLabel>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="targetStartDate"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                  style={{ width: 945 }}
                >
                  <DatePicker
                    className="float-inputs"
                    label="Target Date"
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="targetEndDate"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                  style={{ width: 945 }}
                >
                  <DatePicker
                    className="float-inputs"
                    label="End Date"
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} align="end">
                <Space direction="vertical" size="large">
                  <Button className="submit-button" htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Col>
              <Col span={12} size="large">
                <Space direction="vertical" size="large">
                  <Button
                    className="newCancelButton"
                    onClick={handleModalCancel}
                  >
                    Cancel
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </main>
  );
};

export default Syllabus;
