import { Button, Modal, Select, notification } from "antd";
import "./Books.scss";
import {
  DislikeOutlined,
  LeftOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
import BookImage from "../../../assets/images/BookImage.jpg";
import { LibraryService } from "../../../Services/LibraryService";
import { useState } from "react";
import { useEffect } from "react";
import BookView from "./BookView";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";
import Cookies from "js-cookie";
import RentBookModal from "./RentBookModal";
import RequestBookModal from "./RequestBookModal";
import { dynamicErrorMsg } from "../../../Constants/utilitis";
const { Option } = Select;
const Books = ({ setActiveTabKey }) => {
  const onHandleBack = () => {
    setActiveTabKey(null);
  };
  const [categoryList, setCategoryList] = useState(null);
  const [booksList, setBooksList] = useState(null);
  const [categoryBooksList, categorysetBooksList] = useState(null);
  const [categorySelect, setCategorySelect] = useState(null);
  const [bookView, setBookView] = useState(null);
  const [bookInfo, setBookInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [rentedBooks, setRentedBooks] = useState(null);
  const staffId = Cookies.get("userId");
  const [isRentModalVisible, setRentModalVisible] = useState(null);
  const [isRequestModalVisible, setRequestModalVisible] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const getBooksHistory = () => {
    const params = {
      userId: staffId,
      userType: "Staff",
    };
    LibraryService.getBookHistory(params)
      .then((response) => {
        setRentedBooks(response?.data?.rented);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Books History")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getBooksHistory();
  }, []);

  const getAllbooCategories = () => {
    LibraryService.getAllCategoriesList()
      .then((response) => {
        setCategoryList(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Categories")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(() => setIsLoading(false));
  };
  const getAllBooks = () => {
    LibraryService.getAllBooks()
      .then((response) => {
        setBooksList(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const getAllCategoryBooks = () => {
    // if (!categorySelect) {
    //   return;
    // }
    const params = {
      categoryName: categorySelect,
    };
    LibraryService.getAllCategoryBooks(params)
      .then((response) => {
        categorysetBooksList(response?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Category Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getAllbooCategories();
    getAllBooks();
  }, []);

  useEffect(() => {
    getAllCategoryBooks();
  }, [categorySelect]);

  const handleChange = (value) => {
    if (value) {
      setCategorySelect(value);
    } else {
      setCategorySelect(null);
    }
  };

  useEffect(() => {
    if (categoryList?.categories?.length > 0 && categorySelect === null) {
      setCategorySelect(categoryList.categories[0].categoryName);
    }
  }, [categoryList]);

  const currentDate = new Date();
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = currentDate
    .toLocaleDateString("en-GB", options)
    .split("/")
    .reverse()
    .join("-");

  const getBookInfo = (Book) => {
    setSelectedBook(Book);
    setRentModalVisible(true);
  };

  const onHandleRequest = (Book) => {
    setSelectedBook(Book);
    setRequestModalVisible(true);
  };

  return (
    <div className="books-wrapper">
      <>
        {bookView === null && (
          <div className="book-total-container">
            <h1 className="header-text">Books Count</h1>
            <div className="books-count-container">
              <p className="books-count">{booksList?.bookCount || 0}</p>
            </div>
            <Select
              placeholder="Category"
              style={{ marginLeft:10, width: 150 }}
              allowClear
              onChange={handleChange}
              value={categorySelect}
            >
              {categoryList?.category?.map((category) => (
                <Option key={category.id} value={category.categoryName}>
                  {category.categoryName}
                </Option>
              ))}
            </Select>
          </div>
        )}
        <div className="book-main-container">
          {categorySelect == null &&
            booksList?.books?.map((book) => {
              return (
                <div
                  className="card-container"
                  // onClick={() => handleBookView(book)}
                >
                  <img src={book.image} alt="img" class="card-image" />
                  <div className="author-card-content-container">
                   <div className="auspace">
                   <h5>{book.bookName}</h5>
                    <h5>No. of books {book.numbersOfBooks}</h5>
                    <h5>{book.publisher}</h5>
                    <div>
                      <p>Shelf Number {book.bookSelfNumbers}</p>
                    </div>
                   </div>
                    <div className="buttons-container">
                      {book?.numbersOfBooks > 0 ? (
                        rentedBooks?.some(
                          (item) => item.book.id === book.id
                        ) ? (
                          <Button className="rent-button green-bg">
                            Rented
                          </Button>
                        ) : (
                          <Button
                            className="rent-button orange-bg"
                            onClick={() => getBookInfo(book)}
                          >
                            Rent Now
                          </Button>
                        )
                      ) : (
                        <Button
                          className="request-button"
                          onClick={() => onHandleRequest(book)}
                        >
                          Request
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {categoryBooksList && categoryBooksList.length === 0 ? (
            <div className="empty-component">
              <EmptyComponent />
            </div>
          ) : (
            <>
              {categorySelect !== null &&
                categoryBooksList?.map((book) => (
                  <div className="card-container">
                    <img src={book.image} alt="img" className="card-image" />
                    <div className="author-card-content-container">
                      <p>{book.bookName}</p>
                      <p>No. of books {book.numbersOfBooks}</p>
                      <p>{book.publisher}</p>
                      <div>
                        <p>Self Number {book.bookSelfNumbers}</p>
                      </div>
                      <div className="buttons-container">
                        {book?.numbersOfBooks > 0 ? (
                          rentedBooks?.some(
                            (item) => item.book.id === book.id
                          ) ? (
                            <Button className="rent-button green-bg">
                              Rented
                            </Button>
                          ) : (
                            <Button
                              className="rent-button orange-bg"
                              onClick={() => getBookInfo(book)}
                            >
                              Rent Now
                            </Button>
                          )
                        ) : (
                          <Button
                            className="request-button"
                            onClick={() => onHandleRequest(book)}
                          >
                            Request
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </>
      <RentBookModal
        isRentModalVisible={isRentModalVisible}
        setRentModalVisible={setRentModalVisible}
        selectedBook={selectedBook}
        getAllBooks={getAllBooks}
      />
      <RequestBookModal
        isRequestModalVisible={isRequestModalVisible}
        setRequestModalVisible={setRequestModalVisible}
        selectedBook={selectedBook}
      />
    </div>
  );
};

export default Books;
