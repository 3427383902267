import dayjs from "dayjs";
import moment from "moment";
export function dateFormat(date) {
  return dayjs(date).format("DD-MM-YYYY");
}

export function dateWithTime(date) {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
}

export function yearDateFormat(date) {
  return dayjs(date).format("YYYY-MM-DD");
}

export const createErrorMessage = (str) => {
  return `An error occured while creating ${str}, please try again later`;
}
export const updateErrorMessage = (str) => {
  return `An error occured while updating ${str}, please try again later`;
}
export const deleteErrorMessage = (str) => {
  return `An error occured while deleting ${str}, please try again later`;
}
export const submitErrorMessage = (str) => {
  return `An error occured while submitting ${str}, please try again later`;
}
export const saveErrorMessage = (str) => {
  return `An error occured while saving ${str}, please try again later`;
}
export const getErrorMessage = (str) => {
  return `An error occured while getting ${str}, please try again later`;
}
export const downloadErrorMessage = (str) => {
  return `An error occured while download ${str}, please try again later`;
}



export const dynamicErrorMsg = (error, errorType, errorText) => {
  
  const getErrorMsg = (errorType,errorText) => {
    if (errorType === "Create"){
      return createErrorMessage(errorText)
    } else if(errorType === "Update"){
      return updateErrorMessage(errorText)
    } else if(errorType === "Delete"){
      return deleteErrorMessage(errorText)
    } else if(errorType === "Save"){
      return saveErrorMessage(errorText)
    } else if (errorType === "Submit"){
      return submitErrorMessage(errorText)
    } else if (errorType === "Download"){
      return downloadErrorMessage(errorText)
    } else if (errorType === "Get"){
      return getErrorMessage(errorText)
    }
  
  }
  let errorMsg = getErrorMsg(errorType,errorText)
  
  if ( error?.response?.data?.message) {
    return error.response.data.message;
  } else if (error?.data?.message) {
    return error.data.message;
  } else if (error?.message) {
    return error.message;
  } else if (error?.data?.data){
    return error.data.data
  } else {
    return errorMsg
  }
}