import React, { useState } from "react";
import { Select } from "antd";
import "./index.css";

// const { Option } = Select;

const FloatSelect = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, placeholder, required, children, value } = props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (props.value && props.value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;

  const rules = required
    ? [{ required: true, message: `${label} is required` }]
    : [];
  const capitalizedChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        children: child.props.children.toUpperCase(),
      });
    }
    return child;
  });
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select rules={rules} {...props} showSearch={false}>
        {capitalizedChildren}
      </Select>
      <label className={labelClass}>{isOccupied ? label : placeholder}</label>
    </div>
  );
};

export default FloatSelect;
