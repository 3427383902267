import "../MyAccount.scss";
import { useState, useEffect } from "react";
import { LibraryService } from "../../../../Services/LibraryService";
import bookImage from "../../../../assets/images/BookImage.jpg";
import EmptyComponent from "../../../Common/EmptyComponent";
import { notification } from "antd";
import { DislikeOutlined } from "@ant-design/icons";
import { dynamicErrorMsg } from "../../../../Constants/utilitis";

const ReturnBooks = () => {
  const [returnedBooks, setReturnedBooks] = useState(null);

  const getBooksHistory = () => {
    const params = {
      userId: 3,
      userType: "Staff",
    };
    LibraryService.getBookHistory(params)
      .then((response) => {
        setReturnedBooks(response?.data?.returned);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
  };

  useEffect(() => {
    getBooksHistory();
  }, []);

  return (
    <div className="rent-cards-header">
      {/* {returnedBooks?.length === 0 ? (
        <>
          <EmptyComponent />
        </>
      ) : (
        <>
          {returnedBooks.map((rental) => {
            const { book } = rental;
            return (
              <div key={rental.rentalId} className="rent-card">
                <div className="left-side">
                  <img
                    alt="img"
                    src={`http://15.207.40.153:8011/v1.0/api/media/image?shortUrl=${book.image}`}
                  />
                </div>
                <div className="right-side">
                  <h4>{book?.bookName}</h4>
                  <p>Publisher {book.publisher}</p>
                </div>
              </div>
            );
          })}
        </>
      )} */}
      {returnedBooks && returnedBooks.length > 0 ? (
        returnedBooks.map((rental) => {
          const { book } = rental;
          return (
            <div key={rental.rentalId} className="rent-card">
              <div className="left-side">
                <img
                  alt="img"
                  src={book.image}
                />
              </div>
              <div className="right-side">
                <h4>{book?.bookName}</h4>
                <p>Publisher {book.publisher}</p>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyComponent />
      )}
    </div>
  );
};
export default ReturnBooks;
