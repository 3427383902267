// import { Select } from "antd";
// import { useState, useEffect } from "react";
// import Cookies from "js-cookie";
// const { Option } = Select;
// function ClassDropdown({ onClassChange, selectedClass }) {
//   const [Class, setClass] = useState(null);

//   const classData = Cookies.get("classInfo")
//     ? JSON.parse(Cookies.get("classInfo"))
//     : [];

//   const classAndSections = classData
//     .slice()
//     .sort((a, b) => parseInt(a.classId) - parseInt(b.classId));

//   const uniqueClasses = classAndSections?.reduce((acc, cur) => {
//     if (!acc.includes(cur.className)) {
//       acc.push(cur.className);
//     }
//     return acc;
//   }, []);

//   const handleIntialClass = () => {
//     const initialClass =
//       selectedClass ||
//       (uniqueClasses.length > 0 ? uniqueClasses[0] : undefined);
//     setClass(initialClass);
//     onClassChange(initialClass);
//   };

//   useEffect(() => {
//     handleIntialClass();
//   }, [uniqueClasses]);

//   return (
//     <Select
//       value={Class}
//       onChange={(value) => {
//         setClass(value);
//         onClassChange(value);
//       }}
//       placeholder="Class"

//     >
//       {uniqueClasses.map((option) => (
//         <Option key={option} value={option}>
//           {option}
//         </Option>
//       ))}
//     </Select>
//   );
// }

// export default ClassDropdown;
import { Select } from "antd";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
const { Option } = Select;

function ClassDropdown({ onClassChange, selectedClass }) {
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedClassInternal, setSelectedClassInternal] = useState(null);

  const classData = Cookies.get("classInfo")
    ? JSON.parse(Cookies.get("classInfo"))
    : [];

  const classAndSections = classData
    .slice()
    .sort((a, b) => parseInt(a.classId) - parseInt(b.classId));

  const uniqueClasses = classAndSections?.reduce((acc, cur) => {
    if (!acc.includes(cur.className)) {
      acc.push(cur.className);
    }
    return acc;
  }, []);

  const handleInitialClass = () => {
    const initialClass =
      selectedClass ||
      (uniqueClasses.length > 0 ? uniqueClasses[0] : undefined);
    setSelectedClassInternal(initialClass);
    setSelectedSection(getFirstSection(initialClass));
    onClassChange(initialClass);
  };

  useEffect(() => {
    handleInitialClass();
  }, []);

  const handleClassChange = (value) => {
    setSelectedSection(getFirstSection(value));
    setSelectedClassInternal(value);
    onClassChange(value);
  };

  const getFirstSection = (className) => {
    const sections = classAndSections.filter(
      (item) => item.className === className
    );
    return sections.length > 0 ? sections[0].section : null;
  };

  return (
    <div>
      <Select
        value={selectedClassInternal}
        onChange={handleClassChange}
        placeholder="Class"
        style={{ width: "100px" }}
      >
        {uniqueClasses.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default ClassDropdown;
