import React, { useEffect, useState } from "react";
import "./Exam.scss";
import { Radio, Select, notification } from "antd";
import Present from "./Present/Present";
import Upcoming from "./Upcoming/Upcoming";
import Previous from "./Previous/Previous";
import { ExamService } from "../../Services/ExamServices";
import Cookies from "js-cookie";
import { dynamicErrorMsg } from "../../Constants/utilitis";
import {
  DislikeOutlined,
} from "@ant-design/icons";

const Exam = () => {
  const [activeTabKey, setActiveTabKey] = useState("previous");
  const [examData, setExamData] = useState({});
  const [examNames, setExamNames] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [loading, setLoading] = useState(true);
  const { Option } = Select;
  const [questionPaperData, setQuestionPaperData] = useState([]);
  const RadioGroup = Radio.Group;

  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;

  useEffect(() => {
    questionPaperList();
  }, []);

  const questionPaperList = () => {
    ExamService.questionPaperList(staffId)
      .then((res) => {
        setQuestionPaperData(res.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Question Paper")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  console.log("questionPaperData", questionPaperData);
  console.log("examData", examData);

  const handleTabChange = (e) => {
    setActiveTabKey(e.target.value);
    setSelectedExam(null);
    // examData({});
  };

  const getExamsList = () => {
    ExamService.getExamList(staffId)
      .then((response) => {
        const { UPCOMING, COMPLETED, ONGOING } = response?.data;
        let exams = [];
        if (activeTabKey == "present") {
          exams = Object.keys(ONGOING);
          setExamData(ONGOING);
        } else if (activeTabKey == "upcoming") {
          exams = Object.keys(UPCOMING);
          setExamData(UPCOMING);
        } else if (activeTabKey == "previous") {
          exams = Object.keys(COMPLETED);
          setExamData(COMPLETED);
        }
        setExamNames(exams);
        setSelectedExam(exams[0]);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Exam List")
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getExamsList();
  }, [activeTabKey, questionPaperData]);

  const handleExamChange = (value) => {
    setSelectedExam(value);
    // examData({});
  };

  return (
    <article className="exam-container">
      <div className="">
        <div className="exam-header">
          <RadioGroup
            onChange={(value) => handleTabChange(value)}
            value={activeTabKey}
            optionType="button"
            buttonStyle="solid"
            className="mainradio"
          >
            <Radio.Button value="present">Present</Radio.Button>
            <Radio.Button value="upcoming">Upcoming</Radio.Button>
            <Radio.Button value="previous">Previous</Radio.Button>
          </RadioGroup>
        </div>
        <Select
          style={{ width: 200, marginBottom: 10 }}
          placeholder="Select exam"
          onChange={handleExamChange}
          value={selectedExam}
        >
          {examNames?.map((name) => (
            <Option key={name} value={name}>
              {name}
            </Option>
          ))}
        </Select>
      </div>

      <div className="exam-btns ">
        {activeTabKey === "present" && (
          <Present
            data={examData}
            selectedExam={selectedExam}
            getExamsList={getExamsList}
            loading={loading}
            questionPaperData={questionPaperData}
            questionPaperList={questionPaperList}
          />
        )}
        {activeTabKey === "upcoming" && (
          <Upcoming
            data={examData}
            selectedExam={selectedExam}
            getExamsList={getExamsList}
            loading={loading}
            questionPaperData={questionPaperData}
            questionPaperList={questionPaperList}
          />
        )}
        {activeTabKey === "previous" && (
          <Previous
            data={examData}
            selectedExam={selectedExam}
            getExamsList={getExamsList}
            loading={loading}
            questionPaperData={questionPaperData}
            questionPaperList={questionPaperList}
          />
        )}
      </div>
    </article>
  );
};

export default Exam;
