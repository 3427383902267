import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./StudentAttendanceHome.scss";
import StudentAttendance from "./StudentAttendance/Attendance";
import RequestStudentAttendace from "./RequestStudentAttendace/RequestStudentAttendace";
const RadioGroup = Radio.Group;
const StudentAttendaceHome = () => {
  const [activeTabKey, setActiveTabKey] = useState("studentAttendance");
  const location = useLocation();
  const studentAttendance = location.state;

  const [time, setTime] = useState(
    new Date().toLocaleTimeString("en-IN", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })
  );

  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(
        new Date().toLocaleTimeString("en-IN", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
      );
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <main className="studentattendance-wrapper">
      <div className="radioparent">
        <RadioGroup
          onChange={handleTabChange}
          value={activeTabKey}
          optionType="button"
          buttonStyle="solid"
          className="mainradio"
        >
          <Radio.Button value="studentAttendance">
            Student Attendance
          </Radio.Button>

          <Radio.Button value="requestStudentAttendance">
            Request Student Attendance
          </Radio.Button>
        </RadioGroup>
        {/* <div style={{ textAlign: "center" }}>
      <h4>
        {formattedDate} : {dayOfWeek}, {formattedTime}
      </h4>
    </div> */}
      </div>

      {activeTabKey === "studentAttendance" && (
        <div>
          <StudentAttendance />
        </div>
      )}

      {activeTabKey === "requestStudentAttendance" && (
        <RequestStudentAttendace />
      )}
    </main>
  );
};

export default StudentAttendaceHome;
