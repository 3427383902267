import "./LibraryHome.scss";
import RichBook from "../../../assets/images/book image.jpg";
import AuthorGirl from "../../../assets/images/author girl.jpg";
import BookImage from "../../../assets/images/BookImage.jpg";
import { LibraryService } from "../../../Services/LibraryService";
import { Button, Card, Spin, notification } from "antd";
import { useEffect, useState } from "react";
import EmptyComponent from "../../Common/EmptyComponent/EmptyComponent";
import { DislikeOutlined } from "@ant-design/icons";
import StarRating from "../../../Utils/StarRating";
import RentBookModal from "../Books/RentBookModal";
import RequestBookModal from "../Books/RequestBookModal";
import Cookies from "js-cookie";
import { dynamicErrorMsg } from "../../../Constants/utilitis";
const LibraryHome = () => {
  const [latestBooks, setLatestBooks] = useState([]);
  const [famousBooks, setFamousBooks] = useState([]);
  const [topAuthors, setTopAuthors] = useState([]);
  const [latestBooksCount, setLatestBooksCount] = useState(false);
  const [famousBooksCount, setFamousBooksCount] = useState(false);
  const [topAuthorsCount, setTopAuthorsCount] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRentModalVisible, setRentModalVisible] = useState(null);
  const [isRequestModalVisible, setRequestModalVisible] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [rentedBooks, setRentedBooks] = useState(null);
  const staffId = Cookies.get("userId");
  const getLatestBooks = () => {
    LibraryService.getLatestBooks()
      .then((response) => {
        setLatestBooks(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Latest Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(setIsLoading(false));
  };
  const getFamousBooks = () => {
    LibraryService.getFamousBooks()
      .then((response) => {
        setFamousBooks(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Famous Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(setIsLoading(false));
  };
  const getTopAuthorBooks = () => {
    LibraryService.getTopAuthorBooks()
      .then((response) => {
        setTopAuthors(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Top Author Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(setIsLoading(false));
  };

  useState(() => {
    getLatestBooks();
    getFamousBooks();
    getTopAuthorBooks();
  }, []);

  const handleAllFamousBooks = () => {
    setFamousBooksCount(!famousBooksCount);
  };
  const handleAllLatestBooks = () => {
    setLatestBooksCount(!latestBooksCount);
  };
  const handleAllTopAuthors = () => {
    setTopAuthorsCount(!topAuthorsCount);
  };
  const getBookInfo = (Book) => {
    setSelectedBook(Book);
    setRentModalVisible(true);
  };

  const onHandleRequest = (Book) => {
    setSelectedBook(Book);
    setRequestModalVisible(true);
  };

  const getBooksHistory = () => {
    const params = {
      userId: staffId,
      userType: "Staff",
    };
    LibraryService.getBookHistory(params)
      .then((response) => {
        setRentedBooks(response?.data?.rented);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getBooksHistory();
  }, []);

  return (
    <>
      <div className="home-container">
        <div className="header-container">
          <h3 className="header-text">Latest Books</h3>
          {latestBooks.length > 6 && (
            <h4 onClick={handleAllLatestBooks}>View All</h4>
          )}
        </div>
        {isLoading ? (
          <Spin spinning={isLoading} className="loading-spinner" />
        ) : latestBooks.length === 0 ? (
          <div className="empty-component">
            <EmptyComponent />
          </div>
        ) : (
          <div className="books-container">
            {latestBooks
              ?.slice(0, latestBooksCount ? latestBooks.length : 6)
              .map((item) => (
                <div className="card-container" key={item.id}>
                  <img src={item.image} alt="img" className="card-image" />
                  <div className="author-card-content-container">
                   <div className="auspace">
                   <p>{item.bookName}</p>
                    <p className="publishname">{item.publisher}</p>
                   </div>
                    <StarRating numberOfRatings={item.numberOfRatings} />
                    <div className="buttons-container">
                      {item?.numbersOfBooks > 0 ? (
                        rentedBooks?.some(
                          (each) => each.book.id === item.id
                        ) ? (
                          <Button className="rent-button green-bg">
                            Rented
                          </Button>
                        ) : (
                          <Button
                            className="rent-button orange-bg"
                            onClick={() => getBookInfo(item)}
                          >
                            Rent Now
                          </Button>
                        )
                      ) : (
                        <Button
                          className="request-button"
                          onClick={() => onHandleRequest(item)}
                        >
                          Request
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>

      <div className="home-container">
        <div className="header-container">
          <h3 className="header-text">Famous Books</h3>
          {famousBooks.length > 6 && (
            <h4 onClick={handleAllFamousBooks}>View All</h4>
          )}
        </div>
        {isLoading ? (
          <Spin spinning={isLoading} className="loading-spinner" />
        ) : famousBooks.length === 0 ? (
          <div className="empty-component">
            <EmptyComponent />
          </div>
        ) : (
          <div className="books-container">
            {famousBooks
              ?.slice(0, famousBooksCount ? famousBooks.length : 6)
              .map((item) => {
                return (
                  <div className="card-container">
                    <img src={item.image} alt="img" class="card-image" />
                    <div className="author-card-content-container">
                     <div className="auspace">
                     <p>{item.bookName}</p>
                      <p className="publishname">{item.publisher}</p>                      
                     </div>
                     <StarRating numberOfRatings={item.numberOfRatings} />
                      <div className="buttons-container">
                        {item?.numbersOfBooks > 0 ? (
                          rentedBooks?.some(
                            (each) => each.book.id === item.id
                          ) ? (
                            <Button className="rent-button green-bg">
                              Rented
                            </Button>
                          ) : (
                            <Button
                              className="rent-button orange-bg"
                              onClick={() => getBookInfo(item)}
                            >
                              Rent Now
                            </Button>
                          )
                        ) : (
                          <Button
                            className="request-button"
                            onClick={() => onHandleRequest(item)}
                          >
                            Request
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <div className="home-container">
        <div className="header-container">
          <h3 onClick={handleAllTopAuthors} className="header-text">
            Top Author Books
          </h3>
          {famousBooks.length > 6 && (
            <h4 onClick={handleAllFamousBooks}>View All</h4>
          )}
        </div>
        {isLoading ? (
          <Spin spinning={isLoading} className="loading-spinner" />
        ) : famousBooks.length === 0 ? (
          <div className="empty-component">
            <EmptyComponent />
          </div>
        ) : (
          <div className="books-container">
            {topAuthors
              ?.slice(0, topAuthorsCount ? topAuthors.length : 6)
              .map((item) => {
                return (
                  <div className="card-container">
                    <img src={item.image} alt="img" class="card-image" />
                    <div className="author-card-content-container">
                      <div className="auspace">
                      <p>{item.name}</p>
                      <p>Books Published : {item.numbersOfBooksPublished}</p>
                      </div>
                     
                    
                      <StarRating numberOfRatings={item.averageRatings} />
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {isRentModalVisible && (
        <RentBookModal
          isRentModalVisible={isRentModalVisible}
          setRentModalVisible={setRentModalVisible}
          selectedBook={selectedBook}
        />
      )}
      {isRequestModalVisible && (
        <RequestBookModal
          isRequestModalVisible={isRequestModalVisible}
          setRequestModalVisible={setRequestModalVisible}
          selectedBook={selectedBook}
        />
      )}
    </>
  );
};
export default LibraryHome;
