import {
  EXAM_LIST_URL,
  EXAM_LIST_ENDPOINT,
  POST_QUESTION_PAPER,
  QUESTIONPAPER_LIST,
  EDIT_QUESTIONPAPER,
  EDIT_QUESTIONPAPER_ENDPOINT,
  DELETE_QUESTIONPAPER,
  DELETE_QUESTIONPAPER_ENDPOINT,
} from "../Constants/constants";
import { PostAPIRequest, GetAPIRequest, DeleteAPIRequest } from "./Api";
const getExamList = (staffId) => {
  return GetAPIRequest({ url: EXAM_LIST_URL + staffId + EXAM_LIST_ENDPOINT });
};

const postQuestionPaper = (payload) => {
  return PostAPIRequest({ url: POST_QUESTION_PAPER, data: payload });
};
const questionPaperList = (id) => {
  return GetAPIRequest({ url: QUESTIONPAPER_LIST + id });
};
const editQuestionPaper = (id, payload) => {
  return PostAPIRequest({
    url: EDIT_QUESTIONPAPER + id + EDIT_QUESTIONPAPER_ENDPOINT,
    data: payload,
  });
};

const deleteQuestionPaper = (id, payLoad) => {
  return DeleteAPIRequest({
    url: DELETE_QUESTIONPAPER + id + DELETE_QUESTIONPAPER_ENDPOINT,
    data: payLoad,
  });
};
export const ExamService = {
  getExamList,
  postQuestionPaper,
  questionPaperList,
  editQuestionPaper,
  deleteQuestionPaper,
};
