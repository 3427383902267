import React from "react";
import { Topbar, Timetable } from "../../components";
import SEO from "../../components/reuse";

function Timetables() {
  return (
    <main>
      {/* <Topbar /> */}
      <SEO title="TimeTable" />
      <Timetable />
    </main>
  );
}

export default Timetables;
