import React, { useEffect, useState } from "react";
import { Button, Table, Space, notification } from "antd";
import CreateUnlockRequest from "./CreateUnlockRequest/CreateUnlockRequest";
import { DislikeOutlined, EditOutlined, LeftOutlined } from "@ant-design/icons";
import "./UnlockRequest.scss";
import { hrRequestServices } from "../../../../Services/HumanResourceService";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { dynamicErrorMsg } from "../../../../Constants/utilitis";
const UnlockRequest = () => {
  const [openModal, setOpenModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [unlockData, setUnlockData] = useState([]);
  const [rowData, setRowData] = useState(null);
  const naviagte = useNavigate();
  
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const loadUnlockData = () => {
    const staffId = staffInfo?.staffId;

    hrRequestServices
      .getStaffUnlockRequest(staffId)
      .then((res) => {
        setUnlockData(res.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Staff Unlock")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    loadUnlockData();
  }, []);

  const columns = [
    {
      title: "Month",
      dataIndex: "reqMonth",
      key: "reqMonth",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Year",
      dataIndex: "reqYear",
      key: "reqYear",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "status",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   align: "center",
    //   render: (_, record) => (
    //     <Space>
    //       <Button className="full-details-button">
    //         <EditOutlined />
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  const showModal = (record) => {
    setOpenModal(true);
    setRowData(record);
  };

  const onHandleBack = () => {
    naviagte("/profile");
  };

  return (
    <main>
      <div className="unlock_request ">
        {" "}
        <div
          className="unlock-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 10,
            marginBottom: 5,
          }}
        >
          <div className="backbtn" style={{ display: "flex", marginRight: 10 }}>
            <Button
              className="goback_button"
              type="primary"
              htmlType="submit"
              onClick={onHandleBack}
              style={{ background: "#172b4d" }}
            >
              <LeftOutlined />
            </Button>
            <div style={{ marginLeft: 10 }}>Unlock Request</div>
          </div>
          <Button className="blue-button" onClick={showModal}>
            Create
          </Button>
        </div>
        <CreateUnlockRequest
          openModal={openModal}
          setOpenModal={setOpenModal}
          rowData={rowData}
          setViewModal={setViewModal}
          viewModal={viewModal}
          loadUnlockData={loadUnlockData}
        />
        <Table
          dataSource={unlockData}
          columns={columns}
          showSorterTooltip={false}
        />
      </div>
    </main>
  );
};

export default UnlockRequest;
