import React, { useState } from "react";
import student from "../../../assets/icons/students.png";
import {
  Button,
  Card,
  Col,
  Empty,
  Row,
  Select,
  message,
  notification,
} from "antd";
import { GirlUniform } from "../../../assets/icons";
import CheckOutForm from "../Cart/CheckOutForm";
import { EdpediaStoreService } from "../../../Services/EdpediaStoreService";
import { useEffect } from "react";
import Cookies from "js-cookie";
import {
  CheckCircleFilled,
  HeartFilled,
  DislikeOutlined,
} from "@ant-design/icons";
const ProductsList = ({
  productsData,
  checkOutProduct,
  setCheckOutProduct,
}) => {
  const userId = Cookies.get("userId");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedValueQuantity, setSelectedValueQuantity] = useState("1");
  const [sizeState, setSizeState] = useState(null);
  const { Option } = Select;

  const handleProduct = (product) => {
    // setProductById(product?.id);
    // setSelectedProduct(true);
  };
  const handleBuy = (item) => {
    setCheckOutProduct(true);
    setSelectedProduct(item);
  };
  const handleAddWishList = (product) => {
    const item = product;

    const payload = {
      productId: item?.id,
      userId: "EDBIN1001",
      brandId: item?.brandId,
      sizeId: item?.productSizes[0]?.sizeId,
      quantity: item?.productSizes[0]?.quantity,
    };
    EdpediaStoreService.addInWishList(payload)
      .then((res) => {
        notification.success({
          message: "Added to wishlist successfully",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const productAddToCart = (product) => {
    const payload = {
      productId: product?.id,
      brandId: product?.brandId,
      quantiy: selectedValueQuantity,
      sizeId: sizeState,
    };
    EdpediaStoreService.addProductToCart(userId, payload)
      .then((response) => {
        notification.success({
          message: "Product added to cart successfully .",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
      })
      .catch((error) => {
        message.success("Product added to cart is failed");
      });
  };
  const handleSelectChange = (value) => {
    setSelectedValueQuantity(value);
  };
  const sizeSelectionFunct = (sizes) => {
    setSizeState(sizes?.sizeId);
  };

  const handleSizeSelect = (sizeId) => {
    setSizeState(sizeId);
  };
  useEffect(() => {
    // Set the default size value when the component mounts
    if (productsData.length > 0 && productsData[0]?.productSizes?.length > 0) {
      setSizeState(productsData[0].productSizes[0].sizeId);
    }
  }, [productsData]);
  return (
    <>
      {checkOutProduct === null && (
        <div className="subcategory-container">
          {productsData.length > 0 ? (
            <Row className="row-spacing" gutter={{}}>
              {productsData?.map((product) => {
                return (
                  <Col span={6} key={product.id}>
                    <Card
                      hoverable
                      style={{ width: 240 }}
                      cover={
                        <img
                          onClick={() => handleProduct(product)}
                          alt="example"
                          src={GirlUniform}
                          className="card-img"
                        />
                      }
                    >
                      <div className="card-text">
                        <HeartFilled
                          className="heart-icon"
                          onClick={() => handleAddWishList(product)}
                        />
                        <span className="name">
                          {product?.name} ({product?.subCategoryName})
                        </span>
                        <span className="text-name">
                          {product?.brandName} Brand
                        </span>

                        <span className="text-price">
                          Price: ${product?.actualPrice}
                        </span>
                        <span className="text-percentage">25% - 50% Off</span>
                        <span className="">
                          Discount: ${product?.afterDiscountAmount}
                        </span>
                        <span className="text-item-left">
                          Only {product?.productSizes[0]?.quantity} left
                        </span>
                        <div className="dropdown">
                          <Select
                            placeholder="Select Size"
                            onChange={handleSizeSelect}
                            style={{ width: "100%", margin: "10px 0" }}
                            value={sizeState}
                          >
                            {product?.productSizes?.map((sizes) => (
                              <Select.Option
                                key={sizes.sizeId}
                                value={sizes.sizeId}
                                disabled={sizes.quantity <= 0}
                              >
                                {sizes.sizeName}
                              </Select.Option>
                            ))}
                          </Select>
                          <Select
                            value={selectedValueQuantity}
                            onChange={handleSelectChange}
                            style={{ width: "100%", margin: "10px 0" }}
                          >
                            {[...Array(10)].map((_, index) => (
                              <Option key={index + 1} value={`${index + 1}`}>
                                {index + 1}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="final-button-div">
                          <Button
                            className="submit-button"
                            onClick={() => productAddToCart(product)}
                            style={{ marginRight: "10px" }}
                          >
                            Add to Cart
                          </Button>
                          <Button
                            className="buynow-button"
                            onClick={() => handleBuy(product)}
                          >
                            Buy Now
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Empty />
          )}
        </div>
      )}
      <CheckOutForm
        checkOutProduct={checkOutProduct}
        selectedProduct={selectedProduct}
      />
    </>
  );
};

export default ProductsList;
