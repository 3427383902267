import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Progress, Spin, Switch, Button, notification } from "antd";
import { AiOutlineMessage } from "react-icons/ai";
import { staffServices } from "../../Services/StaffServices";
import { useNavigate } from "react-router-dom";
import { AssignmentApiService } from "../../Services/AssignmentServices";
import EmptyComponent from "../Common/EmptyComponent";
import { LatsetActivityService } from "../../Services/LatestActivityService";
import Cookies from "js-cookie";
import { DislikeOutlined } from "@ant-design/icons";
const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [teachingSubjects, setTeachingSubjects] = useState([]);
  const [timeTable, setTodayTimeTable] = useState([]);
  const [assignmentList, setAssignmentList] = useState([]);
  const [latestActivity, setLatestActivity] = useState([]);
  const [staffId, setStaffId] = useState(
    Cookies.get("userId") ? Cookies.get("userId") : null
  );

  useEffect(() => {
    if (staffId !== null) {
      TeachingSubjectsData();
      TodayTimeTableData();
      attenanceListData();
      getLatestActivity();
    }
  }, [staffId]);

  const getLatestActivity = () => {
    setLoading(true);
    LatsetActivityService.getLatestActivity()
      .then((response) => {
        setLatestActivity(response?.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const attenanceListData = () => {
    setLoading(true);
    AssignmentApiService.assignmentList(staffId)
      .then((res) => {
        const firstfiveItems = res?.data?.slice(0, 5);
        setAssignmentList(firstfiveItems);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSwitchChange = () => {
    navigate("/studentattendance", { state: "studentAttendance" });
    localStorage.setItem("selectedItem", "studentattendance");
  };

  const handleAssignments = (item) => {
    navigate("/assignments", { state: { item } });
    localStorage.setItem("selectedItem", "assignments");
  };

  const TodayTimeTableData = () => {
    setLoading(true);
    staffServices
      .todayTimeTableList(staffId)
      .then((response) => {
        console.log('response', response)
        setTodayTimeTable(response?.data?.todaySchedule);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const TeachingSubjectsData = () => {
    setLoading(true);
    staffServices
      .subjectList(staffId)
      .then((response) => {
        setTeachingSubjects(response?.data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formatActivityDate = (dateString) => {
    const [startDate, endDate] = dateString.match(/\d{2}-\d{2}-\d{4}/g) || [];
    if (startDate && endDate) {
      const formattedStartDate = new Date(startDate).toLocaleDateString(
        "en-US",
        {
          day: "numeric",
          month: "short",
          year: "numeric",
        }
      );
      const formattedEndDate = new Date(endDate).toLocaleDateString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      return `${formattedStartDate} To ${formattedEndDate}`;
    }
    return dateString;
  };

  return (
    <article id="dashboard">
      <div className="title">
        <h2 className="header-text">Today Time Table</h2>
        {loading ? (
          <Spin spinning={loading} className="loading-spinner" />
        ) : timeTable?.length === 0 ? (
          <EmptyComponent />
        ) : (
          <article className="timetable-grid grid-sty">
            {Object.entries(timeTable).map(([key, value]) => {
              const {
                subjectId,
                subject,
                className,
                section,
                attendanceTaken,
              } = value;
              const subjectName =
                subject.charAt(0).toUpperCase() + subject.slice(1);
              return (
                <article
                  key={subjectId}
                  className="timetable-griditem div-sty"
                  onClick={onSwitchChange}
                >
                  <div className="table-class">
                    <p>
                      {className} - {section}
                    </p>
                    <span className="time-table-text">Period {key}</span>
                  </div>
                  <div className="table-content-center">
                    <div className="timetable-img">
                      <h1 className="time-table-text">{subjectName}</h1>
                    </div>
                  </div>
                  {/* <div className="table-attendance"> */}
                  {/* <Switch
                      checked={attendanceTaken}
                      onChange={onSwitchChange}
                      className={
                        attendanceTaken ? "switch-checked" : "switch-unchecked"
                      }
                    /> */}
                  {/* <p>
                      {attendanceTaken ? "Attendance Taken" : "Take Attendance"}
                    </p> */}
                  {/* </div> */}
                </article>
              );
            })}
          </article>
        )}
      </div>

      <div className="dashboard-activites">
        <div className="dashboard-subjects">
          <h2 className="header-text">Teaching Subjects</h2>
          <div className="flex-container">
            {loading ? (
              <Spin spinning={loading} className="loading-spinner" />
            ) : teachingSubjects.length === 0 ? (
              <EmptyComponent />
            ) : (
              <>
                <div className="subject-cards-container">
                  {teachingSubjects &&
                    teachingSubjects.map((i) => {
                      const subject =
                        i?.subject.charAt(0).toUpperCase() +
                        i?.subject.slice(1);
                      return (
                        <>
                          <div className="subject-cards">
                            <div className="progress-div">
                              <div>
                                {" "}
                                <h4>
                                  {i.className} - {i.section}
                                </h4>
                                <p> {subject}</p>
                              </div>

                              <Progress
                                style={{
                                  marginLeft: 10,
                                  padding: 10,
                                  // border: "1px solid white",
                                }}
                                type="circle"
                                percent={i?.overAll?.toFixed(0)}
                                width={40}
                                strokeWidth={12}
                                strokeColor={
                                  i.overAll >= 0 && i.overAll <= 30
                                    ? "#aa0b28"
                                    : i.overAll >= 31 && i.overAll <= 75
                                    ? "#cbaf1e"
                                    : "#5f9e20"
                                }
                                format={() => (
                                  <span
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {i?.overAll?.toFixed(0)} %
                                  </span>
                                )}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="dashboard-activites">
          <div className="dshbottom">
            <div className="dashboard-assignments">
              <h2 className="header-text" id="assign-title">
                Assignments
              </h2>
              {loading ? (
                <Spin spinning={loading} className="loading-spinner" />
              ) : assignmentList.length === 0 ? (
                <EmptyComponent />
              ) : (
                <>
                  {assignmentList &&
                    assignmentList.map((item, index) => {
                      const assignmentName =
                        item?.assignmentTitle.toUpperCase();
                      const dateStr = item.givenDate;
                      const date = new Date(dateStr);
                      const formattedDate = date.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      });
                      return (
                        <>
                          <div className="dash-assignment-wrapper">
                            <div key={item.id} className="dashboard-assignment">
                              <div id="dashboard-assignNum">
                                <span className="statuscir"></span>
                                {item.mode}
                              </div>
                              <div>{formattedDate}</div>
                              <div
                                id="dashboard-assignChaps"
                                className="dashboard-assignChaps"
                              >
                                {assignmentName}
                              </div>
                              <div id="dashboard-members">
                                {item.reviewStatus}
                              </div>
                              {/* <div className="">
                            Latest Activity
                            </div> */}
                              <div>
                                <Button
                                  id="dashboard-assignDetails"
                                  className="blue-button"
                                  onClick={() => handleAssignments(item)}
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </>
              )}
            </div>

            <div className="dashboard-activity">
              <h2 className="header-text">Latest Activity</h2>
              {loading ? (
                <Spin spinning={loading} className="loading-spinner" />
              ) : latestActivity.length === 0 ? (
                <EmptyComponent />
              ) : (
                <>
                  {latestActivity.map((activity, index) => {
                    const itemClass = `dashboard-activity-item bg-color-${
                      index + 1
                    }`;
                    const isNew =
                      index < 3 ? <span className="blink_me">NEW</span> : null;

                    return (
                      <div className={itemClass} key={index}>
                        <div className="activitymain">
                          <div className="acnew">New</div>
                          <div className="accontent">
                            {activity.latestActivity.includes("From")
                              ? activity.latestActivity.replace(
                                  /\d{2}-\d{2}-\d{4}/g,
                                  (date) => {
                                    return new Date(date).toLocaleDateString(
                                      "en-US",
                                      {
                                        day: "numeric",
                                        month: "short",
                                        year: "numeric",
                                      }
                                    );
                                  }
                                )
                              : activity.latestActivity}
                          </div>
                          {/* <div className="acdate">
                            24-05-2023 To 07-06-2023 
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>

          {/* <div className="dashboard-inbox div-sty flexBox-sty">
            <div className="dashboard-inboxNumber result-circle flexBox-sty">
              3
            </div>
            <AiOutlineMessage className="dashboard-inboxIcon" />
            <p>View Inbox</p>
          </div> */}
        </div>
      </div>

      {/* Assignments */}
    </article>
  );
};

export default Dashboard;
