import { GET_ALLROUTE, GET_ALLPICKUP_POINT, ENROLL_TRANSPORT_URL } from "../Constants/constants";
import { GetAPIRequest, PostAPIRequest } from "./Api";

export const getAllRoutes = () => {
  return GetAPIRequest({ url: GET_ALLROUTE });
};
export const getAllPickupPoints = () => {
  return GetAPIRequest({ url: GET_ALLPICKUP_POINT });
};

export const enrollTransport = (payLoad) => {
  return PostAPIRequest({
    url:ENROLL_TRANSPORT_URL,
    data:payLoad
  })
}

export const transportService = {
  getAllRoutes,
  getAllPickupPoints,
  enrollTransport
};
