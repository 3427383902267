import { Button, Table, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./Resignation.scss";
import EmptyComponent from "../../Common/EmptyComponent";
import { EyeOutlined, LeftOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { hrRequestServices } from "../../../Services/HumanResourceService";
import CreateResigation from "./CreateResignation/CreateResigation";
import Cookies from "js-cookie";
const Resignation = () => {
  const [resignationList, setResignationList] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [ViewModalOpen, setViewModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const showViewModal = (record) => {
    setViewModalOpen(true);
    // setEditRowData(record);
  };

  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];

  const staffId = staffInfo?.staffId;

  useEffect(() => {
    loadResignationData();
  }, []);

  const loadResignationData = () => {
    setIsLoading(true);
    hrRequestServices
      .getAllResignation(staffId)
      .then((res) => {
        setResignationList(res?.data);
        setIsLoading(false);
        setEditRowData(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const filterStaffData = resignationList?.filter(
    (item) => item.staffId == staffId
  );

  const showModal = () => {
    setIsOpenModal(true);
    setEditRowData(null);
  };

  const handleEditResignation = (filterStaffData) => {
    setIsOpenModal(true);
    setEditRowData(filterStaffData);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "resDate",
      key: "resDate",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },

    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space>
          <Button
            className="full-details-button"
            onClick={() => showViewModal(record)}
          >
            <EyeOutlined />
          </Button>
          {/* <Button
            className="full-details-button"
            onClick={() => {
              handleEditResignation(record);
            }}
          >
            <EditOutlined />
          </Button> */}
        </Space>
      ),
    },
  ];

  const onHandleBack = () => {
    navigate("/profile");
  };

  return (
    <>
      <main>
        <div className="staff-resign-container">
          <div className="resign-header">
            <div className="resglef">
              <Button
                className="goback_button blue-button"
                type="primary"
                htmlType="submit"
                onClick={onHandleBack}
              >
                <LeftOutlined />
              </Button>
              <h3>Resignation</h3>
            </div>
            {resignationList.length === 0 && (
              <Button className="Add_button" onClick={showModal}>
                Apply
              </Button>
            )}
            <CreateResigation
              isOpenModal={isOpenModal}
              setIsOpenModal={setIsOpenModal}
              loadResignationData={loadResignationData}
              editRowData={editRowData}
              setEditRowData={setEditRowData}
              setViewModalOpen={setViewModalOpen}
              ViewModalOpen={ViewModalOpen}
              setIsLoading={setIsLoading}
              setResignationList={setResignationList}
            />
          </div>

          <Spin spinning={isLoading}>
            {resignationList ? (
              resignationList.length > 0 ? (
                <div className="tables card resign-card">
                  {/* <Table dataSource={filterStaffData} columns={columns} /> */}
                  <h2>Resign Date : {filterStaffData?.[0]?.resDate}</h2>
                  <h2>Reason : {filterStaffData?.[0]?.reason}</h2>
                  <h3>Status : {filterStaffData?.[0]?.status}</h3>
                  <Button
                    className="full-details-button"
                    onClick={() => handleEditResignation(filterStaffData)}
                  >
                    <EditOutlined />
                  </Button>
                </div>
              ) : (
                <div className="data-unavailable">
                  <EmptyComponent />
                </div>
              )
            ) : (
              !isLoading && <div>Loading...</div>
            )}
          </Spin>
        </div>
      </main>
    </>
  );
};

export default Resignation;
