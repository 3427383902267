import React, { useEffect, useState } from "react";
import "./Timetable.scss";
import { Empty, Spin, Switch, Tag, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { staffServices } from "../../Services/StaffServices";
import EmptyComponent from "../Common/EmptyComponent";
import Cookies from "js-cookie";
import {
  CheckOutlined,
  CloseOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import ClassDropdown from "../Common/Class/ClassDropdown";
import SectionDropdown from "../Common/Section/SectionDropdown";
import { dynamicErrorMsg } from "../../Constants/utilitis";

const Timetable = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [classTimetable, setClassTimetable] = useState([]);
  const [periodConfig, setPeriodConfig] = useState([]);
  const navigate = useNavigate();
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  useEffect(() => {
    if (selectedSection !== null) {
      getConfigData();
    }
  }, [selectedSection?.classId]);

  const getConfigData = () => {
    const classId = selectedSection?.classId;
    staffServices
      .periodConfigData(classId)
      .then((res) => {
        setPeriodConfig(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //timetable list
  const getClassTimetableList = () => {
    const params = {
      class: selectedSection?.classId,
      staffId: staffId,
    };
    setIsLoading(true);
    staffServices
      .getClassTimetable(params)
      .then((response) => {
        setClassTimetable(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Class TimeTable")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // TodayTimeTableData();
    if (selectedSection !== null) {
      getClassTimetableList();
    }
  }, [selectedSection]);

  const onClassChange = (value) => {
    setSelectedClass(value);
  };

  const onSectionChange = (value) => {
    setSelectedSection(value);
  };

  const onSwitchChange = () => {
    localStorage.setItem("selectedItem", "attendance");
    navigate("/attendance", { state: "studentAttendance" });
  };

  const currentDate = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = currentDate.toLocaleDateString("en-IN", options);
  const dayOfWeek = currentDate.toLocaleDateString("en-IN", {
    weekday: "long",
  });

  const renderTimetable = () => {
    if (Object.keys(periodConfig).length === 0 || classTimetable.length === 0) {
      return null;
    }
    //teaching
    const renderTeachingStatus = (teaching) => {
      if (teaching) {
        return (
          <Tag color="green">
            <CheckOutlined /> Teaching
          </Tag>
        );
      } else {
        return (
          <Tag color="red">
            <CloseOutlined /> Non-Teaching
          </Tag>
        );
      }
    };

    const periodsBeforeLunch = [];
    const periodsAfterLunch = [];

    classTimetable.forEach((timetableItem) => {
      Object.keys(timetableItem).forEach((periodKey) => {
        if (periodKey !== "timeTableId" && timetableItem[periodKey] !== null) {
          const subjectName = timetableItem[periodKey].subjectName;

          const teachingStatus = renderTeachingStatus(
            timetableItem[periodKey].teaching
          );
          const periodComponent = (
            <div key={periodKey} className="flextable">
              <div className="t_itemname">
                {" "}
                {periodKey.charAt(0).toUpperCase() + periodKey.slice(1)}:
              </div>
              <div className="t_subject">{subjectName}</div>
              <span>{teachingStatus}</span>
            </div>
          );

          if (
            parseInt(periodKey.replace("period", ""), 10) <=
            periodConfig.totalPeriodsBeforeLunch
          ) {
            periodsBeforeLunch.push(periodComponent);
          } else if (
            parseInt(periodKey.replace("period", ""), 10) >
            periodConfig.totalPeriodsBeforeLunch
          ) {
            if (periodKey !== "classId" && periodKey !== "weekDay") {
              periodsAfterLunch.push(periodComponent);
            }
          }
        }
      });
    });

    return (
      <>
        <div className="tableleft">{periodsBeforeLunch}</div>

        <div className="tablecenter">
          <span>
            <b>L</b>
          </span>
          <span>
            <b>u</b>
          </span>
          <span>
            <b>n</b>
          </span>
          <span>
            <b>c</b>
          </span>
          <span>
            <b>h</b>
          </span>
          <br></br>
          <span>
            <b>B</b>
          </span>
          <span>
            <b>r</b>
          </span>
          <span>
            <b>e</b>
          </span>
          <span>
            <b>a</b>
          </span>
          <span>
            <b>k</b>
          </span>
        </div>

        <div className="tablright">{periodsAfterLunch}</div>
      </>
    );
  };
  return (
    <div className="timetable">
      <div className="title">
        <div className="headtitile">
          <h1 className="header-text">Class Timetable</h1>
      
        </div>

        <div>
        <div className="class-section-header">
            <div>
              <ClassDropdown
                onClassChange={onClassChange}
                selectedClass={selectedClass}
              />
            </div>
            <div>
              <SectionDropdown
                classValue={selectedClass}
                onSectionChange={onSectionChange}
                // selectedSection={selectedSection}
              />
            </div>
            <div className="dateshow">
          {formattedDate} : {dayOfWeek}
        </div>
          </div>
        </div>
       
      </div>

      {/* //new timetable style  */}
      {classTimetable.length == [] ? (
        <>
          <EmptyComponent />
        </>
      ) : (
        <div className="timetableblock">{renderTimetable()}</div>
      )}
    </div>
  );
};

export default Timetable;
