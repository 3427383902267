import { useEffect, useState } from "react";
import { Country, State, City } from "country-state-city";

const useLocationData = () => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const [selectedState, setSelectedState] = useState("TG");

  useEffect(() => {
    setCountries(Country.getAllCountries());
    setStates([]);
    setCities([]);
  }, []);
  useEffect(() => {
    setCountries(Country.getAllCountries());
    setStates(State.getStatesOfCountry(selectedCountry)); // Set initial states based on selectedCountry
    setCities(City.getCitiesOfState(selectedCountry, selectedState)); // Set initial cities based on selectedCountry and selectedState
  }, [selectedCountry, selectedState]);
  // useEffect(() => {
  //   if (selectedCountry) {
  //     const country = Country.getCountryByCode(selectedCountry);
  //     setStates(State.getStatesOfCountry(country.isoCode));
  //     setSelectedState(null);
  //     setCities([]);
  //   }
  // }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      const state = State.getStateByCode(selectedState);
      const country = Country.getCountryByCode(selectedCountry);
      setCities(City.getCitiesOfState(country.isoCode, state.isoCode));
    }
  }, [selectedState]);

  const handleCountryChange = (countryCode) => {
    setSelectedCountry(countryCode);
    setSelectedState(null);
  };

  const handleStateChange = (stateCode) => {
    setSelectedState(stateCode);
  };

  return {
    countries,
    states,
    cities,
    handleCountryChange,
    handleStateChange,
    selectedState,
    selectedCountry,
  };
};

export default useLocationData;
