import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Checkbox,
  message,
  Table,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "./LeaveRequest.scss";
// import { useSelector } from "react-redux";
// import { getLeaveCalendarList } from "../../../store/leaveCalendarSlice";
import { leavesService } from "../../../Services/LeaveService";
import DatepickerFloatLabel from "../../Common/FloatLabel/DatePicker";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import TextAreaFloatLabel from "../../Common/FloatLabel/TextArea";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import { createErrorMessage, updateErrorMessage, deleteErrorMessage, dynamicErrorMsg } from "../../../Constants/utilitis";

import dayjs, { Dayjs } from "dayjs";
import { leaveCalendarService } from "../../../Services/LeaveCalanderService";
import Cookies from "js-cookie";
import {
  DislikeOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
const LeaveRequest = ({
  openLeaveRequestForm,
  setOpenLeaveRequestForm,
  leaveViewData,
  setLeaveViewData,
  leaveViewModal,
  setLeaveViewModal,
  leaveRecord,
  getLeaveSLists,
  getStaffAppliedLeaves,
  setLeaveRecord,
}) => {
  const [leaveData, setLeaveData] = useState([]);
  const [leaveDays, setLeaveDays] = useState(null);
  const [leaveRequestForm] = Form.useForm();
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  useEffect(() => {
    getLeavesList();
  }, []);

  const getLeavesList = () => {
    leaveCalendarService
      .leaveCalendarList()
      .then((response) => {
        setLeaveData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (leaveData) {
    var updatedLeaveCalendarList = leaveData.map((item) => {
      return { label: item.leaveType, value: item.leaveTypeId };
    });
  }

  const validateNoOfDates = () => {
    const selectLeaveTypeId = leaveRequestForm.getFieldValue("leaveTypeId");
    const selectDuration = leaveRequestForm.getFieldValue("duration");
    let selectedItem = {};

    if (selectLeaveTypeId && selectDuration) {
      const matchingLeaveType = leaveData.find(
        (item) => item.leaveTypeId === selectLeaveTypeId
      );

      if (matchingLeaveType && selectDuration > matchingLeaveType.noOfLeaves) {
        selectedItem = matchingLeaveType;
        message.error(
          `${selectedItem.leaveType} has ${selectedItem.noOfLeaves} days only, reamining days loss of pay `
        );
        // submitLeaveRequestForm.setFields({
        //   duration: {
        //     value: selectDuration,
        //     errors: ["Please select another category."],
        //   },
        // });
      }
    }
  };

  const calculateDateDiff = () => {
    const { startDate, endDate } = leaveRequestForm.getFieldsValue([
      "startDate",
      "endDate",
    ]);

    if (startDate && endDate) {
      const diffInDays = endDate.diff(startDate, "days") + 1;
      setLeaveDays(diffInDays);
      // leaveRequestForm.setFieldsValue({ duration: `${diffInDays}` });
      validateNoOfDates();
    }
  };

  const cancelViewModal = () => {
    setLeaveViewModal(false);
  };
  const onHalfDayChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      leaveRequestForm.setFieldsValue({ endDate: null, duration: "0.5" });
    } else {
      leaveRequestForm.setFieldsValue({ duration: "" });
      calculateDateDiff();
    }
  };

  const disabledEndDate = (current) => {
    const startDate = leaveRequestForm.getFieldValue("startDate");
    return current && current < startDate.startOf("day");
  };

  const submitLeaveRequestForm = (values) => {
    const leaveId = leaveRecord?.leaveId;
    const updatedStartDate = values.startDate.format("YYYY-MM-DD");
    const updatedEndDate = values.endDate.format("YYYY-MM-DD");
    const daysDiff = parseInt(leaveDays);
    const matchingLeaveType = leaveData.find(
      (item) => item.leaveType == values.leaveType
    );
    const payload = {
      staffId: staffInfo?.staffId,

      startDate: updatedStartDate,
      endDate: updatedEndDate,
      duration: daysDiff,
      reason: values.reason,
      isHalfDay: values.isHalfDay,
      leaveTypeId: values.leaveTypeId,
    };

    if (!leaveId) {
      leavesService
        .createLeave(payload)
        .then((response) => {
          setOpenLeaveRequestForm(false);

          leaveRequestForm.resetFields();
          notification.success({
            message: `Leave Created Successfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          setLeaveDays(null);
          setLeaveRecord(null);
          getLeaveSLists();
        })
        .catch((error) => {
  
          const errorMessage = dynamicErrorMsg(error, "Create", "Leave")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    } else {
      leavesService
        .leaveEdit(leaveId, payload)
        .then((response) => {
          notification.success({
            message: `Leave Updated Successfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          getLeaveSLists();
          setOpenLeaveRequestForm(false);
          setLeaveRecord(null);
          setLeaveDays(null);
          leaveRequestForm.resetFields();
        })
        .catch((error) => {
          if (error) {
            const errorMessage = dynamicErrorMsg(error, "Update", "Leave")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
          }
        });
    }
  };

  const cancelLeaveModal = () => {
    setLeaveRecord(null);
    setOpenLeaveRequestForm(false);
    leaveRequestForm.resetFields();
    setLeaveDays(null);
  };

  const handleChange = (value) => {};

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };
  const shouldDisableEndDate = (current) => {
    const isHalfDayChecked = leaveRequestForm.getFieldValue("isHalfDay");
    const startDate = leaveRequestForm.getFieldValue("startDate");

    // Disable endDate if Half Day is checked or if current date is before the startDate
    return (
      isHalfDayChecked || (startDate && current.isBefore(startDate, "day"))
    );
  };

  const columns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const data = [
    {
      field: "Start Date",
      value: leaveViewData?.startDate,
    },
    {
      field: "End Date",
      value: leaveViewData?.endDate || "-",
    },
    {
      field: "Duration",
      value: leaveViewData?.duration,
    },
    {
      field: "Leave Type",
      value: leaveViewData?.leaveType,
    },
    {
      field: "Reason",
      value: leaveViewData?.reason,
    },
    {
      field: "Status",
      value: leaveViewData?.status,
    },
  ];
  console.log("llll", leaveRecord);

  useEffect(() => {
    if (leaveRecord !== null) {
      const updatedStartDate =
        leaveRecord?.startDate === undefined
          ? dayjs(new Date())
          : dayjs(leaveRecord?.startDate, "YYYY-MM-DD");
      const updatedEndDate =
        leaveRecord?.endDate === undefined
          ? dayjs(new Date())
          : dayjs(leaveRecord?.endDate, "YYYY-MM-DD");
      const matchingLeaveType = leaveData.find(
        (item) => item.leaveType === leaveRecord?.leaveType
      );

      if (leaveRecord?.leaveId) {
        leaveRequestForm.setFieldsValue({
          duration: leaveRecord?.duration,
          startDate: updatedStartDate,
          endDate: updatedEndDate,
          isHalfDay: leaveRecord?.isHalfDay || false,
          leaveTypeId: matchingLeaveType.leaveTypeId,
          reason: leaveRecord?.reason,
          status: leaveRecord?.status,
        });
      } else {
        leaveRequestForm.resetFields();
      }

      calculateDateDiff();
    }
  }, [leaveData, leaveRecord, leaveRequestForm]);

  return (
    <>
      <div className="leavefrom-wrappper">
        <Modal
          title={leaveRecord?.leaveId ? "Edit Leave Form" : "Create Leave Form"}
          open={openLeaveRequestForm}
          onCancel={cancelLeaveModal}
          footer={null}
          // width={500}
          centered
        >
          <Form form={leaveRequestForm} onFinish={submitLeaveRequestForm}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="startDate"
                  // style={{ width: 500 }}
                  rules={[{ required: true, message: "" }]}
                >
                  <DatepickerFloatLabel
                    onChange={calculateDateDiff}
                    disabledDate={disabledDate}
                    label="Start Date"
                    format="DD-MM-YYYY"
                    style={{ width: 235 }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="endDate"
                  // style={{ width: 1000 }}
                  // rules={[{ required: true, message: "" }]}
                >
                  <DatepickerFloatLabel
                    onChange={calculateDateDiff}
                    disabledDate={shouldDisableEndDate}
                    label="End Date"
                    format="DD-MM-YYYY"
                    style={{ width: 235 }}
                    disabled={leaveRequestForm.getFieldValue("isHalfDay")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {/* <Col className="gutter-row" span={12}>
              <Form.Item
                name="duration"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel
                  label="Days"
                  type="number"
                  onChange={validateNoOfDates}
                />
              </Form.Item>
            </Col> */}
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="leaveTypeId"
                  rules={[{ required: true, message: "" }]}
                >
                  <SelectFloatLabel
                    label="Leave Type"
                    name="leaveTypeId"
                    options={updatedLeaveCalendarList}
                    onChange={validateNoOfDates}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="isHalfDay"
                  label="Half Day"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox
                    onChange={onHalfDayChange}
                    checked={leaveRequestForm.getFieldValue("isHalfDay")}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="reason"
                  rules={[{ required: true, message: "" }]}
                >
                  <TextAreaFloatLabel label="Reason" />
                </Form.Item>
              </Col>
            </Row>
            {leaveDays && `You are applying leave for ${leaveDays} days`}
            <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} align="end">
                <Space direction="vertical" size="large">
                  <Button className="submit-button " htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical" size="large">
                  <Button
                    className="newCancelButton"
                    onClick={cancelLeaveModal}
                  >
                    Cancel
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          centered
          open={leaveViewModal}
          title="Leave Applied Details"
          onCancel={cancelViewModal}
          footer={null}
          className="resignation-View-modal"
        >
          {leaveViewModal && (
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              showHeader={false}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

export default LeaveRequest;
