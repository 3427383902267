import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Tag,
  Select,
  Radio,
  message,
  Switch,
  Row,
  Col,
  Table,
  notification,
  Upload,
  Avatar,
  Spin,
  DatePicker,
} from "antd";
import {
  EditOutlined,
  LikeTwoTone,
  UploadOutlined,
  CheckCircleFilled,
  DislikeOutlined,
} from "@ant-design/icons";
import { staffServices } from "../../../Services/StaffServices";
import "./Myprofile.scss";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import TextAreaFloatLabel from "../../Common/FloatLabel/TextArea";
import Cookies from "js-cookie";
import { profileService } from "../../../Services/ProfileServices";
import dayjs from "dayjs";
import { dynamicErrorMsg, saveErrorMessage, updateErrorMessage } from "../../../Constants/utilitis";

const columns = [
  {
    title: "Class",
    dataIndex: "className",
    align: "center",
  },
  {
    title: "Section",
    dataIndex: "section",
    align: "center",
  },
  {
    title: "Subject",
    dataIndex: "subjectName",
    align: "center",
  },
];

const classInfo = Cookies.get("classInfo")
  ? JSON.parse(Cookies.get("classInfo"))
  : [];

const subjectsInfo = Cookies.get("subjectsInfo")
  ? JSON.parse(Cookies.get("subjectsInfo"))
  : [];

const data = classInfo.reduce((acc, classInfo) => {
  const filteredSubjects = subjectsInfo.filter(
    (subjectInfo) => subjectInfo.classId === classInfo.classId
  );
  const newData = filteredSubjects.map((subjectInfo) => ({
    key: `${classInfo.classId}-${subjectInfo.subjectId}`,
    className: classInfo.className,
    section: classInfo.section,
    subjectName: subjectInfo.subjectName,
  }));
  return [...acc, ...newData];
}, []);

const MyProfile = () => {
  const [staffData, setStaffData] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [teachingSubjects, setTeachingSubjects] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [form] = Form.useForm();
  const [instLogoForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [staffId, setStaffId] = useState(Cookies.get("userId"));
  const [logoImage, setLogoImage] = useState(null);
  const [staffAllDetails, setSatffAllDetails] = useState([]);

  useEffect(() => {
    staffDetails();
  }, []);

  useEffect(() => {
    const momentDateOfBirth =
      staffData?.dateOfBirth === undefined
        ? dayjs(new Date(), "YYYY-MM-DD")
        : dayjs(staffData?.dateOfBirth, "YYYY-MM-DD");
    form.setFieldsValue({
      name: staffData.name,
      contact: staffData.contact,
      email: staffData.email,
      address: staffData.address,
      city: staffData.city,
      state: staffData.state,
      country: staffData.country,
      dateOfBirth: momentDateOfBirth,
      nationality: staffData.nationality,
      deptName: staffData.deptName,
      teachingClass: staffData.teachingClass,
      gender: staffData.gender,
      experience: staffData.experience,
      designation: staffData.designation,
      teachingStaff: staffData.teachingStaff,
      deptId: staffData.deptId,
      dateOfJoining: staffData.dateOfJoining,
      qualification: staffData.qualification,
    });
  }, [staffData, form]);

  const getStaffDetails = () => {
    staffServices
      .findStaffDetails(staffId)
      .then((response) => {
        setUploading(false);
        setSatffAllDetails(response.data);
      })
      .catch((error) => {
        setUploading(false)
        const errorMessage = dynamicErrorMsg(error, "Get", "Staff Details")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getStaffDetails();
  }, [staffId]);

  const staffDetails = () => {
    staffServices.findStaffDetails(staffId)
    .then((response) => {
      let findSubjects = response.data.classSubjectsInfo;
      let StaffInfo = response?.data;
      setTeachingSubjects(findSubjects);
      if (response.status === 200) {
        setStaffData(StaffInfo);
        setSubjects(response.data.subjectsInfo);
        setClasses(response.data.classesInfo);
      }
    });
  };
  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleFormChange = () => {
    setFormChanged(form.isFieldsTouched());
  };

  // const handleCancel = () => {
  //   setIsEditMode(false);
  // };

  // Handle tag close event
  // const handleClose = (removedSubject) => {
  //   setSubjects(subjects.filter((subject) => subject !== removedSubject));
  //   setTeachingClass(teachingClass.filter((clas) => clas !== removedSubject));
  // };

  // Add new class
  // const handleAdd = () => {
  //   setTeachingClass([...teachingClass, inputValue]);
  //   setInputValue("");
  //   setShowInput(false);
  // };

  // const handleSubjectsAdd = () => {
  //   setSubjects([...subjects, inputSubValue]);
  //   setInputSubValue("");
  //   setShowSubInput(false);
  // };

  const onFinish = (values) => {
    setIsEditMode(false);

    const {
      name,
      contact,
      email,
      gender,
      designation,
      experience,
      dateOfBirth,
      address,
      city,
      state,
      country,
      nationality,
      isTeachingStaff,
      dateOfJoining,
      deptName,
      qualification,
    } = values;
    const splitOnSpace = name.split(" ");
    const firstName = splitOnSpace[0];
    const lastName = splitOnSpace[1];
    const momentDateOfBirth2 = dayjs(values.dateOfBirth).format("YYYY-MM-DD");
    const payload = {
      firstName,
      lastName,
      contact,
      email,
      gender,
      designationId: staffData.designationId,
      experience,
      dateOfBirth: momentDateOfBirth2,
      address,
      city,
      state,
      country,
      nationality,
      isTeachingStaff,
      dateOfJoining,
      deptName,
      qualification,
      deptId: staffData.deptId,
    };

    staffServices
      .editStaff(payload, staffId)
      .then((response) => {
        if (response.status === 200) {
          setStaffData(response.data);
          notification.success({
            message: `update staff Successfully`,
            placement: "bottomRight",
            icon: (
              <LikeTwoTone
                style={{
                  color: "#108ee9",
                }}
              />
            ),
          });

          setFormChanged(false);
        }
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error,"Update","Staff")
      
      notification.error({
        message: errorMessage,
        placement: "bottomRight",
        icon: <DislikeOutlined style={{ color: "red" }} />,
      });
      });
  };

  const onFinishFailed = (errorInfo) => {};

  // profile

  const handleUploadChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFileList([file]);
      setLogoImage(URL.createObjectURL(file));
      onfinish({ fileList: [file] });
    }
  };

  const onfinish = (values) => {
    const { fileList } = values;
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append(`profilePic`, file);
    });
    const payload = formData;

    if (fileList.length > 0) {
      setUploading(true);
      staffServices
        .staffProfileUpload(staffId, payload)
        .then((res) => {
          instLogoForm.resetFields();
          setUploading(false);
          if (res.status === 200) {
            notification.success({
              description: `Profile Picture Sucessfully`,
              icon: (
                <CheckCircleFilled
                  style={{
                    color: "green",
                  }}
                />
              ),
              duration: 3,
              placement: "bottomRight",
            });
          }
        })
        .catch((error) => {
          setUploading(false);
          if (error) {
            const errorMessage = dynamicErrorMsg(error,"", "Uploading Profile")
        
            notification.error({
              message: errorMessage,
              placement: "bottomRight",
              icon: <DislikeOutlined style={{ color: "red" }} />,
            });
          } else {
            setUploading(false);
          }
          });
        }
  };

  const handleImageClick = () => {
    const uploadInput = document.getElementById("uploadInput");
    if (uploadInput) {
      uploadInput.click();
    }
  };
  
  return (
    <section className="myProfile">
      <div className="profile-card card">
        <div className="ProfileName">
          <p className="profiletext">Profile</p>

          <Form form={instLogoForm} onFinish={onfinish}>
            <Form.Item name="instLogo">
              <div onClick={handleImageClick} style={{ cursor: "pointer" }}>
                <Spin spinning={uploading} size="large">
                  {staffAllDetails?.profilePicUrl ? (
                    <img
                      src={logoImage || staffAllDetails?.profilePicUrl}
                      alt="Uploaded Logo"
                      className="studentprofile-img"
                      loading="lazy"
                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: "50%",

                        marginBlock: 5,
                      }}
                    />
                  ) : (
                    <Avatar
                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: "50%",

                        marginBlock: 5,
                      }}
                      src={
                        "https://media.licdn.com/dms/image/D5603AQFe-YqiBjExVw/profile-displayphoto-shrink_200_200/0/1678376802616?e=1689206400&v=beta&t=7-mfXYEHTTBAHyW9Gs0WH-Yzq4fhWqMAcViF-rlXRD8"
                      }
                      alt="user"
                    />
                  )}
                </Spin>
                <span className="name">{staffAllDetails?.name}</span>
              </div>
            </Form.Item>

            <input
              id="uploadInput"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleUploadChange}
            />
          </Form>
        </div>
        <div>
          <Form
            className="profile-form"
            form={form}
            name="edit-profile"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            onValuesChange={handleFormChange}
          >
            <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 32 }}>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your name",
                    },
                  ]}
                >
                  <InputFloatLabel label="Your Name" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="contact"
                  rules={[
                    {
                      required: true,
                      message: "Please input your contact!",
                    },
                    {
                      min: 10,
                      message: "please enter valid phone number",
                    },
                    {
                      max: 10,
                      message: "cannot exceed more than 10",
                    },
                  ]}
                >
                  <InputFloatLabel label="Mobile Number" type="number" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="dateOfBirth"
                  rules={[{ required: true, message: "" }]}
                >
                  <DatePicker label="Date of Birth" format="DD-MM-YYYY" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="Country" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="City" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel label="State" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="gender"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <SelectFloatLabel label="Gender">
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                    <Select.Option value="other">Other</Select.Option>
                  </SelectFloatLabel>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <InputFloatLabel label="Mail" type="email" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="designation"
                  rules={[
                    {
                      required: true,
                      message: "Please input your designation!",
                    },
                  ]}
                >
                  <InputFloatLabel label="Designation" disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="dateOfJoining"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputFloatLabel label="Date Of Joining" disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="qualification"
                  rules={[
                    {
                      required: true,
                      message: "please enter qualification",
                    },
                  ]}
                >
                  <InputFloatLabel label="Qualification" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="nationality"
                  rules={[
                    {
                      required: true,
                      message: "Please input your nationality",
                    },
                  ]}
                >
                  <InputFloatLabel label="Nationality" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="experience"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Experience!",
                    },
                  ]}
                >
                  <InputFloatLabel label="Experience" disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="deptName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your department name",
                    },
                  ]}
                >
                  <InputFloatLabel label="Department Name" disabled />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  className="form-item"
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your address",
                    },
                  ]}
                >
                  <TextAreaFloatLabel label="Address" />
                </Form.Item>
              </Col>
            </Row>
            <Row align="end">
              <Button
                type="primary"
                htmlType="submit"
                className="blue-button"
                style={{ float: "right", marginTop: 10 }}
              >
                Save
              </Button>
            </Row>
          </Form>
        </div>
      </div>
      <div className="subjects-card card">
        <h1>Teaching Subjects</h1>
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    </section>
  );
};

export default MyProfile;
