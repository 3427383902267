import React from "react";
import { Topbar, Exam } from "../../components";
import SEO from "../../components/reuse";

function Exams() {
  return (
    <main>
      {/* <Topbar /> */}
      <SEO title="exams" />
      <Exam />
    </main>
  );
}

export default Exams;
