import {
  STUDYMATERIAL_LIST,
  CREATE_STUDYMATERIAL,
  EDIT_STUDYMATERIALS,
  EDIT_STUDYMATERIAL_ENDPOINT,
  FIND_STUDYMATERIAL,
  FIND_STUDYMATERIAL_ENDPOINT,
  DELETE_STUDYMATERIAL_FILE,
  DELETE_STUDYMATERIAL_ENDPOINT,
} from "../Constants/constants";

import { DeleteAPIRequest, GetAPIRequest } from "./Api";
import { PostAPIRequest } from "./Api";

const getStudyMaterialist = (params) => {
  return GetAPIRequest({ url: STUDYMATERIAL_LIST, params });
};

const createStudymaterial = (payLoad) => {
  return PostAPIRequest({ url: CREATE_STUDYMATERIAL, data: payLoad });
};

const editStudyMaterial = (id, payLoad) => {
  return PostAPIRequest({
    url: EDIT_STUDYMATERIALS + id + EDIT_STUDYMATERIAL_ENDPOINT,
    data: payLoad,
  });
};
const findStudyMaterial = (id, payLoad) => {
  return GetAPIRequest({
    url: FIND_STUDYMATERIAL + id + FIND_STUDYMATERIAL_ENDPOINT,
    data: payLoad,
  });
};
const DeleteStudyMaterialIndividualFile = (id,params) => {
  return DeleteAPIRequest({
    url: DELETE_STUDYMATERIAL_FILE + id + DELETE_STUDYMATERIAL_ENDPOINT,
    params
  });
};

export const studyMaterialApiService = {
  createStudymaterial,
  getStudyMaterialist,
  editStudyMaterial,
  findStudyMaterial,
  DeleteStudyMaterialIndividualFile,
};
