import React, { useEffect, useState } from "react";
import EmptyComponent from "../../../Common/EmptyComponent";
import { LibraryService } from "../../../../Services/LibraryService";
import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Space,
  notification,
} from "antd";
import "../MyAccount.scss";
import Cookies from "js-cookie";
import {
  CheckOutlined,
  CloseOutlined,
  DislikeOutlined,
  EditOutlined,
  EyeOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import "./SuggestedBook.scss";
import { createErrorMessage, dynamicErrorMsg, updateErrorMessage } from "../../../../Constants/utilitis";
const SuggestedBooks = () => {
  const [suggestionForm] = Form.useForm();
  const [suggestedBooks, setSuggestedBooks] = useState([]);
  const [visible, setVisible] = useState(false);
  const [studyModalDetails, setStudyModalDetails] = useState(false);
  const [viewData, setViewData] = useState({});
  const [editSuggestedBook, seteditSuggestedBook] = useState(null);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  useEffect(() => {
    if (editSuggestedBook?.id) {
      suggestionForm.setFieldsValue({
        authorName: editSuggestedBook?.authorName,
        bookName: editSuggestedBook?.bookName,
      });
    } else {
      suggestionForm.resetFields();
    }
  }, [suggestionForm, editSuggestedBook]);
  const staffId = staffInfo?.staffId;

  const viewSuggetionBook = (record) => {
    setStudyModalDetails(true);
    setViewData(record);
  };
  const handleOnclick = (record) => {
    seteditSuggestedBook(record);
    setVisible(true);
  };

  const columns = [
    {
      title: "Author Name",
      dataIndex: "authorName",
      key: "authorName",
      align: "center",
    },
    {
      title: "Book Name",
      dataIndex: "bookName",
      key: "bookName",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (_, record) => (
        <Space>
          {/* <EyeOutlined
            className="full-details-button"
            onClick={() => viewSuggetionBook(record)}
          /> */}
          <EditOutlined
            className="full-details-button"
            onClick={() => handleOnclick(record)}
          />
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setVisible(true);
  };

  const getAllSuggestedBooks = () => {
    const param = {
      userId: staffId,
      userType: "Staff",
    };
    LibraryService.getAllSuggestedBooksApi(param)
      .then((res) => {
        setSuggestedBooks(res?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Suggested Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getAllSuggestedBooks();
  }, []);

  const handleCancel = () => {
    setVisible(false);
    suggestionForm.resetFields();
    seteditSuggestedBook(null);
  };

  const handleModalCancel = () => {
    setStudyModalDetails(false);
  };

  const viewcColumns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];
  const datas = [
    {
      field: "User Name",
      value: viewData?.userName,
    },
    {
      field: "Book Name",
      value: viewData?.bookName,
    },
    {
      field: "Author Name",
      value: viewData?.authorName,
    },
  ];

  const onFinish = (values) => {
    const params = {
      userId: staffId,
      userType: "Staff",
    };
    const bookId = editSuggestedBook?.id;
    if (!bookId) {
      LibraryService.createSuggestedBooks(values, params)
        .then((response) => {
          setVisible(false);
          getAllSuggestedBooks();
          notification.success({
            message: `${values.bookName} Created successfully `,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
        })
        .catch((error) => {
          let errorMessage = createErrorMessage("Suggested Books")
        if (
          error?.response?.data?.message
        ) {
          errorMessage = error.response.data.message;
        } else if (error?.data?.message) {
          errorMessage = error.data.message;
        }
        else if (error?.message) {
          errorMessage = error.message;
        }
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    } else {
      LibraryService.editSuggestionBook(bookId, values)
      .then((res) => {
        setVisible(false);
        getAllSuggestedBooks();
        suggestionForm.resetFields();
        notification.success({
          message: `${values.bookName} Edited successfully `,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
      })
      .catch((error) => {
        let errorMessage = updateErrorMessage("Suggested Books")
      if (
        error?.response?.data?.message
      ) {
        errorMessage = error.response.data.message;
      } else if (error?.data?.message) {
        errorMessage = error.data.message;
      }
      else if (error?.message) {
        errorMessage = error.message;
      }
      notification.error({
        message: errorMessage,
        placement: "bottomRight",
        icon: <DislikeOutlined style={{ color: "red" }} />,
      });
      });
    }
  };

  return (
    <div className="suggested_book_container">
      <div className="SuggestButtonContainer">
        <Button className="SuggestButton" onClick={showModal}>
          Create
        </Button>
      </div>
      <>
        {/*<Table columns={columns} dataSource={data} />*/}
        <Table columns={columns} dataSource={suggestedBooks} />
      </>
      <div>
        <Modal
          title={
            editSuggestedBook && Object.keys(editSuggestedBook).length > 0
              ? "Edit Suggested Book"
              : "Create Suggested Book"
          }
          open={visible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form onFinish={onFinish} form={suggestionForm}>
            <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} align="end">
                <Space direction="vertical" size="large">
                  <Form.Item
                    name="authorName"
                    style={{ width: "300" }}
                    rules={[{ required: true, message: "" }]}
                  >
                    <InputFloatLabel label="Author Name" />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical" size="large">
                  <Form.Item
                    name="bookName"
                    style={{ width: "300" }}
                    rules={[{ required: true, message: "" }]}
                  >
                    <InputFloatLabel label="Book Name" />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} align="end">
                <Space direction="vertical" size="large">
                  <Button className="submit-button " htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Col>
              <Col span={12}>
                <Space direction="vertical" size="large">
                  <Button className="newCancelButton" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
      <Modal
        open={studyModalDetails}
        title="Suggested Book Details"
        onCancel={handleModalCancel}
        footer={null}
        width={600}
        centered
        className="view-assignment"
      >
        {viewData && (
          <Table
            columns={viewcColumns}
            dataSource={datas}
            pagination={false}
            showHeader={false}
          />
        )}
      </Modal>
    </div>
  );
};
export default SuggestedBooks;
