import React from "react";
import { BellOutlined } from "@ant-design/icons";
import "./EmptyNotification.scss";
const EmptyNotification = (props) => {
  return (
    <div className="empty-notification">
      <BellOutlined className="icon" />
      <span className="notification-message">
        {props.message ?? "No notifications at the moment."}
      </span>
    </div>
  );
};

export default EmptyNotification;
