import { GetAPIRequest, PostAPIRequest } from "./Api";
import {
  CREATE_LEAVE,
  FIND_LEAVE,
  FIND_LEAVE_ENDPOINT,
  LEAVES_LIST,
  EDIT_LEAVE_CALENDAR,
  EDIT_LEAVE_CALENDAR_ENDPOINT,
  FIND_STAFF_APPLIED_LEAVES,
  FIND_STAFF_APPLIED_LEAVES_ENDPOINT,
  APPLIED_LEAVE_EDIT,
  APPLIED_LEAVE_EDIT_ENDPOINT,
  GET_STUDENT_LEAVE_ENDPOINT,
  GET_STUDENT_LEAVE,
  UPDATE_STUDENT_LEAVEREQUEST_STATUS,
  UPDATE_STUDENT_LEAVEREQUEST_STATUS_ENDPOINT,
} from "./../Constants/constants";

const createLeave = (payload) => {
  return PostAPIRequest({ url: CREATE_LEAVE, data: payload });
};
const leaveEdit = (leaveId, payload) => {
  return PostAPIRequest({
    url: APPLIED_LEAVE_EDIT + leaveId + APPLIED_LEAVE_EDIT_ENDPOINT,
    data: payload,
  });
};
const leavesList = (params) => {
  return GetAPIRequest({ url: LEAVES_LIST, params });
};
const findLeave = (leaveId) => {
  return GetAPIRequest({ url: FIND_LEAVE + leaveId + FIND_LEAVE_ENDPOINT });
};

const findStaffAppliedLeaves = (staffId) => {
  return GetAPIRequest({
    url:
      FIND_STAFF_APPLIED_LEAVES + staffId + FIND_STAFF_APPLIED_LEAVES_ENDPOINT,
  });
};

const staffLeaveUsage = (staffId) => {
  return GetAPIRequest({
    url: EDIT_LEAVE_CALENDAR + staffId + EDIT_LEAVE_CALENDAR_ENDPOINT,
  });
};
const getStudentLeave = (staffId) => {
  return GetAPIRequest({
    url: GET_STUDENT_LEAVE + staffId + GET_STUDENT_LEAVE_ENDPOINT,
  });
};
const updateStudentLeaveStatus = (id) => {
  return GetAPIRequest({
    url:
      UPDATE_STUDENT_LEAVEREQUEST_STATUS +
      id +
      UPDATE_STUDENT_LEAVEREQUEST_STATUS_ENDPOINT,
  });
};
// /{staffId}/leaveRequests
export const leavesService = {
  createLeave,
  leaveEdit,
  leavesList,
  findLeave,
  staffLeaveUsage,
  findStaffAppliedLeaves,
  getStudentLeave,
  updateStudentLeaveStatus,
};
