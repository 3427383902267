import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Modal,
  Space,
  notification,
  Table,
} from "antd";
import "./BankDetails.scss";
import { profileService } from "../../../Services/ProfileServices";
import {
  CloseOutlined,
  DislikeOutlined,
  LikeTwoTone,
  EditOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import FloatInputAll from "../../Common/FloatLabel/InputAll";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import Cookies from "js-cookie";
import { createErrorMessage, dynamicErrorMsg, updateErrorMessage } from "../../../Constants/utilitis";
const { Option } = Select;
const Banking = () => {
  const [BankingForm] = Form.useForm();
  const [AddBankDetails] = Form.useForm();
  const [staffData, setStaffData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editBank, setEditBank] = useState(null);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];

  console.log("editBank", editBank);

  const staffBankingDetails = () => {
    profileService
      .findBankDetails(staffInfo?.staffId)
      .then((response) => {
        if (response.status === 200) {
          setStaffData(response?.data);
          console.log("bankkkk", response.data);
        }
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Bank Details")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    staffBankingDetails();
  }, []);

  const handleCancel = () => {
    setIsModalOpen(false);
    setEditBank(null);
    BankingForm.resetFields();
  };

  const column = [
    {
      title: "Account Holder Name",
      dataIndex: "accHolderName",
      key: "accHolderName",
      align: "center",
      sorter: (a, b) => a.accHolderName.localeCompare(b.accHolderName),
      defaultSortOrder: "ascend",
    },
    {
      title: "Account Number",
      dataIndex: "accNumber",
      key: "accNumber",

      sorter: (a, b) => a.accNumber.localeCompare(b.accNumber),
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",

      sorter: (a, b) => a.bankName.localeCompare(b.bankName),
    },
    {
      title: "IFSC Code",
      dataIndex: "ifscCode",
      key: "ifscCode",

      sorter: (a, b) => a.ifscCode.localeCompare(b.ifscCode),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",

      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",

      render: (text, row) => (
        <Space>
          <EditOutlined
            className="full-details-button"
            onClick={() => handleEdit(row)}
          />
        </Space>
      ),
    },
  ];

  const validateConfirmAccountNumber = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("accNumber") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The two Account Numbers do not match!");
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleEdit = (record) => {
    setEditBank(record);
    setIsModalOpen(true);
  };
  useEffect(() => {
    if (editBank?.id) {
      BankingForm.setFieldsValue({
        bankName: editBank?.bankName,
        accHolderName: editBank?.accHolderName,
        accNumber: editBank?.accNumber,
        ifscCode: editBank?.ifscCode,
        staffId: staffInfo?.staffId,
        status: editBank?.status,
        // conformAccNumber: editBank.conformAccNumber,
      });
    } else {
      BankingForm.resetFields();
    }
  }, [editBank, BankingForm]);

  const onFinish = (values) => {
    const payload = {
      bankName: values?.bankName,
      accHolderName: values?.accHolderName,
      accNumber: values?.accNumber,
      ifscCode: values?.ifscCode,
      staffId: staffInfo?.staffId,
      status: values?.status,
    };
    console.log("payload", payload);
    const id = editBank?.id;

    if (id) {
      profileService
        .editBankDetails(id, payload)
        .then((response) => {
          if (response.status === 200) {
            notification.success({
              message: `Bank Account Updated Sucessfully`,
              placement: "bottomRight",
              icon: (
                <CheckCircleFilled
                  style={{
                    color: "green",
                  }}
                />
              ),
            });
            staffBankingDetails();
            setEditBank(null);
            BankingForm.resetFields();
            setIsModalOpen(false);
          }
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error,"Update" ,"Bank details")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    } else {
      profileService
        .createBanking(payload)
        .then((response) => {
          if (response.status === 200) {
            notification.success({
              message: `Bank Account Created Sucessfully`,
              placement: "bottomRight",
              icon: (
                <CheckCircleFilled
                  style={{
                    color: "green",
                  }}
                />
              ),
            });
            staffBankingDetails();
            setEditBank(null);
            BankingForm.resetFields();
            setIsModalOpen(false);
          }
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Create","Bank details")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });    }
  };
  const onFinishFailed = () => {
    notification.error({
      description:
        "An error occurred while Adding Bank Account Please Enter All Information , try again later.",
      duration: 5,
      placement: "topRight",
    });
  };
  return (
    <div className="bankdetails-container">
      <div className="header">
        <h2>Back Account</h2>
        <Button className="blue-button" onClick={showModal}>
          Add Bank Details
        </Button>
      </div>
      <div className="table">
        <Table
          dataSource={staffData}
          columns={column}
          showSorterTooltip={false}
        />
      </div>
      <Modal
        title={
          editBank ? "Edit Bank Account Details" : "Add Bank Account Details"
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={BankingForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="form-div">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item
                  name="bankName"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <SelectFloatLabel label="Bank Name">
                    <Option value="SBI">State Bank Of India</Option>
                    <Option value="Canara">CANARA</Option>
                    <Option value="HDFC">HDFC</Option>
                  </SelectFloatLabel>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="accHolderName"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel
                    label="Account Holder Name"
                    className="float-inputs"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                {" "}
                <Form.Item
                  name="accNumber"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <InputFloatLabel
                    className="float-inputs"
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    label="Account Number"
                    type="number"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="conformAccNumber"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    validateConfirmAccountNumber,
                  ]}
                >
                  <InputFloatLabel
                    type="number"
                    label="Confirm Account Number"
                    className="float-inputs"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="ifscCode"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <FloatInputAll label="IFSC CODE" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} align="end">
                <Space direction="vertical" size="large">
                  <Button className="submit-button" htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Col>
              <Col span={12} size="large">
                <Space direction="vertical" size="large">
                  <Button className="newCancelButton" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default Banking;
