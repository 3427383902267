import { useState } from "react";
import "./Library.scss";
import { Radio } from "antd";
import LibraryHome from "./HomePage/LibraryHome";
import Authors from "./Authors/Authors";
import Books from "./Books/Books";
import Categories from "./Categories/Categories";
import RentBooks from "./MyAccount/MyRentBooks/RentBooks";
import RequestedBooks from "./MyAccount/RequestedBooks/RequestedBooks";
import ReturnBooks from "./MyAccount/ReturnedBooks/ReturnedBooks";
import PendingBooks from "./MyAccount/PendingBooks/PendingBooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MissingBooks from "./MyAccount/MissingBooks/MissingBooks";
import SuggestedBooks from "./MyAccount/SuggestedBooks/SuggestedBooks";

const RadioGroup = Radio.Group;
const Library = () => {
  const [activeTabKey, setActiveTabKey] = useState("home");
  const [selected, setSelected] = useState(null);

  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
    if (event.target.value === "myAccount") {
      setSelected("rentBooks");
    } else {
      setSelected(null);
    }
  };
  const handleTabChangeMyAccount = (e) => {
    setSelected(e.target.value);
  };
  const [selectedAuthorId, setSelectedAuthorId] = useState(null);

  return (
    <main className="library-wrapper">
      <div className="header-container topradio">
        <RadioGroup
          onChange={handleTabChange}
          value={activeTabKey}
          optionType="button"
          buttonStyle="solid"
          className="mainradio"           

        >
          <Radio.Button value="home">Home</Radio.Button>
          <Radio.Button value="authors">Authors</Radio.Button>
          <Radio.Button value="books">Books</Radio.Button>
          <Radio.Button value="myAccount">My Account</Radio.Button>
        </RadioGroup>
      </div>
      {activeTabKey === "myAccount" && (
        <RadioGroup
          onChange={handleTabChangeMyAccount}
          value={selected}
          optionType="button"
          buttonStyle="solid"                  
        >
          <Radio.Button value="rentBooks">My Rent Books</Radio.Button>
          <Radio.Button value="requestedBooks">Request</Radio.Button>
          <Radio.Button value="returned">Returned</Radio.Button>
          <Radio.Button value="pendingBooks">Pending</Radio.Button>
          <Radio.Button value="missingBooks">Missing</Radio.Button>
          <Radio.Button value="suggestedBooks">Suggested</Radio.Button>
        </RadioGroup>
      )}
      {activeTabKey === "home" && <LibraryHome />}
      {activeTabKey === "authors" && (
        <Authors setActiveTabKey={setActiveTabKey} />
      )}
      {activeTabKey === "books" && (
        <Books setActiveTabKey={setActiveTabKey} authorId={selectedAuthorId} />
      )}
      {selected === "rentBooks" && <RentBooks />}
      {selected === "requestedBooks" && <RequestedBooks />}
      {selected === "pendingBooks" && <PendingBooks />}
      {selected === "returned" && <ReturnBooks />}
      {selected === "missingBooks" && <MissingBooks />}
      {selected === "suggestedBooks" && <SuggestedBooks />}
    </main>
  );
};

export default Library;
