import React from "react";
import { Topbar, Assignment } from "../../components";
import SEO from "../../components/reuse";

const Assignments = () => {
  return (
    <main>
      {/* <Topbar /> */}
      <SEO title="assignment" />
      <Assignment />
    </main>
  );
};

export default Assignments;
