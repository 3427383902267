import {
  GETALL_RESIGNATION,
  CREATE_RESIGNATION,
  CREATE_RESIGNATION_ENDPOINT,
  EDIT_RESIGNATION,
  EDIT_RESIGNATION_ENDPOINTS,
  GET_RESIGNATION,
  GET_RESIGNATION_ENDPOINT,
  GET_ALL_REGULARAIZATIONS_REQUEST,
  GETALL_STAFF_UNLOCK_REQUEST,
  CREATE_UNLOCK_REQUEST,
  CREATE_UNLOCK_REQUEST_ENDPOINT,
  EDIT_REGULARIZATION,
  EDIT_REGULARIZATION_ENDPOINTS,
  GET_STAFF_REGULARIZATION,
  GET_STAFF_REGULARIZATION_ENDPOINT,
  GET_STAFF_UNLOCK_REQUEST,
  GET_STAFF_UNLOCK_REQUEST_ENDPOINT,
  CREATE_REGULARIZATION_REQUEST,
  CREATE_REGULARIZATION_REQUEST_ENDPOINT,
} from "../../src/Constants/constants";
import { PostAPIRequest, GetAPIRequest } from "./Api";

const getAllResignation = (staffId) => {
  return GetAPIRequest({
    url: GET_RESIGNATION + staffId + GET_RESIGNATION_ENDPOINT,
  });
};

const CreateResignation = (id, payLoad) => {
  return PostAPIRequest({
    url: CREATE_RESIGNATION + id + CREATE_RESIGNATION_ENDPOINT,
    data: payLoad,
  });
};

const editResignation = (id, payLoad) => {
  return PostAPIRequest({
    url: EDIT_RESIGNATION + id + EDIT_RESIGNATION_ENDPOINTS,
    data: payLoad,
  });
};
const getAllRegularization = (params) => {
  return GetAPIRequest({ url: GET_ALL_REGULARAIZATIONS_REQUEST });
};

const editRegularization = (id, payLoad) => {
  return PostAPIRequest({
    url: EDIT_REGULARIZATION + id + EDIT_REGULARIZATION_ENDPOINTS,
    data: payLoad,
  });
};
const createRegularizationRequest = (payLoad, params) => {
  return PostAPIRequest({
    url: CREATE_REGULARIZATION_REQUEST,
    data: payLoad,
    params,
  });
};
const unlockRequestList = (params) => {
  return GetAPIRequest({ url: GETALL_STAFF_UNLOCK_REQUEST });
};
const CreateUnlockRequest = (id, payLoad) => {
  return PostAPIRequest({
    url: CREATE_UNLOCK_REQUEST + id + CREATE_UNLOCK_REQUEST_ENDPOINT,
    data: payLoad,
  });
};

const getStaffRegularization = (staffId) => {
  return GetAPIRequest({
    url: GET_STAFF_REGULARIZATION + staffId + GET_STAFF_REGULARIZATION_ENDPOINT,
  });
};

const getStaffUnlockRequest = (staffId) => {
  return GetAPIRequest({
    url: GET_STAFF_UNLOCK_REQUEST + staffId + GET_STAFF_UNLOCK_REQUEST_ENDPOINT,
  });
};

export const hrRequestServices = {
  getAllResignation,
  CreateResignation,
  editResignation,
  getAllRegularization,
  unlockRequestList,
  CreateUnlockRequest,
  editRegularization,
  getStaffRegularization,
  getStaffUnlockRequest,
  createRegularizationRequest,
};
