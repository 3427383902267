import {
    GET_HOLIDAYS,
    CREATE_HOLIDAYS,
  } from "../Constants/constants";
import { PostAPIRequest, GetAPIRequest } from "./Api";


  const listOfHolidays = () => {
    return GetAPIRequest({ url: GET_HOLIDAYS,});
  };

  const createHoliday = (payLoad) => {
    return PostAPIRequest({ url: CREATE_HOLIDAYS, data: payLoad });
  };

  export const HolidaysService = {
    listOfHolidays,
    createHoliday
  };