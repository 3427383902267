import React from "react";
import "./Notification.scss";
import EmptyNotification from "../../../src/components/Common/EmptyComponent/EmptyNotification";
import { notificationServices } from "../../Services/Notification";
const Notification = ({ notificationData, getNotificatificationList }) => {
  const handleNotificationClick = (notificationId) => {
    const payload = {
      readStatus: true,
    };

    notificationServices
      .updateReadStatus(notificationId, payload)
      .then((res) => {
        getNotificatificationList();
      });
  };
  return (
    <div className="notificationModal">
      {notificationData.length === 0 ? (
        <div style={{ paddingTop: 150 }}>
          <EmptyNotification />{" "}
        </div>
      ) : (
        <>
          <div className="card-list">
            {notificationData.map((card, index) => (
              <div
                key={index}
                // className="card"
                className={`card ${card?.readState ? "read" : "unread"}`}
                onClick={() => handleNotificationClick(card?.id)}
              >
                <span className="date">{card.sentDate}</span>
                <span className="content"  dangerouslySetInnerHTML={{
                  __html: card.notificationMsg,
                }}  ></span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Notification;
