import { PostAPIRequest, GetAPIRequest } from "./Api";
import {
  VIEW_BANKING_LIST,
  VIEW_PREVIOUS_EMPLOYER_LIST,
  POST_BANKING,
  POST_STORAGE_CONTROLLER,
  FIND_BANK_DETAILS,
  FIND_BANK_DETAILS_ENDPOINT,
  EDIT_BANK_URL,
  EDIT_BANK_ENDPOINT,
  CREATE_PREVIOUS_EMPLOYEMENT,
  FIND_PREVIOUS_EMPLOYEEMENT_DETAILS,
  ENTROLL_TUTOR,
  UPLOAD_PROFILE_PICTURE_ENDPOINT,
  UPLOAD_PROFILE_PICTURE,
  STAFF_IDENTITY_CREATE,
  STAFF_IDENTITY_LIST,
  STAFF_IDENTITY_LIST_ENDPOINT,
  STAFF_IDENTITY_EDIT,
  STAFF_IDENTITY_EDIT_ENDPOINT,
} from "../Constants/constants";

/*banking details*/
const getBankingList = () => {
  return GetAPIRequest({ url: VIEW_BANKING_LIST }); //api function
};

const createBanking = (values) => {
  return PostAPIRequest({ url: POST_BANKING, data: values });
};

/*previous empoyeement*/
const getPreviousEmployeList = () => {
  return GetAPIRequest({ url: VIEW_PREVIOUS_EMPLOYER_LIST });
  //api function
};

/*file upload*/
const postStorageController = (payload) => {
  return PostAPIRequest({ url: POST_STORAGE_CONTROLLER, data: payload });
};

const findBankDetails = (staffId) => {
  return GetAPIRequest({
    url: FIND_BANK_DETAILS + staffId + FIND_BANK_DETAILS_ENDPOINT,
  });
};

const editBankDetails = (staffId, payLoad) => {
  return PostAPIRequest({
    url: EDIT_BANK_URL + staffId + EDIT_BANK_ENDPOINT,
    data: payLoad,
  });
};

const createPreviousEmployement = (values) => {
  return PostAPIRequest({ url: CREATE_PREVIOUS_EMPLOYEMENT, data: values });
};

const findPreviousEmployementDetails = (params) => {
  return GetAPIRequest({
    url: FIND_PREVIOUS_EMPLOYEEMENT_DETAILS,
    params,
  });
};

const EntrollForTutor = (values) => {
  return PostAPIRequest({ url: ENTROLL_TUTOR, data: values });
};

const uploadProfilePic = (staffId, formData) => {
  return PostAPIRequest({
    url: UPLOAD_PROFILE_PICTURE + staffId + UPLOAD_PROFILE_PICTURE_ENDPOINT,
    data: formData,
  });
};
const staffIdentityCreate = (payload) => {
  return PostAPIRequest({
    url: STAFF_IDENTITY_CREATE,
    data: payload,
  });
};
const staffIdentityList = (staffId) => {
  return GetAPIRequest({
    url: STAFF_IDENTITY_LIST + staffId + STAFF_IDENTITY_LIST_ENDPOINT,
  });
};
const staffIdentityEdit = (id, payload) => {
  return PostAPIRequest({
    url: STAFF_IDENTITY_EDIT + id + STAFF_IDENTITY_EDIT_ENDPOINT,
    data: payload,
  });
};

export const profileService = {
  staffIdentityList,
  staffIdentityCreate,
  createBanking,
  getBankingList,
  getPreviousEmployeList,
  postStorageController,
  findBankDetails,
  editBankDetails,
  createPreviousEmployement,
  findPreviousEmployementDetails,
  EntrollForTutor,
  uploadProfilePic,
  staffIdentityEdit,
};
