import "../MyAccount.scss";
import { useState, useEffect } from "react";
import { LibraryService } from "../../../../Services/LibraryService";
import bookImage from "../../../../assets/images/BookImage.jpg";
import EmptyComponent from "../../../Common/EmptyComponent";
import { DislikeOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { dynamicErrorMsg } from "../../../../Constants/utilitis";
const PendingBooks = () => {
  const [rentedBooks, setRentedBooks] = useState(null);

  const getBooksHistory = () => {
    const params = {
      userId: 3,
      userType: "Staff",
    };
    LibraryService.getBookHistory(params)
      .then((response) => {
        setRentedBooks(response?.data?.pending);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Book History")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getBooksHistory();
  }, []);

  return (
    <div className="rent-cards-header">
      {rentedBooks === null ? (
        <div className="empty-component">
          <EmptyComponent />
        </div>
      ) : (
        <>
          {rentedBooks &&
            rentedBooks.map((rental) => {
              const { book } = rental;
              return (
                <div key={rental.rentalId} className="rent-card">
                  <div className="left-side">
                    <img
                      alt="img"
                      src={book.image}
                    />
                  </div>
                  <div className="right-side">
                    <h4>{book?.bookName}</h4>
                    <p>{book.publisher}</p>
                    <p>Fine Amount: {rental.fineAmount}</p>
                    <p>Rent Date: {rental.rentalDate}</p>
                    <p>Due Date: {rental.dueDate}</p>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};

export default PendingBooks;
