import {
  DislikeOutlined,
  EditOutlined,
  EyeOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import LeaveRequest from "../LeavesRequest/LeaveRequest";
import dayjs from "dayjs";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { leaveApprovalService } from "../../../Services/LeaveApprovalService";
import { createErrorMessage, updateErrorMessage, deleteErrorMessage, dynamicErrorMsg } from "../../../Constants/utilitis";

import { leavesService } from "../../../Services/LeaveService";
import "./Leaves.scss";
import { leaveCalendarService } from "../../../Services/LeaveCalanderService";
import Cookies from "js-cookie";

const Leaves = () => {
  const [leaveApprovals, setLeaveApprovals] = useState([]);
  const [leaveApprovalType, setLeaveApprovalType] = useState("OPEN");
  const [editStaffLeaveStatus, setEditStaffLeaveStatus] = useState(null);
  const [leaveStatusForm] = Form.useForm();
  const [aproval, setAproval] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLeaveRequestModalOpen, setIsLeaveRequestModalOpen] = useState(false);
  const [staffLeaves, setStaffLeaves] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [leaveViewModal, setLeaveViewModal] = useState(false);
  const [leaveViewData, setLeaveViewData] = useState([]);
  const [editLeave, setEditLeave] = useState(false);
  const [leaveRecord, setLeaveRecord] = useState(null);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];

  const staffId = staffInfo?.staffId;

  const getStaffAppliedLeaves = () => {
    leavesService
      .findStaffAppliedLeaves(staffId)
      .then((response) => {
        setStaffLeaves(response.data);
        if (response?.data.length > 0) {
          const firstLeaveStatus = response?.data?.status;

          if (firstLeaveStatus === "OPEN" || firstLeaveStatus === "APPROVED") {
            setLeaveApprovalType(firstLeaveStatus);
          }
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getStaffAppliedLeaves();
  }, [staffId]);

  const getFilteredData = () => {
    return staffLeaves.filter(
      (leave) => leave.status === leaveApprovalType.toUpperCase()
    );
  };

  const getLeavesList = () => {
    leaveCalendarService
      .leaveCalendarList()
      .then((response) => {
        setLeaveData(response?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Leaves")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getLeavesList();
  }, []);

  const staffState = [];

  const updatedLeaveCalendarList = leaveData.map((item) => {
    return { label: item.leaveType, value: item.leaveTypeId };
  });

  const handleLeaveRequestButtonClick = () => {
    setIsLeaveRequestModalOpen(true);
  };

  const handleStartDateChange = (date, dateString) => {
    if (date) {
      const formattedStartDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
      setStartDate(formattedStartDate);
    } else {
      setStartDate("");
    }
  };

  const handleEndDateChange = (date, dateString) => {
    if (date) {
      const formattedEndDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
      setEndDate(formattedEndDate);
    } else {
      setEndDate("");
    }
  };

  // const disabledDate = (current) => {
  //     return current && current > moment().endOf('day').add(1, 'year') ||
  //         current && current < moment().endOf('day').subtract(1, 'year');
  // };

  //validations for datepickers
  const academicStartYear = moment("2023-01-01", "YYYY-MM-DD");
  const academicEndYear = moment("2023-12-31", "YYYY-MM-DD");
  const disabledDate = (current) => {
    return (
      current && (current < academicStartYear || current > academicEndYear)
    );
  };

  const getLeaveSLists = () => {
    if (staffLeaves) {
      const initialData = { open: [], closed: [] };

      const { open, closed } = staffLeaves.reduce((acc, curr) => {
        const { status } = curr;

        if (status == "open") {
          acc.open.push(curr);
        } else {
          acc.closed.push(curr);
        }

        return acc;
      }, initialData);
    }
  };

  useEffect(() => {
    getLeaveSLists();
  }, [staffLeaves]);

  const editAppliedLeave = (record) => {
    setEditLeave(true);
    setEditStaffLeaveStatus(record);
    setLeaveRecord(record);
    setIsLeaveRequestModalOpen(true);
  };

  const saveAprrovalForm = (values) => {
    const id = aproval.leaveApprovalId;
    const payload = {
      approverId: 1,
      status: values.status,
      comment: values.comment,
    };
    leaveApprovalService
      .editLeaveApproval(id, payload)
      .then((response) => {
        if (response.status === 200) {
          notification.success({
            message: `Your Leave ${values.status}`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 3,
            placement: "bottomRight",
          });
        }
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Update", "Leave Approval")
   
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const handleAprovalId = () => {
    leaveApprovals.map((item) => {
      if (item?.leaveId === editStaffLeaveStatus?.leaveId) {
        setAproval(item);
      }
    });
  };

  const onHandleView = (record) => {
    setLeaveViewModal(true);
    setLeaveViewData(record);
  };

  useEffect(() => {
    handleAprovalId();
  }, [leaveApprovals, editStaffLeaveStatus]);

  useEffect(() => {
    const staffIdToNames = staffState.filter(
      (item) => item?.staffId === editStaffLeaveStatus?.staffId
    );
    const updatedFromDate = dayjs(
      editStaffLeaveStatus?.startDate || new Date(),
      "YYYY-MM-DD HH:mm:ss"
    );

    const updatedEndDate = dayjs(
      editStaffLeaveStatus?.endDate || new Date(),
      "YYYY-MM-DD HH:mm:ss"
    );

    leaveStatusForm.setFieldsValue({
      ...editStaffLeaveStatus,
      startDate: updatedFromDate.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      endDate: updatedEndDate.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      staffId: staffIdToNames[0]?.name,
    });
  }, [editStaffLeaveStatus]);

  const leaveStatusDropdown = [
    {
      label: "Approved",
      value: "Approved",
    },
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Declined",
      value: "Declined",
    },
    {
      label: "Open",
      value: "open",
    },
  ];

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leaveId",
      key: "leaveTypeId",

      align: "center",
      sorter: (a, b) => a.leaveId - b.leaveId,
      render: (_, record) => {
        let leaveCalendarArray = leaveData.filter(
          (item) => item.leaveType === record.leaveType
        );
        return <p>{leaveCalendarArray[0]?.leaveType}</p>;
      },
    },
    {
      title: "Days",
      dataIndex: "duration",
      key: "duration",
      align: "center",
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      align: "center",
      sorter: (a, b) => a.reason.localeCompare(b.reason),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (_, record) => {
        return (
          <Space size="large">
            {record.status === "OPEN" ? (
              <>
                <Space>
                  <Button
                    className="full-details-button"
                    onClick={() => onHandleView(record)}
                  >
                    <EyeOutlined />
                  </Button>
                  <Button
                    className="full-details-button"
                    onClick={() => editAppliedLeave(record)}
                  >
                    <EditOutlined />
                  </Button>
                </Space>
              </>
            ) : (
              <Button
                className="full-details-button"
                onClick={() => onHandleView(record)}
              >
                <EyeOutlined />
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className="leaves-wrapper">
        <div className="leavetopsection">
          <div style={{ display: "flex", gap: "10px", justifyContent: "" }}>
            {/* <input
              type="date"
              placeholder="Start Date (DD-MM-YYYY)"
              className="inputBox"
              value={
                startDate ? moment(startDate, "YYYY-MM-DD HH:mm:ss") : null
              }
              onChange={handleStartDateChange}
            
              // showTime
              // format="YYYY-MM-DD HH:mm:ss"
            />
            <input
              className="inputBox"
              type="date"
              // showTime
              // format="YYYY-MM-DD HH:mm:ss"
              placeholder="End Date(YYYY-MM-DD)"
              value={endDate ? moment(endDate, "YYYY-MM-DD HH:mm:ss") : null}
              onChange={handleEndDateChange}
              disabledDate={disabledDate}
            
            /> */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            value={leaveApprovalType}
            onChange={(e) => {
              setLeaveApprovalType(e.target.value);
            }}
          >
            <Radio.Button value="OPEN">Open</Radio.Button>
            <Radio.Button value="APPROVED">Approved/Declined</Radio.Button>
          </Radio.Group>
          <Button
            onClick={handleLeaveRequestButtonClick}
            className="blue-button"
          >
            Add Leave
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={getFilteredData()}
          showSorterTooltip={false}
          pagination={false}
        />
      </div>

      <LeaveRequest
        openLeaveRequestForm={isLeaveRequestModalOpen}
        setOpenLeaveRequestForm={setIsLeaveRequestModalOpen}
        leaveViewData={leaveViewData}
        setLeaveViewData={setLeaveViewData}
        leaveViewModal={leaveViewModal}
        setLeaveViewModal={setLeaveViewModal}
        setEditLeave={setEditLeave}
        editLeave={editLeave}
        leaveRecord={leaveRecord}
        getLeaveSLists={getLeaveSLists}
        getStaffAppliedLeaves={getStaffAppliedLeaves}
        setLeaveRecord={setLeaveRecord}
      />
    </>
  );
};

export default Leaves;
