// import {
//   Bhavani,
//   Chandra,
//   Sathyanath,
//   Prameela,
//   Shafi,
// } from "../../assets/images";

// export const staffs = [
//   {
//     pic: Bhavani,
//     name: "Bhavani",
//     role: "Teacher",
//   },
//   {
//     pic: Chandra,
//     name: "Chandra",
//     role: "English Teacher",
//   },
//   {
//     pic: Sathyanath,
//     name: "Sathyanath",
//     role: "School Admin",
//     isAdmin: true,
//   },
//   {
//     pic: Shafi,
//     name: "Shafi",
//     role: "Accountant",
//     isAdmin: true,
//   },
//   {
//     pic: Prameela,
//     name: "Prameela",
//     role: "Physics Teacher",
//   },

//   {
//     pic: Sathyanath,
//     name: "Sathyanath",
//     role: "School Admin",
//     isAdmin: true,
//   },
//   {
//     pic: Shafi,
//     name: "Shafi",
//     role: "Accountant",
//     isAdmin: true,
//   },
//   {
//     pic: Prameela,
//     name: "Prameela",
//     role: "Physics Teacher",
//   },
// ];

export const HolidaysList = [
  {
    "date": "2023-01-01",
    "name": "New Year's Day",
    "type": "Public"
  },
  {
    "date": "2023-01-26",
    "name": "Republic Day",
    "type": "Public"
  },
  {
    "date": "2023-03-11",
    "name": "Maha Shivaratri",
    "type": "Public"
  },
  {
    "date": "2023-04-01",
    "name": "Bank Holiday",
    "type": "Bank"
  },
  {
    "date": "2023-04-14",
    "name": "Good Friday",
    "type": "Public"
  },
  {
    "date": "2023-04-15",
    "name": "Easter Saturday",
    "type": "Public"
  },
  {
    "date": "2023-04-16",
    "name": "Easter Sunday",
    "type": "Public"
  },
  {
    "date": "2023-05-01",
    "name": "May Day",
    "type": "Public"
  },
  {
    "date": "2023-06-05",
    "name": "Eid al-Fitr",
    "type": "Public"
  },
  {
    "date": "2023-08-15",
    "name": "Independence Day",
    "type": "Public"
  },
  {
    "date": "2023-09-02",
    "name": "Ganesh Chaturthi",
    "type": "Public"
  },
  {
    "date": "2023-09-03",
    "name": "Ganesh Chaturthi",
    "type": "Public"
  },
  {
    "date": "2023-10-02",
    "name": "Mahatma Gandhi Jayanti",
    "type": "Public"
  },
  {
    "date": "2023-10-19",
    "name": "Dussehra",
    "type": "Public"
  },
  {
    "date": "2023-11-01",
    "name": "Bank Holiday",
    "type": "Bank"
  },
  {
    "date": "2023-11-04",
    "name": "Diwali",
    "type": "Public"
  },
  {
    "date": "2023-11-05",
    "name": "Diwali",
    "type": "Public"
  },
  {
    "date": "2023-11-06",
    "name": "Diwali",
    "type": "Public"
  },
  {
    "date": "2023-12-25",
    "name": "Christmas Day",
    "type": "Public"
  }
]

