import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { leavesService } from "../../../Services/LeaveService";
import "./LeavesUsage.scss";
import { leaveCalendarService } from "../../../Services/LeaveCalanderService";
import Cookies from "js-cookie";
const LeavesUsageTabs = () => {
  const [staffLeaveUsage, setStaffLeaveUsage] = useState(null);
  const [leaveData, setLeaveData] = useState(null);
  const [leaveUsage, setLeaveUsage] = useState(null);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  const getStaffLeaveUsageData = () => {
    leavesService.staffLeaveUsage(staffId)
    .then((response) => {
      setStaffLeaveUsage(response?.data[0]?.leaveType);
    })
    .catch((error) => {
      console.log(error)
    })
  };

  useEffect(() => {
    getStaffLeaveUsageData();
  }, []);

  const getLeavesList = () => {
    leaveCalendarService.leaveCalendarList()
    .then((response) => {
      setLeaveData(response?.data);
    })
    .catch((error) => {
      console.log(error)
    })
  };

  useEffect(() => {
    getLeavesList();
  }, []);

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
      align: "center",
      sorter: (a, b) => a.leaveType.localeCompare(b.leaveType),
    },
    {
      title: "Total Leaves",
      dataIndex: "noOfLeaves",
      key: "noOfLeaves",
      align: "center",
      sorter: (a, b) => a.noOfLeaves - b.noOfLeaves,
    },
    {
      title: "Utilized Leaves",
      dataIndex: "utilizedLeaves",
      key: "utilizedLeaves",
      align: "center",
      sorter: (a, b) => a.utilizedLeaves - b.utilizedLeaves,
    },
    {
      title: "Reamining Leaves",
      dataIndex: "remainingLeaves",
      key: "remainingLeaves",
      align: "center",
      sorter: (a, b) => a.remainingLeaves - b.remainingLeaves,
      defaultSortOrder: "descend",
    },
  ];

  useEffect(() => {
    if (staffLeaveUsage && leaveData) {
      const leaveUsageData = staffLeaveUsage?.map((item) => {
        const leaveTypeObj = leaveData?.find((leave) => {
          if (leave.leaveTypeId == item.leaveTypeId) {
            return leave;
          }
        });
        return {
          ...item,
          leaveType: leaveTypeObj?.leaveType || item.leaveTypeId,
          noOfLeaves: leaveTypeObj?.noOfLeaves,
        };
      });
      setLeaveUsage(leaveUsageData);
    }
  }, [staffLeaveUsage, leaveData]);

  return (
    <>
      <div className="leavesusage_container ">
        <Table
          columns={columns}
          dataSource={leaveUsage}
          showSorterTooltip={false}
        />
      </div>
    </>
  );
};

export default LeavesUsageTabs;
