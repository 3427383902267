import React, { useState } from "react";
import { Input } from "antd";
import "./index.css";

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, type, required, onBlur, onPressEnter } =
    props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder";

  const rules = required
    ? [{ required: true, message: `${label} is required` }]
    : [];

    const handleKeyPress = (e) => {
      const isNumberOrSymbolOrSpace = /[0-9~`!@#$%^&*()_+\-=\[\]{}|;':"\\,<>/?\s]/.test(e.key);
      if (type !== "email" && type !== "number" && isNumberOrSymbolOrSpace && e.key !== " ") {
        e.preventDefault();
    }
    if (type === "number" && !/^\d$/.test(e.key)) {
      e.preventDefault();
  }
  };
  
  
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input
        {...props}
        defaultValue={value}
        type={type}
        min={0}
        onKeyPress={handleKeyPress}
      />
      <label className={labelClass}>{isOccupied ? label : placeholder}</label>
    </div>
  );
};

export default FloatInput;
