import {
  CloseOutlined,
  DeleteOutlined,
  DislikeOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FolderOpenOutlined,
  LikeOutlined,
  LikeTwoTone,
  CheckCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Table,
  Upload,
  Button,
  message,
  notification,
  Modal,
  Spin,
} from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import "../Exam.scss";
import FileViewModalExams from "./FileViewModalExams";
import AddQuestionPaper from "./AddQuestionPaper";
import FileView from "../../../Utils/FileView";
import QuestionPaperView from "../../../Utils/QuestionPaperView";
const Upcoming = ({
  data,
  selectedExam,
  getExamsList,
  loading,
  questionPaperData,
  questionPaperList,
}) => {
  const filteredExamss = data[selectedExam] || [];
  const [filteredExams, setFilteredExams] = useState(filteredExamss);
  const [view, setView] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [heading, setHeading] = useState(null);
  const accessToken = Cookies.get("accessToken");
  const [currentRecord, setCurrentRecord] = useState({});
  const [files, setFileList] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [openquestionpaperModal, setUploadQuestionPaperModal] = useState(false);

  useEffect(() => {
    setFilteredExams(data[selectedExam] || []);
  }, [data, selectedExam]);

  const columns = [
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
      align: "center",
      sorter: (a, b) => a.className.localeCompare(b.className),
      render: (text, record) => {
        const sectionName = record.section;
        const classNameWithSection = sectionName
          ? `${text} - ${sectionName}`
          : text;
        return <span>{classNameWithSection}</span>;
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
    },
    {
      title: "Exam Date",
      dataIndex: "examDate",
      key: "examDate",
      align: "center",
      sorter: (a, b) => {
        const dateA = new Date(a.examDate).getTime();
        const dateB = new Date(b.examDate).getTime();
        return dateA - dateB;
      },
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      align: "center",
      sorter: (a, b) => a.startTime.localeCompare(b.startTime),
      render: (text) => {
        if (text !== "N/A") {
          return text;
        }
        return "-";
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
      sorter: (a, b) => a.endTime.localeCompare(b.endTime),
      render: (text) => {
        if (text !== "N/A") {
          return text;
        }
        return "-";
      },
    },
    {
      title: "Question Paper",
      dataIndex: "questionPaper",
      key: "questionPaper",
      align: "center",
      render: (text, record) => {
        const matchedQuestionPaper = questionPaperData.find(
          (item) => item.examScheduleId === record.examTTId
        );
        console.log("matchedQuestionPaper", matchedQuestionPaper);

        if (matchedQuestionPaper) {
          return (
            <FilePdfOutlined
              style={{ marginLeft: 80, textAlign: "center" }}
              onClick={() => {
                downloadQuestionPaper(matchedQuestionPaper);
              }}
            />
          );
        }

        return " - ";
      },
    },
    {
      title: "Upload Question Paper",
      dataIndex: "uploadQuestionPaper",
      key: "uploadQuestionPaper",
      align: "center",
      render: (text, record) => {
        const matchedQuestionPaper = questionPaperData?.find(
          (item) => item.examScheduleId == record.examTTId
        );
        console.log("matchedQuestionPaper", matchedQuestionPaper);

        if (matchedQuestionPaper) {
          return (
            <Button
              onClick={() => handleEditQuestionPaper(record)}
              className="blue-button"
              icon={<UploadOutlined />}
            >
              Upload
            </Button>
          );
        } else {
          return (
            <Button
              style={{ backgroundColor: "grey", color: "white" }}
              disabled
              icon={<UploadOutlined />}
            >
              Upload
            </Button>
          );
        }
      },
    },
  ];

  const handleEditQuestionPaper = (record) => {
    setUploadQuestionPaperModal(true);

    const matchedQuestionPaper = questionPaperData.find(
      (item) => item.examScheduleId === record.examTTId
    );

    setRowData(matchedQuestionPaper);
  };

  const downloadQuestionPaper = (record) => {
    setView(true);
    setFileData(record?.questionPaper);
    setHeading(`${record.examName}, ${record.subject} subject`);
  };

  const showDeleteConfirm = (questionPaper, classId, subjectId, examId) => {
    Modal.confirm({
      title: `Are you sure you want to delete Question paper?`,
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        const examToDelete = filteredExams.find((exam) =>
          exam.questionPaper.includes(questionPaper)
        );

        if (examToDelete) {
          deleteQuestionPaper(
            questionPaper,
            examToDelete.classId,
            examToDelete.subjectId,
            examToDelete.examId
          );
        }
      },
    });
  };
  const deleteQuestionPaper = async (
    questionPaper,
    classId,
    subjectId,
    examId
  ) => {
    const payload = {
      examId: examId,
      classId: classId,
      subjectId: subjectId,
      url: questionPaper,
    };

    try {
      const response = await axios.delete(
        `https://api.edpedia.co/v1.0/staff/api/exam/deleteQuestionPaper `,
        {
          data: payload,
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );

      if (response.status === 200) {
        const updatedFilteredExams = filteredExams.map((exam) => {
          if (exam.examId === examId) {
            return {
              ...exam,
              questionPaper: exam.questionPaper.filter(
                (paper) => paper !== questionPaper
              ),
            };
          }
          return exam;
        });
        setFileData(fileData.filter((item) => item !== questionPaper));
        setFilteredExams(updatedFilteredExams);
        getExamsList();
        notification.success({
          message: `Question Paper Deleted Successfully`,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
      }
    } catch (error) {
      notification.success({
        message: `Question Paper Deleted Failed`,
        placement: "bottomRight",
        icon: (
          <DislikeOutlined
            style={{
              color: "red",
            }}
          />
        ),
      });
    }
  };

  const handleUpload = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList(files);
  };

  useEffect(() => {
    if (Object.keys(currentRecord).length > 0 && files?.length > 0) {
      uploadQuestionPaper(
        currentRecord.examId,
        currentRecord.subjectId,
        currentRecord.classId,
        files
      );
    }
  }, [currentRecord, files]);

  const downloadExamPaper = async (questionPaperUrl) => {
    try {
      const response = await axios.get(
        `https://api.edpedia.co/v1.0/instAdmin/api/storage/download?url=${questionPaperUrl}`,

        {
          responseType: "blob",
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "question_paper.pdf";
      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      notification.success({
        message: `Question Paper Downloaded Successfully`,
        placement: "bottomRight",
        icon: (
          <DislikeOutlined
            style={{
              color: "red",
            }}
          />
        ),
      });
    }
  };

  const uploadQuestionPaper = async (examId, subjectId, classId, fileList) => {
    const formData = new FormData();
    formData.append("examId", examId);
    formData.append("subjectId", subjectId);
    formData.append("classId", classId);
    fileList.map((file, i) => {
      formData.append(`questionPaper`, file);
    });
    // setCurrentRecord({});
    setFileList([]);
    try {
      const response = await axios.post(
        "https://api.edpedia.co/v1.0/staff/api/exam/uploadQuestionPaper",
        formData,
        {
          responseType: "json",
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );

      if (response.status === 200) {
        getExamsList();
        notification.success({
          message: `Question Paper Uploaded Successfully`,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
      }
    } catch (error) {
      setCurrentRecord({});
      setFileList([]);
      notification.error({
        message: `${error.message}`,
        description:
          "An error occurred while Saving  the Attendance, try again later.",
        icon: (
          <DislikeOutlined
            style={{
              color: "red",
            }}
          />
        ),
        duration: 3,
        placement: "bottomRight",
      });
    }
  };

  return (
    <div className="upcoming-container">
      {loading ? (
        <Spin spinning={loading} className="loading-spinner" />
      ) : (
        <Table
          dataSource={filteredExams}
          columns={columns}
          showSorterTooltip={false}
          pagination={false}
        />
      )}
      {/* <FileViewModalExams
        view={view}
        setView={setView}
        fileData={fileData}
        showDeleteConfirm={showDeleteConfirm}
        downloadExamPaper={downloadExamPaper}
        heading={heading}
      /> */}
      <QuestionPaperView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />

      <AddQuestionPaper
        rowData={rowData}
        setRowData={setRowData}
        openquestionpaperModal={openquestionpaperModal}
        setUploadQuestionPaperModal={setUploadQuestionPaperModal}
        questionPaperList={questionPaperList}
      />
    </div>
  );
};

export default Upcoming;
