import { Button, Space, Table, notification } from "antd";
import React, { useEffect, useState } from "react";
import {
  LikeTwoTone,
  DislikeOutlined,
  UploadOutlined,
  CheckCircleFilled,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import "./StudentLeaves.scss";
import { leavesService } from "../../../Services/LeaveService";
import Cookies from "js-cookie";
import { dynamicErrorMsg } from "../../../Constants/utilitis";
const StudentLeaves = () => {
  const [studentLeaveData, setudentLeaveData] = useState([]);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];

  const staffId = staffInfo?.staffId;
  const getstudentLeave = () => {
    leavesService
      .getStudentLeave(staffId)
      .then((res) => {
        setudentLeaveData(res?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Student Leave")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  useEffect(() => {
    getstudentLeave();
  }, []);

  const columns = [
    {
      title: "Student Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Leave Reason",
      dataIndex: "reason",
      key: "reason",
      sorter: (a, b) => a.reason.localeCompare(b.reason),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: (a, b) => a.startDate - b.startDate,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      sorter: (a, b) => a.endDate - b.endDate,
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      align: "center",
      render: (text, row) => (
        <Space>
          {/* <Button className="full-details-button">
            <EyeOutlined />
          </Button> */}
          <Button
            className="full-details-button"
            // onClick={(e) => {
            //   handleEditLeave(row, e);
            // }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="studentLeaveContainer">
        <Table
          dataSource={studentLeaveData}
          columns={columns}
          showSorterTooltip={false}
        />
      </div>
    </>
  );
};

export default StudentLeaves;
