import React, { useState, useEffect } from "react";
import "./Result.scss";
import { FaPlus } from "react-icons/fa";
import {
  Table,
  Button,
  Select,
  Spin,
  Modal,
  Row,
  Col,
  Tooltip,
  notification,
} from "antd";
import { staffServices } from "../../Services/StaffServices";
import { DislikeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import SectionDropdown from "../Common/Section/SectionDropdown";
import ClassDropdown from "../Common/Class/ClassDropdown";
import ScoreCardTable from "./ScoreCardTable/ScoreCardTable";

const Result = () => {
  const [selectedClass, setSelectedClass] = useState(null);
  const [resultTable, setResultTable] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [resultViewData, setResultViewData] = useState("");
  const [internalColumns, setInternalColumns] = useState([]);
  const [students, setStudents] = useState([]);
  const [externalColumns, setExternalColumns] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const onClassChange = (value) => {
    setSelectedClass(value);
  };

  const onSectionChange = (value) => {
    setSelectedSection(value);
  };

  const processResultTableData = (studentsData) => {
    return studentsData?.map((student) => {
      const studentData = {
        key: student.studentId,
        studentName: student.fullName,
        rollNo: student.rollNumber,
      };

      student.exams.forEach((exam) => {
        const dataIndex = `${exam.examType.toLowerCase()}_${exam.examId}`;
        studentData[
          dataIndex + "_marks"
        ] = `${exam.securedMarks} / ${exam.totalMarks}`;
        studentData[dataIndex + "_startDate"] = exam.startDate;
        studentData[dataIndex + "_endDate"] = exam.endDate;
        studentData[dataIndex + "_columnName"] = exam.title;

        const scoreCardData = Object?.keys(exam.scoreCard)?.map((key) => {
          return `${key}: ${exam.scoreCard[key]}`;
        });
        studentData[dataIndex + "_scoreCard"] = scoreCardData.join("\n");
        // studentData[dataIndex + "_startDateIndicator"] = exam.rankIndicator;
      });

      return studentData;
    });
  };
  const staffGetResult = () => {
    setIsLoading(true);
    const params = {
      classId: selectedSection.classId,
      studentId: selectedStudent,
    };
    staffServices
      .getResultData(params)
      .then((response) => {
        if (response.status === 200) {
          const dataSource = processResultTableData(response.data.data);
          setResultTable(dataSource);
          const studentOptions = response?.data?.data.map((student) => ({
            label: student.fullName,
            value: student.studentId,
          }));
          setStudents(studentOptions);
          const internalData = response.data.columns.filter(
            (item) => item.examType === "Internal"
          );
          const externalData = response.data.columns.filter(
            (item) => item.examType === "External"
          );

          const commonColumns = [
            {
              title: "Roll No",
              dataIndex: "rollNo",
              key: "rollNo",
              align: "center",
              sorter: (a, b) => a.rollNo.localeCompare(b.rollNo),
            },
            {
              title: "Student Name",
              dataIndex: "studentName",
              key: "studentName",
              align: "center",
              sorter: (a, b) => a.studentName.localeCompare(b.studentName),
            },
          ];

          const newInternalColumns = [
            ...commonColumns,
            ...internalData
              .map((item) => [
                {
                  title: item.examTitle,
                  align: "center",
                  children: [
                    {
                      title: "Start Date",
                      dataIndex: `internal_${item.examId}_startDate`,
                      key: `internal_${item.examId}_startDate`,
                      align: "center",
                      render: (text) => (
                        <span style={{ textAlign: "center" }}>
                          {text || "-"}
                        </span>
                      ),
                      //sorter: (a, b) => a.givenDate - b.givenDate,
                    },
                    {
                      title: "End Date",
                      dataIndex: `internal_${item.examId}_endDate`,
                      key: `internal_${item.examId}_endDate`,
                      align: "center",
                      render: (text) => (
                        <span style={{ textAlign: "center" }}>
                          {text || "-"}
                        </span>
                      ),
                      //sorter: (a, b) => a.givenDate - b.givenDate,
                    },
                    {
                      title: "Marks",
                      dataIndex: `internal_${item.examId}_marks`,
                      key: `internal_${item.examId}_marks`,
                      align: "center",
                      // sorter: (a, b) => a.givenDate - b.givenDate,

                      render: (text, record) => (
                        <div>
                          <Tooltip
                            title={
                              <ScoreCardTable
                                scoreCard={
                                  record[`internal_${item.examId}_scoreCard`] ||
                                  ""
                                }
                              />
                            }
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {text || "-"}
                              <InfoCircleOutlined style={{ marginLeft: 5 }} />
                            </span>
                          </Tooltip>
                        </div>
                      ),
                    },
                  ],
                },
              ])
              .flat(),
          ];

          const newExternalColumns = [
            ...commonColumns,
            ...externalData
              .map((item) => [
                {
                  title: item.examTitle,
                  align: "center",
                  children: [
                    {
                      title: "Start Date",
                      dataIndex: `external_${item.examId}_startDate`,
                      key: `external_${item.examId}_startDate`,
                      align: "center",
                      render: (text) => (
                        <span style={{ textAlign: "center" }}>
                          {text || "-"}
                        </span>
                      ),
                    },
                    {
                      title: "End Date",
                      dataIndex: `external_${item.examId}_endDate`,
                      key: `external_${item.examId}_endDate`,
                      align: "center",
                      render: (text) => (
                        <span style={{ textAlign: "center" }}>
                          {text || "-"}
                        </span>
                      ),
                      //sorter: (a, b) => a.givenDate - b.givenDate,
                    },
                    {
                      title: "Marks",
                      dataIndex: `external_${item.examId}_marks`,
                      key: `external_${item.examId}_marks`,
                      align: "center",
                      sorter: (a, b) => a.givenDate - b.givenDate,
                      render: (text, record) => (
                        <div>
                          <Tooltip
                            title={
                              <ScoreCardTable
                                scoreCard={
                                  record[`external_${item.examId}_scoreCard`]
                                }
                              />
                            }
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {text || "-"}
                              <InfoCircleOutlined style={{ marginLeft: 5 }} />
                            </span>
                          </Tooltip>
                        </div>
                      ),
                    },
                  ],
                },
              ])
              .flat(),
          ];

          setInternalColumns(newInternalColumns);
          setExternalColumns(newExternalColumns);

          setColumns(newExternalColumns);
        }
      })
      .catch((error) => {
        // console.log(error)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onHandleExams = (value) => {
    if (value === "external") {
      setColumns(externalColumns);
    } else if (value === "internal") {
      setColumns(internalColumns);
    } else {
      const combinedColumns = [
        ...externalColumns.slice(0, 2),
        ...internalColumns.slice(2),
        ...externalColumns.slice(2),
      ];
      setColumns(combinedColumns);
    }
  };

  const handleModalOk = () => {
    setOpenViewModal(false);
  };

  const handleModalCancel = () => {
    setOpenViewModal(false);
  };

  useEffect(() => {
    if (selectedSection) {
      staffGetResult();
    }
  }, [selectedSection, selectedStudent]);

  const handlestudents = (value) => {
    setSelectedStudent(value);
  };

  return (
    <article className="staff-result">
      <div className="assignments ">
        <div className="asheder">
          <h2 className="header-text ">Results</h2>
          <div className="headeright">
            <div>
              <ClassDropdown
                onClassChange={onClassChange}
                selectedClass={selectedClass}
              />
            </div>
            <div>
              <SectionDropdown
                classValue={selectedClass}
                onSectionChange={onSectionChange}
                // selectedSection={selectedSection}
              />
            </div>
            <div>
              <Select
                placeholder="Exam Type"
                onChange={onHandleExams}
                defaultValue="external"
                style={{ width: "100px" }}
              >
                <Select.Option value="all">All</Select.Option>
                <Select.Option value="external">External</Select.Option>
                <Select.Option value="internal">Internal</Select.Option>
              </Select>
            </div>
          </div>
          {/* <div>
              <Select
                placeholder="Student"
                onChange={handlestudents}
                
                style={{ width: "130px" }}
              >
                {students.map((student) => (
                  <Select.Option key={student.value} value={student.value}>
                    {student.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="searchButton">
              <Button className="flex-sty search-button">Search</Button>
            </div> */}
        </div>
        <div className="create-assignment" style={{ visibility: "hidden" }}>
          <button className="flex-sty study-button">
            <FaPlus className="assignment-icon" />
            Upload Study Material
          </button>
        </div>
      </div>
      <div className="tables">
        {isLoading ? (
          <Spin spinning={isLoading} className="loading-spinner" />
        ) : (
          <Table
            dataSource={resultTable}
            columns={columns}
            showSorterTooltip={false}
            pagination={false}
          />
        )}
      </div>

      <Modal
        title="Result Details"
        visible={openViewModal}
        width={700}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <h2>ExamId :{resultViewData.examId}</h2>
        <h2>SubjectId :{resultViewData.subjectId}</h2>
        <h2>StudentId</h2>
        <h2>SecuredMarks :{resultViewData.securedMarks}</h2>
        <h2>PassStatus :{resultViewData.passStatus}</h2>
      </Modal>
    </article>
  );
};

export default Result;
