import React, { useState, useEffect } from "react";
import "./Subject.scss";
import { Table, Progress, Spin, notification } from "antd";
import { staffServices } from "../../Services/StaffServices";
import EmptyComponent from "../Common/EmptyComponent";
import Cookies from "js-cookie";
import { DislikeOutlined } from "@ant-design/icons";
const Subject = () => {
  const [teachingSubjects, setTeachingSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;
  const TeachingSubjectsData = () => {
    staffServices
      .teachingSubjectList(staffId)
      .then((response) => {
        if (response.status === 200) {
          setTeachingSubjects(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    TeachingSubjectsData();
  }, []);

  const columns = [
    {
      title: "Class",
      dataIndex: "className",
      key: "className",
      align: "center",
      sorter: (a, b) => a.className.localeCompare(b.className),
      defaultSortOrder: "ascend",
    },
    {
      title: "Section",
      dataIndex: "classId",
      key: "section",
      align: "center",
      render: (classId) => {
        let getSection = teachingSubjects.find(
          (item) => item.classId == classId
        );
        if (getSection) {
          return getSection.section;
        }
        return "";
      },
      sorter: (a, b) => a.section.localeCompare(b.section),
    },
    {
      title: "Subject",
      dataIndex: "subjectName",
      key: "subjectName",
      align: "center",
      sorter: (a, b) => a.subjectName.localeCompare(b.subjectName),
    },
    {
      title: "Chapters",
      dataIndex: "chapters",
      key: "chapters",
      align: "center",
      sorter: (a, b) => a.chapters - b.chapters,
    },
    {
      title: "Completed",
      dataIndex: "completedChapters",
      key: "completedChapter",
      align: "center",
      sorter: (a, b) => a.completedChapter - b.completedChapter,
    },
    {
      title: "Pending",
      dataIndex: "pendingChapters",
      key: "pendingChapter",
      align: "center",
      sorter: (a, b) => a.pendingChapter - b.pendingChapter,
    },
    {
      title: "Overall",
      dataIndex: "overAll",
      key: "overAll",
      align: "center",
      render: (_, record) => {
        let strokeColor = "green";

        if (record.overAll >= 0 && record.overAll <= 30) {
          strokeColor = "red";
        } else if (record.overAll >= 31 && record.overAll <= 75) {
          strokeColor = "orange";
        }

        return (
          <Progress
            percent={record.overAll.toFixed(0)}
            strokeColor={
              record.overAll >= 0 && record.overAll <= 30
                ? "#aa0b28"
                : record.overAll >= 31 && record.overAll <= 75
                ? "#cbaf1e"
                : "#5f9e20"
            }
          />
        );
      },
      sorter: (a, b) => a.overAll - b.overAll,
    },
  ];

  return (
    <div className="subject">
      <div className="title">
        <h2 className="header-text">Teaching Subjects</h2>
      </div>

      {loading ? (
        <Spin spinning={loading} className="loading-spinner" />
      ) : teachingSubjects.length === 0 ? (
        <EmptyComponent />
      ) : (
        <div className="tables">
          <Table
            style={{ borderCollapse: "separate" }}
            dataSource={teachingSubjects}
            columns={columns}
            showSorterTooltip={false}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
};

export default Subject;
