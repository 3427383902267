import { Radio } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./MyattendaceHome.scss";
import StaffAttendance from "./StaffAttendance";
import Regularization from "./RequestAttendance/Regularization";

const RadioGroup = Radio.Group;
const MyattendaceHome = () => {
  const [activeTabKey, setActiveTabKey] = useState("selfAttendance");
  const location = useLocation();
  const studentAttendance = location.state;
  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };
  return (
    <main className="myattendace-wrapper">
      <div className="radioparent">
        <RadioGroup
          onChange={handleTabChange}
          value={activeTabKey}
          optionType="button"
          buttonStyle="solid"
          className="mainradio"
        >
          <Radio.Button value="selfAttendance">My Attendance</Radio.Button>

          <Radio.Button value="requestAttendance">
            Request Staff Attendance
          </Radio.Button>
        </RadioGroup>
        {/* <div style={{ textAlign: "center" }}>
      <h4>
        {formattedDate} : {dayOfWeek}, {formattedTime}
      </h4>
    </div> */}
      </div>
      {/* <SEO title="attendance" /> */}
      {activeTabKey === "selfAttendance" && <StaffAttendance />}
      {activeTabKey === "requestAttendance" && <Regularization />}
    </main>
  );
};

export default MyattendaceHome;
