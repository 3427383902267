import BookImage from "../../../../assets/images/book image.jpg";
import "../MyAccount.scss";
import { LibraryService } from "../../../../Services/LibraryService";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { notification } from "antd";
import { DislikeOutlined } from "@ant-design/icons";
import EmptyComponent from "../../../Common/EmptyComponent";
import { dynamicErrorMsg } from "../../../../Constants/utilitis";
const RequestedBooks = () => {
  const [requestedBooks, setRequestedBooks] = useState([]);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const getRequestedBooks = () => {
    const params = {
      userId:3,
      userType : "staff",
    }
    const staffId = staffInfo?.staffId;
    LibraryService.getRequestedBooks(params)
      .then((response) => {
        setRequestedBooks(response?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getRequestedBooks();
  }, []);

  return (
    <div className="rent-cards-header">
      {requestedBooks && requestedBooks.length === 0 ? (
        <div className="empty-component">
          <EmptyComponent />
        </div>
      ) : (
        requestedBooks?.map((item) => {
          const { book } = item;
          return (
            <div className="rent-card" key={item.id}>
              <div className="left-side">
                <img
                  alt="img"
                  src={book.image}
                />
              </div>
              <div className="right-side">
                <h3>{book.bookName}</h3>
                <h4>{item.requestedDate}</h4>
                <p>{item.status}</p>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
export default RequestedBooks;
