import { Button, Modal, notification } from "antd";
import "./Books.scss";
import BookImage from "../../../assets/images/BookImage.jpg";
import {
  DislikeOutlined,
  LeftOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
import { LibraryService } from "../../../Services/LibraryService";
import { useState } from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";
import { createErrorMessage, dynamicErrorMsg, saveErrorMessage } from "../../../Constants/utilitis";
const BookView = ({ selectedBook, setBookView }) => {
  const [bookInfo, setBookInfo] = useState([]);
  const handleBack = () => {
    setBookView(null);
  };
  const currentDate = new Date();
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formattedDate = currentDate
    .toLocaleDateString("en-GB", options)
    .split("/")
    .reverse()
    .join("-");

  const getBookInfo = () => {
    const params = {
      bookId: selectedBook.id,
    };
    LibraryService.getRentBookInfo(params)
      .then((response) => {
        setBookInfo(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Rent Book")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  useEffect(() => {
    getBookInfo();
  }, [selectedBook]);

  const onHandleRent = () => {
    const params = {
      userId: 3,
      bookId: selectedBook?.id,
      // userName: "Venkatesh",
      userType: "Staff",
    };
    Modal.confirm({
      title: "Confirm Rent Book",
      content: (
        <div>
          <p>
            Are you sure you want to proceed with booking
            <span style={{ fontWeight: "bold" }}>
              {selectedBook?.bookName}
            </span>{" "}
            book for rent?
          </p>
          <p
            style={{
              fontWeight: "bold",
              fontSize: 16,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Book Info:
          </p>
          <p>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              Rental Date:
            </span>{" "}
            {bookInfo?.rentalDate}
          </p>
          <br />
          <p>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              Missing Book Fine Amount:
            </span>{" "}
            {bookInfo?.missingBooksFineAmount}
          </p>
          <br />
          <p>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              Due Date:
            </span>{" "}
            {bookInfo?.dueDate}
          </p>
          <br />
          <p>
            <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
              Per Day Fine Amount:
            </span>{" "}
            {bookInfo?.perDayFineAmount}
          </p>
        </div>
      ),
      okText: "Save",
      cancelText: "Cancel",
      okButtonProps: { className: "save-button" },
      cancelButtonProps: { className: "newCancelButton" },
      onOk: () => {
        LibraryService.rentBook(params)
          .then((response) => {
            notification.success({
              message: `${selectedBook?.bookName} Book Rented Sucessfully`,
              placement: "bottomRight",
            });
          })
          .catch((error) => {
            const errorMessage = dynamicErrorMsg(error, "Submit", "Book Rented")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
          });
      },
    });
  };

  const onHandleRequest = () => {
    const params = {
      userId: 3,
      // userName: "Venkatesh",
      userType: "Staff",
      requestedDate: formattedDate,
    };

    Modal.confirm({
      title: "Confirm Request Book",
      content: `Are you sure you want to proceed with booking ${selectedBook?.bookName} book for request?`,
      okText: "Save",
      cancelText: "Cancel",
      okButtonProps: { className: "modal-save-button" },
      cancelButtonProps: { className: "modal-cancel-button" },
      onOk: () => {
        LibraryService.requestBook(selectedBook?.id, params)
          .then((response) => {
            notification.success({
              message: `${selectedBook?.bookName} Book Requested Sucessfully`,
              placement: "bottomRight",
              icon: (
                <LikeTwoTone
                  style={{
                    color: "#108ee9",
                  }}
                />
              ),
            });
          })
          .catch((error) => {
            const errorMessage = dynamicErrorMsg(error, "Create", "Book Request")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
          });
      },
    });
  };

  return (
    <>
      <div style={{ marginLeft: 10 }}>
        <Button
          className="back-button"
          type="primary"
          htmlType="submit"
          onClick={handleBack}
        >
          <LeftOutlined />
        </Button>
      </div>
      <div className="book-view">
        <div className="book-details">
          <img src={selectedBook.image} alt="book" className="book-image" />
          <div className="data-view">
            <h2 className="book-title">{selectedBook.bookName}</h2>
            <h3>Publisher: {selectedBook.publisher}</h3>
            <p className="description-heading">Description:</p>
            <p>{selectedBook.bookDescription}</p>
            <div className="buttons-container">
              {/* <Button className="later-button">Request For Later</Button> */}
              {selectedBook.numbersOfBooks > 0 && (
                <Button className="rent-button" onClick={onHandleRent}>
                  Rent Now
                </Button>
              )}
              <Button className="request-button" onClick={onHandleRequest}>
                Request
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookView;
