import {
  CREATE_ANNOUNCEMENT_URL,
  EDIT_ANNONCE_URL,
  ANNOUNCE_LIST,
  VIEW_ANNOUNCE_URL,
} from "../Constants/constants";
import { PostAPIRequest, GetAPIRequest } from "./Api";

const createAnnouncement = (payLoad) => {
  return PostAPIRequest({ url: CREATE_ANNOUNCEMENT_URL, data: payLoad });
};

const editAnnouncement = (payLoad, id) => {
  return PostAPIRequest({
    url: EDIT_ANNONCE_URL + id,
    data: payLoad,
  });
};

const listAnnouncements = () => {
  return GetAPIRequest({ url: ANNOUNCE_LIST });
};

const viewAnnouncements = (id) => {
  return GetAPIRequest({ url: VIEW_ANNOUNCE_URL + id });
};

export const AnnouncementService = {
  createAnnouncement,
  editAnnouncement,
  listAnnouncements,
  viewAnnouncements,
};
