import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Button,
  Space,
  notification,
} from "antd";
import moment from "moment/moment";
import { hrRequestServices } from "../../../../Services/HumanResourceService";
import { createErrorMessage, updateErrorMessage, deleteErrorMessage, dynamicErrorMsg } from "../../../../Constants/utilitis"

import {
  CloseOutlined,
  DislikeOutlined,
  LikeTwoTone,
  PlusCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import Cookies from "js-cookie";

const CreateRegularization = ({
  openModal,
  setOpenModal,
  setViewModal,
  viewModal,
  rowData,
  loadResignationData,
  setRowdata,
}) => {
  const [regularizationForm] = Form.useForm();
  const [staffAttendance, setStaffAttendance] = useState(null);
  const [dateTimes, setDateTimes] = useState(staffAttendance || []);
  const [isFormFilled, setIsFormFilled] = useState(false);

  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];

  const staffId = staffInfo?.staffId;

  const handleFormFieldChange = () => {
    const formFields = document.querySelectorAll(".ant-input");
    const isFormFilled = Array.from(formFields).some(
      (field) => field.value !== ""
    );

    setIsFormFilled(isFormFilled);
  };

  useEffect(() => {
    const emptyObjectArray = [];
    const length = rowData?.updatedAttendance?.length;
    for (let i = 0; i < length; i++) {
      emptyObjectArray.push({});
    }
    setStaffAttendance(rowData?.updatedAttendance);
    setDateTimes(emptyObjectArray);
  }, [rowData]);

  useEffect(() => {
    staffAttendance?.forEach((item, index) => {
      const requestedDate = item?.reqDate
        ? moment(item.reqDate, "DD-MM-YYYY")
        : moment(new Date(), "DD-MM-YYYY");
      const requestedCheckIn = item?.checkIn
        ? moment(item.checkIn, "h:mm a")
        : moment(new Date(), "h:mm a");
      const requestedCheckOut = item?.checkOut
        ? moment(item.checkOut, "h:mm a")
        : moment(new Date(), "h:mm a");

      if (staffAttendance !== null) {
        regularizationForm.setFieldsValue({
          [`reqDate_${index}`]: requestedDate,
          [`checkIn_${index}`]: requestedCheckIn,
          [`checkOut_${index}`]: requestedCheckOut,
        });
      } else {
        regularizationForm.resetFields();
      }
    });
  }, [staffAttendance, regularizationForm]);

  const handleCancelViewModal = () => {
    setViewModal(false);
  };

  const handleCancel = () => {
    if (isFormFilled) {
      Modal.confirm({
        title: `Are you sure you want cancel the form, if you cancel data will be deleted?`,
        onOk: () => {
          setOpenModal(false);
        },
        onCancel: () => {
          setOpenModal(true);
        },
      });
    } else {
      setOpenModal(false);
      regularizationForm.resetFields();
    }
  };

  const addDateTime = () => {
    setDateTimes([...dateTimes, {}]);
  };

  useEffect(() => {
    addDateTime();
  }, []);

  const removeDateTime = (index) => {
    const updatedDateTimes = [...dateTimes];
    updatedDateTimes.splice(index, 1);
    setDateTimes(updatedDateTimes);
  };

  const handleFormValueChange = (changedValues, allValues) => {
    // Extract and update the dateTimes state based on changed form values
    const updatedDateTimes = dateTimes.map((dateTime, index) => ({
      reqDate: allValues[`reqDate_${index}`]?.format("DD-MM-YYYY"),
      checkIn: allValues[`checkIn_${index}`]?.format("h:mm a"),
      checkOut: allValues[`checkOut_${index}`]?.format("h:mm a"),
    }));
    setDateTimes(updatedDateTimes);
  };

  const onFinish = (values) => {
    const updatedDateTimes = dateTimes.map((dateTime, index) => ({
      reqDate: values[`reqDate_${index}`].format("DD-MM-YYYY"),
      checkIn: values[`checkIn_${index}`].format("h:mm a"),
      checkOut: values[`checkOut_${index}`].format("h:mm a"),
    }));

    const payload = {
      updatedAttendance: updatedDateTimes,
    };

    const params = {
      staffId: staffId,
    };

    if (rowData === null) {
      hrRequestServices
        .createRegularizationRequest(payload, params)
        .then((res) => {
          loadResignationData();
          regularizationForm.resetFields();
          setViewModal(false);
          notification.success({
            message: `Regularization Created successfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          setOpenModal(false);
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Create", "Regularization" )
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
          setViewModal(false);
          loadResignationData();
        });
    } else {
      hrRequestServices
        .editRegularization(rowData?.regId, payload)
        .then((res) => {
          loadResignationData();
          notification.success({
            message: `Regularization Updated successfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          setOpenModal(false);
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Update","Regularization");
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    }
  };
  const disabledStartDate = (current) => {
    return current && current > moment().startOf("day");
  };

  return (
    <>
      <Modal
        className="studymaterial-modal"
        title={rowData !== null ? "Edit Regularization" : "Regularization"}
        centered
        open={openModal}
        width={850}
        footer={null}
        onCancel={handleCancel}
        bodyStyle={{ maxHeight: "700px", overflowY: "auto" }}
      >
        <Form
          form={regularizationForm}
          initialValues={staffAttendance}
          onFinish={onFinish}
          autoComplete="off"
          onValuesChange={handleFormValueChange}
        >
          <Button
            className="blue-button"
            onClick={addDateTime}
            style={{ marginBottom: 10, float: "right" }}
          >
            Add
            <PlusCircleOutlined />
          </Button>
          {dateTimes.map((dateTime, index) => (
            <div key={index}>
              <Row
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                className="regrow"
              >
                <Col className="gutter-row" span={7}>
                  <Form.Item
                    name={`reqDate_${index}`}
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <DatePicker
                      format="DD-MM-YYYY"
                      onChange={handleFormFieldChange}
                      disabledDate={disabledStartDate}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={7}>
                  <Form.Item
                    name={`checkIn_${index}`}
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <TimePicker
                      format="h:mm a"
                      placeholder="Check In"
                      onChange={handleFormFieldChange}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={7}>
                  <Form.Item
                    name={`checkOut_${index}`}
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <TimePicker
                      format="h:mm a"
                      placeholder="Check Out"
                      handleFormFieldChange={handleFormFieldChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Button
                    className="closebtn"
                    onClick={() => removeDateTime(index)}
                  >
                    <CloseOutlined />
                  </Button>
                </Col>
              </Row>
            </div>
          ))}

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button" htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateRegularization;
