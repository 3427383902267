import React from "react";
import { Topbar, Holiday } from "../../components";
import SEO from "../../components/reuse";

const Holidays = () => {
  return (
    <main>
      {/* <Topbar /> */}
      <SEO title="holidays" />
      <Holiday />
    </main>
  );
};

export default Holidays;
