import React from "react";
import {
  CloseSquareOutlined,
  DislikeOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  LikeTwoTone,
  CheckCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Modal,
  message,
  Space,
  Upload,
  notification,
  Select,
} from "antd";
import Cookies from "js-cookie";
import _ from "lodash";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../Common/FloatLabel/Select";
import "./CreateStudyMaterial.scss";
import TextAreaFloatLabel from "../../Common/FloatLabel/TextArea";

import { studyMaterialApiService } from "../../../Services/StudyMaterials";
import FileView from "../../../Utils/FileView";
import { createErrorMessage, deleteErrorMessage, dynamicErrorMsg, updateErrorMessage } from "../../../Constants/utilitis";
const CreateStudyMaterial = ({
  setIsOpenModal,
  isOpenModal,
  editStaffRow,
  setEditStaffRow,
  loadStudymaterialData,
}) => {
  const { Option } = Select;
  const [fileData, setFileData] = useState(null);
  const [view, setView] = useState(false);
  const [heading, setHeading] = useState(null);
  const [classOptions, setClassOptions] = useState(null);
  const [sectionOptions, setSctionOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [fileList2, setFileList2] = useState([]);
  const [studyMaterialForm] = Form.useForm();

  const subjectsInfo = Cookies.get("subjectsInfo")
    ? JSON.parse(Cookies.get("subjectsInfo"))
    : [];
  const classInfo = Cookies.get("classInfo")
    ? JSON.parse(Cookies.get("classInfo"))
    : [];

  useEffect(() => {
    const subjectWithAssignment = subjectsInfo?.filter(
      (item) => parseInt(item?.subjectId) === editStaffRow?.subjectId
    );
    setSelectedSubject(subjectWithAssignment[0]?.subjectId);
    const classWithAssignment = classInfo?.filter(
      (item) => parseInt(item?.classId) === editStaffRow?.classId
    );
    const subjects = getSubjectsByClassName(
      classWithAssignment[0]?.className,
      classInfo,
      subjectsInfo
    );

    setSubjectOptions(subjects);

    setSelectedSection(classWithAssignment[0]?.section);

    if (editStaffRow?.id) {
      studyMaterialForm.setFieldsValue({
        className: classWithAssignment[0]?.className,
        searchKeyWords: editStaffRow?.searchKeyWords,
        description: editStaffRow?.description,
        bookTitle: editStaffRow?.bookTitle,
        author: editStaffRow?.author,
        studyMaterialImage: editStaffRow?.studyMaterialImage,
        staffStudyMaterialUrls: editStaffRow?.staffStudyMaterialUrls,
      });
    } else {
      studyMaterialForm.resetFields();
    }
  }, [editStaffRow, studyMaterialForm]);

  const onFinish = (values) => {
    console.log(values);
    const studyMaterialName =
      values.bookTitle.charAt(0).toUpperCase() + values.bookTitle.slice(1);
    const id = editStaffRow?.id;

    const classId = classInfo.filter(
      (item) => item.className === values.className
      // item.section === selectedSection[0]
    )[0]?.classId;

    console.log("study", selectedSubject);

    const formData = new FormData();
    formData.append("classId", parseInt(classId));
    formData.append("subjectId", parseInt(selectedSubject));
    formData.append("searchKeyWords", values.searchKeyWords);
    formData.append("description", values.description);
    formData.append("bookTitle", values.bookTitle);
    formData.append("author", values.author);
    fileList2.map((file, i) => {
      formData.append(`staffStudyMaterialUrls`, file);
    });
    console.log("formdata", formData);
    if (!id) {
      studyMaterialApiService
        .createStudymaterial(formData)
        .then((response) => {
          setIsOpenModal(false);
          loadStudymaterialData();
          notification.success({
            message: `${studyMaterialName} Created Sucessfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          studyMaterialForm.resetFields();
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error,"Create","Study material")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    } else {
      studyMaterialApiService
        .editStudyMaterial(id, formData)
        .then((response) => {
          loadStudymaterialData();
          setIsOpenModal(false);
          notification.success({
            message: `${studyMaterialName} Info Updated`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          studyMaterialForm.resetFields();
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error,"Update","Study material")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    }
  };

  /* class state*/
  useEffect(() => {
    const uniqueClasses = _.uniqBy(classInfo, "className").map((getClass) => {
      return { label: getClass.className, value: getClass.className };
    });
    setClassOptions(uniqueClasses);
  }, []);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  /*get subject*/
  function getSubjectsByClassName(classID, classList, subjectList) {
    var classId;
    for (var i = 0; i < classList.length; i++) {
      if (classList[i].classId == classID) {
        classId = classList[i].classId;
        break;
      }
    }
    var matchingSubjects = [];
    for (var i = 0; i < subjectList.length; i++) {
      if (subjectList[i].classId === classId) {
        matchingSubjects.push({
          label: capitalize(subjectList[i].subjectName),
          value: subjectList[i].subjectId,
        });
      }
    }
    return matchingSubjects;
  }

  /*get section*/
  const getsectionOptionsFromClass = (className, classList) => {
    const filteredSection = classList?.filter(
      (item) => item.className === className
    );
    const getSection = filteredSection?.map((item) => {
      return {
        label: item.section,
        value: item.classId,
      };
    });
    return getSection ?? [];
  };

  const handleClassDropdown = (e) => {
    setSelectedSection([]);
    setSelectedSubject([]);
    const sectionOptions = getsectionOptionsFromClass(e, classInfo);

    if (Array.isArray(sectionOptions)) {
      const sort = sectionOptions.sort((a, b) => {
        if (a.label && b.label) {
          return a.label.localeCompare(b.label);
        } else {
          return 0;
        }
      });

      setSctionOptions(sort);
    } else {
      console.error("Section options are not an array:", sectionOptions);
      setSctionOptions([]);
    }
  };
  const handleSectionChange = (val) => {
    setSelectedSection(val);
    const subjects = getSubjectsByClassName(val, classInfo, subjectsInfo);

    setSubjectOptions(subjects);
  };
  const handleSubject = (val) => {
    setSelectedSubject(val);
  };

  const handleCancel = () => {
    setIsOpenModal(false);
    studyMaterialForm.resetFields();
    setEditStaffRow(null);
    setSelectedSection([]);
    setSelectedSubject([]);
  };

  const handleUploadPDF = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList2(files);
  };

  const onFinishFailed = () => {};

  const handleFileView = (url, record) => {
    setView(true);
    setFileData([url]);
    setHeading(record?.bookTitle);
  };

  const handleDeleteFile = (url, index) => {
    const studyMaterial = editStaffRow?.id;
    const params = {
      url: url,
    };
    studyMaterialApiService
      .DeleteStudyMaterialIndividualFile(studyMaterial, params)
      .then((res) => {
        notification.success({
          message: `File Deleted Sucessfully`,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
        if (editStaffRow) {
          const updatedStaffStudyMaterialUrls = [
            ...editStaffRow.staffStudyMaterialUrls,
          ];
          updatedStaffStudyMaterialUrls.splice(index, 1);
          setEditStaffRow((prevRow) => ({
            ...prevRow,
            staffStudyMaterialUrls: updatedStaffStudyMaterialUrls,
          }));
        }

        setFileList2([]);
        loadStudymaterialData();
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error,"Delete","Study material")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  return (
    <>
      <Modal
        className="studymaterial-modal"
        title={
          editStaffRow && Object.keys(editStaffRow).length > 0
            ? "Edit Study Material"
            : "Add Study Material"
        }
        centered
        open={isOpenModal}
        width={700}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          form={studyMaterialForm}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="className"
                rules={[{ required: true, message: "" }]}
              >
                <SelectFloatLabel
                  options={classOptions}
                  onChange={handleClassDropdown}
                  label="Class"
                ></SelectFloatLabel>
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item rules={[{ required: true, message: "" }]}>
                <SelectFloatLabel
                  onChange={handleSectionChange}
                  value={selectedSection}
                  options={sectionOptions}
                  label="Section"
                  style={{ height: "40px" }}
                ></SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item rules={[{ required: true, message: "" }]}>
                <Select
                  value={selectedSubject}
                  onChange={handleSubject}
                  placeholder="Subject"
                  style={{ height: "40px", width: "320px" }}
                >
                  {subjectOptions?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="bookTitle"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel label="Book Title" id="inputs" />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="searchKeyWords"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel label="Search By Key Words" />
              </Form.Item>
              <Form.Item
                name="author"
                rules={[{ required: true, message: "" }]}
              >
                <InputFloatLabel label="Author" id="inputs" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <TextAreaFloatLabel
                  label="Description"
                  style={{ height: "95px" }}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={12}>
              <Form.Item
                name="staffStudyMaterialUrls"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Upload
                  onChange={handleUploadPDF}
                  type="file"
                  accept=".jpg, .jpeg, .png , .docx, .pdf"
                  multiple
                  beforeUpload={() => false}
                  showUploadList={true}
                >
                  <Button icon={<UploadOutlined />}>
                    Upload Studymterial File
                  </Button>
                </Upload>
              </Form.Item>
              {editStaffRow && Object.keys(editStaffRow).length > 0 ? (
                <div className="file_uploading-icon">
                  {editStaffRow.staffStudyMaterialUrls.map((url, index) => {
                    return (
                      <div key={index} className="file-wrapper">
                        <FilePdfOutlined
                          style={{ fontSize: "30px", color: "black" }}
                          onClick={() => handleFileView(url, editStaffRow)}
                        />
                        <CloseSquareOutlined
                          onClick={() => handleDeleteFile(url, index)}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button  " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />
    </>
  );
};

export default CreateStudyMaterial;
