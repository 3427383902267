import { Button, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import "./Reimbursement.scss";
import { reimbursementService } from "../../Services/ReimbursementService";
import {
  LeftOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  DislikeOutlined,
  FileOutlined,
  LikeTwoTone,
  CheckCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import CreateReimbursement from "./CreateReimbursement/CreateReimbursement";
import FileView from "../../Utils/FileView";
const Reimbursement = () => {
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [heading, setHeading] = useState(null);
  const [view, setView] = useState(false);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    reimbursementService
      .getreimbursementList(staffId)
      .then((res) => {
        console.log(res);
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };
  const showModal = () => {
    setOpenModal(true);
  };
  const handleEdit = (row) => {
    setOpenModal(true);
    setRowData(row);
  };

  const handleFileView = (url, record) => {
    setView(true);
    setFileData([url]);
    setHeading(record?.assignmentTitle);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => (
        <Space>
          <span>{amount}</span>
        </Space>
      ),
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true, // Adds ellipsis for long text
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "APPROVED" ? "green" : "orange"}>{status}</Tag>
      ),
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
      ellipsis: true,
      render: (text) => {
        if (text == "" || text == null) {
          return "-";
        }
        return text;
      },
    },
    {
      title: "Expense Date",
      dataIndex: "expenseDate",
      key: "expenseDate",
    },
    {
      title: "Receipt",
      dataIndex: "receipt",
      key: "receipt",
      render: (docs, record) => (
        <div className="file_uploading-icon">
          <div className="file-wrapper">
            <FileOutlined
              style={{ fontSize: "30px", color: "black" }}
              onClick={() => handleFileView(docs, record)}
            />
            {/* Uncomment the following line if you want to provide a delete option */}
            {/* <CloseSquareOutlined onClick={() => handleDeleteFile(docs, record)} /> */}
          </div>
        </div>
      ),
    },
    {
      title: "Actions",
      key: "details",
      dataIndex: "details",
      align: "center",
      render: (text, row) => (
        <Space>
          <Button
            className="full-details-button"
            onClick={(e) => {
              handleEdit(row, e);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <main className="reimbursement-container">
      <header className="header">
        <h1>Reimbursement</h1>

        <Button className="blue-button" onClick={showModal}>
          <PlusOutlined />
        </Button>
      </header>
      <div>
        <Table dataSource={data} columns={columns} pagination={false} />
        <CreateReimbursement
          getData={getData}
          openModal={openModal}
          setOpenModal={setOpenModal}
          rowData={rowData}
          setRowData={setRowData}
        />
        <FileView
          view={view}
          setView={setView}
          fileData={fileData}
          heading={heading}
        />
      </div>
    </main>
  );
};

export default Reimbursement;
