import React, { useEffect, useState } from "react";
import "./Attendance.scss";
import {
  Table,
  Button,
  Progress,
  DatePicker,
  Radio,
  Select,
  notification,
  Spin,
  Modal,
  Alert,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import ClassDropdown from "../../../Common/Class/ClassDropdown";
import {
  createErrorMessage,
  updateErrorMessage,
  deleteErrorMessage,
  saveErrorMessage,
  dynamicErrorMsg,
} from "../../../../Constants/utilitis";

import SectionDropdown from "../../../Common/Section/SectionDropdown";
import { attendanceService } from "../../../../Services/AttendanceService";
import {
  CheckOutlined,
  CloseOutlined,
  DislikeOutlined,
  LikeOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { staffId } from "../../../../Constants/constants";
import Cookies from "js-cookie";
import moment from "moment";
const options = ["Present", "Absent"];
const dateFormat = "DD-MM-YYYY";
const StudentAttendance = () => {
  const { Option } = Select;
  const [attendanceType, setAttendanceType] = useState("");
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [todaytimeTableList, setTodaytimeTableList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);
  const [classAttendanceId, setClassAttendanceId] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [todayAttendanceTakenStatus, setTodayAttendnaceTakenStatus] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [columns, setColumns] = useState([]);

  const userId = Cookies.get("userId");
  const onClassChange = (value) => {
    setSelectedClass(value);
  };
  console.log("selectedSection", selectedSection);

  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;

  const onSectionChange = (value) => {
    setSelectedPeriod("");
    setStudentList([]);
    console.log("value", value);
    setSelectedSection(value);
  };

  const handleDateChange = (date, dateString) => {
    setSelectedDate(date);
  };

  const saveAttendance = () => {
    const today = new Date(selectedDate);
    const dayOfWeek = today.getDay();
    const attendanceMap = {};

    attendanceList.forEach((item) => {
      attendanceMap[item.rollNo] = item.isPresent;
    });

    console.log("classatt", attendanceMap);

    const params = {
      date: today,
      classId: selectedSection?.classId,
      userId: userId,
      userType: "STAFF",
    };
    let attendanceObject = {
      attendanceMap: attendanceMap,
    };

    if (dayOfWeek === 0) {
      Modal.confirm({
        title: `Today is sunday, Are you sure you want take attendance?`,
        onOk: () => {
          attendanceService
            .saveAttendance(classAttendanceId, params, attendanceObject)
            .then((res) => {
              getAttendance();
              getAttendanceTakenStatus();
              notification.success({
                message: `Attendance Saved Sucessfully`,
                icon: (
                  <CheckCircleFilled
                    style={{
                      color: "green",
                    }}
                  />
                ),
                duration: 2,
                placement: "bottomRight",
              });
            })
            .catch((error) => {
              const errorMessage = dynamicErrorMsg(error, "Save", "Attendance");
              notification.error({
                message: errorMessage,
                placement: "bottomRight",
                icon: <DislikeOutlined style={{ color: "red" }} />,
              });
            });
        },
      });
    } else {
      attendanceService
        .saveAttendance(classAttendanceId, params, attendanceObject)
        .then((res) => {
          getAttendance();
          getAttendanceTakenStatus();
          notification.success({
            message: `Attendance Saved Sucessfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 2,
            placement: "bottomRight",
          });
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Save", "Attendance");
          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        });
    }
  };

  const createAttendance = (record, value) => {
    setLoading(true); // Start the loading spinner
    const dateObject = new Date(selectedDate);
    const formattedDate = dateObject.toISOString().split("T")[0];
    const params = {
      attndDate : formattedDate
    }
    const attenanceStatus = value === "PRESENT" ? true : false 
    attendanceService.saveStudentAttendance(selectedSection.classId, record.rollNo, attenanceStatus, params )
        .then((res) => {
          getAttendance()
          const newStudentData = studentList.map(student => {
            if (res?.data?.rollNumber === student?.rollNo){
              return {
                studentName: student?.studentName,
                rollNo: student?.rollNo,
                todayAttendanceStatus: res?.data.recentStatus,
                last7DaysData: student?.last7DaysData,
                percentage: student?.percentage,
              }
            }else{
              return {
                studentName: student?.studentName,
                rollNo: student?.rollNo,
                todayAttendanceStatus: student?.todayAttendanceStatus,
                last7DaysData: student?.last7DaysData,
                percentage: student?.percentage,
              }
            }
          })

          setStudentList(newStudentData)
          notification.success({
            message: `Student Attendance Saved Sucessfully`,
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
            duration: 2,
            placement: "bottomRight",
          });
        })
        .catch((error) => {
          setLoading(false);
          const errorMessage = dynamicErrorMsg(error, "Save", "Student Attendance")
          notification.error({
            message: errorMessage,
            placement: "bottomRight",
            icon: <DislikeOutlined style={{ color: "red" }} />,
          });
        })
        .finally(() => {
          setLoading(false); // Stop the loading spinner
        });

      
    let studentAttendanceIndex = attendanceList.findIndex(
      (item) => item.rollNo == record.rollNo
    );
    value = value.split("-")[0];
    if (studentAttendanceIndex !== -1 && value.toUpperCase() === "PRESENT") {
      attendanceList[studentAttendanceIndex].isPresent = true;
      setAttendanceType("PRESENT");
    } else if (
      studentAttendanceIndex !== -1 &&
      value.toUpperCase() === "ABSENT"
    ) {
      attendanceList[studentAttendanceIndex].isPresent = false;
      setAttendanceType("ABSENT");
    }
  };

  useEffect(() => {
    if (selectedDate && selectedSection) {
      getAttendance();
    }
  }, [selectedDate, selectedSection]);

  useEffect(() => {
    if (selectedSection !== null) {
      getPeriodAndSubject();
    }
  }, [selectedSection]);

  useEffect(() => {
    getColums();
  }, [studentList]);

  const getAttendance = () => {
    debugger;
    setClassAttendanceId(null);
    setAttendanceList([]);
    setStudentList([]);
    console.log('selectedSection', selectedSection)
    const dateObject = new Date(selectedDate);
    const formattedDate = dateObject.toISOString().split("T")[0];
    const params = {
      date: formattedDate,
    };
    attendanceService
      .getAttendenceDetails(selectedSection.classId, params)
      .then((response) => {
        const studentData = response[0]?.studentDetailsList?.map((item) => {
          // const last7DaysAttendance = item.last7DaysData;
          return {
            studentName: item?.studentName,
            rollNo: item?.rollNumber,
            todayAttendanceStatus: item?.attendanceStatus,
            last7DaysData: item?.last7DaysData,
            percentage: item?.percentage,
          };
        });
        let attendance = response[0]?.studentDetailsList?.map((res) => {
          return {
            rollNo: res?.rollNumber,
            isPresent: res?.attendanceStatus,
            gender: res?.gender,
          };
        });
        setClassAttendanceId(response[0].classAttendanceId);
        setAttendanceList(attendance);
        setStudentList(studentData);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Attendance");
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const getAttendanceTakenStatus = () => {
    const dateObject = new Date(selectedDate);
    const formattedDate = dateObject.toISOString().split("T")[0];
    const params = {
      classId: selectedSection.classId,
      date: formattedDate,
    };
    attendanceService
      .checkAttendanceTakenStatus(params)
      .then((response) => {
        console.log('response', response)
        setTodayAttendnaceTakenStatus(response);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Attendance Status");
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  useEffect(() => {
    if (selectedSection && selectedDate) {
      getAttendanceTakenStatus();
    }
  }, [selectedSection?.classId, selectedDate]);

  const getPeriodAndSubject = () => {
    attendanceService.todayTimeTableAttendanceList(staffId).then((response) => {
      setTodaytimeTableList(response?.data?.todaySchedule);
      const data = response?.data?.todaySchedule;
      let period = [];
      let subject = [];
      Object.entries(data).forEach(([key, schedule]) => {
        if (schedule.classId == selectedSection?.classId) {
          period.push({ Period: `Period-${key}`, PeriodId: key });
          subject.push({
            subjectId: schedule?.subjectId,
            subjectName: schedule?.subject,
            periodId: `${key}`,
          });
        }
      });
      setPeriods(period);
      setSubjects(subject);
      setSelectedPeriod(subject[0]?.periodId);
      setSelectedSubject(subject[0]);
    });
  };

  const getColums = () => {
    const last7Days = studentList?.[0]?.last7DaysData;
    if (!last7Days || Object?.keys(last7Days)?.length === 0) {
      let intialColumns = [
        {
          title: "Roll No",
          dataIndex: "rollNo",
          key: "rollNo",
          align: "center",
          sorter: (a, b) => a.rollNo.localeCompare(b.rollNo),
        },
        {
          title: "Student Name",
          dataIndex: "studentName",
          key: "studentName",
          align: "center",
          sorter: (a, b) => a.studentName.localeCompare(b.studentName),
          defaultSortOrder: "ascend",
        },
        {
          title: "Today Attendance",
          dataIndex: "todayAttendanceStatus",
          align: "center",
          render: (_, record) => {
            let attendancesOptions = options.map((item) => {
              return {
                label: item,
                value: item.toUpperCase(),
                //value: item + "-" + record.rollNo,
              };
            });
            // console.log("record", record);
            return (
              <Spin spinning={loading}>
                <Radio.Group
                  options={attendancesOptions}
                  defaultValue={record?.todayAttendanceStatus === true ? "PRESENT" : "ABSENT"}
                  buttonStyle="solid"
                  optionType="button"
                  className="present-btn"
                  onChange={(e) => {
                    createAttendance(record, e.target.value);
                    setAttendanceType(e.target.value.split("-")[0]);
                  }}
                />
              </Spin>
            );
          },
        },
        {
          title: "Previous 7 Days",
          key: "periodWiseLast7DaysAttendance",
          align: "center",
        },
        {
          title: "Percentage",
          dataIndex: "percentage",
          key: "percentage",
          align: "center",
          render: (record, _) => {
            let strokeColor = "red";

            if (record >= 0 && record <= 30) {
              strokeColor = "red";
            } else if (record >= 31 && record <= 75) {
              strokeColor = "orange";
            } else if (record > 75) {
              strokeColor = "green";
            }

            return (
              <Tooltip title={`${record}%`}>
                <Progress
                  type="circle"
                  width={30}
                  strokeColor={strokeColor}
                  percent={record}
                  showInfo={false} // Hides the percentage inside the circle
                  strokeWidth={15} // Adjust this value to increase the width of the color
                />
              </Tooltip>
            );
          },
        },
      ];

      setColumns(intialColumns);
    } else {
      const daysOfWeek = Object?.keys(last7Days);
      daysOfWeek?.reverse();
      const children = daysOfWeek?.map((day) => ({
        title: day,
        dataIndex: day,
        key: day,
        align: "center",
        render: (_, record) => {
          const attendance = record?.last7DaysData[day];

          if (attendance === true) {
            return (
              <CheckOutlined
                type="check-circle"
                theme="filled"
                style={{ color: "green" }}
              />
            );
          } else if (attendance === false) {
            return (
              <CloseOutlined
                type="close-circle"
                theme="filled"
                style={{ color: "red" }}
              />
            );
          } else if (attendance == "-") {
            return attendance;
          } else {
            return "-";
          }
        },
      }));
      //    let children;

      let columns = [
        {
          title: "Roll No.",
          dataIndex: "rollNo",
          key: "rollNo",
          align: "center",
          sorter: (a, b) => a.rollNo.localeCompare(b.rollNo),
          fixed: "left",
          width: 90,
        },
        {
          title: "Student Name",
          dataIndex: "studentName",
          key: "studentName",
          align: "center",
          sorter: (a, b) => a.studentName.localeCompare(b.studentName),
          defaultSortOrder: "ascend",
        },
        {
          title: "Today Attendance",
          dataIndex: "todayAttendanceStatus",
          align: "center",
          render: (_, record) => {
            return (
              <Radio.Group
                defaultValue={
                  record.todayAttendanceStatus ? "PRESENT" : "ABSENT"
                }
                className="attendance-btn-group"
                buttonStyle="solid"
                optionType="button"
                onChange={(e) => {
                  createAttendance(record, e.target.value);
                  setAttendanceType(e.target.value.split("-")[0]);
                }}
              >
                <Radio.Button
                  key="PRESENT"
                  value="PRESENT"
                  style={{
                    backgroundColor:
                      record.todayAttendanceStatus === true && "lignGreen",
                  }}
                >
                  PRESENT
                </Radio.Button>

                <Radio.Button
                  key="ABSENT"
                  value="ABSENT"
                  style={{
                    backgroundColor:
                      record.todayAttendanceStatus === false && "orange",
                  }}
                >
                  ABSENT
                </Radio.Button>
              </Radio.Group>
            );
          },
        },
        {
          title: "Previous 7 Days",
          key: "periodWiseLast7DaysAttendance",
          align: "center",
          children: children,
        },
        {
          title: "Percentage",
          dataIndex: "percentage",
          key: "percentage",
          align: "center",
          fixed: "right",
          render: (record, _) => {
            let strokeColor = "red";

            if (record >= 0 && record <= 30) {
              strokeColor = "red";
            } else if (record >= 31 && record <= 75) {
              strokeColor = "orange";
            } else if (record > 75) {
              strokeColor = "green";
            }

            return (
              <Tooltip title={`${record}%`}>
                <Progress
                  type="circle"
                  width={30}
                  strokeColor={strokeColor}
                  percent={record}
                  showInfo={false} // Hides the percentage inside the circle
                  strokeWidth={15} // Adjust this value to increase the width of the color
                />
              </Tooltip>
            );
          },
        },
      ];

      setColumns(columns);
    }
  };

  const disabledDate = (current) => {
    return current && current >= moment().add("day");
  };

  const icon = todayAttendanceTakenStatus ? (
    <CheckCircleOutlined />
  ) : (
    <ExclamationCircleOutlined />
  );
  const message = todayAttendanceTakenStatus
    ? "Attendance has already been recorded for today."
    : "Attendance for today has not been taken yet; please proceed to take the attendance.";

  return (
    <section className="staff-attenance">
      <div className="headerblock-staff">
        <h2 className="header-text">Student Attendance</h2>

        <div className="hright">
          {/* <DatePicker defaultValue={dayjs(new Date())} format={dateFormat} /> */}
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            format={dateFormat}
            disabledDate={disabledDate}
          />

          <ClassDropdown
            onClassChange={onClassChange}
            selectedClass={selectedClass}
          />

          <SectionDropdown
            classValue={selectedClass}
            onSectionChange={onSectionChange}
            // selectedSection={selectedSection}
          />

          {/* <Button onClick={() => saveAttendance()} className="submit-button">
            Save
          </Button> */}
        </div>
      </div>
      <Alert
        message={message}
        type={todayAttendanceTakenStatus ? "success" : "warning"}
        showIcon
        icon={icon}
        closable
      />
      <div className="tables">
        {isLoading ? (
          <Spin spinning={isLoading} className="loading-spinner" />
        ) : (
          <Table
            dataSource={studentList}
            columns={columns}
            pagination={false}
            showSorterTooltip={false}
          />
        )}
      </div>
    </section>
  );
};

export default StudentAttendance;
