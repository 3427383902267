import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { notification } from "antd";
import { Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { checkAuth } from "./Utils/cookie-helper";
import {
  Home,
  Results,
  Timetables,
  Exams,
  Subjects,
  Profiles,
  Assignments,
  Studys,
  Holidays,
  MyattendaceHome,
  StudentAttendaceHome,
} from "./pages";
import { Attendance, Sidebar, Topbar } from "./components";
import Announcement from "./components/Announce/Announcement";
import { staffServices } from "./Services/StaffServices";
import Cookies from "js-cookie";
import Meetings from "./components/OneOnOneMeeting/Meetings";
import Resignation from "./components/HumanResource/Resignation/Resignation";
import StaffAttendance from "./components/HumanResource/Attendance/UnlockRequest/UnlockRequest";
import Syllabus from "./components/Syllabus/Syllabus";
import CreateResult from "./components/Exam/CreateResults/CreateResults";
import Library from "./components/LibraryManagement/Library";
import Books from "./components/LibraryManagement/Books/Books";
import TransportHome from "./components/Transport/TransportHome";
import EdpediaStore from "./components/EdpediaStore/EdpediaStore";
import Address from "./components/EdpediaStore/Address/UserAddress";
import Orders from "./components/EdpediaStore/Orders/Orders";
import Footer from "./components/Footer/Footer";
import Students from "./components/Students/Students";
import Reimbursement from "./components/Reimbursement/Reimbursement";
import Payslips from "./components/Payslips/Payslips";
import LeavesManagement from "./components/LeaveManegement/LeavesManagement";
import Homework from "./components/Homework/homework";
// import MyattendaceHome from "./components/Attendance/MyAttendaceHome/MyattendaceHome";
// import StudentAttendaceHome from "./components/Attendance/StudentAttendaceHome/StudentAttendaceHome";
function App() {
  const [isAuth, setIsAuth] = useState(false);

  const [staffId, setStaffId] = useState(Cookies.get("userId"));
  const branchCode = Cookies.get("branchCode");

  useEffect(() => {
    const socket = new SockJS("https://api.edpedia.co/v1.0/staff/ws");
    const stompClient = Stomp.over(socket);

    const connectCallback = (frame) => {
      stompClient.subscribe(`/topic/exams/staff/${staffId}`, (greeting) => {
        const response = JSON.parse(greeting.body);
        notification.success({
          message: (
            <div>
              <strong>{response.message}</strong>
              <div style={{ textAlign: "end" }}>
                <p>From</p>
                <em>{response.sender}</em>
              </div>
            </div>
          ),
          // description: ,
          duration: 10,
          placement: "topRight",
        });
      });
      stompClient.subscribe(
        `/topic/${branchCode}/results/staff/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            // description: ,
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${branchCode}/announcement`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            // description: ,
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${branchCode}/library/staff/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            // description: ,
            duration: 10,
            placement: "topRight",
          });
        }
      );
      stompClient.subscribe(
        `/topic/${branchCode}/studentLeave/${staffId}`,
        (greeting) => {
          const response = JSON.parse(greeting.body);
          notification.success({
            message: (
              <div>
                <strong>{response.message}</strong>
                <div style={{ textAlign: "end" }}>
                  <p>From</p>
                  <em>{response.sender}</em>
                </div>
              </div>
            ),
            // description: ,
            duration: 10,
            placement: "topRight",
          });
        }
      );
    };

    stompClient.connect({}, connectCallback);

    // Cleanup function to disconnect the WebSocket when the component unmounts
    return () => {
      stompClient.disconnect();
    };
  }, [staffId]);

  const getToken = () => {
    const token = Cookies.get("accessToken");
    if (token) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
      window.location.replace("https://dev.d285gbdoz6er7k.amplifyapp.com/");
    }
  };

  const gotoLogin = () => {
    let token = Cookies.get("accessToken");
    if (token === null || token === "" || (token === undefined && !isAuth)) {
      checkAuth();
    } else if (token) {
      checkAuth();
      setIsAuth(true);
    }
  };

  useEffect(() => {
    gotoLogin();
  }, [isAuth]);

  const logOut = () => {
    setIsAuth(false);
    // Cookies.remove("accessToken");
    const cookieNames = Object.keys(Cookies.get());
    cookieNames.forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    // window.location.replace("https://dev.d285gbdoz6er7k.amplifyapp.com/");
    window.location.replace("http://localhost:3000");
  };

  if (!isAuth) {
    gotoLogin();
  } else {
    return (
      <div>
        <div className="app flexBox-sty">
          {isAuth && <Topbar logOut={logOut} />}
          <Sidebar />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/timetable" element={<Timetables />} />
            <Route exact path="/myattendance" element={<MyattendaceHome />} />
            <Route
              exact
              path="/studentattendance"
              element={<StudentAttendaceHome />}
            />

            {/* <Route exact path="/attendance" element={<Attendance />} /> */}
            <Route exact path="/subjects" element={<Subjects />} />
            <Route exact path="/exams" element={<Exams />} />
            <Route exact path="/results" element={<Results />} />
            <Route exact path="/profile" element={<Profiles />} />
            <Route exact path="/assignments" element={<Assignments />} />
            <Route exact path="/studymaterials" element={<Studys />} />
            <Route exact path="/holidays" element={<Holidays />} />
            <Route exact path="/announcement" element={<Announcement />} />
            <Route exact path="/meetings" element={<Meetings />} />
            <Route exact path="/resignation" element={<Resignation />} />
            <Route
              exact
              path="/staffAttendance"
              element={<StaffAttendance />}
            />
            <Route exact path="/syllabus" element={<Syllabus />} />
            <Route exact path="/createResult" element={<CreateResult />} />
            <Route exact path="/library" element={<Library />} />
            <Route exact path="/author/books" element={<Books />} />
            <Route exact path="/transport" element={<TransportHome />} />
            <Route exact path="/reimbursement" element={<Reimbursement />} />
            <Route exact path="/Edpedia-store" element={<EdpediaStore />} />
            <Route exact path="/Address" element={<Address />} />
            <Route exact path="/orders" element={<Orders />} />
            <Route exact path="/students" element={<Students />} />
            <Route exact path="/payslips" element={<Payslips />} />
            <Route exact path="/leaves" element={<LeavesManagement />} />
            <Route exact path="/homework" element={<Homework />} />
          </Routes>
        </div>
        <div>Hello</div>
      </div>
    );
  }
}

export default App;

//deployment

// import React, { useEffect, useState } from "react";
// import "./App.css";
// import { Routes, Route } from "react-router-dom";
// import { notification } from "antd";
// import { Stomp } from "@stomp/stompjs";
// import SockJS from "sockjs-client";
// import { checkAuth } from "./Utils/cookie-helper";
// import {
//   Home,
//   Results,
//   Timetables,
//   Exams,
//   Attendances,
//   Subjects,
//   Profiles,
//   Assignments,
//   Studys,
//   Holidays,
// } from "./pages";
// import { Sidebar, Topbar } from "./components";
// import Announcement from "./components/Announce/Announcement";
// import { staffServices } from "./Services/StaffServices";
// import Cookies from "js-cookie";
// import Meetings from "./components/OneOnOneMeeting/Meetings";
// import Resignation from "./components/HumanResource/Resignation/Resignation";
// import StaffAttendance from "./components/HumanResource/Attendance/UnlockRequest/UnlockRequest";
// import Syllabus from "./components/Syllabus/Syllabus";
// import CreateResult from "./components/Exam/CreateResults/CreateResults";
// import Library from "./components/LibraryManagement/Library";
// import Books from "./components/LibraryManagement/Books/Books";
// import TransportHome from "./components/Transport/TransportHome";
// // import EdpediaStoreHome from "./components/EdpediaStore/EdpediaStoreHome";
// import EdpediaStore from "./components/EdpediaStore/EdpediaStore";
// import Address from "./components/EdpediaStore/Address/UserAddress";
// import Orders from "./components/EdpediaStore/Orders/Orders";
// function App() {
//   const [isAuth, setIsAuth] = useState(false);

//   const [staffId, setStaffId] = useState(Cookies.get("userId"));
//   const branchCode = Cookies.get("branchCode");

//   useEffect(() => {
//     const socket = new SockJS("https://api.edpedia.co/v1.0/staff/ws");
//     const stompClient = Stomp.over(socket);

//     const connectCallback = (frame) => {
//       stompClient.subscribe(`/topic/exams/staff/${staffId}`, (greeting) => {
//         const response = JSON.parse(greeting.body);
//         notification.success({
//           message: (
//             <div>
//               <strong>{response.message}</strong>
//               <div style={{ textAlign: "end" }}>
//                 <p>From</p>
//                 <em>{response.sender}</em>
//               </div>
//             </div>
//           ),
//           // description: ,
//           duration: 10,
//           placement: "topRight",
//         });
//       });
//       stompClient.subscribe(
//         `/topic/${branchCode}/results/staff/${staffId}`,
//         (greeting) => {
//           const response = JSON.parse(greeting.body);
//           notification.success({
//             message: (
//               <div>
//                 <strong>{response.message}</strong>
//                 <div style={{ textAlign: "end" }}>
//                   <p>From</p>
//                   <em>{response.sender}</em>
//                 </div>
//               </div>
//             ),
//             // description: ,
//             duration: 10,
//             placement: "topRight",
//           });
//         }
//       );
//       stompClient.subscribe(
//         `/topic/${branchCode}/library/staff/${staffId}`,
//         (greeting) => {
//           const response = JSON.parse(greeting.body);
//           notification.success({
//             message: (
//               <div>
//                 <strong>{response.message}</strong>
//                 <div style={{ textAlign: "end" }}>
//                   <p>From</p>
//                   <em>{response.sender}</em>
//                 </div>
//               </div>
//             ),
//             // description: ,
//             duration: 10,
//             placement: "topRight",
//           });
//         }
//       );
//       stompClient.subscribe(
//         `/topic/${branchCode}/studentLeave/${staffId}`,
//         (greeting) => {
//           const response = JSON.parse(greeting.body);
//           notification.success({
//             message: (
//               <div>
//                 <strong>{response.message}</strong>
//                 <div style={{ textAlign: "end" }}>
//                   <p>From</p>
//                   <em>{response.sender}</em>
//                 </div>
//               </div>
//             ),
//             // description: ,
//             duration: 10,
//             placement: "topRight",
//           });
//         }
//       );
//     };

//     stompClient.connect({}, connectCallback);

//     // Cleanup function to disconnect the WebSocket when the component unmounts
//     return () => {
//       stompClient.disconnect();
//     };
//   }, [staffId]);

//   // const getToken = () => {
//   //   const token = Cookies.get("accessToken");
//   //   if (token) {
//   //     setIsAuth(true);
//   //   } else {
//   //     setIsAuth(false);
//   //     window.location.replace("https://dev.d285gbdoz6er7k.amplifyapp.com/");
//   //   }
//   // };

//   // const gotoLogin = () => {
//   //   let token = Cookies.get("accessToken");
//   //   if (token === null || token === "" || (token === undefined && !isAuth)) {
//   //     checkAuth();
//   //   } else if (token) {
//   //     checkAuth();
//   //     setIsAuth(true);
//   //   }
//   // };

//   // useEffect(() => {
//   //   gotoLogin();
//   // }, [isAuth]);

//   // const logOut = () => {
//   //   setIsAuth(false);
//   //   // Cookies.remove("accessToken");
//   //   const cookieNames = Object.keys(Cookies.get());
//   //   cookieNames.forEach((cookieName) => {
//   //     Cookies.remove(cookieName);
//   //   });
//   //   // window.location.replace("https://dev.d285gbdoz6er7k.amplifyapp.com/");
//   //   window.location.replace("http://localhost:3000");
//   // };

//   if (false) {
//     // gotoLogin();
//   } else {
//     return (
//       <div className="app flexBox-sty">
//         {true && <Topbar />}
//         <Sidebar />
//         <Routes>
//           <Route exact path="/" element={<Home />} />
//           <Route exact path="/timetable" element={<Timetables />} />
//           <Route exact path="/attendance" element={<Attendances />} />
//           <Route exact path="/subjects" element={<Subjects />} />
//           <Route exact path="/exams" element={<Exams />} />
//           <Route exact path="/results" element={<Results />} />
//           <Route exact path="/profile" element={<Profiles />} />
//           <Route exact path="/assignments" element={<Assignments />} />
//           <Route exact path="/studymaterials" element={<Studys />} />
//           <Route exact path="/holidays" element={<Holidays />} />
//           <Route exact path="/announcement" element={<Announcement />} />
//           <Route exact path="/meetings" element={<Meetings />} />
//           <Route exact path="/resignation" element={<Resignation />} />
//           <Route exact path="/staffAttendance" element={<StaffAttendance />} />
//           <Route exact path="/syllabus" element={<Syllabus />} />
//           <Route exact path="/createResult" element={<CreateResult />} />
//           <Route exact path="/library" element={<Library />} />
//           <Route exact path="/author/books" element={<Books />} />
//           <Route exact path="/transport" element={<TransportHome />} />
//           <Route exact path="/Edpedia-store" element={<EdpediaStore />} />
//           <Route exact path="/Address" element={<Address />} />
//           <Route exact path="/orders" element={<Orders />} />
//         </Routes>
//       </div>
//     );
//   }
// }

// export default App;
