import {
  CloseOutlined,
  DeleteOutlined,
  DislikeOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FolderOpenOutlined,
  LikeOutlined,
  LikeTwoTone,
  CheckCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Spin, Table } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import FileView from "../../../Utils/FileView";
import AddQuestionPaper from "../Upcoming/AddQuestionPaper";

const Present = ({
  data,
  selectedExam,
  loading,
  questionPaperData,
  questionPaperList,
}) => {
  const filteredExams = data[selectedExam] || [];
  const [fileData, setFileData] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [heading, setHeading] = useState(null);
  const [openquestionpaperModal, setUploadQuestionPaperModal] = useState(false);
  const [view, setView] = useState(false);

  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
      align: "center",
      sorter: (a, b) => a.examName.localeCompare(b.examName),
    },
    {
      title: "Class Name",
      dataIndex: "className",
      key: "className",
      align: "center",
      sorter: (a, b) => a.className.localeCompare(b.className),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
    },
    {
      title: "Exam Date",
      dataIndex: "examDate",
      key: "examDate",
      align: "center",
      sorter: (a, b) => {
        const dateA = new Date(a.examDate).getTime();
        const dateB = new Date(b.examDate).getTime();
        return dateA - dateB;
      },
    },
    {
      title: "Start Date",
      dataIndex: "startTime",
      key: "startTime",
      align: "center",
      sorter: (a, b) => a.startTime.localeCompare(b.startTime),
    },
    {
      title: "End Date",
      dataIndex: "endTime",
      key: "endTime",
      align: "center",
      sorter: (a, b) => a.endTime.localeCompare(b.endTime),
    },
    {
      title: "Question Paper",
      dataIndex: "questionPaper",
      key: "questionPaper",
      align: "center",
      render: (text, record) => {
        const matchedQuestionPaper = questionPaperData.find(
          (item) => item.examScheduleId === record.examTTId
        );
        console.log("matchedQuestionPaper", matchedQuestionPaper);

        if (matchedQuestionPaper) {
          return (
            <FilePdfOutlined
              style={{ marginLeft: 80, textAlign: "center" }}
              onClick={() => {
                downloadQuestionPaper(matchedQuestionPaper);
              }}
            />
          );
        }

        return " - ";
      },
    },

    {
      title: "Upload Question Paper",
      dataIndex: "uploadQuestionPaper",
      key: "uploadQuestionPaper",
      align: "center",
      render: (text, record) => (
        <Button
          onClick={() => handleEditQuestionPaper(record)}
          className="blue-button"
          icon={<UploadOutlined />}
        >
          Upload
        </Button>
      ),
    },
  ];

  const handleEditQuestionPaper = (record) => {
    setUploadQuestionPaperModal(true);

    const matchedQuestionPaper = questionPaperData.find(
      (item) => item.examScheduleId === record.examTTId
    );

    setRowData(matchedQuestionPaper);
  };

  const downloadQuestionPaper = (record) => {
    console.log("downloadQuestionPaper", downloadQuestionPaper);
    setView(true);
    setFileData(record?.questionPaper);
  };

  return (
    <div className="present-container">
      {loading ? (
        <Spin spinning={loading} className="loading-spinner" />
      ) : (
        <Table
          dataSource={filteredExams}
          columns={columns}
          showSorterTooltip={false}
          pagination={false}
        />
      )}
      <FileView view={view} setView={setView} fileData={fileData} />
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />

      <AddQuestionPaper
        rowData={rowData}
        setRowData={setRowData}
        openquestionpaperModal={openquestionpaperModal}
        setUploadQuestionPaperModal={setUploadQuestionPaperModal}
        questionPaperList={questionPaperList}
      />
    </div>
  );
};

export default Present;
