import React, { useEffect } from "react";
import "./TransportHome.scss";
import { Button, Card, Col, Row, Select, notification } from "antd";
import { useState } from "react";
import { transportService } from "../../Services/Transport";
import schoolImage from "../../assets/icons/school_img.jpg";
import { CheckCircleFilled, CaretRightOutlined, DislikeOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { dynamicErrorMsg } from "../../Constants/utilitis";
const { Option } = Select;
const TransportHome = () => {
  const [routeData, setRouteData] = useState([]);
  const [pickupPoint, setPickUpPoint] = useState([]);
  const [pickupPoints, setPickupPoints] = useState([]);
  const [transportDetailsData, setTransportDetailsData] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState(null);
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  useEffect(() => {
    const filteredPickupPoints =
      routeData.find((route) => route.routeId == selectedRoute)?.pickupPoints ||
      [];
    setPickupPoints(filteredPickupPoints);
  }, [routeData, selectedRoute]);
  const staffId = staffInfo?.staffId;
  const allRouteList = () => {
    transportService
      .getAllRoutes()
      .then((res) => {
        setRouteData(res?.data);
        setSelectedRoute(res?.data?.[0]?.routeId);
        // setSelectedPickupPoint(res?.data?.[0]?.pickupPoints?.id);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Routes")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  useEffect(() => {
    allRouteList();
  }, []);
  const allPickUpPointList = () => {
    transportService
      .getAllPickupPoints()
      .then((res) => {
        setPickUpPoint(res?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "PickUp Points")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  useEffect(() => {
    allPickUpPointList();
  }, []);
  const handleRouteChange = (value) => {
    const selectedRouteData = routeData?.find(
      (route) => route.routeId == value
    );

    if (selectedRouteData) {
      setPickupPoints(selectedRouteData.pickupPoints);
      setSelectedRoute(selectedRouteData.routeId);
      setTransportDetailsData(selectedRouteData.vehicles);
    }
  };
  const handleChangePickUpPoint = (value) => {
    setSelectedPickupPoint(value);
  };

  const onHandleSave = () => {
    const payload = {
      routeId: selectedRoute,
      pickupId: selectedPickupPoint,
      enrolledUsers: [staffId],
      userType: "Staff",
    };
    transportService
      .enrollTransport(payload)
      .then((response) => {
        notification.success({
          message: `Transport Enrolled Sucessfully`,
          placement: "bottomRight",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
        });
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <main>
        <div className="transport-container">
          <div className="transport-title">
            <h2 className="header-text ">Transport</h2>
            <div style={{ display: "flex", gap: 10 }}>
              <Select
                placeholder="Select a route"
                onChange={handleRouteChange}
                value={selectedRoute}
                style={{width:"220px"}}
              >
                {routeData?.map((route) => (
                  <Option key={route.routeId} value={route.routeId}>
                    {route.fromStop.charAt(0).toUpperCase() +
                      route.fromStop.slice(1)}{" "}
                    to&nbsp;
                     {route.toStop.charAt(0).toUpperCase() +
                      route.toStop.slice(1)}
                  </Option>
                ))}
              </Select>
              <Select
                placeholder="Select a pickup point"
                onChange={handleChangePickUpPoint}
                value={selectedPickupPoint}
                style={{width:"220px"}}
              >
                {pickupPoints?.map((pickupPoint) => (
                  <Option key={pickupPoint.id} value={pickupPoint.id}>
                    {pickupPoint.pickupName.charAt(0).toUpperCase() +
                      pickupPoint.pickupName.slice(1)}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="route-container">
            <h3 className="header-text ">Route Information</h3>

            <div className="trasportblock-main">
              {pickupPoints?.map((item, index) => (
                <>
                  <div className="trasportblock">
                    <Card className="transport-card">
                      <span className="name"> {item?.pickupName}</span>
                      <span>{item?.address}</span>
                      <span className="time">{item?.pickupTime}</span>
                      {/* <span>Total Strength : {item?.totalUsers}</span> */}
                    </Card>
                    {index !== pickupPoint.length - 1 && (
                      <>
                        <div className="horizontal-line"></div>
                        <CaretRightOutlined
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            marginTop: 10,
                            marginLeft: -8,
                          }}
                        />
                      </>
                    )}
                  </div>
                </>
              ))}
              {pickupPoints.length > 0 && (
                <img src={schoolImage} alt="school" className="school-imgage" />
              )}
            </div>
          </div>
          <Button className="submit-button save-button" onClick={onHandleSave}>
            Save
          </Button>
        </div>
      </main>
    </>
  );
};

export default TransportHome;
