import {
  EDIT_STAFF_URL,
  EDIT_ENDPOINT,
  GET_STAFF_EXAM,
  GET_STAFF_EXAM_ENDPOINT,
  FIND_TEACHING_STAFF,
  FIND_TEACHING_STAFF_ENDPOINT,
  VIEW_TEACHING_SUBJECTS,
  VIEW_TEACHING_SUBJECTS_ENDPOINT,
  GETTODAY_TIMETABLE,
  GETTODAY_TIMETABLE_END_PONT,
  VIEW_STAFF_LIST,
  GET_RESULT_DATA,
  TIMETABLE_CONFIG_DATA,
  TIMETABLE_CONFIG_DATA_ENDPOINT,
  STAFF_FIND,
  STAFF_FIND_ENDPOINT,
  GET_RESULT_DATA_ENDPOINT,
  GET_ALL_LIST,
  GET_ALL_SYLLABUS_ENDPOINTS,
  GET_CLASS_TIME_TABLE,
  STAFF_PROFILE_UPLOAD_ENDPOINT,
  STAFF_PROFILE_UPLOAD,
  TEACHING_SUBJECT_LIST,
  TEACHING_SUBJECT_LIST_ENDPOINT,
} from "../Constants/constants";

import { PostAPIRequest, GetAPIRequest } from "./Api";

const editStaff = (payLoad, staffId) => {
  return PostAPIRequest({
    url: EDIT_STAFF_URL + staffId + EDIT_ENDPOINT,
    data: payLoad,
  });
};

const findStaffDetails = (staffId) => {
  return GetAPIRequest({
    url: FIND_TEACHING_STAFF + staffId + FIND_TEACHING_STAFF_ENDPOINT,
  });
};
const getStaffList = () => {
  return GetAPIRequest({ url: VIEW_STAFF_LIST });
};

const teachingSubjectList = (staffId) => {
  return GetAPIRequest({
    url: VIEW_TEACHING_SUBJECTS + staffId + VIEW_TEACHING_SUBJECTS_ENDPOINT,
  });
};

const todayTimeTableList = (id) => {
  return GetAPIRequest({
    url: GETTODAY_TIMETABLE + id + GETTODAY_TIMETABLE_END_PONT,
  });
};
const getExamByStaffId = (id) => {
  return GetAPIRequest({
    url: GET_STAFF_EXAM + id + GET_STAFF_EXAM_ENDPOINT,
  });
};

const getClassTimetable = (params) => {
  return GetAPIRequest({
    url: GET_CLASS_TIME_TABLE,
    params,
  });
};

const getResultData = (params) => {
  return GetAPIRequest({
    url: GET_RESULT_DATA,
    params,
  });
};
const periodConfigData = (classId) => {
  return GetAPIRequest({
    url: TIMETABLE_CONFIG_DATA + classId + TIMETABLE_CONFIG_DATA_ENDPOINT,
  });
};
export const staffProfileUpload = (userId, payLoad) => {
  return PostAPIRequest({
    url: STAFF_PROFILE_UPLOAD + userId + STAFF_PROFILE_UPLOAD_ENDPOINT,
    data: payLoad,
  });
};
export const staffFind = (userId) => {
  return GetAPIRequest({ url: STAFF_FIND + userId + STAFF_FIND_ENDPOINT });
};

export const subjectList = (staffId) => {
  return GetAPIRequest({ url: TEACHING_SUBJECT_LIST + staffId + TEACHING_SUBJECT_LIST_ENDPOINT})
}

export const staffServices = {
  editStaff,
  findStaffDetails,
  getStaffList,
  teachingSubjectList,
  todayTimeTableList,
  getExamByStaffId,
  getResultData,
  getClassTimetable,
  periodConfigData,
  staffProfileUpload,
  subjectList
};
