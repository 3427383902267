import { PostAPIRequest,GetAPIRequest } from "./Api";
import {GET_STAFF_ATTENDANCE, GET_STAFF_ATTENDANCE_ENDPOINT, STAFF_CHECKIN,STAFF_CHECKIN_ENDPOINT,STAFF_CHECKOUT,STAFF_CHECKOUT_ENDPOINT} from "../Constants/constants"

const staffCheckIn = (staffId, payLoad) => {
    return PostAPIRequest({
        url: STAFF_CHECKIN + staffId + STAFF_CHECKIN_ENDPOINT,
        data:payLoad
    })
}

const staffCheckOut = (staffId, payLoad) => {
    return PostAPIRequest({
        url: STAFF_CHECKOUT + staffId + STAFF_CHECKOUT_ENDPOINT,
        data:payLoad
    })
}

const getStaffAttendance = (staffId) => {
    return GetAPIRequest({
        url : GET_STAFF_ATTENDANCE + staffId + GET_STAFF_ATTENDANCE_ENDPOINT
    })
}
export const staffAttendanceService = {
    staffCheckIn,
    staffCheckOut,
    getStaffAttendance
}