import {
  METTING_LIST,
  MEETING_LIST_ENDPOINT,
  REJECT_MEETING,
  ACCEPT_METTING,
  ACCEPT_MEETING_ENDPOINT,
  REJECT_MEETING_ENDPOINT,
  MEETING_VIEW,
  MEETING_VIEW_ENDPOINT,
} from "../Constants/constants";

import { PostAPIRequest, GetAPIRequest } from "./Api";

const rejectMetting = (staffId, params) => {
  return PostAPIRequest({
    url: REJECT_MEETING + staffId + REJECT_MEETING_ENDPOINT,
    params,
  });
};
const acceptMetting = (staffId, payload) => {
  return PostAPIRequest({
    url: ACCEPT_METTING + staffId + ACCEPT_MEETING_ENDPOINT,
    data: payload,
  });
};
const viewMeeting = (staffId) => {
  return GetAPIRequest({
    url: MEETING_VIEW + staffId + MEETING_VIEW_ENDPOINT,
  });
};

const meetingList = (id) => {
  return GetAPIRequest({ url: METTING_LIST + id + MEETING_LIST_ENDPOINT });
};

export const meetingServices = {
  meetingList,
  acceptMetting,
  rejectMetting,
  viewMeeting,
};
