import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_ENDPOINT,
  NOTIFICATION_UPDATE_READ_STATUS,
  NOTIFICATION_UPDATE_READ_STATUS_ENDPOINT,
} from "../Constants/constants";

import { GetAPIRequest, PostAPIRequest } from "./Api";

const getNotification = (id, params) => {
  return GetAPIRequest({
    url: GET_NOTIFICATION + id + GET_NOTIFICATION_ENDPOINT,
    params,
  });
};
const updateReadStatus = (id, payload) => {
  return PostAPIRequest({
    url:
      NOTIFICATION_UPDATE_READ_STATUS +
      id +
      NOTIFICATION_UPDATE_READ_STATUS_ENDPOINT,
    data: payload,
  });
};

export const notificationServices = {
  getNotification,
  updateReadStatus,
};
