import React, { useEffect, useState } from "react";
import "./WorkHostory.scss";
import {
  Button,
  Col,
  Modal,
  Row,
  Form,
  Space,
  message,
  notification,
} from "antd";
import { profileService } from "../../../Services/ProfileServices";
import moment from "moment";
import DatePickerFloatLabel from "../../Common/FloatLabel/DatePicker";
import InputFloatLabel from "../../Common/FloatLabel/Input";
import Cookies from "js-cookie";
import { LikeTwoTone } from "@ant-design/icons";
import FloatInputAll from "../../Common/FloatLabel/InputAll";

function WorkHistory() {
  const [experienceLength, setExperienceLength] = useState(null);
  const [experience, setExperience] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [PreviousEmployementForm] = Form.useForm();

  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    PreviousEmployementForm.resetFields();
  };
  const onFinish = (values) => {
    const payload = {
      joiningDate: moment(values.joiningDate).format("YYYY-MM-DD HH:mm:ss"),
      staffId: staffInfo?.staffId,
      instName: values.instName,
      designation: values.designation,
      experiencePeriod: values.experiencePeriod,
      reference1: values.reference1,
      reference2: values.reference2,
      responsibility: values.responsibility,
      workedTill: moment(values.workedTill).format("YYYY-MM-DD HH:mm:ss"),
    };

    const staffPreviousEmployementPostData = () => {
      profileService
        .createPreviousEmployement(payload)
        .then((response) => {
          if (response.status === 200) {
            getPreviousEmployment();
            setIsModalOpen(false);

            notification.success({
              message: `Previous Employment Created Sucessfully`,
              placement: "bottomRight",
              icon: (
                <LikeTwoTone
                  style={{
                    color: "#108ee9",
                  }}
                />
              ),
            });
            PreviousEmployementForm.resetFields();
          }
        })
        .catch((err) => console.log(err));
    };
    staffPreviousEmployementPostData();
  };

  const getPreviousEmployment = (params = {}) => {
    const staffIds = staffInfo?.staffId;
    params = { staffId: staffIds };
    profileService
      .findPreviousEmployementDetails(params)
      .then((response) => {
        if (response.status === 200) {
          setExperience(response?.data);
          // setExperienceLength(response?.data?.recordCount);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getPreviousEmployment();
  }, []);

  return (
    <>
      {experience?.length > 0 ? (
        <section className="work-history">
          <div className="add-container">
            <Button className="addbutton" onClick={showModal}>
              Add
            </Button>
          </div>
          {experience?.map((item) => {
            const startDate = new Date(item.joiningDate);
            const endDate = new Date(item.workedTill);
            const startMonth = startDate.toLocaleString("default", {
              month: "short",
            });
            const endMonth = endDate.toLocaleString("default", {
              month: "short",
            });
            const startYear = startDate.getFullYear();
            const endYear = endDate.getFullYear();
            const displayDate = `${startMonth} ${startYear} - ${endMonth} ${endYear}`;

            return (
              <>
                <div className="work-history-item card">
                  <div className="history-item">
                    <div className="work-history-headers">
                      <h3>{item.designation}</h3>
                      <h5>
                        {item.instName} | {displayDate}
                      </h5>
                      <p>{item.responsibility}</p>
                    </div>

                    <div className="work-history-subjects">
                      <h1>References</h1>
                      <div className="work-Subjectbtns">
                        <Button className="blue-button">
                          {item.reference1 === null ? "-" : item.reference1}
                        </Button>
                        <Button className="blue-button">
                          {item.reference2 === null ? "-" : item.reference2}
                        </Button>
                      </div>
                    </div>

                    <div className="work-performance">
                      <h1>Experience</h1>
                      <span className="flex-sty">
                        {item.experiencePeriod === 0
                          ? "Fresher"
                          : item.experiencePeriod === 1
                          ? "1 year"
                          : `${
                              item.experiencePeriod === null
                                ? "0"
                                : item.experiencePeriod
                            } years`}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </section>
      ) : (
        <>
          <div className="bg-container">
            <h1>You don't have any Experience, Add experience</h1>
            <div className="add-container">
              <Button className="addbutton" onClick={showModal}>
                Add
              </Button>
            </div>
          </div>
        </>
      )}
      <Modal
        title="Add Experience"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="employeement-div">
          <Form form={PreviousEmployementForm} onFinish={onFinish}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12}>
                <Form.Item name="instName">
                  <InputFloatLabel label="Institution Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="experiencePeriod">
                  <FloatInputAll label="Experience Period" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="reference1">
                  <InputFloatLabel label="Reference 1" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="reference2">
                  <InputFloatLabel label="Reference 2" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="responsibility">
                  <InputFloatLabel label="Responsibility" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="designation">
                  <InputFloatLabel label="Designation" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="joiningDate">
                  <DatePickerFloatLabel
                    showTime
                    label="Joining Date"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="workedTill">
                  <DatePickerFloatLabel
                    showTime
                    label="Worked Till"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} align="end">
                <Space direction="vertical" size="large">
                  <Button className="submit-button" htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Col>
              <Col span={12} size="large">
                <Space direction="vertical" size="large">
                  <Button className="newCancelButton" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default WorkHistory;
