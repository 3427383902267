import { PostAPIRequest, GetAPIRequest } from "./Api";
import {
  GET_ALL_LIST,
  EDIT_SYLLABUS,
  EDIT_SYLLABUS_ENDPOINT,
} from "../Constants/constants";
const syllabusList = (params) => {
  return GetAPIRequest({
    url: GET_ALL_LIST,
    params,
  });
};
const editSyllabus = (topicId, payLoad) => {
  return PostAPIRequest({
    url: EDIT_SYLLABUS + topicId + EDIT_SYLLABUS_ENDPOINT,
    data: payLoad,
  });
};
export const syllabusServices = {
  syllabusList,
  editSyllabus,
};
