import React from "react";
import { Topbar, Dashboard } from "../../components";
import SEO from "../../components/reuse";

const Home = () => {
  return (
    <main>
      {/* <Topbar /> */}
      <SEO title="home" />
      <Dashboard />
    </main>
  );
};

export default Home;
