import { Input, Carousel, Button, Radio, Popover } from "antd";
import "./EdpediaStore.scss";
import Bags from "../../assets/icons/bags.svg";
import Stationery from "../../assets/icons/stationery.svg";
import Toys from "../../assets/icons/toys.svg";
import TextBooks from "../../assets/icons/textBooks.png";
import Uniform from "../../assets/icons/Uniform.svg";
import StoreFilter from "../../assets/icons/storeFilter.svg";
import footWear from "../../assets/icons/footwear.svg";
import StoreCart from "../../assets/icons/storeCart.svg";
import CarousalImage1 from "../../assets/icons/carousalImage1.svg";
import CategoryList from "./CategoryList/CategoryList";
import SubCategoryList from "./CategoryList/SubCategoryList";
import { useState } from "react";
import {
  CloseCircleOutlined,
  ShoppingCartOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import CartProducts from "./Cart/CartProducts";
import CartWishlist from "./Cart/CartWishlist";
import { useNavigate } from "react-router-dom";
import { EdpediaStoreService } from "../../Services/EdpediaStoreService";
import { useEffect } from "react";
import { StoreHome } from "../../assets/icons";
import ProductsList from "./CategoryList/SubCategoryList";
// import {StoreHome} from "../../assets/Icons"
const contentStyle = {
  height: "300px",
  color: "#fff",
  width: "100%",
  lineHeight: "160px",
};
const EdpediaStore = () => {
  const [selected, setSelected] = useState("home");
  const [offer, setOffer] = useState(true);
  const [selectedOption, setSelectedOption] = useState("cart");
  const [cartClick, setCartClick] = useState(false);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [productsData, setProductsData] = useState([]);
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [hoveredCategoryId, setHoveredCategoryId] = useState(null);
  const [checkOutProduct, setCheckOutProduct] = useState(null);
  const onSubcategoryClick = (subcategoryId) => {
    setSelected("home")
    setCheckOutProduct(null)
    getProductsList(subcategoryId);
    setSelected(subcategoryId);
    setSelectedSubCategoryId(subcategoryId);
    setProductsData([]);
  };

  const getCategoryList = () => {
    EdpediaStoreService.getProductsCategoriesList()
      .then((response) => {
        setCategoryList(response.data);
      })
      .catch((error) => console.log(error));
  };

  const getSubcategoryList = (categoryId) => {
    EdpediaStoreService.findSubCategoryList(categoryId)
      .then((response) => {
        setSubcategoryList(response.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  useEffect(() => {
    if (hoveredCategoryId) {
      getSubcategoryList(hoveredCategoryId);
    }
  }, [hoveredCategoryId]);


  const homeNavigation = () => {
    setSelected("home");
    setCartClick(false);
  };
  const handleClsoe = () => {
    setOffer(false);
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const addressClick = () => {
    navigate("/address");
  };

  const ordersClick = () => {
    navigate("/orders");
  };

  const getProductsList = () => {
    EdpediaStoreService.getProductBySubCategoryId(selectedSubCategoryId)
      .then((res) => {
        setProductsData(res?.data);

      })
      .catch((err) => console.log());
  };

  useEffect(() => {
    getProductsList();
  }, [selectedSubCategoryId]);

  const handleCartClick = () => {
    setSelected("home");
    setCartClick(true);
  };

  return (
    <main className="main-bg" id="culture">
      <section className="edpedia-store">
        {offer && (
          <div className="header-title">
            <h1>
              Summer Sale For All Items - OFF 30%! <span>Shop Now</span>
              <CloseCircleOutlined
                onClick={handleClsoe}
                className="closeIcon"
              />
            </h1>
          </div>
        )}
        <div className="header-container">
          <h5>Edpedia Store</h5>
          <Input.Search
            className="store_search"
            placeholder="Search for item"
          />
          <div className="filter-container" onClick={addressClick}>
            {/* <img src={StoreFilter} alt="Filters" className="icon" /> */}
            <h5>Address</h5>
          </div>
          <div className="filter-container" onClick={ordersClick}>
            {/* <img src={StoreFilter} alt="Filters" className="icon" /> */}
            <h5>Orders</h5>
          </div>
          <div className="filter-container">
            <img src={StoreFilter} alt="Filters" className="icon" />
            <h5>Filters</h5>
          </div>
          <div className="filter-container" onClick={handleCartClick}>
            <img src={StoreCart} alt="Cart" className="icon" />
            <h5>Cart</h5>
          </div>
        </div>
        {/* <CategoryList
          setSelected={setSelected}
          setSelectedSubCategoryId={setSelectedSubCategoryId}
        /> */}
        <div className="category-container">
          <div
            className="category-item"
            style={{ backgroundColor: "#172b4d" }}
            onClick={homeNavigation}
          >
            <img src={StoreHome} alt="HomeIcon" className="img-icon" />
          </div>
          {categoryList?.map((category) => {
            return (
              <div className="category-item" key={category.id}>
                <Popover
                  placement="bottom"
                  title={category.categoryName}
                  content={
                    <div className="subcategory-container">
                      {subcategoryList.map((subcategory) => (
                        <h3
                          key={subcategory.id}
                          onClick={() => onSubcategoryClick(subcategory.id)}
                        >
                          {subcategory.subCategoryName}
                        </h3>
                      ))}
                    </div>
                  }
                  onMouseEnter={() => setHoveredCategoryId(category.id)}
                  onMouseLeave={() => setHoveredCategoryId(null)}
                >
                  <div className="buttonstore">
                    <img
                      src={Uniform}
                      alt="CategoryIcon"
                      className="img-icon"
                    />
                    <h3>{category.categoryName}</h3>
                  </div>
                </Popover>
              </div>
            );
          })}
        </div>
        {selected === "home" && cartClick === false && (
          <div>
            <Carousel
              autoplay
              autoplaySpeed={1000}
              style={{ width: 1150, height: 300 }}
            >
              <div>
                <img src={CarousalImage1} alt="HomeIcon" style={contentStyle} />
              </div>
              <div>
                <img src={CarousalImage1} alt="HomeIcon" style={contentStyle} />
              </div>
              <div>
                <img src={CarousalImage1} alt="HomeIcon" style={contentStyle} />
              </div>
              <div>
                <img src={CarousalImage1} alt="HomeIcon" style={contentStyle} />
              </div>
            </Carousel>
            <div className="dicount-container-main">
              <h1>Discounts For You</h1>
              <div className="discount-container">
                <div className="category-item">
                  <img src={Uniform} alt="HomeIcon" className="img-icon" />
                  <h3>Uniforms</h3>
                </div>
                <div className="category-item">
                  <img src={TextBooks} alt="HomeIcon" className="img-icon" />
                  <h3>Textbooks</h3>
                </div>
                <div className="category-item">
                  <img src={Stationery} alt="HomeIcon" className="img-icon" />
                  <h3>Stationery</h3>
                </div>
                <div className="category-item">
                  <img src={Bags} alt="HomeIcon" className="img-icon" />
                  <h3>Bags</h3>
                </div>
                <div className="category-item">
                  <img src={footWear} alt="HomeIcon" className="img-icon" />
                  <h3>Footwear</h3>
                </div>
                <div className="category-item">
                  <img src={Toys} alt="HomeIcon" className="img-icon" />
                  <h3>Toys and Greetings</h3>
                </div>
              </div>
            </div>
            <div
              className="dicount-container-main"
              style={{ marginBottom: 20 }}
            >
              <h1>Best Sports Wear</h1>
              <div className="discount-container">
                <div className="category-item">
                  <img src={Uniform} alt="HomeIcon" className="img-icon" />
                  <h3>Uniforms</h3>
                </div>
                <div className="category-item">
                  <img src={TextBooks} alt="HomeIcon" className="img-icon" />
                  <h3>Textbooks</h3>
                </div>
                <div className="category-item">
                  <img src={Stationery} alt="HomeIcon" className="img-icon" />
                  <h3>Stationery</h3>
                </div>
                <div className="category-item">
                  <img src={Bags} alt="HomeIcon" className="img-icon" />
                  <h3>Bags</h3>
                </div>
                <div className="category-item">
                  <img src={footWear} alt="HomeIcon" className="img-icon" />
                  <h3>Footwear</h3>
                </div>
                <div className="category-item">
                  <img src={Toys} alt="HomeIcon" className="img-icon" />
                  <h3>Toys and Greetings</h3>
                </div>
              </div>
            </div>
          </div>
        )}
        {selected !== "home" && (
          <ProductsList
            selectedSubCategoryId={selectedSubCategoryId}
            productsData={productsData}
            setProductsData={setProductsData}
            checkOutProduct={checkOutProduct}
            setCheckOutProduct={setCheckOutProduct}
          />
        )}
        {selected === "home" && cartClick === true && (
          <div>
            <Radio.Group
              defaultValue={selectedOption}
              buttonStyle="solid"
              onChange={handleOptionChange}
            >
              <Radio.Button value="cart">
                <ShoppingCartOutlined className="cart-icon" />
                Cart
              </Radio.Button>
              <Radio.Button value="wishlist">
                <HeartOutlined className="cart-icon" />
                Wishlist
              </Radio.Button>
            </Radio.Group>
            {selectedOption === "cart" && <CartProducts />}
            {selectedOption === "wishlist" && <CartWishlist />}
          </div>
        )}
      </section>
    </main>
  );
};

export default EdpediaStore;
