import React, { useEffect } from "react";
import "./RequestStudentAttendace.scss";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Table,
  notification,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DislikeOutlined,
  EditOutlined,
  EyeOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
import DatepickerFloatLabel from "../../../Common/FloatLabel/DatePicker";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import TextAreaFloatLabel from "../../../Common/FloatLabel/TextArea";
import SelectFloatLabel from "../../../Common/FloatLabel/Select";
import { createErrorMessage, updateErrorMessage, deleteErrorMessage, dynamicErrorMsg } from "../../../../Constants/utilitis";
import { attendanceService } from "../../../../Services/AttendanceService";
import { useState } from "react";
import Cookies from "js-cookie";
import _, { keys } from "lodash";
import dayjs from "dayjs";
const RequestStudentAttendace = () => {
  const [openModal, setOpenModal] = useState(false);
  const [studentAttendaceRequestForm] = Form.useForm();
  const [classOptions, setClassOptions] = useState(null);
  const [sectionOptions, setSctionOptions] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [todaytimeTableList, setTodaytimeTableList] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [editRequestData, setEditRequestData] = useState(null);
  const [periods, setPeriods] = useState([]);
  const { Option } = Select;
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;

  useEffect(() => {
    getStudentAttendaceRequest();
  }, []);

  const classInfo = Cookies.get("classInfo")
    ? JSON.parse(Cookies.get("classInfo"))
    : [];

  useEffect(() => {
    const classWithAssignment = classInfo?.filter(
      (item) => parseInt(item?.classId) == editRequestData?.classId
    );
    handleClassDropdown(classWithAssignment[0]?.className);
    const findperiods = periods.find(
      (item) => item.value == editRequestData?.periods
    );

    const dates =
      editRequestData?.date === undefined
        ? dayjs(new Date())
        : dayjs(editRequestData?.date, "YYYY-MM-DD");
    if (editRequestData?.id) {
      studentAttendaceRequestForm.setFieldsValue({
        status: editRequestData?.status,
        className: classWithAssignment[0]?.className,
        classId: classWithAssignment[0]?.classId,
        periods: periods?.values,
        date: dates,
      });
    } else {
      studentAttendaceRequestForm.resetFields();
    }
  }, [editRequestData, studentAttendaceRequestForm]);
  const getStudentAttendaceRequest = () => {
    attendanceService
      .StudentAttendaceRequestLists(staffId)
      .then((res) => {
        setRequestData(res?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Student Attendance")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const columns = [
    {
      title: "Class Name",
      dataIndex: "classId",
      key: "classId",
      align: "center",
      render: (classId) => {
        const foundClass = classInfo.find(
          (item) => parseInt(item.classId) == classId
        );
        return foundClass ? foundClass.className : "N/A";
      },
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Details",
      key: "details",
      dataIndex: "details",
      align: "center",
      render: (text, row) => (
        <Space>
          <Button
            className="full-details-button"
            onClick={(e) => handleEdit(row, e)}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handleEdit = (row) => {
    setEditRequestData(row);
    setOpenModal(true);
  };
  useEffect(() => {
    if (selectedSection !== null) {
      getPeriodAndSubject();
    }
  }, [selectedSection]);

  const handleClick = () => {
    setOpenModal(true);
  };
  const handleCancel = () => {
    setOpenModal(false);
    studentAttendaceRequestForm.resetFields();
    setEditRequestData(null);
  };

  const onFinish = (values) => {
    const payload = {
      ...values,
      staffId: staffId,
    };
    const id = editRequestData?.id;

    if (id) {
      attendanceService
        .editStudentAttendaceRequest(id, payload)
        .then((res) => {
          getStudentAttendaceRequest();
          setOpenModal(false);
          setEditRequestData(null);
          notification.success({
            message: `${values.date} Student Attendace Request Updated.`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          studentAttendaceRequestForm.resetFields();
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Update", "Student Attendance")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    } else {
      attendanceService
        .StudentAttendaceRequest(payload)
        .then((res) => {
          getStudentAttendaceRequest();
          setOpenModal(false);
          setEditRequestData(null);
          notification.success({
            message: `${values.date} Student Attendace Request Sumitted .`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
          studentAttendaceRequestForm.resetFields();
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Submit", "Student Attendance")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    }
  };
  const disabledDate = (current) => {
    return current && current > dayjs().startOf("day");
  };

  /*get section*/
  const getsectionOptionsFromClass = (className, classList) => {
    const filteredSection = classList?.filter(
      (item) => item.className === className
    );

    const getSection = filteredSection?.map((item) => {
      return {
        label: item.section,
        value: item.classId,
      };
    });
    return getSection ?? [];
  };

  useEffect(() => {
    const uniqueClasses = _.uniqBy(classInfo, "className").map((getClass) => {
      return { label: getClass.className, value: getClass.className };
    });
    setClassOptions(uniqueClasses);
  }, []);

  const onSectionChange = (value) => {
    setSelectedPeriod("");
    setSelectedSection(value);
  };
  const handleClassDropdown = (e) => {
    const sectionOptions = getsectionOptionsFromClass(e, classInfo);
    setSctionOptions(sectionOptions);
  };

  const handlePeriodChange = (value) => {
    setSelectedPeriod(value);
  };

  const getPeriodAndSubject = () => {
    attendanceService
      .todayTimeTableAttendanceList(staffId)
      .then((response) => {
        setTodaytimeTableList(response?.data?.todaySchedule);
        const data = response?.data?.todaySchedule;
        let period = [];
        let subject = [];
        Object.entries(data).forEach(([key, schedule]) => {
          if (schedule.classId == selectedSection?.classId) {
            period.push({ Period: `Period-${key}`, PeriodId: key });
          }
        });
        const periodsArray = Object.keys(data).map((period) => ({
          Period: `Period - ${period}`,
          value: period,
        }));
        setPeriods(periodsArray);
        setSelectedPeriod(subject[0]?.periodId);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Time Table")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };
  return (
    <>
      <div className="stuentAttendace_Request">
        <div className="header">
          <h3 className="header-text">Student Atendace Request</h3>
          <Button onClick={handleClick} className="submit-button request-btn">
            Request
          </Button>
        </div>
        <Table dataSource={requestData} columns={columns} pagination={false} />
      </div>
      <Modal
        open={openModal}
        onCancel={handleCancel}
        footer={null}
        title="Student Attendace"
      >
        <Form form={studentAttendaceRequestForm} onFinish={onFinish}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              <Form.Item name="date" rules={[{ required: true, message: "" }]}>
                <DatepickerFloatLabel
                  label=" Date"
                  format="DD-MM-YYYY"
                  disabledDate={disabledDate}
                  style={{ width: 230 }}
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="className"
                rules={[{ required: true, message: "" }]}
              >
                <SelectFloatLabel
                  dropdownStyle={{ height: "200px" }}
                  options={classOptions}
                  onChange={handleClassDropdown}
                  label="Select Class"
                  name="className"
                />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                rules={[{ required: true, message: "" }]}
                name="classId"
              >
                <SelectFloatLabel
                  onChange={onSectionChange}
                  value={selectedSection}
                  // options={sectionOptions}
                  name="classId"
                  label="Select Section"
                >
                  {sectionOptions?.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                rules={[{ required: true, message: "" }]}
                name="perods"
              >
                <Select
                  value={selectedPeriod}
                  onChange={handlePeriodChange}
                  placeholder="Periods"
                >
                  {periods.map((period) => (
                    <Option key={period.value} value={period.value}>
                      {period.Period}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default RequestStudentAttendace;
