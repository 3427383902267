import React, { useState, useEffect } from "react";
import HolidayCard from "../HolidayCard/HolidayCard";

const HolidayList = ({ holidays }) => {
  const [upcomingHolidays, setUpcomingHolidays] = useState([]);

  useEffect(() => {
    //only future dates will shows
    const futureHolidays = holidays.filter((holiday) => {
      const holidayDate = new Date(holiday.holidayDate);
      return holidayDate >= new Date();
    });

    //date wisely
    futureHolidays.sort((a, b) => {
      const dateA = new Date(a.holidayDate);
      const dateB = new Date(b.holidayDate);
      return dateA - dateB;
    });

    setUpcomingHolidays(futureHolidays);
  }, [holidays]);

  return (
    <div>
      <p className="upcoming-holiday">
        Upcoming Holidays : {upcomingHolidays.length}
      </p>
      {upcomingHolidays.map((holiday) => (
        <HolidayCard key={holiday.holidayId} holiday={holiday} />
      ))}
    </div>
  );
};

export default HolidayList;
