import { LATEST_ACTIVITY } from "../Constants/constants";
import { GetAPIRequest } from "./Api";

const getLatestActivity = () => {
   return GetAPIRequest({
    url:LATEST_ACTIVITY
   })
}

export const LatsetActivityService = {
getLatestActivity
}