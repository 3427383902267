import React, { useState } from "react";
import "./Holiday.scss";
import { Button, DatePicker, Input, Space, Table, Tabs, Radio } from "antd";
import { HolidaysList } from "./data";
import Holidays from "./HolidaysTabs/HolidaysTabs";
import LeavesUsageTabs from "../Holiday/LeavesUsage/LeavesUsageTabs";
import Leaves from "./Leaves/Leaves";
import StudentLeaves from "./StudentLeaves/StudentLeaves";
const RadioGroup = Radio.Group;

const Holiday = (props) => {
  const [holidays, setHolidays] = useState(HolidaysList);
  const [onHoverDate, setIsonHoverDate] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("Holidays");
  const [leaveTab, setLeaveTab] = useState("Leaves");

  const handleLeaveTabChange = (event) => {
    setLeaveTab(event.target.value);
  };

  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };

  return (
    <section id="dashboard" className="holiday-wrapper-block">
      <Holidays />
    </section>
  );
};

export default Holiday;
