import React, { useState } from "react";
import "./Profile.scss";
import {
  Button,
  Radio,
  Form,
  Row,
  Col,
  Modal,
  Space,
  Input,
  DatePicker,
  TimePicker,
  notification,
  Select,
} from "antd";
import {
  DeleteOutlined,
  LikeTwoTone,
  CheckCircleFilled,
} from "@ant-design/icons";
import SelectFloatLabel from "../Common/FloatLabel/Select";
import MyProfile from "./MyProfile/MyProfile";
import WorkHistory from "./WorkHistory/WorkHistory";
import Document from "./Documents/Document";
import BankingDetails from "./Banking/BankingDetails";
import InputFloatLabel from "../Common/FloatLabel/Input";
import { profileService } from "../../Services/ProfileServices";
import { useEffect } from "react";
import { subjectService } from "../../Services/SubjectService";
import Cookies from "js-cookie";
import Banking from "./Banking/Banking";
const Profile = () => {
  const RadioGroup = Radio.Group;
  const [isOpen, setIsOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("Profile");
  const [subjects, setSubjects] = useState([]);
  const [tutorForm] = Form.useForm();
  const subjectsInfo = Cookies.get("subjectsInfo")
    ? JSON.parse(Cookies.get("subjectsInfo"))
    : [];

  useEffect(() => {
    setSubjects();
  }, []);
  const handleSubjectChange = () => {};
  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };
  const showModal = () => {
    setIsOpen(true);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];

  const onFinish = (values) => {
    const availability = values.availability.map((item) => ({
      ...item,
      // startTime: new Date(`1970-01-01T${item.startTime}`).toISOString(),
      // endTime: new Date(`1970-01-01T${item.endTime}`).toISOString(),
    }));
    const payload = {
      ...values,
      tutorName: staffInfo.name,
      availability: availability,
      subjects: values.subjects,
    };
    profileService.EntrollForTutor(payload)
    .then((res) => {
      notification.success({
        message: "Tutor Created successfully",
        description: `Tutor has been updated with the name ${values.tutorName}`,
        icon: (
          <LikeTwoTone
            style={{
              color: "#108ee9",
            }}
          />
        ),
        duration: 5,
        placement: "bottomRight",
      });
      setIsOpen(false);
      tutorForm.resetFields();
    });
  };
  return (
    <article className="staff-profile">
      <div className="radioparent">
        <RadioGroup
          onChange={handleTabChange}
          value={activeTabKey}
          optionType="button"
          buttonStyle="solid"
          className="mainradio"
        >
          <Radio.Button value="Profile">MyProfile</Radio.Button>
          <Radio.Button value="History">Work History</Radio.Button>
          {/*<Radio.Button value="Documents">Education</Radio.Button>*/}
          <Radio.Button value="BankDetails">Bank Details</Radio.Button>
          <Radio.Button value="documents">Documents</Radio.Button>
        </RadioGroup>
        <div className="staffrightbutton">
          {/* <a
            href="staffAttendance"
            style={{
              textDecoration: "underline",
              fontSize: 14,
              marginRight: 15,
            }}
          >
            Request Attendance Unlock
          </a> */}

          <Button className="blue-button" onClick={showModal}>
            Enroll Tutor
          </Button>

          <a href="resignation">
            <Button className="blue-button">Resign</Button>
          </a>
        </div>
      </div>
      {activeTabKey === "Profile" && <MyProfile />}
      {activeTabKey === "History" && <WorkHistory />}
      {activeTabKey === "documents" && <Document />}
      {activeTabKey === "BankDetails" && <Banking />}

      <Modal
        title="Enroll As Tutor"
        visible={isOpen}
        onCancel={handleCancel}
        width={500}
        footer={null}
        centered
      >
        <Form onFinish={onFinish} form={tutorForm}>
          <Row>
            <Col span={24}>
              <Form.Item
                name="contactInfo"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label="Contact Information" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="subjects"
                rules={[{ required: true, message: "" }]}
              >
                <SelectFloatLabel label="Select Subjects" mode="multiple">
                  {subjectsInfo.map((subject) => (
                    <Select.Option key={subject} value={subject.subjectName}>
                      {subject.subjectName}
                    </Select.Option>
                  ))}
                </SelectFloatLabel>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="location"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label="Location" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="experience"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label="Experience" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.List name="availability">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Space
                        key={field.key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="start"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "startTime"]}
                          fieldKey={[field.fieldKey, "startTime"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <TimePicker format={"h:mm A"} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "endTime"]}
                          fieldKey={[field.fieldKey, "endTime"]}
                          rules={[
                            {
                              required: true,
                              message: "",
                            },
                          ]}
                        >
                          <TimePicker format={"h:mm A"} />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "day"]}
                          fieldKey={[field.fieldKey, "day"]}
                          rules={[{ required: true, message: "" }]}
                        >
                          <InputFloatLabel label="Day" />
                        </Form.Item>
                        <Button
                          type="danger"
                          onClick={() => remove(field.name)}
                          style={{
                            marginTop: 5,
                            color: "White",
                            backgroundColor: "#aa0b28",
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add Availability
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
            <Col span={24}>
              <Form.Item
                name={["priceInfo", "hourlyPrice"]}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label="Hourly Price" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={["priceInfo", "monthlyPrice"]}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <InputFloatLabel label="Monthly Price" />
              </Form.Item>
            </Col>
          </Row>
          <Row className="my-row" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button " htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
    </article>
  );
};

export default Profile;
