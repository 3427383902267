import { useEffect, useState } from "react";
import { staffServices } from "../../Services/StaffServices";
import Cookies from "js-cookie";
import { Select, Space, Table } from "antd";
import { attendanceService } from "../../Services/AttendanceService";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import BoyAvatar from "../../assets/images/Boy.png";
import GirlAvatar from "../../assets/images/girl.png";
import "./Students.scss";
import EditStudent from "./EditStudent.jsx/EditStudent";
const Students = () => {
  const [studentList, setStudentList] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [classes, setClasses] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [editStudentRow, setEditStudentRow] = useState(null);
  const { Option } = Select;
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;

  console.log("selectedSection", selectedSection);
  const getAllStudents = () => {
    const params = {
      className: selectedClass,
      section: selectedSection,
    };
    attendanceService
      .getAllStudents(params)
      .then((response) => {
        setStudentList(response.data.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (selectedSection !== null) {
      getAllStudents();
    }
  }, [selectedSection, selectedClass]);

  const staffFind = () => {
    staffServices
      .findStaffDetails(staffId)
      .then((response) => {
        if (response.data.classesInfo.length > 0) {
          const firstClass = response.data.classesInfo[0];
          setSelectedClass(firstClass.className);
          setSelectedSection(firstClass.section);
          setClasses(response.data.classesInfo);
        }
      })
      .catch((error) => console.log(error));
  };
  console.log("singh", selectedClass, selectedSection);
  useEffect(() => {
    staffFind();
  }, [staffId]);

  // const handleClassChange = (value) => {
  //   setSelectedClass(value);
  //   setSelectedSection(value);
  // };
  const handleClassChange = (value) => {
    setSelectedClass(value);
    const firstSection = classes.find((cls) => cls.className == value)?.section;

    if (firstSection) {
      setSelectedSection(firstSection);
    } else {
      setSelectedSection(null);
    }
  };

  const handleSectionChange = (value) => {
    setSelectedSection(value);
  };

  const uniqueClasses = classes?.reduce((acc, cur) => {
    if (!acc.includes(cur.className)) {
      acc.push(cur.className);
    }
    return acc;
  }, []);

  const editTableRow = (record) => {
    setIsEditing(true);
    setEditStudentRow(record);
  };

  const viewTableRow = (record) => {
    const studentId = record?.studentId;
    // navigate(`/studentdetails/${studentId}`);
  };

  const columns = [
    {
      title: "Roll No",
      dataIndex: "rollNo",
      key: "rollNo",
      // sorter: true,
      fixed: "left",
      // sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.rollNo - b.rollNo,
      render: (text, record) => (
        <div style={{ padding: "0px 20px" }}>
          <span onClick={() => viewTableRow(record)}>{text}</span>
        </div>
      ),
    },
    {
      title: "Student Name",
      dataIndex: "studentName",
      key: "firstName",
      fixed: "left",
      sorter: true,
      align: "center",

      defaultSortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
        <div className="table-cell">
          <div className="image-container">
            <img
              onClick={() => viewTableRow(record)}
              className="avatar-img"
              src={
                record?.imageUrl || record.gender === "Male"
                  ? BoyAvatar
                  : GirlAvatar
              }
              // src={``````````
              //   record.gender === "Male"
              //     ? "https://i.pravatar.cc/50?img=2"
              //     : "https://i.pravatar.cc/50?img=1"
              // }
              alt={record.gender}
            />
            <span
              className="student-name"
              style={{
                // textDecoration: "underline",
                color: "#1890ff",
                fontWeight: "bold",
              }}
              onClick={() => {
                // e.stopPropagation();
                viewTableRow(record);
              }}
            >
              {text}
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      align: "center",

      sortDirections: ["ascend", "descend"],
      render: (text) => (
        <div style={{ padding: "0px 20px" }}>
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              textTransform: "lowercase",
            }}
          >
            <a
              // href={`mailto:${text}`}
              href={`mailto:${text.toLowerCase()}`}
              style={{
                // textDecoration: "underline",
                color: "#1890ff",
                fontWeight: "bold",
              }}
            >
              {text}
            </a>
          </span>
        </div>
      ),
    },
    {
      title: "Parent Name",
      dataIndex: "fatherName",
      key: "fatherName",
      align: "center",
      sorter: true,

      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      fixed: "right",

      render: (_, record) => (
        <Space>
          <EditOutlined
            onClick={(e) => {
              e.stopPropagation();
              editTableRow(record, e);
            }}
          />
          <EyeOutlined
            style={{ color: "white", fontWeight: 1000 }}
            onClick={(e) => {
              e.stopPropagation();
              viewTableRow(record, e);
            }}
          />
          {/* <DeleteOutlined
            onClick={(e) => {
              e.stopPropagation();
              deleteTableRow(record, e);
            }}
          /> */}
        </Space>
      ),
    },
  ];

  return (
    <main>
      <section className="students-container">
        <div className="header-container">
          <h1 className="header-text ">Students</h1>
          <div className="dropdown-div">
            {" "}
            <Select
              placeholder="Select Class"
              onChange={handleClassChange}
              value={selectedClass}
              style={{ width: 110 }}
            >
              {uniqueClasses.map((className) => (
                <Option key={className} value={className}>
                  {console.log("classname", className)}
                  {className}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Select Section"
              onChange={handleSectionChange}
              value={selectedSection}
              style={{ width: 100 }}
            >
              {classes
                .filter((cls) => cls.className === selectedClass)
                .map((cls) => (
                  <Option key={cls.section} value={cls.section}>
                    {cls.section}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={studentList}
          showSorterTooltip={false}
          // onChange={onTableChange}
          rowKey={(record) => record.studentId}
          pagination={false}
        />
        <EditStudent
          editStudentRow={editStudentRow}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setEditStudentRow={setEditStudentRow}
          getAllStudents={getAllStudents}
        />
      </section>
    </main>
  );
};

export default Students;
