import "../MyAccount.scss";
import { useState, useEffect } from "react";
import { LibraryService } from "../../../../Services/LibraryService";
import bookImage from "../../../../assets/images/BookImage.jpg";
import EmptyComponent from "../../../Common/EmptyComponent/EmptyComponent";
import { Button, Col, Modal, Row, Space, Spin, notification } from "antd";
import { DislikeOutlined } from "@ant-design/icons";
import { dynamicErrorMsg } from "../../../../Constants/utilitis";
const RentBooks = () => {
  const [rentedBooks, setRentedBooks] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [missingRentalId, setMissingRentalId] = useState(null);
  const showModal = (rental) => {
    setOpenModal(true);

    setMissingRentalId(rental.rentalId);
  };

  const getBooksHistory = () => {
    const params = {
      userId: 3,
      userType: "Staff",
    };
    LibraryService.getBookHistory(params)
      .then((response) => {
        setRentedBooks(response?.data?.rented);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getBooksHistory();
  }, []);
  const handleCancel = () => {
    setOpenModal(false);
  };
  const handleSubmit = (values) => {
    const id = missingRentalId;

    LibraryService.missingBookReport(id)
      .then((response) => {
        // setMissingBook(response?.data?.returned);
        setOpenModal(false);
        setIsLoading(false);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Book Report")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
  };
  return (
    <div className="rent-cards-header">
      {rentedBooks === null ? (
        // <Spin size="medium" className="antd-spin" />
        <div className="empty-component">
          <EmptyComponent />
        </div>
      ) : rentedBooks.length === 0 ? (
        <>
          <EmptyComponent />
        </>
      ) : (
        <>
          {rentedBooks.map((rental) => {
            const { book } = rental;
            return (
              <div key={rental.rentalId} className="rent-card">
                <div className="left-side">
                  <img src={book.image} alt="CardImage" />
                </div>
                <div className="right-side">
                  <h4>{book?.bookName}</h4>
                  <p>{book.publisher}</p>
                  <p>Rent Date: {rental.rentalDate}</p>
                  <p>Due Date: {rental.dueDate}</p>
                  <Button
                    onClick={() => showModal(rental)}
                    className="missing-btn"
                  >
                    Missing Report
                  </Button>
                </div>
              </div>
            );
          })}
        </>
      )}

      <Modal
        title="Approve Request"
        open={openModal}
        footer={null}
        onCancel={handleCancel}
        className="approve-modal"
      >
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          align="center"
          className="modal-buttons-row"
        >
          <Col span={24}>
            <div className="approval-content">
              <p> Are you sure , you wants to send missing report ?</p>
            </div>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align="center">
          <Col span={12} size="large" align="end">
            <Space direction="vertical" size="large">
              <Button className="newCancelButton" onClick={handleCancel}>
                Cancel
              </Button>
            </Space>
          </Col>
          <Col span={12}>
            <Space direction="vertical" size="large">
              <Button
                className="cancel-button"
                htmlType="submit"
                onClick={handleSubmit}
              >
                Yes
              </Button>
            </Space>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default RentBooks;
