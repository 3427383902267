import React, { useState } from "react";
import { Button, Input, Modal, Space, Table, notification } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DislikeOutlined,
  LeftOutlined,
  LikeTwoTone,
  SaveOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { ResultService } from "../../../Services/ResultService";
import { createErrorMessage, updateErrorMessage, deleteErrorMessage, dynamicErrorMsg } from "../../../Constants/utilitis";

import { useEffect } from "react";
import { EditOutlined } from "@mui/icons-material";
import "./CreateResults.scss";
const CreateResult = () => {
  const [studentList, setStudentList] = useState(null);
  const [marksData, setMarksData] = useState([]);
  const [studentMarks, setStudentMarks] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editedMarks, setEditedMarks] = useState("");
  const [resultId, setResultId] = useState("");
  const [studentResultRecord, setStuddentResultRecord] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state;

  const onHandleBack = () => {
    navigate(-1);
  };

  const examResults = () => {
    const params = {
      classId: data?.classId,
      subjectId: data?.subjectId,
      examId: data?.examId,
    };

    ResultService.getClassSubjectResults(params).then((response) => {
      setStudentMarks(response.data);
    });
  };

  const getStudentsList = () => {
    ResultService.getStudentsList(data.classId).then((response) => {
      setStudentList(response.data);
      setMarksData(
        response?.data?.map((student) => ({
          studentId: student.studentId,
          securedMarks: 0,
        }))
      );
    });
  };

  useEffect(() => {
    getStudentsList();
    examResults();
  }, []);

  const handleMarksChange = (value, studentId) => {
    setMarksData((prevMarksData) => {
      return prevMarksData.map((marks) => {
        if (marks.studentId === studentId) {
          return { ...marks, securedMarks: value };
        }
        return marks;
      });
    });
  };

  const handleSave = () => {
    const payLoad = {
      examId: data.examId,
      subjectId: data.subjectId,
      classId: data.classId,
      marksData: marksData,
    };

    ResultService.createResult(payLoad)
      .then((response) => {
        notification.success({
          message: `${data.examName} Results Created`,
          placement: "bottomRight",
          icon: (
            <LikeTwoTone
              style={{
                color: "#108ee9",
              }}
            />
          ),
        });
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error,"Create","Result")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const columns = [
    {
      title: "Student Name",
      dataIndex: "studentName",
      key: "studentName",
      align: "center",
    },
    {
      title: "Marks",
      dataIndex: "securedMarks",
      key: "securedMarks",
      align: "center",
      render: (text, record) => (
        <div
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) =>
            handleMarksChange(e.target.textContent, record.studentId)
          }
        >
          {text}
        </div>
      ),
    },
  ];
  const handleEditMarks = (row) => {
    setStuddentResultRecord(row);
    setEditedMarks(row.securedMarks);
    setModalVisible(true);
  };

  const handleSaveMarks = () => {
    setModalVisible(false);
    const payload = {
      securedMarks: editedMarks,
    };
    ResultService.saveStudentResult(
      studentResultRecord?.resultId,
      payload
    )
    .then((response) => {
      examResults();
      notification.success({
        message: `${studentResultRecord?.studentName} Result Updated Sucessfully`,
        placement: "bottomRight",
        icon: (
          <LikeTwoTone
            style={{
              color: "#108ee9",
            }}
          />
        ),
      });
    })
    .catch((error) => {
      const errorMessage = dynamicErrorMsg(error, "Update" ,"Result")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
    })
  };

  const handleCancelEdit = () => {
    setModalVisible(false);
    setStuddentResultRecord(null);
  };

  const studentColumns = [
    {
      title: "Student Name",
      dataIndex: "studentName",
      key: "studentName",
      align: "center",
      width: 600,
    },
    {
      title: "Marks",
      dataIndex: "securedMarks",
      key: "securedMarks",
      align: "center",
      render: (text, row) => {
        const isEditing =
          modalVisible && studentResultRecord.studentId === row.studentId;

        return isEditing ? (
          <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
            <Input
              value={editedMarks}
              onChange={(e) => setEditedMarks(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "5px",
              }}
            >
              <Button onClick={handleSaveMarks} className="blue-button">
                <SaveOutlined />
              </Button>
              <Button className="closebtn" onClick={handleCancelEdit}>
                <CloseOutlined />
              </Button>
            </div>
          </div>
        ) : (
          text
        );
      },
    },
    {
      title: "Details",
      key: "details",
      dataIndex: "details",
      align: "center",
      width: 100,
      render: (text, row) => (
        <Space>
          <Button
            className="full-details-button"
            onClick={() => handleEditMarks(row)}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <main className="createresult-wrapper">
      {studentMarks && studentMarks.length > 0 ? (
        <div className="result-container">
          <div className="header-container">
            <div className="mainheader">
              <Button
                className="goback_button"
                type="primary"
                htmlType="submit"
                onClick={onHandleBack}
                style={{ background: "#172b4d" }}
              >
                <LeftOutlined />
              </Button>
              <h3 className="header-text">Student Results</h3>
            </div>
            <div className="resultright">
              <h3 className="header-text">Exam : {data.examName}</h3>
              <h3 className="header-text">Class : {data.className}</h3>
              <h3 className="header-text">Subject : {data.subject}</h3>
            </div>
          </div>
          <Table
            dataSource={studentMarks}
            columns={studentColumns}
            pagination={false}
          />
        </div>
      ) : (
        <div className="result-container" style={{ marginTop: 10 }}>
          <div
            className="header-container"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex" }}>
              <Button
                className="goback_button"
                type="primary"
                htmlType="submit"
                onClick={onHandleBack}
                style={{ background: "#172b4d" }}
              >
                <LeftOutlined />
              </Button>
              <h3>Create Result</h3>
            </div>
            <h3>Exam : {data.examName}</h3>
            <h3>Class : {data.className}</h3>
            <h3>Subject : {data.subject}</h3>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </div>
          <Table
            dataSource={studentList}
            columns={columns}
            pagination={false}
          />
        </div>
      )}
    </main>
  );
};

export default CreateResult;
