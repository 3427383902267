import { Radio } from "antd";
import React, { useState } from "react";
import Leaves from "../Holiday/Leaves/Leaves";
import LeavesUsageTabs from "../Holiday/LeavesUsage/LeavesUsageTabs";
import StudentLeaves from "../Holiday/StudentLeaves/StudentLeaves";
const RadioGroup = Radio.Group;
const LeavesManagement = () => {
  const [activeTabKey, setActiveTabKey] = useState("Leaves");

  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };
  return (
    <main>
      <section id="dashboard" className="holiday-wrapper-block">
        <div className="holidayradio">
          <RadioGroup
            onChange={handleTabChange}
            value={activeTabKey}
            optionType="button"
            buttonStyle="solid"
            className="mainradio"
          >
            <Radio.Button className="RadioButtons" value="Leaves">
              Staff Applied Leaves
            </Radio.Button>
            <Radio.Button className="RadioButtons" value="LeavesUsage">
              Staff Used Leaves
            </Radio.Button>
            <Radio.Button className="RadioButtons" value="studentLeaves">
              Student Leaves
            </Radio.Button>
          </RadioGroup>
        </div>

        {activeTabKey === "Leaves" && (
          <div>
            <Leaves />
          </div>
        )}

        {activeTabKey === "LeavesUsage" && (
          <div>
            <LeavesUsageTabs />
          </div>
        )}

        {activeTabKey === "studentLeaves" && (
          <div>
            <StudentLeaves />
          </div>
        )}
      </section>
    </main>
  );
};

export default LeavesManagement;
