import {CREATE_RESULT, GET_CLASSWISE_STUDENTS, GET_CLASSWISE_STUDENTS_ENDPOINT, GET_CLASS_SUBJECTWISE_RESULTS, SAVE_STUDENT_RESULT, SAVE_STUDENT_RESULT_ENDPOINT} from '../Constants/constants'
import { GetAPIRequest, PostAPIRequest } from './Api'

const createResult = (payLoad) => {
return PostAPIRequest({
    url:CREATE_RESULT,
    data:payLoad
})
}
const getStudentsList = (classId) => {
    return GetAPIRequest({
        url:GET_CLASSWISE_STUDENTS + classId + GET_CLASSWISE_STUDENTS_ENDPOINT
    })
}
const getClassSubjectResults = (params) => {
    return GetAPIRequest({
        url:GET_CLASS_SUBJECTWISE_RESULTS, params
    })
}

const saveStudentResult = (resultId, payLoad) => {
return PostAPIRequest({
    url:SAVE_STUDENT_RESULT + resultId + SAVE_STUDENT_RESULT_ENDPOINT,
    data:payLoad
})
}

export const ResultService = {
    createResult,
    getStudentsList,
    getClassSubjectResults,
    saveStudentResult
}