import React from "react";
import "./HolidayCard.scss";

const HolidayCard = ({ holiday }) => {
  // Set background color based on holiday type
  let backgroundColor;
  switch (holiday.holidayType) {
    case "Public":
      backgroundColor = "#ff6666";
      break;
    case "Private":
      backgroundColor = " #66cc00";
      break;
    case "bank":
      backgroundColor = "#8080ff";
      break;
    case "government":
      backgroundColor = "#c266ff";
      break;
    case "religious":
      backgroundColor = "#ffaa00";
      break;
    default:
      backgroundColor = "#684715";
  }

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "Asia/Kolkata",
    };
    return new Date(dateString).toLocaleDateString("en-IN", options);
  };

  return (
    <div className="holidayCard" style={{ backgroundColor }}>
      <p className="h3-text">{holiday.holidayName}</p>
      <p>{formatDate(holiday.holidayDate)}</p>
      <p>{holiday.holidayType}</p>
      <p>{holiday.observance}</p>
    </div>
  );
};

export default HolidayCard;
