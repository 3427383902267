import { CREATE_LEAVE_CALENDAR, EDIT_LEAVE_CALENDAR, EDIT_LEAVE_CALENDAR_ENDPOINT, LEAVE_CALENDAR_LIST } from "./../Constants/constants";
import { GetAPIRequest, PostAPIRequest } from "./Api";


const leaveCalendarList = ()=>{
    return GetAPIRequest({url: LEAVE_CALENDAR_LIST})
};

const createLeaveCalendar =(payload)=>{
    return PostAPIRequest({url:CREATE_LEAVE_CALENDAR, data:payload})
}
const editLeaveCalendar =(id, payload)=>{
    return PostAPIRequest({url:EDIT_LEAVE_CALENDAR +id+ EDIT_LEAVE_CALENDAR_ENDPOINT , data:payload})
}

export const leaveCalendarService ={
    leaveCalendarList,
    createLeaveCalendar,
    editLeaveCalendar
};
