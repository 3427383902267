import {
  GET_TODAYTIMETABLE_ATTENDANCE,
  GET_STUDENT_DETAILS,
  SAVE_ATTENDANCE,
  GET_ATTENDANCE_DETAILS,
  GET_TODAYTIMETABL_ATTENDANCE_ENDPOINT,
  STUDENT_ATTENDACE_REQUEST_CREATE,
  STUDENT_ATTENDACE_REQUEST_GET,
  STUDENT_ATTENDACE_REQUEST_GET_ENDPOINT,
  EDIT_STUDENT_ATTENDACE_REQUEST_ENDPOINT,
  EDIT_STUDENT_ATTENDACE_REQUEST,
  ATTENDANCE_TAKEN,
  SAVE_ATTENDANCE_ENDPOINT,
  GET_ALL_STUDENTS,
  STUDENT_ATTENDANCE_LIST,
  GENERATE_PAYSLIP,
  GENERATE_PAYSLIP_ENPOINT,
  STUDENT_ATTENDANCE_PUT_REQUEST,
  STUDENT_ATTENDANCE_PUT_REQUEST_MIDDLE_CONTENT,
  STUDENT_ATTENDANCE_PUT_REQUEST_END_POINT
} from "../Constants/constants";
import { GetAPIRequest, PostAPIRequest , PutAPIRequest} from "./Api";

export const getAttendenceDetails = (classId, params) => {
  return GetAPIRequest({
    url: STUDENT_ATTENDANCE_LIST.replace("{classId}", classId),
    params,
  });
};
export const getStudentDetails = (id) => {
  return GetAPIRequest({
    url: GET_STUDENT_DETAILS + id + "/getClassStudents",
  });
};

export const saveAttendance = (classAttendanceId, params, payload) => {
  return PostAPIRequest({
    url: SAVE_ATTENDANCE_ENDPOINT.replace(
      "{classAttendanceId}",
      classAttendanceId
    ),
    params,
    data: payload,
  });
};

export const todayTimeTableAttendanceList = (id) => {
  return GetAPIRequest({
    url:
      GET_TODAYTIMETABLE_ATTENDANCE +
      id +
      GET_TODAYTIMETABL_ATTENDANCE_ENDPOINT,
  });
};
export const StudentAttendaceRequest = (payload) => {
  return PostAPIRequest({
    url: STUDENT_ATTENDACE_REQUEST_CREATE,
    data: payload,
  });
};
export const StudentAttendaceRequestLists = (id) => {
  return GetAPIRequest({
    url:
      STUDENT_ATTENDACE_REQUEST_GET +
      id +
      STUDENT_ATTENDACE_REQUEST_GET_ENDPOINT,
  });
};
export const checkAttendanceTakenStatus = (params) => {
  return GetAPIRequest({
    url: ATTENDANCE_TAKEN,
    params,
  });
};
export const editStudentAttendaceRequest = (id, payload) => {
  return PostAPIRequest({
    url:
      EDIT_STUDENT_ATTENDACE_REQUEST +
      id +
      EDIT_STUDENT_ATTENDACE_REQUEST_ENDPOINT,
    data: payload,
  });
};

const getAllStudents = (params) => {
  return GetAPIRequest({
    url: GET_ALL_STUDENTS,
    params,
  });
};

export const generatePayslips = (id, params) => {
  return GetAPIRequest({
    url: GENERATE_PAYSLIP + id + GENERATE_PAYSLIP_ENPOINT,
    params,
  });
};

export const saveStudentAttendance = (classId, rollNumber, attendaceStatus, params) =>{
  return PutAPIRequest({
    url: STUDENT_ATTENDANCE_PUT_REQUEST + classId + STUDENT_ATTENDANCE_PUT_REQUEST_MIDDLE_CONTENT + rollNumber + STUDENT_ATTENDANCE_PUT_REQUEST_END_POINT + attendaceStatus,
    params
  })
}

export const attendanceService = {
  getAttendenceDetails,
  saveAttendance,
  todayTimeTableAttendanceList,
  getStudentDetails,
  StudentAttendaceRequest,
  StudentAttendaceRequestLists,
  editStudentAttendaceRequest,
  checkAttendanceTakenStatus,
  getAllStudents,
  generatePayslips,
  saveStudentAttendance
};
