import {
  VIEW_ASSIGNMENT_LIST,
  EDIT_ASSIGNMENT,
  EDIT_ASSIGNMENT_ENDPOINT,
  SUBMIT_ASSIGNMET,
  SUBMIT_ASSIGNMENT_ENDPOINT,
  FIND_ASSIGNMENT,
  FIND_ASSIGNMENT_ENDPOINT,
  ASSIGNMENT_LIST_URL_ENDPOINTS,
  DELETE_INDIVIDUAL_FILE,
  DELETE_INIDIVIDUAL_FILE_ENDPOINT,
  ASSIGNMENT_LIST,
  STUDENT_ASSIGNMENT_SCORE,
} from "../Constants/constants";
import { POST_ASSIGNMENT } from "../Constants/constants";
import { DeleteAPIRequest, GetAPIRequest } from "./Api"; //api call
import { PostAPIRequest } from "./Api";

const assignmentList = (id, params) => {
  return GetAPIRequest({
    url: ASSIGNMENT_LIST + id + ASSIGNMENT_LIST_URL_ENDPOINTS,
    params,
  });
};

const createAssignment = (payLoad) => {
  return PostAPIRequest({ url: POST_ASSIGNMENT, data: payLoad });
};

const submitedAssignments = (userId) => {
  return GetAPIRequest({
    url: SUBMIT_ASSIGNMET + userId + SUBMIT_ASSIGNMENT_ENDPOINT,
    // data: payLoad,
  });
};
const findAssignment = (id) => {
  return GetAPIRequest({
    url: FIND_ASSIGNMENT + id + FIND_ASSIGNMENT_ENDPOINT,
  });
};
const editAssignment = (id, payLoad) => {
  return PostAPIRequest({
    url: EDIT_ASSIGNMENT + id + EDIT_ASSIGNMENT_ENDPOINT,
    data: payLoad,
  });
};

const deleteIndividualFile = (id, payLoad) => {
  return DeleteAPIRequest({
    url: DELETE_INDIVIDUAL_FILE + id + DELETE_INIDIVIDUAL_FILE_ENDPOINT,
    data: payLoad,
  });
};
const updateStudentAssignmentScore = (payLoad) => {
  return PostAPIRequest({
    url: STUDENT_ASSIGNMENT_SCORE,
    data: payLoad,
  });
};

export const AssignmentApiService = {
  createAssignment,
  assignmentList,
  editAssignment,
  findAssignment,
  submitedAssignments,
  deleteIndividualFile,
  updateStudentAssignmentScore,
};
