import React from "react";
import {
  LikeTwoTone,
  UploadOutlined,
  CheckCircleFilled,
  DislikeOutlined
} from "@ant-design/icons";
import { useState, useEffect } from "react";
import "./CreateResignation.scss";
import {
  Button,
  Input,
  Form,
  Select,
  DatePicker,
  Row,
  Col,
  Modal,
  message,
  Space,
  Upload,
  Radio,
  Table,
  notification,
} from "antd";
import Cookies from "js-cookie";
import InputFloatLabel from "../../../Common/FloatLabel/Input";
import SelectFloatLabel from "../../../Common/FloatLabel/Select";
import { hrRequestServices } from "../../../../Services/HumanResourceService";
import TextAreaFloatLabel from "../../../Common/FloatLabel/TextArea";
import { createErrorMessage, dynamicErrorMsg, updateErrorMessage } from "../../../../Constants/utilitis";
const CreateResigation = ({
  isOpenModal,
  setIsOpenModal,
  loadResignationData,
  editRowData,
  setEditRowData,
  setViewModalOpen,
  ViewModalOpen,
  setResignationList,
}) => {
  const [resignationForm] = Form.useForm();
  const [fileList1, setFileList1] = useState([]);

  const cancelViewModal = () => {
    setViewModalOpen(false);
    setEditRowData(null);
  };
  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];

  const handleCancel = () => {
    setIsOpenModal(false);
    resignationForm.resetFields("");
    setFileList1([]);
    setEditRowData(null);
  };

  const handleUploadPDF = ({ fileList }) => {
    let files = fileList.map((file) => {
      return file.originFileObj;
    });
    setFileList1(files);
  };

  useEffect(() => {
    const id = staffInfo?.staffId;
    const rowData = editRowData?.[0];

    if (id) {
      resignationForm.setFieldsValue({
        reason: rowData?.reason,
      });
      if (editRowData?.resLetter) {
        const file = new File([], editRowData.resLetter);
        setFileList1([file]);
      } else {
        setFileList1([]);
      }
    } else {
      resignationForm.resetFields();
      setFileList1([]);
    }
  }, [editRowData, resignationForm]);

  const onFinish = (values) => {
    const formData = new FormData();
    formData.append("reasonForResignation", values.reason);
    if (fileList1.length > 0) {
      fileList1.map((file, i) => {
        formData.append(`resLetter`, file);
      });
    }
    const id = staffInfo?.staffId;
    hrRequestServices.CreateResignation(id, formData)
    .then((res) => {
      setIsOpenModal(false);
      // setResignationList(res);
      loadResignationData();
      notification.success({
        message: `${staffInfo?.name} Resignation Created Successfully`,
        placement: "bottomRight",
        icon: (
          <LikeTwoTone
            style={{
              color: "#108ee9",
            }}
          />
        ),
      });
    })
    .catch((error) => {
      const errorMessage = dynamicErrorMsg(error, "Create", "Resignation")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
    })
    if (id) {
      hrRequestServices
        .editResignation(id, formData)
        .then((response) => {
          setIsOpenModal(false);
          message.success("Edit study material successfully");
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Update", "Resignation")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    } else {
      hrRequestServices
        .CreateResignation(id, formData)
        .then((res) => {
          setIsOpenModal(false);
          message.success("Create Resignation successfully");
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error,"Create", "Resignation")
        
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    }
  };
  const columns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const data = [
    {
      field: "Staff Name",
      value: editRowData?.staffName,
    },
    {
      field: "Resignation Date",
      value: editRowData?.resDate,
    },
    {
      field: "Status",
      value: editRowData?.status,
    },
    {
      field: "Reason",
      value: editRowData?.reason,
    },
    {
      field: "Last Date",
      value: editRowData?.lastDate,
    },
  ];

  return (
    <>
      <Modal
        className="studymaterial-modal"
        title={editRowData !== null ? "Edit Resignation" : "Create Resignation"}
        centered
        open={isOpenModal}
        width={500}
        footer={null}
        onCancel={handleCancel}
      >
        {/* <AiFillDelete /> */}
        <Form
          form={resignationForm}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //  onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={24}>
              <Form.Item
                name="reason"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <TextAreaFloatLabel
                  label="Reason for Resign"
                  // id="inputs"
                  rows={8}
                  maxLength={100}
                  autoSize={{ minRows: 4, maxRows: 5 }}
                />
              </Form.Item>
            </Col>

            <Col className="gutter-row" span={24}>
              <Form.Item
                name="resLetter"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
              >
                <Upload
                  fileList={fileList1}
                  onChange={handleUploadPDF}
                  listType="picture"
                  accept=".jpg, .jpeg, .png ,.docx"
                  multiple
                  id="inputs"
                >
                  <Button icon={<UploadOutlined />} style={{ width: "465px" }}>
                    Upload Resignation Letter
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} align="end">
              <Space direction="vertical" size="large">
                <Button className="submit-button" htmlType="submit">
                  Save
                </Button>
              </Space>
            </Col>
            <Col span={12} size="large">
              <Space direction="vertical" size="large">
                <Button className="newCancelButton" onClick={handleCancel}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Modal>
      {/* <Modal
        open={ViewModalOpen}
        title="Resignation Details"
        onCancel={cancelViewModal}
        footer={null}
        className="resignation-View-modal"
      >
        {editRowData && (
          <div>
            <div className="flex-div">
              <span>StaffName :</span> <p>{editRowData.staffName}</p>
            </div>
            <div className="flex-div">
              <span>Resignation Date :</span> <p>{editRowData.resDate}</p>
            </div>
            <div className="flex-div">
              <span>Status :</span>
              <p>{editRowData.status}</p>
            </div>
            <div className="flex-div">
              <span>Reason: </span> <p>{editRowData.reason}</p>
            </div>

            <div className="flex-div">
              <span>Last Date :</span> <p>{editRowData.lastDate}</p>
            </div>
          </div>
        )}
      </Modal> */}
      <Modal
        open={ViewModalOpen}
        title="Resignation Details"
        onCancel={cancelViewModal}
        footer={null}
        className="resignation-View-  modal"
      >
        {editRowData && (
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            showHeader={false}
          />
        )}
      </Modal>
    </>
  );
};

export default CreateResigation;
