import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  Spin,
  Modal,
  Radio,
  Row,
  Col,
  Card,
  Table,
} from "antd";
import "./Announcement.scss";
import { AnnouncementService } from "../../Services/AnnouncementService";
import EmptyComponent from "../Common/EmptyComponent/EmptyComponent";
const Announcement = () => {
  const [loading, setLoading] = useState(true);
  const [annonceData, setAnnounceData] = useState([]);
  const [upcomingAnnouncements, setUpcomingAnnouncements] = useState([]);
  const [previousAnnouncements, setPreviousAnnouncement] = useState([]);
  const [announceReloadedData, setAnnounceReloadedData] = useState([]);
  const [allData, setallData] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("All");
  const [viewModal, setViewModal] = useState(false);
  const [announcement, setAnnouncment] = useState([]);
  const RadioGroup = Radio.Group;

  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };
  const [selectedMonth, setSelectedMonth] = useState(null);

  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  const year = new Date().getFullYear();

  useEffect(() => {
    setSelectedMonth(`${currentMonth}-${year}`);
  }, [currentMonth]);

  useEffect(() => {
    announceTableData();
  }, [announceReloadedData]);

  useEffect(() => {
    if (selectedMonth) {
      const filteredData = allData.filter((announcement) => {
        const announcementDate = new Date(announcement?.announcementDate);
        const selectedMonthValue = new Date(selectedMonth);
        return (
          announcementDate.getMonth() === selectedMonthValue.getMonth() &&
          announcementDate.getFullYear() === selectedMonthValue.getFullYear()
        );
      });

      setAnnounceData(filteredData);
      // Categorize the filtered data into "Upcoming" and "Previous"
      divideDate(filteredData);
    } else {
      // If no month selected, categorize all data
      divideDate(allData);
    }
  }, [selectedMonth, allData]);

  const filterAnounceMonth = (value) => {
    if (value) {
      const year = new Date().getFullYear();
      const month = value.length === 1 ? `0${value}` : value;
      const formattedMonth = `${year}-${month}`;
      setSelectedMonth(formattedMonth);
    } else {
      setSelectedMonth(null);
    }
  };

  /* announcement List API */
  const announceTableData = () => {
    AnnouncementService.listAnnouncements()
      .then((response) => {
        setallData(response?.data?.data); // Set all data
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleCancelViewModal = () => {
    setViewModal(false);
  };

  const handleOpenViewModal = (item) => {
    setViewModal(true);
    setAnnouncment(item);
  };

  function divideDate(announcement) {
    const currentDate = new Date();
    const olderEvents = [];
    const newerEvents = [];

    announcement.forEach((event) => {
      const announcementDate = new Date(event.announcementDate);

      if (announcementDate < currentDate) {
        olderEvents.push(event);
      } else {
        newerEvents.push(event);
      }
    });

    setPreviousAnnouncement(olderEvents);
    setUpcomingAnnouncements(newerEvents);
  }

  const isPrevious = (id) => {
    return previousAnnouncements.some((announcement) => announcement.id === id);
  };
  const isUpcoming = (id) => {
    return upcomingAnnouncements.some((announcement) => announcement.id === id);
  };

  const isToday = (id) => {
    return !isPrevious(id) && !isUpcoming(id);
  };

  const DescriptionComponent = ({ description }) => {
    const strippedText = () => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = description;
      return tempDiv.innerText;
    };

    return <div>{strippedText()}</div>;
  };

  const data = [
    {
      field: "Title",
      value: announcement?.title,
    },
    // {
    //   field: "Type",
    //   value: announcement?.type,
    // },
    {
      field: "Announcment Date",
      value: announcement?.announcementDate,
    },
    {
      field: "Staff Type",
      value: announcement?.staffType,
    },
    {
      field: "Target Class",
      value: announcement?.targetClass,
    },
    {
      field: "Audience",
      value: announcement?.audience,
    },
  ];
  const columns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];
  const noDataFunction = () => {
    setLoading(false);
  };

  return (
    <main>
      <article className="announcment-container">
        <div className="headmainann">
          <div className="annheader">
          <h2>Announcements</h2>
           
          </div>
         
          <div className="select-option">

          <RadioGroup
              onChange={handleTabChange}
              value={activeTabKey}
              optionType="button"
              buttonStyle="solid"
            >
              <Radio.Button value="All">All</Radio.Button>
              <Radio.Button value="Previous">Previous</Radio.Button>
              <Radio.Button value="Upcoming">Upcoming</Radio.Button>
            </RadioGroup>
            <Select
              size="large"
              showSearch
              onChange={filterAnounceMonth}
              style={{ width: 150, textAlign: "center" }}
              placeholder="Months"
              value={selectedMonth}
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={[
                { value: "January", label: "January" },
                { value: "February", label: "February" },
                { value: "March", label: "March" },
                { value: "April", label: "April" },
                { value: "May", label: "May" },
                { value: "June", label: "June" },
                { value: "July", label: "July" },
                { value: "August", label: "August" },
                { value: "September", label: "September" },
                { value: "October", label: "October" },
                { value: "November", label: "November" },
                { value: "December", label: "December" },
              ]}
            />
          </div>
        </div>
        {activeTabKey === "All" && (
          <div>
            {loading ? (
              <>
                <Spin size="midium" className="antd-spin" />
                <p>{noDataFunction()}</p>
              </>
            ) : (
              <>
                {annonceData.length === 0 ? (
                  <>
                    <EmptyComponent />
                  </>
                ) : (
                  <>
                    <Row gutter={[16, 16]}>
                      {annonceData &&
                        annonceData?.map((item, index) => {
                          const date = new Date(item.announcementDate);
                          const formattedDate = date.toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          );
                          let statusLabel = "";

                          if (isUpcoming(item.id)) {
                            statusLabel = "Upcoming";
                          } else if (isPrevious(item.id)) {
                            statusLabel = "Previous";
                          } else if (isToday(item.id)) {
                            statusLabel = "Today";
                          }
                          return (
                            <Col span={12} key={index}>
                              <Card
                                key={index}
                                className="annuncement-card"
                                hoverable
                              >
                                <small>{statusLabel}</small>

                                <div className="ann-head"
                                 
                                >
                                  <div className="datetime">
                                    <p>
                                      {formattedDate}
                                    </p>
                                    <p>{date.toLocaleTimeString()}</p>
                                  </div>
                                  <div className="ann-content"
                                    
                                  >
                                    <div
                                    
                                    >
                                      <h1 style={{ color: "black" }}>
                                        {item.title}
                                      </h1>
                                    
                                    </div>
                                    <DescriptionComponent
                                      description={item.description}
                                    />
                                      <Button
                                        className="submit-button"
                                        onClick={() =>
                                          handleOpenViewModal(item)
                                        }
                                      >
                                        View More
                                      </Button>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                  </>
                )}
              </>
            )}
          </div>
        )}
        {activeTabKey === "Previous" && (
          <div>
            {loading ? (
              <Spin size="midium" className="antd-spin" />
            ) : (
              <>
                {previousAnnouncements.length === 0 ? (
                  <>
                    {" "}
                    <EmptyComponent />
                  </>
                ) : (
                  <>
                    <Row gutter={[16, 16]}>
                      {previousAnnouncements &&
                        previousAnnouncements.map((item, index) => {
                          const date = new Date(item.announcementDate);
                          const formattedDate = date.toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          );
                          return (
                            <Col span={12} key={index}>
                              <Card key={index} hoverable>
                              <div className="ann-head">
                                  <div className="datetime">
                                    <p
                                    >
                                      {formattedDate}
                                    </p>
                                    <p>{date.toLocaleTimeString()}</p>
                                  </div>
                                  <div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <h1 style={{ color: "black" }}>
                                        {item.title}
                                      </h1>
                                  
                                    </div>
                                    <DescriptionComponent
                                      description={item.description}
                                    />
                                        <Button
                                        className="submit-button"
                                        onClick={() =>
                                          handleOpenViewModal(item)
                                        }
                                      >
                                        View More
                                      </Button>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                  </>
                )}
              </>
            )}
          </div>
        )}
        {activeTabKey === "Upcoming" && (
          <div>
            {loading ? (
              <Spin size="midium" className="antd-spin" />
            ) : (
              <>
                {upcomingAnnouncements.length === 0 ? (
                  <>
                    <EmptyComponent />
                  </>
                ) : (
                  <>
                    {" "}
                    <Row gutter={[16, 16]}>
                      {upcomingAnnouncements &&
                        upcomingAnnouncements.map((item, index) => {
                          const date = new Date(item.announcementDate);
                          const formattedDate = date.toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          );
                          return (
                            <Col span={12} key={index}>
                              <Card key={index} hoverable>
                                <div className="ann-head">
                                  <div className="datetime">
                                    <p>
                                      {formattedDate}
                                    </p>
                                    <p>{date.toLocaleTimeString()}</p>
                                  </div>
                                  <div
                                    
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <h1 style={{ color: "black" }}>
                                        {item.title}
                                      </h1>
                                      
                                    </div>
                                    <DescriptionComponent
                                      description={item.description}
                                    />
                                    <Button
                                        className="submit-button"
                                        onClick={() =>
                                          handleOpenViewModal(item)
                                        }
                                      >
                                        View More
                                      </Button>
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          );
                        })}
                    </Row>
                  </>
                )}
              </>
            )}
          </div>
        )}
        <Modal
          open={viewModal}
          title="Announcement Details"
          onCancel={handleCancelViewModal}
          footer={null}
          centered
          className="view-assignment"
        >
          {viewModal && (
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              showHeader={false}
            />
          )}
        </Modal>
      </article>
    </main>
  );
};
export default Announcement;
