import { Table } from "antd";
import "../Result.scss";
const ScoreCardTable = ({ scoreCard }) => {
  const dataSource = scoreCard.split("\n").map((row, index) => {
    const [subject, marks] = row.split(": ");
    return { key: index, subject, marks };
  });

  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      align: "center",
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",
      align: "center",
    },
  ];

  return <Table dataSource={dataSource} columns={columns} pagination={false} />;
};

export default ScoreCardTable;
