import { Button, Col, Form, Modal, Row, Space } from "antd";
import React from "react";
import FloatInput from "../../Common/FloatLabel/Input";
import "./AddressForm.scss";
const AddressForm = ({ setShowAddressModal, showAddressModal }) => {
  const handleCancel = () => {
    setShowAddressModal(false);
  };
  return (
    <>
      {/* /checkout form/ */}
      <Modal
        title="Enter Address Details"
        open={showAddressModal}
        onCancel={handleCancel}
        footer={null}
        width={500}
        centered
        className="checkout-modal"
      >
        <div className="checkout-form">
          <div className="from-header">
            <h4>Autofill by current location</h4>
            <Button className="defaul-btn">Auto Fill</Button>
          </div>
          <Form>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24} style={{ marginBottom: "16px" }}>
                <FloatInput label="Full Name " className="input-float" />
              </Col>
              <Col span={24} style={{ marginBottom: "16px" }}>
                <FloatInput label="Mobile Number " />
              </Col>
              <Col span={24} style={{ marginBottom: "16px" }}>
                <FloatInput label="Pincode " />
              </Col>
              <Col span={24} style={{ marginBottom: "16px" }}>
                <FloatInput label="Flat no, House no, Building, Apartment Name" />
              </Col>
              <Col span={24} style={{ marginBottom: "16px" }}>
                <FloatInput label="Area, Sector, Street, Village" />
              </Col>
              <Col span={24} style={{ marginBottom: "16px" }}>
                <FloatInput label="Landmark" />
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={12} style={{ marginBottom: "16px" }}>
                <FloatInput label="Town / City " />
              </Col>
              <Col span={12} style={{ marginBottom: "16px" }}>
                <FloatInput label="State " />
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={24} align="end">
                <Space direction="vertical" size="large">
                  <Button className="submitButton" htmlType="submit">
                    Auto Fill
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddressForm;
