import React, { useEffect, useState } from "react";
import {
  Card,
  Image,
  Input,
  Button,
  Select,
  Row,
  Col,
  Empty,
  notification,
} from "antd";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
import GirlUniform from "../../../assets/icons/girlUniform.svg";
import { BiRupee } from "react-icons/bi";
import "./CartProducts.scss";
import { EdpediaStoreService } from "../../../Services/EdpediaStoreService";
const { Option } = Select;

const CartWishlist = () => {
  const [wishlistData, setWishlistData] = useState([]);
  useEffect(() => {
    getwishlistData();
  }, []);
  const getwishlistData = () => {
    EdpediaStoreService.getWishlistData("EDBIN1001")
      .then((res) => {
        setWishlistData(res?.data);
      })
      .catch((err) => console.log(err));
  };
  const handleMovetoCart = (item) => {};
  const handleRemoveFromWishList = (item) => {
    const productId = item?.productId;
    EdpediaStoreService.removeFromWishlist(productId)
      .then((res) => {
        notification.success({
          message: "Remove from wishlist",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
        getwishlistData();
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="cart-product">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {wishlistData &&
        wishlistData.products &&
        wishlistData.products.length > 0 ? (
          <Empty />
        ) : (
          <>
            {wishlistData?.products?.map((item) => {
              return (
                <Col span={12} key={item.productId}>
                  <Card hoverable>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={12}>{/* ... */}</Col>
                      <Col span={10}>
                        <div className="product-info">
                          {/* ... */}
                          <div className="product-details">
                            <div className="price-quantity">
                              <p>Price: $ {item?.productPrices}</p>
                              <Select
                                defaultValue="1"
                                className="quantity-select"
                              >
                                {[...Array(10)].map((_, index) => (
                                  <Option
                                    key={index}
                                    value={(index + 1).toString()}
                                  >
                                    {index + 1}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                          <div className="action-buttons">{/* ... */}</div>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              );
            })}
          </>
        )}
      </Row>

      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {wishlistData?.products?.map((item) => {
          return (
            <>
              <Col span={12}>
                <Card hoverable>
                  {" "}
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12}>
                      <Image
                        src={GirlUniform}
                        alt="Product"
                        className="product-image"
                        style={{ width: "250px", height: "250px" }}
                      />
                    </Col>
                    <Col span={10}>
                      <div className="product-info">
                        <h3>{item.productName}</h3>
                        <p>
                          Description: Lorem ipsum dolor sit amet, consectetur
                          adipiscing elit.
                        </p>
                        <p>Seller : Eaglemount Company</p>
                        <h4>25% - OFF</h4>
                        <div className="product-details">
                          <div className="price-quantity">
                            <p>Price: $ {item?.productPrices}</p>
                            <Select
                              defaultValue="1"
                              className="quantity-select"
                            >
                              {[...Array(10)].map((_, index) => (
                                <Option
                                  key={index}
                                  value={(index + 1).toString()}
                                >
                                  {index + 1}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="action-buttons">
                          <Button
                            type="primary"
                            className="move-to-wishlist"
                            onClick={handleMovetoCart(item)}
                          >
                            Move to Cart
                          </Button>
                          <Button
                            type="danger"
                            className="remove-from-cart"
                            onClick={() => handleRemoveFromWishList(item)}
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </>
          );
        })}
      </Row> */}
    </div>
  );
};

export default CartWishlist;
