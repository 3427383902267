import { PostAPIRequest, GetAPIRequest } from "./Api";
import {
  REIMBURSEMENT_LIST,
  REIMBURSEMENT_CREATE,
  REIMBURSEMENT_EDIT,
  REIMBURSEMENT_EDIT_ENDPOINT,
} from "../Constants/constants";

const getreimbursementList = (staffId) => {
  return GetAPIRequest({ url: REIMBURSEMENT_LIST + staffId });
};

const createReimbursement = (payload) => {
  return PostAPIRequest({ url: REIMBURSEMENT_CREATE, data: payload });
};

const editReimbursement = (id, payload) => {
  return PostAPIRequest({
    url: REIMBURSEMENT_EDIT + id + REIMBURSEMENT_EDIT_ENDPOINT,
    data: payload,
  });
};

export const reimbursementService = {
  getreimbursementList,
  createReimbursement,
  editReimbursement,
};
