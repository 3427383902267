import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Space,
  Spin,
  Row,
  Col,
  notification,
} from "antd";
import {
  AuditOutlined,
  DislikeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./Assignment.scss";
import { dateWithTime, dynamicErrorMsg } from "../../Constants/utilitis";
import CreateAssignments from "./CreateAssignment/CreateAssignments";
import { AssignmentApiService } from "../../Services/AssignmentServices";
import SubmittedStudents from "./SubmittedStudents/SubmittedStudents";
import _ from "lodash";
import ClassDropdown from "../Common/Class/ClassDropdown";
import SectionDropdown from "../Common/Section/SectionDropdown";
import SubjectDropdown from "../Common/Subject/SubjectDropdown";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { FaUsers } from "react-icons/fa";
import Cookies from "js-cookie";
const Assignment = ({ data }) => {
  const [assignmentList, setAssignmentList] = useState([]);
  const [reloadAssignmentData, setReloadAssignmentData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [modalRowData, setModalRowData] = useState({});
  const [editStaffRow, setEditStaffRow] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitView, setSubmitView] = useState(false);
  const [assignmentRecord, setAssignmentRecord] = useState();
  const [sorting, setSorting] = useState({
    columnKey: "",
    order: "",
    searchItem: "",
  });

  const staffInfo = Cookies.get("staffInfo")
    ? JSON.parse(Cookies.get("staffInfo"))
    : [];
  const staffId = staffInfo?.staffId;

  useEffect(() => {
    handleSortingColumns();
  }, [sorting]);

  const assignmentLists = () => {
    setLoading(true);
    const params = {
      classId: selectedSection?.classId,
      subjectId: selectedSubject,
    };
    AssignmentApiService.assignmentList(staffId, params)
      .then((response) => {
        setAssignmentList(response?.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Assignment")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedSection && selectedSubject) {
      assignmentLists();
    }
  }, [selectedSection, selectedSubject]);

  const handleSortingColumns = (params = {}) => {
    const { columnKey, order, searchItem } = sorting;
    let sortOrder = "";
    let column = "";
    column = columnKey === "name" ? "firstName" : columnKey;
    sortOrder = order === "ascend" ? "ASC" : "DESC";
    params = {
      sortField: column,
      sortOrder: sortOrder,
      searchKey: searchItem,
      ...params,
    };
  };

  const onTableChange = (pagination, filters, sorter) => {
    setSorting({ columnKey: sorter.columnKey, order: sorter.order });
  };
  const handleButtonClick = (row) => {
    setModalRowData(row);
    setEditStaffRow(null);
    setIsModalVisible(true);
  };
  const showModal = () => {
    setIsModalVisible1(true);
  };
  const onClassChange = (value) => {
    setSelectedClass(value);
    setSelectedSubject("");
  };

  const onSectionChange = (value) => {
    setSelectedSection(value);
    // setSelectedSubject(null);
  };
  const onSubjectChange = (value) => {
    setSelectedSubject(value);
  };

  /*edit Assignment*/
  const handleEditAssignment = (row) => {
    setIsModalVisible1(true);
    setEditStaffRow(row);
  };

  const onHandleView = (record) => {
    setSubmitView(true);
    setAssignmentRecord(record);
  };

  const columns = [
    {
      title: "Assignment Name",
      dataIndex: "assignmentTitle",
      key: "assignment_name",
      align: "center",
      sorter: (a, b) => a.assignmentTitle.localeCompare(b.assignmentTitle),
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      align: "center",
      sorter: (a, b) => a.mode.localeCompare(b.mode),
    },
    {
      title: "Given Date",
      dataIndex: "givenDate",
      key: "givenDate",
      align: "center",
      sorter: (a, b) => {
        const dateA = new Date(a.givenDate);
        const dateB = new Date(b.givenDate);

        return dateA - dateB;
      },
      defaultSortOrder: "descend",
    },
    {
      title: "Review Status",
      dataIndex: "reviewStatus",
      key: "reviewStatus",
      align: "center",
      sorter: (a, b) => a.reviewStatus.localeCompare(b.reviewStatus),
    },
    {
      title: "Submitted Students",
      dataIndex: "submittedStudents",
      key: "submittedStudents",
      align: "center",
      render: (text, row) => {
        return (
          <Space>
            <Button
              onClick={(e) => onHandleView(row, e)}
              className="full-details-button"
            >
              <AuditOutlined />
            </Button>
          </Space>
        );
      },
    },

    {
      title: "Details",
      key: "details",
      dataIndex: "details",
      align: "center",
      render: (text, row) => (
        <Space>
          <Button
            onClick={(e) => handleButtonClick(row, e)}
            className="full-details-button"
          >
            <EyeOutlined />
          </Button>
          <Button
            className="full-details-button"
            onClick={(e) => {
              handleEditAssignment(row, e);
            }}
          >
            <EditOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      {!submitView ? (
        <article className="staff_assignment">
          <div className="assignments flex-sty">
            <div className="assignment flex-sty">
              <div className="dropdown-group">
                <h2 className="header-text ">Assignments</h2>
              </div>
            </div>

            <div className="rightheader">
              <div>
                <ClassDropdown
                  onClassChange={onClassChange}
                  selectedClass={selectedClass}
                />
              </div>
              <div>
                <SectionDropdown
                  classValue={selectedClass}
                  onSectionChange={onSectionChange}
                  // selectedSection={selectedSection}
                />
              </div>
              <div>
                <SubjectDropdown
                  selectedSubject={selectedSubject}
                  onSubjectChange={onSubjectChange}
                  selectedClass={selectedClass}
                  style={{ width: 200 }}
                />
              </div>
              <div className="create-btn">
                <Button className="annouce-btnModal" onClick={showModal}>
                  <PlusOutlined className="assignment-icon" />
                  Create Assignment
                </Button>

                <CreateAssignments
                  isModalVisible1={isModalVisible1}
                  setIsModalVisible1={setIsModalVisible1}
                  setEditStaffRow={setEditStaffRow}
                  editStaffRow={editStaffRow}
                  setReloadAssignmentData={setReloadAssignmentData}
                  modalRowData={modalRowData}
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  assignmentLists={assignmentLists}
                />
              </div>
            </div>
          </div>

          <div className="tables">
            {loading ? (
              <Spin spinning={loading} className="loading-spinner" />
            ) : (
              <Table
                dataSource={assignmentList}
                columns={columns}
                // onChange={onTableChange}
                showSorterTooltip={false}
                pagination={false}
              />
            )}
          </div>
        </article>
      ) : (
        <SubmittedStudents
          setSubmitView={setSubmitView}
          assignmentRecord={assignmentRecord}
          setAssignmentRecord={setAssignmentRecord}
        />
      )}
    </>
  );
};

export default Assignment;
