import { EDIT_STUDENT, EDIT_STUDENT_ENDPOINT } from "../Constants/constants"
import { PostAPIRequest } from "./Api"


export const editStudent = (studentId, payload) => {
    return PostAPIRequest({
        url:EDIT_STUDENT + studentId + EDIT_STUDENT_ENDPOINT,
        data:payload
    })
}

export const studentService = {
    editStudent
}