import React from "react";
import { BellOutlined, DislikeOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { Avatar } from "@mui/material";
import {
  Dropdown,
  Menu,
  Input,
  Button,
  Popover,
  Tooltip,
  Form,
  notification,
} from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { FaSun, FaMoon } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { notificationServices } from "../../Services/Notification";
import Notification from "./Notification";
import "./Topbar.scss";
import { useState } from "react";
import { useEffect } from "react";
import { InstituteService } from "../../Services/InstituteService";
import { staffServices } from "../../Services/StaffServices";
import { dynamicErrorMsg } from "../../Constants/utilitis";

const Topbar = ({ logOut }) => {
  const [instituteCode, setInstituteCode] = useState(
    Cookies.get("branchCode") ? Cookies.get("branchCode") : null
  );
  const [staffId, setStaffId] = useState(
    Cookies.get("userId") ? Cookies.get("userId") : null
  );
  const [darkMode, setDarkMode] = useState(false);
  const [instituteData, setInstituteData] = useState([]);
  const [staffDetails, setStaffDetails] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const navigate = useNavigate();
  const [logoImage, setLogoImage] = useState(null);
  const [instLogoForm] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [staffData, setStaffData] = useState([]);

  const getNotificatificationList = () => {
    const params = {
      userType: "Staff",
    };
    notificationServices
      .getNotification(staffId, params)
      .then((res) => {
        setNotificationData(res?.data);
      })
      .catch((error) =>{
        const errorMessage = dynamicErrorMsg(error, "Get", "Notification")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getNotificatificationList();
  }, []);

  const handleSignOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    logOut();
  };

  const handlePopoverVisibleChange = (visible) => {
    setPopoverVisible(visible);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => navigate("/profile")}>
        Profile
      </Menu.Item>
      <Menu.Item key="1" onClick={handleSignOut}>
        Log Out
      </Menu.Item>
    </Menu>
  );

  const getStaffDetails = () => {
    staffServices
      .findStaffDetails(staffId)
      .then((response) => {
        setStaffDetails(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Staff Details")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    if (staffId) {
      getStaffDetails();
    }
  }, [staffId]);

  const getInstituteDetails = () => {
    const params = {
      branchCode: instituteCode,
    };
    InstituteService.getInstituteInfo(params)
      .then((response) => {
        setInstituteData(response.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (instituteCode !== undefined && instituteCode !== null) {
      getInstituteDetails();
    }
  }, [instituteCode]);

  const handleDarkModeToggle = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    sessionStorage.setItem("darkmode", newDarkMode.toString());
  };

  useEffect(() => {
    // Check if data is available in cookies
    const subjectsInfo = Cookies.get("subjectsInfo");
    const classInfo = Cookies.get("classInfo");
    const staffInfo = Cookies.get("staffInfo");

    if ((staffId !== null && !subjectsInfo) || !classInfo || !staffInfo) {
      // Data is not available in cookies, fetch from API
      staffServices
        .findStaffDetails(staffId)
        .then((res) => {
          setStaffData(res.data);

          const { subjectsInfo, classesInfo, ...staffData } = res?.data;

          Cookies.set("subjectsInfo", JSON.stringify(subjectsInfo), {
            expires: 30,
          });
          Cookies.set("classInfo", JSON.stringify(classesInfo), {
            expires: 30,
          });
          Cookies.set("staffInfo", JSON.stringify(staffData), {
            expires: 30,
          });
        })
        .catch((error) => {
          const errorMessage = dynamicErrorMsg(error, "Get", "Staff Details")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    }
  }, [staffId]);

  useEffect(() => {
    const initialDarkMode = sessionStorage.getItem("darkmode") === "true";
    setDarkMode(initialDarkMode);

    if (initialDarkMode) {
      document.body.classList.add("darkmode");
    } else {
      document.body.classList.remove("darkmode");
    }
  }, [darkMode]);

  const handleUploadChange = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFileList([file]);
      setLogoImage(URL.createObjectURL(file));
      onfinish({ fileList: [file] });
    }
  };

  const onfinish = (values) => {
    const { fileList } = values;
    const formData = new FormData();

    fileList.forEach((file) => {
      formData.append(`profilePic`, file);
    });
    const payload = formData;

    if (fileList.length > 0) {
      staffServices
        .staffProfileUpload(staffId, payload)
        .then((res) => {
          instLogoForm.resetFields();
          setUploading(false);
          if (res.status === 200) {
            notification.success({
              description: `Profile Picture Sucessfully`,
              icon: (
                <CheckCircleFilled
                  style={{
                    color: "green",
                  }}
                />
              ),
              duration: 3,
              placement: "bottomRight",
            });
          }
        })
        .catch((error) => {
          setUploading(false);
          const errorMessage = dynamicErrorMsg(error, "Save", "Profile Picture")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
        });
    } else {
      setUploading(false);
    }
  };

  const handleImageClick = () => {
    const uploadInput = document.getElementById("uploadInput");
    if (uploadInput) {
      uploadInput.click();
    }
  };

  // const handleLogout = () => {
  //   localStorage.removeItem("accessToken");
  //   logOut();
  //   navigate("/login");
  //   localStorage.clear()
  //   Cookies.clear()
  //   sessionStorage.clear()
  // };
  const buttonStyle = {
    backgroundColor: popoverVisible ? "#172b4d" : "white",
    border: "none",
    borderRadius: "50%",
    color: popoverVisible ? "white" : "black",
    paddingBottom: 2,
  };

  return (
    <div className="topbar-components-wrapper">
      <navbar className="topbar div-sty flex-sty">
        <div className="topbar-logo">
          <img src={instituteData.instLogo} alt="institute_image" />
          <h4>{instituteData?.instituteName}</h4>
        </div>
        <div className="topbar-icons flex-sty">
          {/* <div className="">
            <Input.Search placeholder="Search...." />
          </div> */}
          <div
            className="bell exam-cirlce none flexBox-sty"
            onClick={handleDarkModeToggle}
          >
            {/* <button>
              {darkMode ? (
                <FaSun className="bell-icon" />
              ) : (
                <FaMoon className="bell-icon" />
              )}
            </button> */}
          </div>
          <div className="bell none flexBox-sty">
            <Tooltip title="Notifications" style={{ zIndex: 999 }}>
              <Popover
                open={popoverVisible}
                onVisibleChange={handlePopoverVisibleChange}
                content={
                  <Notification
                    notificationData={notificationData}
                    getNotificatificationList={getNotificatificationList}
                  />
                }
                trigger="click"
                placement="bottom"
                width={700}
                title="Notification"
              >
                <Button
                  style={buttonStyle}
                  icon={<BellOutlined style={{ fontSize: "25px" }} />}
                />
              </Popover>
            </Tooltip>
          </div>
          <span className="admin-sp">
            Hello , {staffDetails?.name?.split(" ")[0]}{" "}
          </span>
          <div className=" exam-cirlce none flexBox-sty">
            <Dropdown overlay={menu} trigger={["hover"]}>
              <Avatar
                src={staffDetails ? staffDetails?.profilePicUrl : ""}
                alt="user"
              />
            </Dropdown>
          </div>
        </div>
      </navbar>
    </div>
  );
};

export default Topbar;
