import {
  LeftOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  DislikeOutlined,
  FilePdfOutlined,
  LikeTwoTone,
  CheckCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Row,
  Col,
  Radio,
  Input,
  notification,
  Modal,
  Form,
  Space,
} from "antd";
import { AssignmentApiService } from "../../../Services/AssignmentServices";
import { createErrorMessage, updateErrorMessage, deleteErrorMessage, dynamicErrorMsg } from "../../../Constants/utilitis";

import InputFloatLabel from "../../Common/FloatLabel/Input";
import { useState } from "react";
import { useEffect } from "react";
import "./SubmittedStudents.scss";
import Cookies from "js-cookie";
import axios from "axios";
import FileView from "../../../Utils/FileView";
const SubmittedStudents = ({
  setSubmitView,
  assignmentRecord,
  setAssignmentRecord,
}) => {
  const [submitedStudents, setSubmitedStudents] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("total");
  const [openScoreCardModal, setOpenScoreCardModal] = useState(false);
  const [editedScore, setEditedScore] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [assignmentId, setAssignmentId] = useState(null);
  const [StudentIdS, setStudentIds] = useState("");
  const [documentUrls, setDocumentUrls] = useState([]);
  const [isOpenpdfModal, setIsOpenpdfModal] = useState(false);
  const [selectedPdfUrl, setSelectedPdfUrl] = useState("");
  const [edited, setEdited] = useState(null);
  const [heading, setHeading] = useState(null);
  const [fileData, setFileData] = useState(null);
  const [view, setView] = useState(false);
  const [scoreUpdateForm] = Form.useForm();
  const onHandleBack = () => {
    setSubmitView(false);
  };
  const accessToken = Cookies.get("accessToken");
  const getSubmitedStudents = () => {
    AssignmentApiService.submitedAssignments(assignmentRecord?.assignmentId)
      .then((response) => {
        setSubmitedStudents(response.data);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Submit", "Assignment")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getSubmitedStudents();
  }, [assignmentRecord]);

  const submittedStudentsInfo = submitedStudents?.submittedStudentsInfo;
  let filteredStudentsInfo = [];

  if (activeTabKey === "total") {
    filteredStudentsInfo = submittedStudentsInfo;
  } else if (activeTabKey === "completed") {
    filteredStudentsInfo = submittedStudentsInfo?.filter(
      (student) => student.status === "COMPLETED"
    );
  } else if (activeTabKey === "pending") {
    filteredStudentsInfo = submittedStudentsInfo?.filter(
      (student) => student.status === "PENDING"
    );
  }

  const handleTabChange = (event) => {
    setActiveTabKey(event.target.value);
  };

  const handleEditScore = (item) => {
    setStudentIds(item);
    setEdited(item?.studentId);
  };

  const onFinish = (values) => {
    const payload = {
      assignmentId: assignmentRecord?.assignmentId,
      studentId: StudentIdS?.studentId,
      achivedScore: parseInt(values?.achivedScore) || StudentIdS?.achievedScore,
    };

    AssignmentApiService.updateStudentAssignmentScore(payload)
      .then((response) => {
        if (response.status === 200) {
          setEdited(null);
          getSubmitedStudents();
          setOpenScoreCardModal(false);
          scoreUpdateForm.resetFields();
          notification.success({
            message: `Student Score Updated Successfully`,
            placement: "bottomRight",
            icon: (
              <CheckCircleFilled
                style={{
                  color: "green",
                }}
              />
            ),
          });
        }
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Update", "Assignment Score")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  const handleCancel = () => {
    setEdited(null);
    setOpenScoreCardModal(false);
    setAssignmentRecord(null);
    scoreUpdateForm.resetFields();
  };
  const handleViewDocuments = (item) => {
    setIsOpenpdfModal(true);
    setDocumentUrls(item?.docUrl);
    setView(true);
    setFileData(item?.docUrl);

    setHeading(item.studentName);
  };

  const handlePdfDownload = async (url) => {
    try {
      const response = await axios.get(
        `https://api.edpedia.co/v1.0/instAdmin/api/storage/download?url=${url}`,
        {
          responseType: "blob",
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "assignment.pdf";
      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      notification.success({
        message: "Assignment Downloaded Failed, try again later",
        placement: "bottomRight",
        icon: (
          <DislikeOutlined
            style={{
              color: "red",
            }}
          />
        ),
      });
    }
  };

  return (
    <div className="submitstudent-wrapper">
      <div className="backbtn">
        <Button
          className="goback_button"
          type="primary"
          htmlType="submit"
          onClick={onHandleBack}
        >
          <LeftOutlined />
        </Button>
        {/* <div> */}
        <Radio.Group
          onChange={handleTabChange}
          value={activeTabKey}
          optionType="button"
          buttonStyle="solid"
          style={{ marginRight: 20, marginLeft: 20 }}
        >
          <Radio.Button value="total">Total</Radio.Button>
          <Radio.Button value="completed">Completed</Radio.Button>
          <Radio.Button value="pending">Pending</Radio.Button>
        </Radio.Group>
        {/* </div> */}

        {/* <div style={{ float: "right", marginTop: "-80px" }}> */}
        <Button.Group>
          <Button style={{ backgroundColor: "#172b4d", color: "white" }}>
            Total
          </Button>
          <Button
            style={{
              backgroundColor: "white",
              color: "black",
              marginRight: 10,
              fontWeight: "bold",
            }}
          >
            {submitedStudents?.totalStudents}
          </Button>
        </Button.Group>
        <Button.Group>
          <Button style={{ backgroundColor: "green", color: "white" }}>
            Submitted
          </Button>
          <Button
            style={{
              backgroundColor: "white",
              color: "black",
              marginRight: 10,
              fontWeight: "bold",
            }}
          >
            {submitedStudents?.submittedStudents}
          </Button>
        </Button.Group>
        <Button.Group>
          <Button style={{ backgroundColor: "#aa0b28", color: "white" }}>
            Reamining
          </Button>
          <Button
            style={{
              backgroundColor: "white",
              color: "black",
              marginRight: 10,
              fontWeight: "bold",
            }}
          >
            {submitedStudents?.remainingStudents}
          </Button>
        </Button.Group>
        {/* </div> */}
      </div>
      <Row>
        {filteredStudentsInfo?.map((item) => {
          const submitDate = item.submittedDate.split(" ")[0];
          const submitTime = item.submittedDate.split(" ")[1];
          return (
            <Col span={6}>
              <div className="studendcard-main">
                <div className="student-card" key={item.studentId}>
                  {activeTabKey === "total" && (
                    <small className="small-tags">{item.status}</small>
                  )}
                  <span className="studentname">{item.studentName}</span>
                   <span className="studentscore">
                    Score:{" "}
                    {item.studentId === edited ? (
                      <>
                        <Form
                          name="ScoreForm"
                          onFinish={onFinish}
                          // onFinishFailed={onFinishFailed}
                          form={scoreUpdateForm}
                        >
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                              <Form.Item name="achivedScore">
                                <Input
                                  className="float-inputs"
                                  // label="Achived Score"
                                  defaultValue={item?.achievedScore}
                                  type="number"
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Button
                                htmlType="submit"
                                style={{ border: "none" }}
                              >
                                <SaveOutlined /> 
                              </Button>
                            </Col>
                            <Col span={4}>
                              <Button
                                onClick={handleCancel}
                                style={{ border: "none" }}
                              >
                                <CloseOutlined
                                  style={{ backgroundColor: "red" }}
                                />
                              </Button>
                            </Col>
                          </Row>
                          {/* <Row
                            className="my-row"
                            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                          >
                           
                           
                          </Row> */}
                        </Form>
                      </>
                    ) : (
                      <>{item?.achievedScore}</>
                    )}
                  </span>
                  
                  <span className="studentdate">Submitted Date : {submitDate}</span>
                  <span className="studenttime">Time : {submitTime}</span>
                 
                  {edited === null && (
                    <div className="f-button"
                    >
                     <div className="submit-button ">
                     <EditOutlined
                        onClick={() => handleEditScore(item)}
                        className="edit-score"
                      /> Save
                     </div>
                     <div className="cancel-button">
                     <FilePdfOutlined
                        className="view-doc"
                        onClick={() => handleViewDocuments(item)}
                        View
                        Pdf
                      /> View
                     </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />
    </div>
  );
};

export default SubmittedStudents;
