import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Space,
  Row,
  Col,
  Spin,
  message,
  notification,
} from "antd";
import "./StudyMaterial.scss";
import {
  DislikeOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  FolderOpenOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import ClassDropdown from "../Common/Class/ClassDropdown";
import SectionDropdown from "../Common/Section/SectionDropdown";
import SubjectDropdown from "../Common/Subject/SubjectDropdown";
import CreateStudyMaterial from "./CreateStudyMaterial/CreateStudyMaterial";
import { studyMaterialApiService } from "../../Services/StudyMaterials";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import axios from "axios";
import FileView from "../../Utils/FileView";
const StudyMaterial = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [studyModalDetails, setStudyModalDetails] = useState(false);
  const [editStaffRow, setEditStaffRow] = useState(null);
  const [studyMaterialList, setStudyMaterialList] = useState([]);
  const [viewData, setViewData] = useState({});
  const [loading, setLoading] = useState(true);
  const [fileData, setFileData] = useState(null);
  const [view, setView] = useState(false);
  const [heading, setHeading] = useState(null);

  const subjectsInfo = Cookies.get("subjectsInfo")
    ? JSON.parse(Cookies.get("subjectsInfo"))
    : [];
  const classInfo = Cookies.get("classInfo")
    ? JSON.parse(Cookies.get("classInfo"))
    : [];

  // const [classAndSubject, setClassAndSubject] = useState({
  //   firstClassId: classInfo[0]?.classId,
  // });

  useEffect(() => {
    if (selectedSection && selectedSubject) {
      loadStudymaterialData();
    }
  }, [selectedSection, selectedSubject]);

  const loadStudymaterialData = () => {
    const params = {
      classId: selectedSection?.classId,
      subjectId: selectedSubject,
    };

    studyMaterialApiService
      .getStudyMaterialist(params)
      .then((res) => {
        setStudyMaterialList(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addstudy = () => {
    setIsOpenModal(true);
  };
  const onClassChange = (value) => {
    setSelectedClass(value);
    setSelectedSubject("");
  };

  const onSectionChange = (value) => {
    setSelectedSection(value);
  };
  const onSubjectChange = (value) => {
    setSelectedSubject(value);
  };

  const handleModalCancel = () => {
    setStudyModalDetails(false);
  };
  const handleAdd = (row) => {
    studyMaterialApiService
      .findStudyMaterial(row.id)
      .then((res) => {
        setViewData(res.data);
        setEditStaffRow(null);
        setStudyModalDetails(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditStudyMaterial = (row) => {
    setEditStaffRow(row);
    setIsOpenModal(true);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "bookTitle",
      key: "bookTitle",
      align: "center",
      sorter: (a, b) => a.bookTitle.localeCompare(b.bookTitle),
      defaultSortOrder: "ascend",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",

      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "center",

      render: (text, row) => (
        <Space>
          <EyeOutlined
            className="full-details-button"
            onClick={() => handleAdd(row)}
          />

          <EditOutlined
            className="full-details-button"
            onClick={(e) => {
              handleEditStudyMaterial(row, e);
            }}
          />
        </Space>
      ),
    },
  ];

  const datas = [
    {
      field: "Class Name",
      value:
        classInfo.find((c) => c.classId == viewData.classId)?.className ||
        "N/A",
    },
    {
      field: "Section",
      value:
        classInfo.find((c) => c.classId == viewData.classId)?.section || "N/A",
    },
    {
      field: "Subject Name",
      value:
        subjectsInfo.find((c) => c.subjectId == viewData.subjectId)
          ?.subjectName || "N/A",
    },
    {
      field: "Description",
      value: viewData?.description,
    },
    {
      field: "Search Keywords",
      value: viewData?.searchKeyWords,
    },
    {
      field: "Book Title",
      value: viewData.bookTitle,
    },
    {
      field: "Author",
      value: viewData?.author,
    },
    {
      field: "Study Material",
      value: viewData?.staffStudyMaterialUrls ? (
        <FolderOpenOutlined
          style={{
            fontSize: "30px",
            color: "black",
            cursor: "pointer",
          }}
          onClick={() => {
            downloadStudymaterial(viewData);
          }}
        />
      ) : (
        "N/A"
      ),
    },
  ];

  const accessToken = Cookies.get("accessToken");
  //download apicall
  const downloadStudymaterial = (record) => {
    const sub = subjectsInfo.find(
      (c) => c.subjectId == record.subjectId
    )?.subjectName;
    setView(true);
    setFileData(record.staffStudyMaterialUrls);
    setHeading(`${sub} subject`);
  };

  const viewcColumns = [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  return (
    <article className="staff-study-material">
      <div className="staff-header">
        <h2 className="header-text">Study Material</h2>
        <div className="rightheader">
          <ClassDropdown
            onClassChange={onClassChange}
            selectedClass={selectedClass}
          />
          <SectionDropdown
            classValue={selectedClass}
            onSectionChange={onSectionChange}
            // selectedSection={selectedSection}
          />
          <SubjectDropdown
            selectedSubject={selectedSubject}
            onSubjectChange={onSubjectChange}
            selectedClass={selectedClass}
          />

          <div className="create-studymaterial">
            <Button
              className="flex-sty upload-study-button"
              onClick={() => addstudy()}
            >
              <PlusOutlined className="assignment-icon" />
              Upload Study Material
            </Button>
          </div>
        </div>
      </div>

      <div className="assignments flex-sty"></div>
      <CreateStudyMaterial
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        setEditStaffRow={setEditStaffRow}
        editStaffRow={editStaffRow}
        loadStudymaterialData={loadStudymaterialData}
      />

      <div className="tables">
        <Table
          dataSource={studyMaterialList}
          columns={columns}
          showSorterTooltip={false}
          pagination={false}
        />
      </div>
      <Modal
        open={studyModalDetails}
        title="Study Material Details"
        onCancel={handleModalCancel}
        footer={null}
        width={600}
        centered
        className="view-assignment"
      >
        {viewData && (
          <Table
            columns={viewcColumns}
            dataSource={datas}
            pagination={false}
            showHeader={false}
          />
        )}
      </Modal>
      <FileView
        view={view}
        setView={setView}
        fileData={fileData}
        heading={heading}
      />
    </article>
  );
};

export default StudyMaterial;
