import React, { useEffect, useState } from "react";
import EmptyComponent from "../../../Common/EmptyComponent";
import { LibraryService } from "../../../../Services/LibraryService";
import { notification } from "antd";
import { DislikeOutlined } from "@ant-design/icons";
import { dynamicErrorMsg } from "../../../../Constants/utilitis";

const MissingBooks = () => {
  const [missingBooks, setMissingBooks] = useState(null);

  const getBooksHistory = () => {
    const params = {
      userId: 3,
      userType: "Staff",
    };
    LibraryService.getBookHistory(params)
      .then((response) => {
        setMissingBooks(response?.data?.missing);
      })
      .catch((error) => {
        const errorMessage = dynamicErrorMsg(error, "Get", "Books")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      });
  };

  useEffect(() => {
    getBooksHistory();
  }, []);
  return (
    <div className="rent-cards-header">
      {missingBooks === null ? (
        <>
          <EmptyComponent />
        </>
      ) : (
        <>
          {missingBooks &&
            missingBooks.map((rental) => {
              const { book } = rental;
              return (
                <div key={rental.rentalId} className="rent-card" style={{marginLeft:10}}>
                  <div className="left-side">
                    <img
                      alt="img"
                      src={book.image}
                    />
                  </div>
                  <div className="right-side">
                    <h4>{book?.bookName}</h4>
                    <p>{book.publisher}</p>
                    <p>Fine Amount: {rental.fineAmount}</p>
                    <p>Rent Date: {rental.rentalDate}</p>
                    <p>Due Date: {rental.dueDate}</p>
                  </div>
                </div>
              );
            })}
        </>
      )}
    </div>
  );
};

export default MissingBooks;
