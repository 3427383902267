import { Select } from "antd";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
const { Option } = Select;
function SectionDropdown({
  classValue,
  onSectionChange,
  selectedSection = null,
}) {
  const [Section, setSection] = useState(null);
  const classInfo = Cookies.get("classInfo")
    ? JSON.parse(Cookies.get("classInfo"))
    : [];

  const classData = classInfo
    .slice()
    .sort((a, b) => parseInt(a.classId) - parseInt(b.classId));

  let filteredSection = [];
  if (classData) {
    filteredSection = classData?.filter(
      (item) => item.className === classValue
    );
  }

  const getSectionDetails = (value) => {
    let sections = classData?.filter(
      (x) => x.className === classValue && x.section === value
    );
    if (sections.length > 0) {
      onSectionChange({
        classId: sections[0].classId,
        section: sections[0].section,
      });
    }
  };

  useEffect(() => {
    const initialSection =
      selectedSection ||
      (filteredSection.length > 0 ? filteredSection[0].section : undefined);
    setSection(initialSection);
    getSectionDetails(initialSection);
  }, [classValue]);

  return (
    <Select
      value={Section}
      onChange={(value) => {
        setSection(value);
        getSectionDetails(value);
      }}
      placeholder="Section"
      style={{ width: "100px" }}
    >
      {classValue !== null &&
        filteredSection.map((option) => (
          <Option key={option.classId} value={option.section}>
            {option.section}
          </Option>
        ))}
    </Select>
  );
}

export default SectionDropdown;
