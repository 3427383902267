import { Button, Col, Form, Modal, notification, Row, Select, Space } from "antd";
import InputFloatLabel from "../Common/FloatLabel/Input";
import DatepickerFloatLabel from "../Common/FloatLabel/DatePicker";
import SelectFloatLabel from "../Common/FloatLabel/Select";
import { useEffect, useState } from "react";
import { HomeworkService } from "../../Services/HomeworkService";
import Cookies from "js-cookie";
import _ from "lodash";
import dayjs from "dayjs";
import { Option } from "antd/es/mentions";
import { CheckCircleFilled, DislikeOutlined } from "@ant-design/icons";
import { dynamicErrorMsg } from "../../Constants/utilitis";
import { staffServices } from "../../Services/StaffServices";
import SubjectDropdown from "../Common/Subject/SubjectDropdown";
const CreateHomework = ({
  setIsModalVisible1,
  isModalVisible1,
  seteditRow,
  editRow,
  getHomeworks,
  selectedSectionn,
  selectedStaff
}) => {
  const [HomeworkForm] = Form.useForm();
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [classOptions, setClassOptions] = useState(null);
  const [selectedSection, setSelectedSection] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [sectionOptions, setSctionOptions] = useState([]);
  const [defaultSubject, setDefaultSubject] = useState(null);
  const subjectsInfo = Cookies.get("subjectsInfo")
    ? JSON.parse(Cookies.get("subjectsInfo"))
    : [];
  const classInfo = Cookies.get("classInfo")
    ? JSON.parse(Cookies.get("classInfo"))
    : [];
  useEffect(() => {
    const uniqueClasses = _.uniqBy(classInfo, "className").map((getClass) => {
      return { label: getClass.className, value: getClass.className };
    });
    setClassOptions(uniqueClasses);
  }, []);
  const handleClassDropdown = (e) => {
    setSelectedSection([]);
    setSelectedSubject([]);

    const sectionOptions = getsectionOptionsFromClass(e, classInfo);

    const uniqueSubjectIds = subjectsInfo.filter(
      (subject) => subject.classId == sectionOptions?.classId
    );
    // Filter sectionOptions based on uniqueSubjectIds
    const filteredSectionOptions = sectionOptions.filter((section) =>
      uniqueSubjectIds.includes(section.value)
    );

    if (Array.isArray(sectionOptions)) {
      const sort = sectionOptions.sort((a, b) => {
        if (a.label && b.label) {
          return a.label.localeCompare(b.label);
        } else {
          return 0;
        }
      });

      setSctionOptions(sort);
    } else {
      setSctionOptions([]);
    }
  };
  // useEffect(() => {
  //   TeachingSubjectsData()
  // },[HomeworkForm, selectedSection])

  // const TeachingSubjectsData = () => {
  //   staffServices
  //     .teachingSubjectList(selectedStaff)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setSubjectOptions(response.data);
  //         setIsModalVisible1(false)
  //         HomeworkForm.resetFields()
  //       }
  //     })
  //     .catch((error) => {
  //       const errorMessage = dynamicErrorMsg(error, "Get", "Teaching Subjects")
  //     });
  // };
  const getsectionOptionsFromClass = (className, classList) => {
    const filteredSection = classList?.filter(
      (item) => item.className === className
    );

    const getSection = filteredSection?.map((item) => {
      return {
        label: item.section,
        value: item.classId,
      };
    });
    return getSection ?? [];
  };
  const handleSectionChange = (val) => {
    setSelectedSection(val);

    const subjects = getSubjectsByClassName(val, classInfo, subjectsInfo);

    setSubjectOptions(subjects);
  };
  const handleCancel = () => {
    setIsModalVisible1(false);
  };
  const handleSubject = (val) => {
    setSelectedSubject(val);
  };
  useEffect(() => {
    const classWithAssignment = classInfo?.filter(
      (item) => parseInt(item?.classId) == editRow?.classId
    );
    console.log('classWithAssignment', classWithAssignment)
    const subjectWithAssignment = subjectsInfo?.filter(
      (item) =>
        parseInt(item?.subjectId) === editRow?.subjectId &&
        parseInt(item?.classId) === editRow?.classId
    );
    setSelectedSubject(subjectWithAssignment[0]?.subjectId);

    const subjects = getSubjectsByClassName(
      classWithAssignment[0]?.className,
      classInfo,
      subjectsInfo
    );

    setSubjectOptions(subjects);
    const givenDates =
      editRow?.date === undefined
        ? dayjs(new Date(), "YYYY-MM-DD")
        : dayjs(editRow?.date, "YYYY-MM-DD");
    setSelectedSection(classWithAssignment[0]?.section);

    if (editRow?.subjectId) {
      HomeworkForm.setFieldsValue({
        className: classWithAssignment[0]?.className,
        date: givenDates,
        subject: editRow?.subject,
        classId: selectedSectionn,
        task: editRow?.task,
      });
    } else {
      HomeworkForm.resetFields();
    }
  }, [editRow, HomeworkForm]);

  function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function getSubjectsByClassName(classID, classList, subjectList) {
    var classId;
    for (var i = 0; i < classList.length; i++) {
      if (classList[i].classId == classID) {
        classId = classList[i].classId;
        break;
      }
    }
    var matchingSubjects = [];
    for (var i = 0; i < subjectList.length; i++) {
      if (subjectList[i].classId === classId) {
        matchingSubjects.push({
          label: capitalize(subjectList[i].subjectName),
          value: subjectList[i].subjectId,
        });
      }
    }

    return matchingSubjects;
  }

  const onFinish = (values) => {
    console.log("values are here", values);
    console.log('section', selectedSection)
    console.log('subject', selectedSubject)
    const dateObject = new Date(values?.date);
    const today = dateObject.toISOString().split("T")[0];
    const dayOfWeek = dateObject.getDay();
    console.log('today', today)
    const payload = {
      ...values,
      staffId: selectedStaff
    }
    const params = {
      date: today
    }
    console.log('payload', payload)
    HomeworkService.createHomework(payload, params)
      .then(response => {
        console.log('response', response)
        notification.success({
          message: "Homework Created Successfully",
          icon: (
            <CheckCircleFilled
              style={{
                color: "green",
              }}
            />
          ),
          duration: 3,
          placement: "bottomRight",
        });
        getHomeworks()

      }).catch(error => {
        console.log('error', error)
        const errorMessage = dynamicErrorMsg(error,"Create", "Homework")
        notification.error({
          message: errorMessage,
          placement: "bottomRight",
          icon: <DislikeOutlined style={{ color: "red" }} />,
        });
      })
  };

  console.log('aaaa', selectedSubject)
  return (
    <Modal
      title={editRow ? "Create Homework " : "Edit Homework"}
      open={isModalVisible1}
      onCancel={handleCancel}
      footer={null}
    >
      <Form form={HomeworkForm} onFinish={onFinish} autoComplete="off">
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <DatepickerFloatLabel style={{ width: "100%" }} label="Date" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              // name="className"
              rules={[{ required: true, message: "" }]}
            >
              <SelectFloatLabel
                dropdownStyle={{ height: "200px" }}
                options={classOptions}
                onChange={handleClassDropdown}
                label="Select Class"
                name="className"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              rules={[{ required: true, message: "" }]}
              name="classId"
            >
              <SelectFloatLabel
                onChange={handleSectionChange}
                value={selectedSection}
                options={sectionOptions}
                // name="section"
                label="Select Section"
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item name="subjectId" rules={[{ required: true, message: "" }]}>
              <Select
                // style={{ height: 40, width: "310px" }}
                value={selectedSubject}
                onChange={handleSubject}
                placeholder="Subject"
              >
                {subjectOptions?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
              {/* <SubjectDropdown
              selectedSubject={selectedSubject}
              onSubjectChange={handleSectionChange}
              selectedClass={selectedSection}
              style={{ width: 200 }}
            /> */}
            </Form.Item>
            <h1>{defaultSubject}</h1>
          </Col>
          <Col span={24}>
            <Form.Item name="task" rules={[{ required: true, message: "" }]}>
              <InputFloatLabel label="Enter Task" type="text" name="task" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12} align="end">
            <Space direction="vertical" size="large">
              <Button className="blue-button" type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Col>
          <Col span={12} size="large">
            <Space direction="vertical" size="large">
              <Button className="newCancelButton " onClick={handleCancel}>
                Cancel
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
export default CreateHomework;
